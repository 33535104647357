import _, { find, isArray, size } from "lodash";
import React, {
  Fragment,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import InfoCircle from "../../../../assets/media/icons/other_icons/info.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import FormDatePickerField from "../../../../components/formComponent/FormDatePickerField";
import PriceField from "../../../../components/formComponent/PriceField";
import TableDropdown from "../../../../components/tableComponent/TableDropdown";
import TableMultiDropdown from "../../../../components/tableComponent/TableMultiDropdown";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
// import LockIconOpen from "../../../../assets/media/icons/other_icons/lock-open-solid.svg";
// import LockIcon from "../../../../assets/media/icons/other_icons/lock-solid.svg";
import handCross from "../../../../assets/media/icons/other_icons/cross-hand-icon.svg";
import handIcon from "../../../../assets/media/icons/other_icons/hand-icon.svg";
import logIcon from "../../../../assets/media/icons/other_icons/logSvg.svg";
import {
  colorForMeter,
  crc32,
  decimalNumber,
  firstLetterCaptital,
  handleKeyDown,
  handleShift,
  isDateBeforeValidLogDate,
  priceCalculation,
  removeSelectAll,
  tooltipForListingMeter,
  uniqueByNameWithPriority,
} from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import {
  INVENTORY_VALIDATE_COLUMN,
  deliveryTypeOrders,
} from "../../../../helpers/const";
import { TicketError } from "../../../add_inventory/core/_functions";
import useDebounce from "../../../add_inventory/core/useDebounce";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import { InventoryContext } from "../../core/InventoryProvider";
import {
  getFieldErrorColumns,
  priceFieldInputKeyDown,
  secureStateWhileTabbing,
  setEventRecordPayload,
  visibilityMeterCalculationForInventory,
  visiblityMeterForBelowListing,
} from "../../core/_functions";
import { UpdateInventory } from "../../core/_request";

const EventField = memo(
  ({
    // layout,
    // currentIndex,
    setCurrentIndex,
    handleCurrentIndex,
    event,
    eIndex,
    tempEventRecord,
    isTableEditable,
    list,
    setList,
    forRowDisabled,
    rIndex,
    firstSelectedIndex,
    matchIndexView,
    disabledAll,
    getColumnItems,
    setActiveField,
    //MemoizedHandleTableField,
    paperTypeExist,
    seletedDeliveryTypes,
    Warning1,
    subTicketType,
    //cancelRecord,
    Cross,
    //updateRecord,
    Check,
    handleEticket,
    setIsOpenTicketPopUp,
    display,
    tableRefSide,
    setEditData,
    Upload,
    handleListingAction,
    HaxPublishGray,
    unpublish,
    resetPPEValues,
    Refresh,
    closePPEValues,
    confirmPPEValues,
    Messages,
    editData,
    updateVisibility,
    setIsOpenListingQualityPopup,
  }: any) => {
    const layout = useContext(InventoryContext);
    const [eventRecord, setEventRecord] = useState(tempEventRecord);
    // const [currentIndex, setCurrentIndex] = useState({
    //   rIndex: "",
    //   eIndex: "",
    // });
    const [updateChecker, setUpdateChecker] = useState<any>();
    let isTableEditableLength: any = layout.isTableEditable.length;
    const globalLayout = useContext(LayoutContext);
    const teamOptionsData = globalLayout?.allowedAccess?.teams;
    const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
    let pngImagePath = "/media/png/";
    const [priceFiledFocused, setPriceFieldFocused] = useState<any>();
    const [priceFiledFocusedSticky, setPriceFieldFocusedSticky] =
      useState<any>();

    const conversation_rate = globalLayout?.conversationRate;
    const priceFactor = Number(
      globalLayout?.allowedAccess?.price_suggestion_factor
    );

    let isLoaderActive = list?.reduce((count: any, event: any) => {
      const selectedCount = event?.eventRecords?.filter(
        (record: any) => record?.loader
      );
      return count + (selectedCount ? selectedCount.length : 0);
    }, 0);

    useEffect(() => {
      // console.log(isLoaderActive);
      if (isLoaderActive === 0) {
        // console.log('ji');
        layout?.setUpdateAllLoader(false);
      }
    }, [isLoaderActive]);

    // useEffect(() => {
    //   //console.log(layout.refreshList, 'layout.refreshList')
    //   if (layout.refreshList) {
    //     console.log(tempEventRecord, "tempEventRecord");
    //     console.log(list, "list");
    //     setEventRecord(tempEventRecord);
    //   }
    // }, [layout.refreshList]);

    const tempEventRecordMemo = useMemo(() => {
      return tempEventRecord;
    }, [tempEventRecord]);

    useEffect(() => {
      //console.log(layout.refreshList, 'layout.refreshList')
      if (layout.refreshList || tempEventRecordMemo) {
        setEventRecord(tempEventRecordMemo);
      }
    }, [tempEventRecordMemo, layout.refreshList]);

    //CANCEL EVENT RECORD
    const cancelRecord = (currentEventRecord: any) => {
      let tempErecord = {
        ...eventRecord,
        ...eventRecord?.replacible,
        isInputChange: false,
        loader: false,
        selected: false,
        replacible: {},
        fieldErrors: {},
      };
      setEventRecord(tempErecord);

      setList((prev: any) => {
        return prev.map((event: any, inEIndex: any) => {
          if (inEIndex === eIndex) {
            return {
              ...event,
              eventRecords:
                event?.eventRecords &&
                event?.eventRecords.map((eventRecord: any) => {
                  if (eventRecord?.id === currentEventRecord?.id) {
                    let tempErecord = {
                      ...eventRecord,
                      ...eventRecord?.replacible,
                      isInputChange: false,
                      loader: false,
                      selected: false,
                      replacible: {},
                      fieldErrors: {},
                    };
                    return tempErecord;
                  } else {
                    return eventRecord;
                  }
                }),
            };
          } else {
            return event;
          }
        });
      });
    };

    //SET EVENT RECORD LOADER
    const setEventRecordLoader = async (eventRecord: any, status: boolean) => {
      setList((events: any) => {
        return events.map((inEvent: any) => {
          if (inEvent.id === event.id) {
            return {
              ...inEvent,
              eventRecords: Array.isArray(inEvent["eventRecords"])
                ? inEvent["eventRecords"].map((inEventRecord: any) => {
                    if (eventRecord?.id === inEventRecord?.id) {
                      return {
                        ...inEventRecord,
                        loader: status,
                      };
                    } else {
                      return inEventRecord;
                    }
                  })
                : [],
            };
          } else {
            return inEvent;
          }
        });
      });
    };

    const [singleRecordLoader, setSetSingleRecordLoader] = useState(false);

    // UPDATE EVENT RECORD
    const updateRecord = async (
      data: any,
      eventRecord: any,
      onlyUpdate: Boolean = false,
      evIndex: any,
      rcInxex: any
    ) => {
      //CHECK LOCAL VALIDATION
      if (!(await setLocalValidationToEventRecords(eventRecord?.id, false))) {
        setEventRecordLoader(eventRecord, false);
        return true;
      } else {
        setTimeout(() => {
          setLocalValidationToEventRecords(eventRecord?.id, false);
          eventRecord.status = onlyUpdate
            ? eventRecord?.status
            : eventRecord?.status === "no"
            ? "yes"
            : "no";
          setSetSingleRecordLoader(true);
          layout.setAnimationForVisibility({
            eIndex: evIndex,
            rIndex: rcInxex,
            flag: true,
          });
          UpdateInventory(
            setEventRecordPayload(
              event,
              { ...eventRecord, price_per_exchange: 0 } //REMOVE PPE CHANGE
            )
          ).then((response: any) => {
            let responseData = response?.data?.payload?.post_data;
            responseData && responseData.status
              ? showAlert(response.message, false)
              : showAlert(Object.values(response?.errors)?.toString(), true);

            setTimeout(() => {
              setSetSingleRecordLoader(false);
              layout.setAnimationForVisibility({
                eIndex: evIndex,
                rIndex: rcInxex,
                flag: false,
              });
            }, 3000);

            // setEventRecord({
            //   ...eventRecord,
            //   visibility: 0,
            //   selected: false,
            //   oldVisibility: 0,
            //   loader: false,
            //   isInputChange: false,
            //   replacible: {},
            // });

            //UPDATE VISBILITY STATE
            updateVisibility({
              ...eventRecord,
              ...(response?.data?.payload?.is_ticket_type_change === 1
                ? {
                    listing_ticket_files: [],
                    ticketFiles: [],
                    mobile_links: [],
                  }
                : {}),
            });
            setTimeout(() => {
              //setIsLoading(false);
            }, 100);
          });
        }, 600);
      }
    };

    const setLocalValidationToEventRecords = async (
      id: any,
      isPublish: any
    ) => {
      let success = true;
      setList((events: any) => {
        return events.map((inEvent: any) => {
          if (inEvent?.id === event?.id) {
            return {
              ...inEvent,
              eventRecords: Array.isArray(inEvent["eventRecords"])
                ? event["eventRecords"].map(
                    (eventRecord: any, eventRecordKey: any) => {
                      if (eventRecord?.id === id) {
                        let fieldErrors: any = {};

                        const faceValueCheck =
                          Number(eventRecord?.face_value) <= 0 ||
                          (typeof eventRecord?.face_value === "object" &&
                            Number(eventRecord?.face_value?.value) <= 0);

                        const processValueCheck =
                          Number(eventRecord?.sell_price) <= 0 ||
                          (typeof eventRecord?.sell_price === "object" &&
                            Number(eventRecord?.sell_price?.value) <= 0 &&
                            !eventRecord?.ppe);

                        const isHoldTicket =
                          eventRecord?.hold_tickets?.length > 0 &&
                          (eventRecord?.listing_ticket_files?.length > 0 ||
                            eventRecord?.mobile_links?.length > 0) &&
                          Number(eventRecord?.quantity_available) !==
                            (Number(
                              eventRecord?.listing_ticket_files?.length
                            ) || Number(eventRecord?.mobile_links?.length));

                        const isUploadedTicketLessWithQuantity = isPublish
                          ? (eventRecord?.listing_ticket_files?.length > 0 ||
                              eventRecord?.mobile_links?.length > 0) &&
                            eventRecord?.quantity_available !==
                              (eventRecord?.listing_ticket_files?.length ||
                                eventRecord?.mobile_links?.length)
                          : eventRecord?.quantity_available &&
                            eventRecord?.quantity_available <
                              (eventRecord?.listing_ticket_files?.length ||
                                eventRecord?.mobile_links?.length);

                        !eventRecord?.ticket_type &&
                          (fieldErrors = {
                            ...fieldErrors,
                            ticket_type: "Ticket type field is required",
                          });

                        (eventRecord?.ticket_type === "paper" ||
                          eventRecord?.ticket_type?.id === "paper") &&
                          eventRecord?.delivery_options?.length > 0 &&
                          eventRecord?.delivery_options?.map(
                            (singleDelivery: any) => {
                              let price =
                                eventRecord?.[singleDelivery?.id] != undefined
                                  ? Number(eventRecord?.[singleDelivery?.id])
                                  : Number(singleDelivery?.price);
                              (!price || price <= 0) &&
                                (fieldErrors = {
                                  ...fieldErrors,
                                  [singleDelivery?.id]:
                                    layout.deliveryType?.find(
                                      (item: any) =>
                                        singleDelivery?.id === item?.id
                                    )?.type +
                                    " field is required more than zero",
                                });
                            }
                          );

                        (!eventRecord?.quantity_available ||
                          isUploadedTicketLessWithQuantity ||
                          isHoldTicket) &&
                          (fieldErrors = {
                            ...fieldErrors,
                            quantity_available:
                              isUploadedTicketLessWithQuantity || isHoldTicket
                                ? TicketError
                                : "Quantity field is required",
                          });

                        !eventRecord?.category &&
                          event?.categories &&
                          Object.keys(event?.categories).length > 0 &&
                          (fieldErrors = {
                            ...fieldErrors,
                            category: "Category value field is required",
                          });

                        (!eventRecord?.face_value ||
                          (typeof eventRecord?.face_value === "object" &&
                            eventRecord?.face_value?.value === "")) &&
                          (fieldErrors = {
                            ...fieldErrors,
                            face_value: "Face value field is required",
                          });

                        //  new
                        faceValueCheck &&
                          (fieldErrors = {
                            ...fieldErrors,
                            face_value:
                              "Face value field is required more than zero",
                          });

                        (!eventRecord?.sell_price ||
                          (typeof eventRecord?.sell_price === "object" &&
                            eventRecord?.sell_price?.value === "")) &&
                          !eventRecord?.ppe &&
                          (fieldErrors = {
                            ...fieldErrors,
                            sell_price: "Proceed value field is required",
                          });

                        // new

                        processValueCheck &&
                          (fieldErrors = {
                            ...fieldErrors,
                            sell_price:
                              "Proceed value field is required more than zero",
                          });

                        // !eventRecord?.section &&
                        //   eventRecord?.category?.sections &&
                        //   Object.keys(eventRecord?.category?.sections).length > 0 &&
                        //   (fieldErrors = {
                        //     ...fieldErrors,
                        //     section: "Section value field is required",
                        //   });
                        !eventRecord?.split_type &&
                          (fieldErrors = {
                            ...fieldErrors,
                            splitType: "Split Type value field is required",
                          });
                        !eventRecord?.MULTIPLES &&
                          eventRecord?.splitType?.id === "MULTIPLES" &&
                          (fieldErrors = {
                            ...fieldErrors,
                            MULTIPLES: "Sell in Multiples Field is required",
                          });

                        !eventRecord?.sub_ticket_type?.id &&
                          eventRecord?.ticketType?.id === "eticket" &&
                          (fieldErrors = {
                            ...fieldErrors,
                            sub_ticket_type:
                              "Sub Ticket type Field is required",
                          });

                        // eventRecord?.date_to_ship &&
                        //   moment(event?.date).valueOf() <
                        //   moment(eventRecord?.date_to_ship).valueOf() &&
                        //   (fieldErrors = {
                        //     ...fieldErrors,
                        //     date_to_ship:
                        //       "Date of ship should not be greater than the event date",
                        //   });

                        Object.keys(fieldErrors).length > 0 &&
                          (success = false);

                        let errors: any = [
                          {
                            errors: [
                              ...(eventRecord?.errors &&
                              _.size(eventRecord?.errors) > 0
                                ? eventRecord?.errors?.[0]?.errors
                                : []),
                              ...(fieldErrors && _.size(fieldErrors) > 0
                                ? Object.keys(fieldErrors)
                                : []),
                            ],
                            exchange: eventRecord?.errors?.[0]?.exchange,
                          },
                        ];
                        let [firstKey] = Object.keys(fieldErrors);
                        setTimeout(() => {
                          let inputFields: any = document.getElementById(
                            `${firstKey}-${event.id}-${eventRecordKey}`
                          );
                          if (inputFields) {
                            inputFields.scrollIntoView({
                              behavior: "auto",
                              inline: "center",
                              block: `center`,
                            });
                            showAlert(
                              "Please fix the errors on the listings",
                              true
                            );
                          }
                        }, 500);
                        return {
                          ...eventRecord,
                          fieldErrors: fieldErrors,
                          ...(_.size(errors?.[0]?.errors) > 0 && {
                            errors: errors,
                          }),
                        };
                      } else {
                        return eventRecord;
                      }
                    }
                  )
                : inEvent["eventRecords"],
            };
          } else {
            return inEvent;
          }
        });
      });
      return success;
    };

    let isDisabled;
    if (
      (eventRecord?.selected === false ||
        eventRecord?.selected === undefined) &&
      isTableEditable.length > 0
    ) {
      isDisabled = "row-disabled";
      forRowDisabled = "";
    } else if (
      (eventRecord?.selected === false ||
        eventRecord?.selected === undefined) &&
      isTableEditable.length === 0
    ) {
      isDisabled = "";
      forRowDisabled = "";
    } else if (eventRecord?.selected === true && isTableEditable.length === 0) {
      isDisabled = "";
      forRowDisabled = "";
    } else if (eventRecord?.selected === true && isTableEditable.length > 0) {
      isDisabled = "row-indigo-light row-indigo-light -selected";
      forRowDisabled = "pointer-events-none !border-gray-300";
    }

    function calculateAvgPriceForProceedValue(
      sectionname: any,
      markplaceArry: any,
      record: any
    ) {
      // let sectionValue =
      //   sectionname?.target?.value?.originalName
      //     .trim()
      //     ?.toLowerCase()
      //     .split(" ")
      //     .join("") ||
      //   sectionname?.originalName?.trim()?.toLowerCase().split(" ").join("");

      const section =
        record?.section && record?.section?.id
          ? record?.section
          : record?.section &&
            event?.categories &&
            Array.isArray(event?.categories) &&
            event?.categories
              .find(
                (category: any) =>
                  Number(category.id) === Number(record?.category)
              )
              ?.sections?.find(
                (item: any) => Number(item?.id) === Number(record?.section)
              );

      let sectionValue = section?.originalName
        ?.trim()
        ?.toLowerCase()
        .split(" ")
        .join("");

      let markeplaceValueWithAvg: any =
        markplaceArry &&
        Object.entries(markplaceArry)?.map((item: any) => {
          return {
            id: item?.[0],
            name: item?.[0]?.trim()?.toLowerCase().split(" ").join(""),
            avgPrice: item?.[1]?.total?.price_avg,
          };
        });

      let avgPrice = markeplaceValueWithAvg?.find(
        (obj: any) => obj.name === sectionValue
      )?.avgPrice;

      const conversationRate = isGodAdmin
        ? conversation_rate?.[record?.sell_price_currency]
        : conversation_rate;

      let finalAvgRate = avgPrice ? avgPrice * conversationRate : null;

      return finalAvgRate;
    }
    const updateSetList = useCallback(() => {
      setList((prev: any) => {
        return prev?.map((inEvent: any, inEIndex: number) => {
          if (inEIndex == eIndex) {
            return {
              ...inEvent,
              eventRecords: inEvent["eventRecords"]?.map(
                (inEventRecord: any, inRIndex: number) => {
                  if (inRIndex === rIndex) {
                    return {
                      ...eventRecord,
                      isInputChange: true,
                    };
                  } else {
                    return inEventRecord;
                  }
                }
              ),
            };
          } else {
            return inEvent;
          }
        });
      });
    }, [eIndex, eventRecord, rIndex, setList]);

    const MemoizedHandleTableFieldOld = useMemo(() => {
      const MemoizedHandleTableField = (data: any) => {
        let tempEvents = list;
        // If table is not edit-able

        if (layout.isTableEditable.length === 0) {
          // REPLACIBLE RECORD
          if (
            !tempEvents[eIndex]["eventRecords"][rIndex]["replacible"] ||
            _.size(tempEvents[eIndex]["eventRecords"][rIndex]["replacible"]) ===
              0
          ) {
            tempEvents[eIndex]["eventRecords"][rIndex]["replacible"] = {
              ...tempEvents[eIndex]["eventRecords"][rIndex],
            };
          }

          tempEvents[eIndex]["eventRecords"][rIndex][data.target.name] =
            data.target.value !== undefined && Array.isArray(data.target.value)
              ? data.target.value
              : typeof data.target.value === "object"
              ? data.target.value
              : typeof data.target.value === "string" &&
                data.target.type !== "checkbox"
              ? data.target.value
              : data.target.type === "checkbox"
              ? data.target.checked
              : data.target.value;

          //for the category change
          if (data.target.name === "category") {
            tempEvents[eIndex]["eventRecords"][rIndex]["section"] = null;
          }
          if (data.target.name === "section") {
            const avg_price = calculateAvgPriceForProceedValue(
              data,
              tempEvents[eIndex]["eventRecords"][rIndex]["markinsight"],
              tempEvents[eIndex]["eventRecords"][rIndex]
            );

            tempEvents[eIndex]["eventRecords"][rIndex]["avg_price"] = avg_price;
          }

          if (
            data.target.type === "checkbox" &&
            data.target.name === "selected" &&
            _.size(tempEvents[eIndex]?.["requireSelectAll"]) > 0
          ) {
            delete tempEvents[eIndex]["requireSelectAll"];
          }

          data.target.name !== "selected" &&
            (tempEvents[eIndex]["eventRecords"][rIndex]["isInputChange"] =
              true);

          // data.target.name === "ticket_type" &&
          //   data.target?.value?.id !== "eticket" &&
          //   (tempEvents[eIndex]["eventRecords"][rIndex]["sub_ticket_type"] =
          //     "");
          // WHEN TICKIT TYPE IS EMPTY THEN SLECTED SUBTICKIT TYPE FIRST BY DEFAULT
          data.target.name === "ticket_type" &&
            data.target?.value?.id === "eticket" &&
            tempEvents[eIndex]["eventRecords"][rIndex]["sub_ticket_type"] ===
              "" &&
            (tempEvents[eIndex]["eventRecords"][rIndex]["sub_ticket_type"] =
              subTicketType?.[0]);

          data.target.name === "split_type" &&
            data.target.value?.id !== "MULTIPLES" &&
            (tempEvents[eIndex]["eventRecords"][rIndex]["split_quantity"] = 0);

          //for the eticket change
          if (data.target.name === "ticket_type") {
            //for delivery type field
            if (data.target.value?.id !== "paper") {
              tempEvents[eIndex]["eventRecords"][rIndex]["delivery_options"] =
                [];
            }
          }
          if (data.target.name === "ticketsInHands" && data.target?.checked) {
            tempEvents[eIndex]["eventRecords"][rIndex]["date_to_ship"] =
              new Date();
          }

          // GET SUM OF QUANTITY
          // const sumQuantityAvailable = _.sumBy(tempEvents, (event) =>
          //   _.sumBy(event.eventRecords, (record: any) => {
          //     const quantityAvailable = parseInt(record.quantity_available, 10);
          //     return Number.isNaN(quantityAvailable) ? 0 : quantityAvailable;
          //   })
          // );
          // tempEvents[eIndex]["total_qty_available"] = sumQuantityAvailable;
          // id? "eticket" =  ticket_type

          let ticketType =
            tempEvents[eIndex]["eventRecords"][rIndex]?.["ticket_type"];

          let quantity_available =
            tempEvents[eIndex]["eventRecords"][rIndex]?.quantity_available;

          let visibility: any = visibilityMeterCalculationForInventory(
            tempEvents[eIndex],
            tempEvents[eIndex]["eventRecords"][rIndex],
            layout.ticketType
          );

          const currentVisibility =
            tempEvents[eIndex]["eventRecords"][rIndex]["visibility"];
          const oldVisibility =
            currentVisibility === visibility
              ? tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"]
              : currentVisibility;

          tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"] =
            oldVisibility;
          tempEvents[eIndex]["eventRecords"][rIndex]["visibility"] = visibility;
          //setEventRecord(tempEvents[eIndex]["eventRecords"][rIndex]);
          setList(tempEvents);
          // If table is edit-able
        } else {
          setList((prev: any) => {
            return prev.map((innerEvent: any, eventIndex: number) => {
              if (event.id === innerEvent.id) {
                let eventRecords: any = Array.isArray(
                  innerEvent["eventRecords"]
                )
                  ? event["eventRecords"].map((eventRecord: any) => {
                      if (eventRecord?.selected === true) {
                        let newArrDelivery = layout.deliveryType?.reduce(
                          (count: any, item: any) => {
                            count[item.id] = item.type;
                            return count;
                          },
                          {}
                        );

                        layout.setTableDataEditChanging((pre: any[]) => {
                          const eventPre = pre[eIndex] || [];
                          const conditions: any = newArrDelivery;
                          const newName = conditions[data.target.name] || null;

                          let updatedEventPre = [...eventPre];

                          if (
                            newName &&
                            updatedEventPre.indexOf(newName) === -1 &&
                            data.target.name
                          ) {
                            updatedEventPre = [
                              ...updatedEventPre,
                              newName,
                              data.target.name,
                            ];
                          } else if (
                            !newName &&
                            updatedEventPre.indexOf(data.target.name) === -1 &&
                            data.target.name
                          ) {
                            updatedEventPre = [
                              ...updatedEventPre,
                              data.target.name,
                            ];
                          }

                          const newPre = [...pre];
                          newPre[eIndex] = updatedEventPre;

                          return newPre;
                        });

                        //for split type
                        let multipleData = eventRecord?.split_quantity;
                        if (
                          data.target.name === "split_type" &&
                          data.target.value?.id !== "MULTIPLES"
                        ) {
                          multipleData = "";
                        }

                        let final = {
                          ...eventRecord,
                          split_quantity: multipleData,
                          replacible:
                            _.size(eventRecord?.replacible) === 0
                              ? eventRecord
                              : eventRecord?.replacible,
                          [data.target.name]:
                            data.target.value !== undefined &&
                            Array.isArray(data.target.value)
                              ? data.target.value
                              : typeof data.target.value === "object"
                              ? data.target.value
                              : typeof data.target.value === "string" &&
                                data.target.type !== "checkbox"
                              ? data.target.value
                              : data.target.type === "checkbox"
                              ? data.target.checked
                              : data.target.value,
                          ...(data.target.name !== "selected"
                            ? { isInputChange: true }
                            : {}),
                        };

                        return {
                          ...final,
                          ...(final?.ticketsInHands
                            ? { date_to_ship: new Date() }
                            : { date_to_ship: final?.date_to_ship }),
                        };
                      } else {
                        return eventRecord;
                      }
                    })
                  : innerEvent["eventRecords"];

                const final = eventRecords?.map(
                  (record: any, index: number) => {
                    let newArrDelivery = eventRecord?.delivery_options?.reduce(
                      (count: any, item: any) => {
                        count[item.id] = item.type;
                        return count;
                      },
                      {}
                    );

                    if (record?.selected) {
                      // console.log(record, "record");
                      if (record?.section?.id) {
                        // const sectionValue =
                        // eventRecord?.section && eventRecord?.section?.id
                        //   ? eventRecord?.section
                        //   : eventRecord?.section &&
                        //     event?.categories &&
                        //     Array.isArray(event?.categories) &&
                        //     event?.categories
                        //       .find(
                        //         (category: any) =>
                        //           Number(category.id) === Number(eventRecord?.category)
                        //       )
                        //       ?.sections?.find(
                        //         (item: any) => Number(item?.id) === Number(eventRecord?.section)
                        //       );

                        let categoryArray: any = record.category;
                        if (typeof categoryArray === "string") {
                          categoryArray = innerEvent?.categories?.find(
                            (obj: any) =>
                              Number(obj?.id) === Number(categoryArray)
                          );
                        }
                        const matchingId = record?.section?.id;
                        const matchingSections =
                          categoryArray?.sections?.filter(
                            (innerObj: any) => innerObj?.id === matchingId
                          );

                        if (
                          !matchingSections ||
                          matchingSections?.length === 0
                        ) {
                          record.section = "";
                        }
                      }

                      if (firstSelectedIndex === -1) {
                        // This is the first selected record, store its index
                        firstSelectedIndex = index;
                        return {
                          ...record,
                        };
                      } else {
                        // Check if ticketType is "paper"
                        if (
                          record.ticket_type?.id === "paper" ||
                          record.ticket_type === "paper"
                        ) {
                          return {
                            ...record,
                          };
                        } else {
                          // Create a copy of the record without the specific key
                          // const {
                          //   "01fg98zqgrtfe7avqvv575e970": _,
                          //   "01fnv9gwfd8wmnn27aw196pj37": __,
                          //   "01fnv9ja884j9empp7a4z663wj": ___,
                          //   ...rest
                          // } = record;
                          const keysToDelete = Object.keys(newArrDelivery);
                          let rest = record;

                          keysToDelete.forEach((key: any) => {
                            if (rest.hasOwnProperty(key)) {
                              delete rest[key];
                            }
                          });
                          return {
                            ...rest,
                            delivery_options: [],
                          };
                        }
                      }
                    }

                    return record;
                  }
                );

                // console.log(final?.date_to_ship);
                return {
                  ...innerEvent,
                  eventRecords: final?.map((obj: any) => {
                    // VISIBILITY LOGIC
                    let visibility: any =
                      visibilityMeterCalculationForInventory(
                        event,
                        obj,
                        layout?.ticketType
                      );
                    let avg_price: any;

                    avg_price = calculateAvgPriceForProceedValue(
                      obj?.section,
                      obj?.["markinsight"],
                      obj
                    );
                    return {
                      ...obj,
                      visibility: visibility,
                      oldVisibility:
                        obj?.visibility === visibility
                          ? obj?.oldVisibility
                          : obj?.visibility,
                      avg_price: avg_price,
                    };
                  }),
                };
              } else {
                return innerEvent;
              }
            });
          });
          // setTimeout(() => {
          //   setList((current: any) => {
          //     layout.setIsLoader(false);

          //     return current;
          //   });
          // }, 500);
        }
      };

      return MemoizedHandleTableField;
    }, [eIndex, event, layout, list, rIndex, setList, subTicketType]);
    const MemoizedHandleTableField = useMemo(() => {
      return (data: any, eIndex: number, rIndex: number) => {
        setEventRecord((prev: any) => {
          return {
            ...prev,
            [data?.target?.name]:
              data.target.value !== undefined &&
              Array.isArray(data.target.value)
                ? data.target.value
                : typeof data.target.value === "object"
                ? data.target.value
                : typeof data.target.value === "string" &&
                  data.target.type !== "checkbox"
                ? data.target.value
                : data.target.type === "checkbox"
                ? data.target.checked
                : data.target.value,
          };
        });

        if (layout.isTableEditable.length === 0) {
          // SINGLE EDIT
          MemoizedHandleTableFieldOld(data);
          setUpdateChecker({
            eventId: eIndex,
            eventRecordId: rIndex,
          });
        } else {
          // MULTI EDIT
          setUpdateChecker(data);
        }
      };
    }, [MemoizedHandleTableFieldOld, layout.isTableEditable.length]);

    //SINGLE EDIT
    useEffect(() => {
      if (layout.isTableEditable.length === 0) {
        setTimeout(() => {
          updateChecker?.eventId !== undefined &&
            setEventRecord(
              list?.[updateChecker?.eventId]?.eventRecords?.[
                updateChecker?.eventRecordId
              ]
            );
        }, 1000);
      }
    }, [layout.isTableEditable.length, list, updateChecker]);
    // MULTI EDIT
    let eventRecordMemo: any = useMemo(() => {
      return [updateChecker];
    }, [updateChecker]);

    const [lastChanged, setLastChanged] = useState<String | undefined>(
      undefined
    );

    const saveDataInStateTimer: number = 500;

    let debounceHandler = useDebounce(eventRecordMemo, saveDataInStateTimer);
    // if (lastChanged) {
    //   debounceHandler = eventRecordMemo;

    // } else {
    //   debounceHandler = eventRecordMemo;
    // }

    useEffect(() => {
      if (
        updateChecker &&
        updateChecker?.eventId === undefined &&
        layout.isTableEditable.length > 0
      ) {
        // layout.setIsLoader(true);

        MemoizedHandleTableFieldOld(updateChecker);
      }
    }, [debounceHandler]);

    //send response to eventField component
    function refreshEventsWhileActionPerform() {
      //send response to eventField component
      const tempEvents = (events: any) => {
        return events.map((event: any) => {
          return {
            ...event,
            eventRecords:
              Array.isArray(event["eventRecords"]) &&
              event["eventRecords"].length > 0
                ? event["eventRecords"].map((eventRecord: any) => {
                    return {
                      ...eventRecord,
                    };
                  })
                : undefined,
          };
        });
      };
      setList(tempEvents([...list]));
    }

    // const isEventRecordError = eventRecord?.errors && Object.keys(eventRecord?.errors).length > 0;
    const isEventRecordError =
      eventRecord?.fieldErrors &&
      Object.keys(eventRecord?.fieldErrors).length > 0;
    const isEventRecordBEError =
      eventRecord?.errors && Object.keys(eventRecord?.errors)?.length > 0;
    const isEventRecordHoldTickets =
      eventRecord?.hold_tickets &&
      Object.keys(eventRecord?.hold_tickets).length > 0;

    const selectedExchanges = eventRecord?.exchanges?.[0]?.exchange_id
      ? eventRecord?.exchanges
      : event?.event_exchanges?.filter((market: any) =>
          eventRecord?.exchanges?.includes(market.exchange_id)
        );
    // OLD;
    // let ticketTypeValue =
    //   typeof eventRecord?.ticket_type === "object"
    //     ? eventRecord?.ticket_type
    //     : Array.isArray(layout.ticketType) &&
    //       layout.ticketType?.find(
    //         (ticket: any) => ticket?.id === eventRecord?.ticket_type
    //       );

    // NEWE
    let ticketTypeValue: any;

    if (typeof eventRecord?.ticket_type === "object") {
      if (
        eventRecord?.ticket_type === "eticket" &&
        eventRecord?.sub_ticket_type === "pdf"
      ) {
        ticketTypeValue = {
          id: "pdf",
          name: "PDF E-Ticket",
        };
      } else if (
        eventRecord?.ticket_type === "eticket" &&
        eventRecord?.sub_ticket_type === "mobile-qr"
      ) {
        ticketTypeValue = {
          id: "mobile-qr",
          name: "Mobile QR",
        };
      } else {
        ticketTypeValue = eventRecord?.ticket_type;
      }
    } else if (Array.isArray(layout.ticketType)) {
      let subTicket = !eventRecord?.sub_ticket_type?.trim()
        ? "pdf"
        : eventRecord?.sub_ticket_type;
      const foundTicket = layout.ticketType.find((ticket: any) => {
        if (eventRecord?.ticket_type === "eticket") {
          return ticket?.id === subTicket;
        } else {
          return ticket?.id === eventRecord?.ticket_type;
        }
      });

      ticketTypeValue = foundTicket;
    }

    // SPLIT CONDITION - FOR MULTIPLE
    let splitCondition: string = "";
    if (
      (typeof eventRecord?.split_type === "string" &&
        eventRecord?.split_type !== "MULTIPLES") ||
      (typeof eventRecord?.split_type === "object" &&
        eventRecord?.split_type?.id !== "MULTIPLES")
    ) {
      splitCondition =
        "pointer-events-none bg-gray-100 !border-gray-100 text-gray-400 placeholder:text-gray-400 disable";
    } else {
      splitCondition = "";
    }

    // SPLIT TYPE
    let splitTypeValue: any =
      typeof eventRecord?.split_type === "object"
        ? eventRecord?.split_type
        : Array.isArray(layout.splitType) &&
          layout.splitType.find(
            (split: any) => split.id === eventRecord?.split_type
          );

    const handleOnUnLockAll = (e: any) => {
      setList((obj: any) => {
        return obj.map((order: any) => {
          return {
            ...order,
            isLock: !e?.target?.checked,
            selected: obj?.selected ? obj?.selected : false,
          };
        });
      });
    };

    const handleOnSingleUnLock = (e: any, eId: any, sIndex: any) => {
      setList((prev: any) => {
        return prev.map((event: any, eInnerIndex: any) => {
          if (event?.id === eId) {
            return {
              ...event,
              eventRecords: Array.isArray(event["eventRecords"])
                ? event["eventRecords"].map((eventRecord: any, index: any) => {
                    if (rIndex === index) {
                      return {
                        ...eventRecord,
                        isLock: !eventRecord?.isLock,
                        selected: false,
                      };
                    } else {
                      return { ...eventRecord };
                    }
                  })
                : [],
            };
          } else {
            return event;
          }
        });
      });
    };

    const isALLTicketUploadedWithPublish =
      Number(eventRecord?.quantity_available) > 0 &&
      Number(eventRecord?.quantity_available) ===
        (Number(eventRecord?.listing_ticket_files?.length) ||
          Number(eventRecord?.mobile_links?.length)) &&
      eventRecord?.status === "yes";

    const categoryValue = eventRecord?.category?.id
      ? eventRecord?.category
      : event?.categories &&
        event?.categories.find(
          (category: any) =>
            Number(category.id) === Number(eventRecord?.category)
        );

    const sectionValue =
      eventRecord?.section && eventRecord?.section?.id
        ? eventRecord?.section
        : eventRecord?.section &&
          event?.categories &&
          Array.isArray(event?.categories) &&
          event?.categories
            .find(
              (category: any) =>
                Number(category.id) === Number(eventRecord?.category)
            )
            ?.sections?.find(
              (item: any) => Number(item?.id) === Number(eventRecord?.section)
            );

    const benefitsValue = eventRecord?.benefits?.[0]?.id
      ? eventRecord?.benefits
      : layout?.benefits.filter((benefit: any) =>
          eventRecord?.benefits?.includes(benefit.id)
        );

    const restrictionValue =
      eventRecord &&
      eventRecord?.restrictions &&
      eventRecord?.restrictions[0]?.id
        ? eventRecord?.restrictions
        : layout?.restrictions.filter((restriction: any) =>
            eventRecord?.restrictions?.includes(restriction.id)
          );

    function checkIsFullfillUploadExist(event: any, eventRecord: any) {
      const selectedEventRecords = list
        ?.flatMap((item: any) =>
          item?.eventRecords?.filter(
            (record: any) =>
              record?.selected &&
              (record?.ticket_type === "eticket" ||
                record?.ticket_type?.id === "eticket" ||
                record?.ticket_type?.id === "pdf" ||
                record?.ticket_type?.id === "mobile-qr" ||
                record?.ticket_type?.id === "mobile-link" ||
                record?.ticket_type?.id === "mobile-link")
          )
        )
        .filter((obj: any) => obj !== undefined);

      const flag =
        selectedEventRecords?.length > 1
          ? selectedEventRecords?.filter(
              (obj: any) =>
                obj?.quantity_available ===
                  (obj?.listing_ticket_files?.length ||
                    obj?.mobile_links?.length) && obj?.status === "yes"
            )
          : [];
      return flag || "";
    }

    // console.log(eventRecord, "eventRecord");

    const CHECKQTYFILEUPLOAD =
      eventRecord?.quantity_available &&
      Number(eventRecord?.quantity_available) !==
        eventRecord?.listing_ticket_files?.filter(
          (obj: any) => JSON.stringify(obj) !== "{}"
        )?.length;
    let totalHold = 0;
    eventRecord?.hold_tickets &&
      Object.keys(eventRecord?.hold_tickets).length > 0 &&
      Object.values(eventRecord?.hold_tickets)?.map((holdData: any) => {
        totalHold = totalHold + holdData?.hold_quantity;
      });

    let errorsArrays = eventRecord?.errors
      ?.map((item: any) => item.errors)
      .flat();

    const ticketInHandFromListings = (eIndex: any, rIndex: any) => {
      setList((prevList: any) => {
        return prevList?.map((obj: any, index: any) => {
          if (eIndex === index) {
            return {
              ...obj,
              eventRecords: obj?.eventRecords?.map(
                (innerObj: any, innerIndex: any) => {
                  if (rIndex === innerIndex) {
                    return {
                      ...innerObj,
                      ticketsInHands: innerObj?.ticketsInHands ? false : true,
                      isInputChange: true,
                    };
                  }
                  return innerObj;
                }
              ),
            };
          }
          return obj;
        });
      });
    };
    const handleOnSelection = (
      data: any,
      eIndex: any,
      rIndex: any,
      option: any,
      filedName: any,
      visibleOptions: any,
      currentOptionsSelected: any
    ) => {
      let updatedOptions =
        visibleOptions && size(visibleOptions) > 0
          ? visibleOptions
          : data?.target?.id === "select-all"
          ? option
          : null;
      if (data?.target?.id === "select-all") {
        let target = {
          name: filedName,
          value: updatedOptions,
        };
        MemoizedHandleTableField({ target }, eIndex, rIndex);
      } else if (data?.target?.id === "deselect-all") {
        let target = {
          name: filedName,
          value: removeSelectAll(currentOptionsSelected, updatedOptions),
        };
        MemoizedHandleTableField({ target }, eIndex, rIndex);
      }
    };

    const focusOnPriceField = (data: any, eIndex: any, rIndex: any) => {
      setPriceFieldFocused({ data: data, eIndex: eIndex, rIndex: rIndex });
    };
    const focusOnPriceFieldSticky = (data: any, eIndex: any, rIndex: any) => {
      setPriceFieldFocusedSticky({
        data: data,
        eIndex: eIndex,
        rIndex: rIndex,
      });
    };

    function categoryExist(event: any, record: any, type: any) {
      const categoryValue = record?.category?.id
        ? record?.category
        : event?.categories &&
          event?.categories.find(
            (category: any) => Number(category.id) === Number(record?.category)
          );

      const sectionValue =
        record?.section && record?.section?.id
          ? record?.section
          : record?.section &&
            event?.categories &&
            Array.isArray(event?.categories) &&
            event?.categories
              .find(
                (category: any) =>
                  Number(category.id) === Number(record?.category)
              )
              ?.sections?.find(
                (item: any) => Number(item?.id) === Number(record?.section)
              );

      const priceField =
        typeof record?.sell_price === "object"
          ? eventRecord?.sell_price?.value
          : record?.sell_price;

      const hasProceedValue = priceField !== "0.00" && priceField !== "";
      const hasNoCategory = _.size(categoryValue) === 0;
      const hasNoSection = _.size(sectionValue) === 0;

      if (type === "withoutcategory") {
        return _.size(record?.markinsight) > 0
          ? hasProceedValue && (hasNoCategory || hasNoSection)
          : false;
      }
      if (type === "withcategory") {
        return _.size(record?.markinsight) > 0
          ? hasProceedValue &&
              _.size(categoryValue) > 0 &&
              _.size(sectionValue) > 0
          : false;
      }
    }
    let singleEdit = `accordion-body relative inline-flex  w-full justify-center  !rounded-none h-[2.5rem] border-l
    ${eventRecord?.loader && "pointer-events-none opacity-40"} 
    ${layout?.isBulkActionLoader && "pointer-events-none opacity-40"} 
    left-shad
`;

    let multiEdit = `1232 bg-white border-l st_icons ${
      _.size(layout.isTableEditable) > 0 &&
      !eventRecord?.selected &&
      "row-disabled"
    } ${eventRecord?.loader && " pointer-events-none opacity-20 "} left-shad 
                  ${
                    eventRecord?.selected &&
                    !(
                      isEventRecordError ||
                      eventRecord?.status === "failed" ||
                      isEventRecordBEError
                    )
                      ? "!bg-indigo-500 !bg-opacity-[7%]"
                      : ""
                  }

                  ${
                    isEventRecordHoldTickets &&
                    "row-hold-new group-hover:bg-sky-blue-300"
                  } 
                  ${
                    (isEventRecordError || isEventRecordBEError) &&
                    "row-error-new group-hover:bg-rose-550"
                  }
                  ${eventRecord?.processing && "row-processing"}
                  
                  `;

    return (
      <div
        className={` ${
          (isEventRecordError ||
            eventRecord?.status === "failed" ||
            isEventRecordBEError) &&
          "row-error-new group-hover:bg-rose-550 relative"
        } ${
          isEventRecordHoldTickets && "group-hover:bg-sky-blue-300 row-hold-new"
        } ${eventRecord?.processing && "row-processing-new"}`}
        onClick={() => {
          if (layout.isTableEditable.length === 0) {
            layout.setCurrentIndex({ rIndex: rIndex, eIndex: event?.id });
          }
        }}
      >
        <React.Fragment
          key={"eventFieldIn" + eventRecord?.id + rIndex + `ListingRecord`}
        >
          {/* {Date.now()} */}
          <div
            className={`first_row border-b items-center ${
              (isALLTicketUploadedWithPublish || eventRecord?.isDisabled) &&
              "bulkUploaded"
            }   
            ${eventRecord?.bulkUpdate ? "row-disabled !bg-gray-300/40" : ""}
            ${layout?.isBulkActionLoader ? "row-disabled !bg-gray-300/40" : ""}
          ${eventRecord?.deleteLoader ? "fade-effect" : ""} 
          ${eventRecord?.bulkLoader ? "row-disabled" : ""} 
            
            ${layout.tableAlert?.confirm ? "pointer-events-none" : ""} ${
              eventRecord?.id === firstSelectedIndex?.id &&
              eventRecord?.selected
                ? "first"
                : ""
            } ${event.value === "" && "row-disabled"} ${
              eventRecord?.processing && "row-processing"
            } 
          ${isDisabled}  ${
              eventRecord?.selected ||
              (matchIndexView?.pIndex === eIndex &&
                matchIndexView?.cIndex === rIndex) ||
              layout.logsData?.id === eventRecord?.id
                ? "bg-indigo-500 bg-opacity-[7%]"
                : ""
            } ${
              eventRecord?.status === "partPublished" &&
              "bg-orange-500/10 hover:bg-orange-500/10"
            } 
          ${layout.isDeleteActive && "pointer-events-none"}
          ${layout.isEditPopUpActive && "pointer-events-none"}
          ${eventRecord?.selected && layout.confirmRowDelete && "fade"}

          
          ${
            (eventRecord?.loader ||
              (eventRecord?.loader &&
                (eventRecord?.bulkLoader || layout?.updateAllLoader))) &&
            "row-disabled"
          } flex table_row ${
              isGodAdmin &&
              eventRecord?.isLock === true &&
              "pointer-events-none bg-gray-300/40"
            } 
            
           

            `}
          >
            {isGodAdmin && (
              <div
                style={{ width: "40px" }}
                // className={`flex td_block items-center justify-center
                // pointer-events-auto
                //    min-w-[2.5rem] max-w-[2.5rem]
                //   h-[2.5rem] text-center  checkbox_td `}
                className={`${
                  _.size(layout?.isTableEditable) > 0 ||
                  layout?.selectAllConfirmation ||
                  layout?.selectAllLoader
                    ? "!pointer-events-none"
                    : "pointer-events-auto"
                }  flex td_block items-center checkbox_td justify-center h-[2.5rem] pointer-events-auto td_block min-w-[2.5rem] max-w-[2.5rem] w-[2.5rem] sticky left-0 no-scroll p-0 z-[3]
                lock-img-view
                 group-hover:bg-indigo-500 group-hover:bg-opacity-[7%] `}
                id="checkbox_shad_lock"
              >
                {" "}
                <div
                  className={`lock-icon relative w-full h-full border-r ${
                    isGodAdmin && eventRecord?.isLock === true
                      ? "bg-[#f0f0f0]"
                      : ""
                  } `}
                >
                  <label
                    className={`cursor-pointer w-full h-full flex items-center justify-center ${
                      eventRecord?.isLock === true ? " lock" : "unlock"
                    }`}
                  >
                    <input
                      type="checkbox"
                      className={`invisible absolute ${
                        _.size(layout?.isTableEditable) > 0
                          ? "!pointer-events-none"
                          : "pointer-events-auto"
                      }`}
                      onClick={(e: any) => {
                        handleOnSingleUnLock(e, event?.id, rIndex);
                      }}
                    />

                    <img
                      src={
                        eventRecord?.isLock === true
                          ? `${pngImagePath}lock.png`
                          : `${pngImagePath}unlock.png`
                      }
                      className="w-[.8125rem] h-[.9375rem]"
                    />
                    {/* <IKTSVG
                      svgClassName="!w-[.9375rem] !h-[.9375rem]"
                      path={
                        eventRecord?.isLock === true ? LockIcon : LockIconOpen
                      }
                    /> */}
                  </label>
                </div>
              </div>
            )}
            {/* ------------ {JSON.stringify(eventRecord?.soft_deleted)} */}
            {/* ------------- */}
            <div
              style={{ width: "40px" }}
              className={`flex td_block items-center justify-center 
              ${isGodAdmin && "!left-[2.5rem]"} min-w-[2.5rem] max-w-[2.5rem]
                h-[2.5rem] text-center sticky left-0 no-scroll p-0 checkbox_td z-[3] bg-inherit`}
              id="checkbox_shad"
            >
              <label
                className={`bg-inherit select-none w-full h-full top-0 left-0 absolute cursor-pointer p-1 border-r group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]
                 ${forRowDisabled} ${disabledAll && "pointer-events-none"} ${
                  eventRecord?.selected ? "bg-indigo-500 bg-opacity-[7%]" : ""
                }  ${
                  ((eventRecord?.errors &&
                    Object.keys(eventRecord?.errors ? eventRecord?.errors : [])
                      .length > 0) ||
                    eventRecord?.status === "failed") &&
                  "row-error-new bg-opacity-[5%] !group-hover:bg-rose-550 group-hover:bg-opacity-[5%]"
                } ${
                  (isEventRecordError ||
                    eventRecord?.status === "failed" ||
                    isEventRecordBEError) &&
                  "row-error-new !group-hover:bg-rose-550"
                }`}
                htmlFor={`checkbox-all-${eIndex}${rIndex}`}
              ></label>
              {/* {JSON.stringify(Date.now())}
               -{JSON.stringify(eventRecord?.updateMode)}- */}
              <input
                name="selected"
                id={`checkbox-all-${eIndex}${rIndex}`}
                type="checkbox"
                checked={eventRecord?.selected}
                className={`${forRowDisabled} ${
                  disabledAll && "pointer-events-none "
                } ${
                  eventRecord?.hold_tickets?.length && "pointer-events-none "
                }  w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 relative`}
                onChange={(event: any) =>
                  MemoizedHandleTableField(event, eIndex, rIndex)
                }
                onClick={(e) => {
                  handleShift(e, rIndex, event?.eventRecords);
                  setTimeout(() => {
                    refreshEventsWhileActionPerform();
                  }, 70);
                }}
                disabled={eventRecord?.hold_tickets?.length > 0}
              />
            </div>
            {getColumnItems?.map((columnItem: any) => {
              switch (columnItem.id) {
                case 0:
                  return (
                    <div
                      className={`p-1.5 font-medium td_block`}
                      key={
                        "eventFieldListingID" + eventRecord?.id + columnItem.id
                      }
                    >
                      <input
                        type="number"
                        className={`bg-gray-100 truncate h-6 max-w-[6.25rem] min-w-[6.25rem] text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 !border-none !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                        value={crc32(eventRecord?.id)}
                        placeholder="Listing ID"
                        disabled={true}
                      />
                      {/* <div className="ellips-text max-w-[4.6875rem] min-w-[4.6875rem] bg-gray-100 text-violet-800">
                        {crc32(eventRecord?.id)}
                      </div> */}
                    </div>
                  );
                case 1:
                  return (
                    <div
                      className={`p-1.5 font-medium td_block `}
                      // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                    >
                      {/* <input
                                    type="number"
                                    className={`  truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                                    value={eventRecord?.team_name}
                                    placeholder="Team name"
                                    name="team_name"
                                    id={`${event?.id}${rIndex}team_name`}
                                    disabled
                                    readOnly
                                  /> */}
                      <div
                        className="ellips-text max-w-[6.875rem] min-w-[6.875rem]"
                        // title={eventRecord?.team_name}
                      >
                        {eventRecord?.team_name &&
                          firstLetterCaptital(eventRecord?.team_name)}
                      </div>
                    </div>
                  );
                case 2:
                  return (
                    <div
                      className={`p-1.5 font-medium td_block `}
                      // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                    >
                      {/* <input
                                      type="number"
                                      className={`  truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                                      value={eventRecord?.team_name}
                                      placeholder="Team name"
                                      name="team_name"
                                      id={`${event?.id}${rIndex}team_name`}
                                      disabled
                                      readOnly
                                    /> */}
                      <div
                        className="ellips-text max-w-[6.875rem] min-w-[6.875rem] "
                        // title={eventRecord?.user_name}
                      >
                        {eventRecord?.user_name &&
                          firstLetterCaptital(eventRecord?.user_name)}
                      </div>
                    </div>
                  );
                // case 1:
                //   return (
                //     <Fragment key={'eventFieldIn'+eventRecord?.id+columnItem.id}>
                //       {/* MarketPlaces Multi-dropdown */}
                //       <td className="p-1.5 font-medium">
                //         <TableMultiDropdown
                //           name="exchanges"
                //           value={selectedExchanges}
                //           options={event?.event_exchanges}
                //           classNamePrefix={
                //             "table_multiDropdown"
                //           }
                //           placeholder={"Marketplaces"}
                //           handleOnChange={(data: any) =>
                //             MemoizedHandleTableField(
                //               data,
                //               eIndex,
                //               rIndex
                //             )
                //           }
                //           getOptionLabel={"title"}
                //           getOptionValue={"exchange_id"}
                //           isDisabled={isEventRecordHoldTickets}
                //         />
                //       </td>
                //     </Fragment>
                //   );

                case 3:
                  return (
                    <Fragment
                    // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                    >
                      {/* Ticket-Type Dropdown */}

                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <>
                          <div className=" p-1.5 max-w-[7.625rem] min-w-[7.625rem]">
                            <input
                              type="text"
                              className={`bg-gray-100 truncate h-6 w-full text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5 `}
                              value={ticketTypeValue?.name}
                              placeholder="Listing ID"
                              disabled={true}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`p-1.5 font-medium ${
                              layout.tableDataEditChanging?.includes(
                                "ticket_type"
                              ) && "edited"
                            }   `}
                            id={`ticket_type-${event.id}-${rIndex}`}
                          >
                            <TableDropdown
                              isCapital={true}
                              isValueCapital={true}
                              value={ticketTypeValue}
                              isClearable={false}
                              options={layout.ticketType}
                              placeholder={"Ticket type"}
                              name="ticket_type"
                              handleOnChange={(data: any) => {
                                setLastChanged("ticket_type");
                                MemoizedHandleTableField(data, eIndex, rIndex);
                                setTimeout(() => {
                                  refreshEventsWhileActionPerform();
                                }, 1);
                              }}
                              isDisabled={isEventRecordHoldTickets}
                              errorClassName={
                                eventRecord?.fieldErrors?.ticket_type &&
                                "error-field"
                              }
                              errorEventIndex={
                                eventRecord?.fieldErrors?.ticket_type &&
                                `ticket_type-${eventRecord?.id}`
                              }
                              errorMessage={
                                eventRecord?.fieldErrors?.ticket_type
                                  ? eventRecord?.fieldErrors?.ticket_type
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}

                      {paperTypeExist && (
                        <div
                          className={`p-1.5 font-medium min-w-[7.875rem] ${
                            layout.tableDataEditChanging?.includes(
                              "delivery_options"
                            ) && "edited"
                          }`}
                        >
                          <TableMultiDropdown
                            name="delivery_options"
                            isCapital={true}
                            options={layout.deliveryType}
                            value={eventRecord?.delivery_options}
                            classNamePrefix={"table_multiDropdown"}
                            placeholder={
                              eventRecord?.delivery_options &&
                              size(eventRecord?.delivery_options) > 0
                                ? ""
                                : "Delivery type"
                            }
                            handleOnChange={(data: any) => {
                              setLastChanged("delivery_options");
                              MemoizedHandleTableField(data, eIndex, rIndex);
                              // setTimeout(() => {
                              //   refreshEventsWhileActionPerform();
                              // }, 1);
                            }}
                            getOptionLabel="type"
                            isDisabled={
                              eventRecord?.hold_tickets?.length > 0 ||
                              eventRecord?.isDisabled ||
                              (typeof eventRecord?.ticket_type === "object" &&
                                eventRecord?.ticket_type?.id !== "paper")
                                ? true
                                : typeof eventRecord?.ticket_type ===
                                    "string" &&
                                  eventRecord?.ticket_type !== "paper"
                                ? true
                                : false
                            }
                          />
                        </div>
                      )}

                      {paperTypeExist &&
                        isArray(seletedDeliveryTypes) &&
                        seletedDeliveryTypes.length > 0 &&
                        _.orderBy(seletedDeliveryTypes, deliveryTypeOrders, [
                          "asc",
                        ]).map((selected: any) => {
                          let typeTemp = _.find(layout.deliveryType, {
                            type: selected,
                          });
                          let delivery = find(eventRecord?.delivery_options, {
                            id: typeTemp?.id,
                          });
                          return delivery &&
                            eventRecord?.hold_tickets.length === 0 ? (
                            <div
                              id={`${delivery?.id}-${event.id}-${rIndex}`}
                              className={`p-1.5 font-medium ${
                                layout.tableDataEditChanging?.includes(
                                  delivery?.id
                                ) && "edited"
                              }`}
                            >
                              <div className="relative">
                                <input
                                  type="text"
                                  name={delivery?.id}
                                  className={`
              truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5 
            ${
              eventRecord?.fieldErrors?.[delivery?.id] &&
              "border-rose-500 focus:border-rose-500 #{!important} placeholder:text-rose-500"
            }
              `}
                                  value={
                                    eventRecord?.[delivery?.id] != undefined
                                      ? eventRecord?.[delivery?.id]
                                      : Number(delivery?.price)
                                      ? Number(delivery?.price)
                                      : ""
                                  }
                                  // onClick={(e: any) => {
                                  //   e.preventDefault();
                                  //   setTimeout(() => {
                                  //     setActiveField(
                                  //       `${[delivery?.id]}`
                                  //     );
                                  //   }, 100);
                                  // }}
                                  placeholder={selected}
                                  id={`${[delivery?.id]}`}
                                  onChange={(event: any) => {
                                    setLastChanged(undefined);

                                    MemoizedHandleTableField(
                                      event,
                                      eIndex,
                                      rIndex
                                    );
                                    // setTimeout(() => {
                                    //   setActiveField(`${[delivery?.id]}`);
                                    // }, 200);
                                  }}
                                  onClick={(e) => {
                                    // setTimeout(() => {
                                    //   refreshEventsWhileActionPerform();
                                    // }, 70);
                                  }}
                                  onKeyDown={(e: any) => {
                                    secureStateWhileTabbing(
                                      e,
                                      saveDataInStateTimer
                                    );

                                    priceFieldInputKeyDown(e);
                                  }}
                                />
                                {eventRecord?.[delivery?.id]
                                  ? ""
                                  : eventRecord?.fieldErrors?.[
                                      delivery?.id
                                    ] && (
                                      <>
                                        <TooltipPortal
                                          id={`delivery-type-${delivery.id}-error-${eIndex}-${rIndex}`}
                                          content={
                                            eventRecord?.fieldErrors?.[
                                              delivery?.id
                                            ]
                                          }
                                          place="top"
                                          variant="light"
                                          className="text-center !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-20 max-w-[9rem] !text-rose-500"
                                        />
                                        <div
                                          data-tooltip-id={`delivery-type-${delivery.id}-error-${eIndex}-${rIndex}`}
                                          className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                                        >
                                          <IKTSVG
                                            className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                                            path={Warning1}
                                          />
                                        </div>
                                      </>
                                    )}
                              </div>
                            </div>
                          ) : (
                            <div className="p-1.5 font-medium">
                              <div className="relative">
                                <input
                                  type="number"
                                  className={`bg-gray-100 text-gray-400 placeholder:text-gray-400 placeholder:truncate pointer-events-none truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-none rounded focus:border-indigo-500 focus:bg-violet-300/50 px-2.5 123 `}
                                  placeholder={selected}
                                  disabled={true}
                                  value={``}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </Fragment>
                  );
                // eticket
                // case 4:
                //   return (
                //     <Fragment
                // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                //     >
                //       {/* Ticket-Type Dropdown */}
                //       <div
                //         className={`p-1.5 font-medium ${
                //           layout.tableDataEditChanging?.includes(
                //             "sub_ticket_type"
                //           ) && "edited"
                //         }`}
                //       >
                //         <TableDropdown
                //           isCapital={true}
                //           isValueCapital={true}
                //           value={
                //             typeof eventRecord?.sub_ticket_type === "object"
                //               ? eventRecord?.sub_ticket_type
                //               : layout.ticketType
                //                   .find(
                //                     (ticket: any) => ticket.id === "eticket"
                //                   )
                //                   ?.subtypes?.filter(
                //                     (item: any) =>
                //                       item?.id === eventRecord?.sub_ticket_type
                //                   )?.[0]
                //           }
                //           isDisabled={
                //             typeof eventRecord?.ticket_type === "object" &&
                //             eventRecord?.ticket_type?.id !== "eticket"
                //               ? true
                //               : typeof eventRecord?.ticket_type === "string" &&
                //                 eventRecord?.ticket_type !== "eticket"
                //               ? true
                //               : false
                //           }
                //           isClearable={false}
                //           options={subTicketType}
                //           placeholder={"Sub Ticket.."}
                //           name="sub_ticket_type"
                //           handleOnChange={(data: any) => {
                //             setActiveField("");
                //             MemoizedHandleTableField(data, eIndex, rIndex);
                //           }}
                //           // isDisabled={isEventRecordHoldTickets}
                //           errorClassName={
                //             eventRecord?.fieldErrors?.ticket_type &&
                //             "error-field"
                //           }
                //           errorEventIndex={
                //             eventRecord?.fieldErrors?.ticket_type &&
                //             `ticket_type-${eventRecord?.id}`
                //           }
                //           errorMessage={
                //             eventRecord?.fieldErrors?.ticket_type
                //               ? eventRecord?.fieldErrors?.ticket_type
                //               : ""
                //           }
                //         />
                //       </div>
                //     </Fragment>
                //   );

                case 5:
                  return (
                    <>
                      <div
                        className={`p-1.5 font-medium 
                      ${
                        layout.tableDataEditChanging?.includes(
                          "quantity_available"
                        ) && "edited"
                      }
                      `}
                        id={`quantity_available-${event.id}-${rIndex}`}
                        // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                      >
                        {/* -{JSON.stringify(eventRecord?.id)}- */}
                        <div className="relative">
                          <input
                            disabled={isEventRecordHoldTickets}
                            id={event?.id + rIndex + `_quantity_available`}
                            type="text"
                            name="quantity_available"
                            className={`truncate h-6 max-w-[4.0625rem] text-xs font-weight-500 py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5 ${
                              eventRecord?.fieldErrors?.quantity_available &&
                              "border-rose-500 focus:border-rose-500 #{!important} placeholder:text-rose-500"
                            } ${
                              (isALLTicketUploadedWithPublish ||
                                eventRecord?.isDisabled) &&
                              "pointer-events-none bg-gray-100 !border-gray-100"
                            }
                            ${isEventRecordHoldTickets && `bg-gray-100`}
                              `}
                            value={eventRecord?.quantity_available}
                            placeholder="Quantity"
                            // onClick={(e: any) => {
                            //   e.preventDefault();
                            //   setTimeout(() => {
                            //     setActiveField(
                            //       `${event?.id}${rIndex}_quantity_available`
                            //     );
                            //   }, 1000);
                            // }}
                            onKeyDown={(e: any) => {
                              secureStateWhileTabbing(e, saveDataInStateTimer);

                              handleKeyDown(e);
                            }}
                            onChange={(e: any) => {
                              e.preventDefault();
                              setLastChanged(undefined);
                              MemoizedHandleTableField(e, eIndex, rIndex);
                              // setTimeout(() => {
                              //   setActiveField(
                              //     `${event?.id}${rIndex}_quantity_available`
                              //   );
                              // }, 1);
                            }}
                            // onClick={(e) => {
                            //   setTimeout(() => {
                            //     refreshEventsWhileActionPerform();
                            //   }, 70);
                            // }}
                          />
                          {eventRecord?.fieldErrors?.quantity_available && (
                            <>
                              <TooltipPortal
                                id={`quantity-error-${eIndex}-${rIndex}`}
                                content={
                                  eventRecord?.fieldErrors?.quantity_available
                                }
                                className="text-center !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-20 max-w-[13.75rem] !text-rose-500"
                              />
                              {/* <Tooltip
                                anchorId={`quantity-error-${eIndex}-${rIndex}`}
                                content={
                                  eventRecord?.fieldErrors?.quantity_available
                                }
                                place={rIndex === 0 ? "left" : "top"}
                                variant="light"
                                className="text-center !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-10 max-w-[9rem] !text-rose-500"
                              /> */}
                              <div
                                data-tooltip-id={`quantity-error-${eIndex}-${rIndex}`}
                                className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                              >
                                <IKTSVG
                                  className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                                  path={Warning1}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className="p-1.5 font-medium"
                        // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                      >
                        <input
                          type="number"
                          className={`bg-gray-100 truncate h-6 max-w-[3.4375rem] text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-none !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                          value={eventRecord?.quantity_sold}
                          placeholder="Sold"
                          disabled={true}
                        />
                      </div>
                    </>
                  );

                case 6:
                  return (
                    <>
                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <>
                          <div className=" p-1.5 max-w-[7.625rem] min-w-[7.625rem]">
                            <input
                              type="text"
                              className={`bg-gray-100 truncate h-6 w-full  text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                              value={
                                splitTypeValue?.name === "No Preference"
                                  ? "No preference"
                                  : splitTypeValue?.name
                              }
                              placeholder="Split type"
                              disabled={true}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`p-1.5 font-medium ${
                              layout.tableDataEditChanging?.includes(
                                "split_type"
                              ) && "edited"
                            }`}
                            key={
                              "eventFieldIn" + eventRecord?.id + columnItem.id
                            }
                          >
                            <TableDropdown
                              value={splitTypeValue}
                              isClearable={false}
                              options={layout.splitType}
                              placeholder={"Split type"}
                              name="split_type"
                              handleOnChange={(data: any) => {
                                setLastChanged("split_type");
                                MemoizedHandleTableField(data, eIndex, rIndex);
                                setTimeout(() => {
                                  refreshEventsWhileActionPerform();
                                }, 1);
                              }}
                              isDisabled={isEventRecordHoldTickets}
                              errorClassName={
                                eventRecord?.fieldErrors?.splitType &&
                                "error-field"
                              }
                              errorEventIndex={
                                eventRecord?.fieldErrors?.splitType &&
                                `splitType-${eventRecord?.id}`
                              }
                              errorMessage={
                                eventRecord?.fieldErrors?.splitType &&
                                eventRecord?.fieldErrors?.splitType
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  );
                case 7:
                  return (
                    <div
                      className={`p-1.5 font-medium ${
                        layout.tableDataEditChanging?.includes(
                          "split_quantity"
                        ) && "edited"
                      }`}
                      // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                    >
                      <input
                        type="text"
                        className={`${splitCondition} ${
                          isEventRecordHoldTickets &&
                          `bg-gray-100 pointer-events-none !border-gray-100 text-gray-400 placeholder:text-gray-400 disable`
                        }  ${
                          (isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled) &&
                          "pointer-events-none bg-gray-100 !border-gray-100"
                        }  truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                        value={eventRecord?.split_quantity}
                        placeholder="Sell In Multiples"
                        name="split_quantity"
                        id={`${event?.id}${rIndex}split_quantity`}
                        // onClick={(e) => {
                        //   setTimeout(() => {
                        //     refreshEventsWhileActionPerform();
                        //   }, 70);
                        // }}
                        onKeyDown={(e: any) => {
                          secureStateWhileTabbing(e, saveDataInStateTimer);

                          handleKeyDown(e);
                        }}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setLastChanged(undefined);
                          MemoizedHandleTableField(e, eIndex, rIndex);
                          // setTimeout(() => {
                          //   setActiveField(
                          //     `${event?.id}${rIndex}split_quantity`
                          //   );
                          // }, 200);
                        }}
                        disabled={isEventRecordHoldTickets}
                      />
                    </div>
                  );

                case 8:
                  return (
                    <div
                      className={`p-1.5 font-medium ${
                        layout.tableDataEditChanging?.includes(
                          "quantity_display"
                        ) && "edited"
                      }`}
                      // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                    >
                      <input
                        type="text"
                        className={`${
                          isEventRecordHoldTickets &&
                          `bg-gray-100 pointer-events-none !border-gray-100 text-gray-400 placeholder:text-gray-400 disable`
                        }  ${
                          (isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled) &&
                          "pointer-events-none bg-gray-100 !border-gray-100"
                        }   truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                        value={eventRecord?.quantity_display}
                        placeholder="Max display quantity"
                        name="quantity_display"
                        // onClick={(e) => {
                        //   setTimeout(() => {
                        //     refreshEventsWhileActionPerform();
                        //   }, 70);
                        // }}
                        id={`${event?.id}${rIndex}quantity_display`}
                        onKeyDown={(e: any) => {
                          secureStateWhileTabbing(e, saveDataInStateTimer);

                          handleKeyDown(e);
                        }}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setLastChanged(undefined);
                          MemoizedHandleTableField(e, eIndex, rIndex);
                          // setTimeout(() => {
                          //   setActiveField(
                          //     `${event?.id}${rIndex}quantity_display`
                          //   );
                          // }, 200);
                        }}
                        disabled={isEventRecordHoldTickets}
                      />
                    </div>
                  );

                case 9:
                  return (
                    <>
                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <>
                          <div
                            className=" p-1.5 md:max-w-[13.25rem] md:min-w-[13.25rem] max-w-[11.25rem] min-w-[11.25rem]"
                            id={`category-${event.id}-${rIndex}`}
                          >
                            <input
                              type="text"
                              className={` bg-gray-100 truncate h-6 w-full text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none !border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                              value={categoryValue?.name}
                              placeholder="Category"
                              disabled={true}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`p-1.5 font-medium auto_width_row ${
                              layout.tableDataEditChanging?.includes(
                                "category"
                              ) && "edited"
                            }`}
                            key={
                              "eventFieldIn" + eventRecord?.id + columnItem.id
                            }
                            id={`category-${event.id}-${rIndex}`}
                          >
                            <TableDropdown
                              value={
                                eventRecord?.category?.id
                                  ? eventRecord?.category
                                  : event?.categories &&
                                    event?.categories.find(
                                      (category: any) =>
                                        Number(category.id) ===
                                        Number(eventRecord?.category)
                                    )
                              }
                              options={uniqueByNameWithPriority(
                                event?.categories,
                                eventRecord?.category?.id
                                  ? eventRecord?.category?.id
                                  : eventRecord?.category
                              )}
                              placeholder={"Category"}
                              isCapital={true}
                              isValueCapital={true}
                              name="category"
                              handleOnChange={(data: any) => {
                                setLastChanged("category");
                                MemoizedHandleTableField(data, eIndex, rIndex);
                                setTimeout(() => {
                                  refreshEventsWhileActionPerform();
                                }, 1);
                              }}
                              isDisabled={isEventRecordHoldTickets}
                              errorClassName={
                                eventRecord?.fieldErrors?.category &&
                                "error-field"
                              }
                              errorEventIndex={
                                eventRecord?.fieldErrors?.category &&
                                `category-${eventRecord?.id}`
                              }
                              errorMessage={
                                eventRecord?.fieldErrors?.category
                                  ? eventRecord?.fieldErrors?.category
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  );

                case 10:
                  return (
                    <>
                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <>
                          <div className=" p-1.5 md:max-w-[13.25rem] md:min-w-[13.25rem] max-w-[11.25rem] min-w-[11.25rem]">
                            <input
                              type="text"
                              className={`bg-gray-100 w-full  truncate h-6  text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                              value={sectionValue?.name?.trim()}
                              placeholder="Section"
                              disabled={true}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`p-1.5 font-medium auto_width_row ${
                              layout.tableDataEditChanging?.includes(
                                "section"
                              ) && "edited"
                            }`}
                            key={
                              "eventFieldIn" + eventRecord?.id + columnItem.id
                            }
                          >
                            <TableDropdown
                              value={
                                eventRecord?.section && eventRecord?.section?.id
                                  ? eventRecord?.section
                                  : eventRecord?.section &&
                                    event?.categories &&
                                    Array.isArray(event?.categories) &&
                                    event?.categories
                                      .find(
                                        (category: any) =>
                                          Number(category.id) ===
                                          Number(eventRecord?.category)
                                      )
                                      ?.sections?.find(
                                        (item: any) =>
                                          Number(item?.id) ===
                                          Number(eventRecord?.section)
                                      )
                              }
                              options={
                                eventRecord?.category?.sections
                                  ? uniqueByNameWithPriority(
                                      eventRecord?.category?.sections,
                                      eventRecord?.section?.id
                                        ? eventRecord?.section?.id
                                        : eventRecord?.section
                                    )
                                  : event?.categories &&
                                    Array.isArray(event?.categories) &&
                                    uniqueByNameWithPriority(
                                      event?.categories.find(
                                        (category: any) =>
                                          Number(category.id) ===
                                          Number(eventRecord?.category)
                                      )?.sections,
                                      eventRecord?.section?.id
                                        ? eventRecord?.section?.id
                                        : eventRecord?.section
                                    )
                              }
                              placeholder={"Section/block"}
                              isCapital={true}
                              isValueCapital={true}
                              name="section"
                              handleOnChange={(data: any) => {
                                setLastChanged("section");
                                MemoizedHandleTableField(data, eIndex, rIndex);
                                setTimeout(() => {
                                  refreshEventsWhileActionPerform();
                                }, 1);
                              }}
                              isDisabled={isEventRecordHoldTickets}
                              errorClassName={
                                eventRecord?.fieldErrors?.section &&
                                "error-field"
                              }
                              errorEventIndex={
                                eventRecord?.fieldErrors?.section &&
                                `section-${eventRecord?.id}`
                              }
                              errorMessage={
                                eventRecord?.fieldErrors?.section
                                  ? eventRecord?.fieldErrors?.section
                                  : ""
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  );

                case 11:
                  return (
                    <div
                      className={`p-1.5 font-medium ${
                        layout.tableDataEditChanging?.includes("row") &&
                        "edited"
                      }`}
                      // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                    >
                      <input
                        type="text"
                        className={`${
                          isEventRecordHoldTickets &&
                          `pointer-events-none bg-gray-100 !border-gray-100 text-gray-400 placeholder:text-gray-400 disable`
                        }  ${
                          (isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled) &&
                          "pointer-events-none bg-gray-100 !border-gray-100"
                        }  h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                        value={eventRecord?.row && eventRecord?.row}
                        placeholder="Row"
                        name="row"
                        // onClick={(e: any) => {
                        //   e.preventDefault();
                        //   setTimeout(() => {
                        //     setActiveField(`${event?.id}${rIndex}row`);
                        //   }, 100);
                        // }}
                        id={`${event?.id}${rIndex}row`}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setLastChanged(undefined);
                          MemoizedHandleTableField(e, eIndex, rIndex);
                          // setTimeout(() => {
                          //   setActiveField(`${event?.id}${rIndex}row`);
                          // }, 200);
                        }}
                        // onClick={(e) => {
                        //   setTimeout(() => {
                        //     refreshEventsWhileActionPerform();
                        //   }, 70);
                        // }}
                        disabled={isEventRecordHoldTickets}
                        onKeyDown={(e: any) =>
                          secureStateWhileTabbing(e, saveDataInStateTimer)
                        }
                      />
                    </div>
                  );

                case 12:
                  return (
                    <div
                      className={`p-1.5 ${
                        layout.tableDataEditChanging?.includes("first_seat") &&
                        "edited"
                      }`}
                      // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                    >
                      <input
                        type="text"
                        className={`${
                          isEventRecordHoldTickets &&
                          `pointer-events-none bg-gray-100 !border-gray-100 text-gray-400 placeholder:text-gray-400 disable`
                        }  ${
                          (isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled) &&
                          "pointer-events-none bg-gray-100 !border-gray-100"
                        } h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5`}
                        value={eventRecord?.first_seat}
                        placeholder="First seat"
                        name="first_seat"
                        id={`${event?.id}${rIndex}first_seat`}
                        // onClick={(e) => {
                        //   setTimeout(() => {
                        //     refreshEventsWhileActionPerform();
                        //   }, 70);
                        // }}
                        onKeyDown={(e: any) => {
                          secureStateWhileTabbing(e, saveDataInStateTimer);
                          handleKeyDown(e);
                        }}
                        onChange={(e: any) => {
                          // e.preventDefault();
                          setLastChanged(undefined);
                          MemoizedHandleTableField(e, eIndex, rIndex);
                          // setTimeout(() => {
                          //   setActiveField(
                          //     `${event?.id}${rIndex}first_seat`
                          //   );
                          // }, 200);
                        }}
                        disabled={isEventRecordHoldTickets}
                      />
                    </div>
                  );

                // face value
                case 13:
                  return (
                    <div
                      className={`p-1.5 font-medium ${
                        layout.tableDataEditChanging?.includes("face_value") &&
                        "edited"
                      }`}
                      id={`face_value-${event.id}-${rIndex}`}
                      // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                    >
                      <div>
                        <PriceField
                          value={
                            typeof eventRecord?.face_value === "object"
                              ? eventRecord?.face_value?.value
                              : decimalNumber(eventRecord?.face_value)
                          }
                          currenncySymbol={
                            eventRecord?.face_value_currency
                              ? eventRecord?.face_value_currency
                              : eventRecord?.face_value?.symbol
                          }
                          placeholder={"Face Value"}
                          textFielClassName={`truncate`}
                          name="face_value"
                          // handleOnBlur={(data: any) => {
                          //   const value = decimalNumber(
                          //     data.target?.value?.value
                          //   );
                          //   let event = {
                          //     target: {
                          //       name: data.target.name,
                          //       value: value,
                          //     },
                          //   };

                          //   MemoizedHandleTableField(
                          //     event,
                          //     eIndex,
                          //     rIndex
                          //   );
                          // }}
                          id={`${event?.id}${rIndex}face_value`}
                          // onClick={(e: any) => {
                          //   setTimeout(() => {
                          //     refreshEventsWhileActionPerform();
                          //   }, 70);
                          // }}
                          // onClick={(e: any) => {
                          //   e.preventDefault();
                          //   setTimeout(() => {
                          //     setActiveField(
                          //       `${event?.id}${rIndex}face_value`
                          //     );
                          //   }, 100);
                          // }}
                          handleOnChange={(data: any) => {
                            data?.target?.value?.event?.preventDefault();
                            setLastChanged(undefined);
                            MemoizedHandleTableField(data, eIndex, rIndex);
                            // setTimeout(() => {
                            //   setActiveField(
                            //     `${event?.id}${rIndex}face_value`
                            //   );
                            // }, 200);
                          }}
                          isDisabled={
                            isEventRecordHoldTickets ||
                            isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled
                          }
                          tabTiming={saveDataInStateTimer}
                          errorClass={`${
                            eventRecord?.fieldErrors?.face_value
                              ? eventRecord?.fieldErrors?.face_value
                              : ""
                          }`}
                          errorMessage={`${
                            eventRecord?.fieldErrors?.face_value
                              ? eventRecord?.fieldErrors?.face_value
                              : ""
                          }`}
                          errorEventIndex={
                            eventRecord?.fieldErrors?.face_value &&
                            `face_value-${eventRecord?.id}`
                          }
                        />
                      </div>
                    </div>
                  );

                // process value

                case 14:
                  return (
                    !layout?.proceedPriceToggle && (
                      <div
                        className={`p-1.5 font-medium proceed-price ${
                          layout.tableDataEditChanging?.includes(
                            "sell_price"
                          ) && "edited"
                        }`}
                        id={`sell_price-${event.id}-${rIndex}`}
                        // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                      >
                        <div>
                          <PriceField
                            // sell_price
                            value={
                              typeof eventRecord?.sell_price === "object"
                                ? eventRecord?.sell_price?.value
                                : decimalNumber(eventRecord?.sell_price)
                            }
                            id={`${event?.id}${rIndex}sell_price`}
                            currenncySymbol={
                              eventRecord?.sell_price_currency
                                ? eventRecord?.sell_price_currency
                                : eventRecord?.sell_price?.symbol
                            }
                            placeholder={"Proceed Price"}
                            className={`${
                              eventRecord?.errors?.sell_price &&
                              "focus:border-rose-500 border-rose-500 text-rose-500"
                            }`}
                            textFielClassName={`${
                              eventRecord?.errors?.sell_price &&
                              "focus:border-rose-500 border-rose-500 text-rose-500"
                            } truncate`}
                            name="sell_price"
                            // onClick={(e: any) => {
                            //   setTimeout(() => {
                            //     refreshEventsWhileActionPerform();
                            //   }, 70);
                            // }}
                            handleOnChange={(data: any) => {
                              data?.target?.value?.event?.preventDefault();
                              setLastChanged(undefined);
                              MemoizedHandleTableField(data, eIndex, rIndex);
                            }}
                            errorClass={`${
                              eventRecord?.fieldErrors?.sell_price
                                ? eventRecord?.fieldErrors?.sell_price
                                : ""
                            }`}
                            errorMessage={`${
                              eventRecord?.fieldErrors?.sell_price
                                ? eventRecord?.fieldErrors?.sell_price
                                : ""
                            }`}
                            errorEventIndex={
                              eventRecord?.fieldErrors?.sell_price &&
                              `sell_price-${eventRecord?.id}`
                            }
                            handleOnFocus={(data: any) => {
                              focusOnPriceField(data, eIndex, rIndex);
                            }}
                            tabTiming={saveDataInStateTimer}
                            isPriceField={
                              priceFiledFocused?.data &&
                              priceFiledFocused?.eIndex === eIndex &&
                              priceFiledFocused?.rIndex === rIndex
                                ? {
                                    withoutCategory: categoryExist(
                                      event,
                                      eventRecord,
                                      "withoutcategory"
                                    ),
                                    withCategory: categoryExist(
                                      event,
                                      eventRecord,
                                      "withcategory"
                                    ),
                                    isTableField: false,
                                    isFocused: priceFiledFocused?.data,
                                  }
                                : false
                            }
                            fieldMessage={
                              eventRecord?.avg_price &&
                              categoryExist(event, eventRecord, "withcategory")
                                ? priceCalculation(
                                    typeof eventRecord?.sell_price === "object"
                                      ? eventRecord?.sell_price?.value
                                      : eventRecord?.sell_price,
                                    eventRecord?.avg_price
                                      ? Number(eventRecord?.avg_price)
                                      : null,
                                    priceFactor
                                  )
                                : false
                            }
                            isValidationPopupVisible={
                              categoryExist(
                                event,
                                eventRecord,
                                "withcategory"
                              ) && !eventRecord?.avg_price
                                ? true
                                : false
                            }
                            isPriceTable={false}
                            indexPlusLength={{
                              rIndex: rIndex,
                              recordLength: event?.eventRecords?.length,
                            }}
                            avgPrice={eventRecord?.avg_price}
                            isDisabled={
                              isEventRecordHoldTickets &&
                              eventRecord?.quantity_available <
                                eventRecord?.listing_ticket_files?.length
                            }
                          />
                        </div>
                      </div>
                    )
                  );

                case 15:
                  return (
                    <>
                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <>
                          <div className="p-1.5 max-w-[13.25rem] min-w-[13.25rem]">
                            <input
                              type="text"
                              className={`bg-gray-100 truncate w-full h-6  text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none !border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                              value={benefitsValue?.name}
                              placeholder="Benefits"
                              disabled={true}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`p-1.5 font-medium auto_width_row ${
                              layout.tableDataEditChanging?.includes(
                                "benefits"
                              ) && "edited"
                            }`}
                            key={
                              "eventFieldIn" + eventRecord?.id + columnItem.id
                            }
                          >
                            <TableMultiDropdown
                              name="benefits"
                              options={layout.benefits}
                              value={benefitsValue}
                              classNamePrefix={"table_multiDropdown"}
                              placeholder={
                                benefitsValue && size(benefitsValue) > 0
                                  ? ""
                                  : "Benefits"
                              }
                              handleOnChange={(data: any) => {
                                setLastChanged(undefined);
                                MemoizedHandleTableField(data, eIndex, rIndex);
                                // setTimeout(() => {
                                //   refreshEventsWhileActionPerform();
                                // }, 1);
                              }}
                              isDisabled={isEventRecordHoldTickets}
                              handleOnSelection={(
                                data: any,
                                visibleOptions: any
                              ) => {
                                handleOnSelection(
                                  data,
                                  eIndex,
                                  rIndex,
                                  layout.benefits,
                                  "benefits",
                                  visibleOptions,
                                  benefitsValue
                                );
                              }}
                            />
                          </div>
                        </>
                      )}
                    </>
                  );

                case 16:
                  return (
                    <>
                      {isALLTicketUploadedWithPublish ||
                      eventRecord?.isDisabled ? (
                        <>
                          {" "}
                          <div className="p-1.5 max-w-[13.25rem] min-w-[13.25rem]">
                            <input
                              type="text"
                              className={`bg-gray-100 truncate w-full h-6  text-xs font-weight-500 py-0 rounded focus:border-indigo-500 focus:bg-violet-300/50 pointer-events-none !border-gray-100 !text-violet-800 placeholder:!text-violet-800 disable px-2.5`}
                              value={restrictionValue?.name}
                              placeholder="Restrictions"
                              disabled={true}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`p-1.5 font-medium auto_width_row ${
                              layout.tableDataEditChanging?.includes(
                                "restrictions"
                              ) && "edited"
                            }`}
                            key={
                              "eventFieldIn" + eventRecord?.id + columnItem.id
                            }
                          >
                            <TableMultiDropdown
                              name="restrictions"
                              options={layout.restrictions}
                              value={restrictionValue}
                              classNamePrefix={"table_multiDropdown"}
                              placeholder={
                                restrictionValue && size(restrictionValue) > 0
                                  ? ""
                                  : "Restrictions"
                              }
                              handleOnChange={(data: any) => {
                                setLastChanged(undefined);
                                MemoizedHandleTableField(data, eIndex, rIndex);
                                // setTimeout(() => {
                                //   refreshEventsWhileActionPerform();
                                // }, 1);
                              }}
                              handleOnSelection={(
                                data: any,
                                visibleOptions: any
                              ) => {
                                handleOnSelection(
                                  data,
                                  eIndex,
                                  rIndex,
                                  layout.restrictions,
                                  "restrictions",
                                  visibleOptions,
                                  restrictionValue
                                );
                              }}
                              isDisabled={isEventRecordHoldTickets}
                            />
                          </div>
                        </>
                      )}
                    </>
                  );

                case 17:
                  return (
                    <div
                      className={`relative p-1.5 font-medium ${
                        layout.tableDataEditChanging?.includes(
                          "ticketsInHands"
                        ) && "edited"
                      }`}
                      // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                    >
                      <label
                        className={` in-hand-check px-4 cursor-pointer py-1 border rounded text-center h-6 flex items-center justify-center ${
                          isEventRecordHoldTickets
                            ? `bg-gray-100 pointer-events-none`
                            : `bg-white`
                        }  ${
                          (isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled) &&
                          "pointer-events-none !bg-gray-100"
                        } `}
                      >
                        <input
                          id={`inHand-${eIndex}${rIndex}`}
                          type="checkbox"
                          className={`w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 
                        rounded-sm focus:ring-indigo-500 cursor-pointer ${
                          isEventRecordHoldTickets &&
                          `pointer-events-none disable`
                        } ${
                            isALLTicketUploadedWithPublish &&
                            "pointer-events-none "
                          } `}
                          checked={eventRecord?.ticketsInHands}
                          name={"ticketsInHands"}
                          onChange={(data: any) => {
                            setLastChanged("ticketsInHands");
                            MemoizedHandleTableField(data, eIndex, rIndex);
                          }}
                          onClick={(e: any) => {
                            // setTimeout(() => {
                            //   refreshEventsWhileActionPerform();
                            // }, 1);
                          }}
                          disabled={isEventRecordHoldTickets}
                        />
                      </label>
                    </div>
                  );

                case 18:
                  return (
                    <div
                      className={`relative p-1.5 font-medium ${
                        layout.tableDataEditChanging?.includes(
                          "date_to_ship"
                        ) && "edited"
                      }`}

                      // key={"eventFieldIn" + eventRecord?.id + columnItem.id}
                    >
                      <div className="w-[7.5rem]">
                        <FormDatePickerField
                          name="date_to_ship"
                          maxDate={event?.date ? new Date(event?.date) : null}
                          placeholder={`Date to ship`}
                          value={eventRecord?.date_to_ship}
                          handleOnChange={(data: any) => {
                            if (data.target.value === "") {
                              data.target.value = new Date();
                            }
                            setLastChanged("date_to_ship");

                            MemoizedHandleTableField(data, eIndex, rIndex);
                            setTimeout(() => {
                              refreshEventsWhileActionPerform();
                            }, 1);
                          }}
                          disabledKeyboardNavigation={false}
                          isDisabled={
                            isEventRecordHoldTickets ||
                            eventRecord?.ticketsInHands ||
                            isALLTicketUploadedWithPublish ||
                            eventRecord?.isDisabled
                          }
                          error={
                            eventRecord?.fieldErrors?.date_to_ship
                              ? true
                              : false
                          }
                          intializeField={eventRecord?.intializeField}
                        />
                        {eventRecord?.fieldErrors?.date_to_ship && (
                          <>
                            <Tooltip
                              anchorId={`dateship-error-${eventRecord?.id}`}
                              content={eventRecord?.fieldErrors?.date_to_ship}
                              place="top"
                              variant="light"
                              className="text-center !bg-white !opacity-100 shadow px-3 py-1 #{!important} font-medium text-xs z-10 max-w-[10rem] !text-rose-500"
                            />
                            <div
                              id={`dateship-error-${eventRecord?.id}`}
                              className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                            >
                              <IKTSVG
                                className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                                path={Warning1}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
              }
            })}
            {/* ACTION BUTTONS */}
            <Tooltip
              anchorId={`pricePerExchange-tooltip${eIndex}${rIndex}`}
              content="Price per exchange"
              place="top"
              variant="light"
              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
            />
            {/* <Tooltip
            anchorId={`status-${eIndex}${rIndex}`}
            content={`${
              eventRecord?.status === "yes"
                ? "Publish"
                : "Unpublish"
            }`}
            place="top"
            variant="light"
            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
          /> */}
            <div
              className={`p-0 z-[2] md:sticky relative right-0 min-w-[12.5625rem] max-w-[12.5625rem]  ml-auto bg-white sticky-icons ${
                isGodAdmin && eventRecord?.isLock === true
                  ? "pointer-events-none "
                  : ""
              } ${singleRecordLoader && "!pointer-events-none"} ${
                layout.proceedPriceToggle &&
                "!min-w-[20.75rem] !max-w-[20.75rem]"
              } `}
            >
              <React.Fragment key={"eventFieldIn" + eventRecord?.id + rIndex}>
                <div
                  className={
                    eventRecord?.isInputChange &&
                    layout?.isTableEditable?.length === 0
                      ? singleEdit
                      : multiEdit
                  }
                  id="left-shad-list"
                >
                  <div
                    className={`${
                      eventRecord?.isInputChange &&
                      layout?.isTableEditable?.length === 0
                        ? "flex"
                        : "flex z-[1]"
                    }  `}
                  >
                    {layout?.proceedPriceToggle && (
                      <div
                        className={`${
                          eventRecord?.isInputChange &&
                          layout?.isTableEditable?.length === 0
                            ? "flex items-center proceed-price border-r px-2.5"
                            : "flex items-center proceed-price border-r px-2.5 max-w-[8.1819rem]"
                        } `}
                        id={`sell_price-${event.id}-${rIndex}`}
                      >
                        <PriceField
                          value={
                            typeof eventRecord?.sell_price === "object"
                              ? eventRecord?.sell_price?.value
                              : decimalNumber(eventRecord?.sell_price)
                          }
                          id={`${event?.id}${rIndex}sell_price`}
                          currenncySymbol={
                            eventRecord?.sell_price_currency
                              ? eventRecord?.sell_price_currency
                              : eventRecord?.sell_price?.symbol
                          }
                          placeholder={"Proceed Price"}
                          className={`${
                            eventRecord?.errors?.sell_price &&
                            "focus:border-rose-500 border-rose-500 text-rose-500"
                          }`}
                          textFielClassName={`${
                            eventRecord?.errors?.sell_price &&
                            "focus:border-rose-500 border-rose-500 text-rose-500"
                          } truncate`}
                          name="sell_price"
                          // onClick={(e: any) => {
                          //   setTimeout(() => {
                          //     refreshEventsWhileActionPerform();
                          //   }, 70);
                          // }}
                          handleOnChange={(data: any) => {
                            data?.target?.value?.event?.preventDefault();
                            setLastChanged(undefined);
                            MemoizedHandleTableField(data, eIndex, rIndex);
                          }}
                          errorClass={`${
                            eventRecord?.fieldErrors?.sell_price
                              ? eventRecord?.fieldErrors?.sell_price
                              : ""
                          }`}
                          errorMessage={`${
                            eventRecord?.fieldErrors?.sell_price
                              ? eventRecord?.fieldErrors?.sell_price
                              : ""
                          }`}
                          errorEventIndex={
                            eventRecord?.fieldErrors?.sell_price &&
                            `sell_price-${eventRecord?.id}`
                          }
                          handleOnFocus={(data: any) => {
                            focusOnPriceFieldSticky(data, eIndex, rIndex);
                          }}
                          isPriceField={
                            priceFiledFocusedSticky?.data &&
                            priceFiledFocusedSticky?.eIndex === eIndex &&
                            priceFiledFocusedSticky?.rIndex === rIndex
                              ? {
                                  withoutCategory: categoryExist(
                                    event,
                                    eventRecord,
                                    "withoutcategory"
                                  ),
                                  withCategory: categoryExist(
                                    event,
                                    eventRecord,
                                    "withcategory"
                                  ),
                                  isTableField: false,
                                  isFocused: priceFiledFocusedSticky?.data,
                                }
                              : false
                          }
                          fieldMessage={
                            eventRecord?.avg_price &&
                            categoryExist(event, eventRecord, "withcategory")
                              ? priceCalculation(
                                  typeof eventRecord?.sell_price === "object"
                                    ? eventRecord?.sell_price?.value
                                    : eventRecord?.sell_price,
                                  eventRecord?.avg_price
                                    ? Number(eventRecord?.avg_price)
                                    : null,
                                  priceFactor
                                )
                              : false
                          }
                          isValidationPopupVisible={
                            categoryExist(event, eventRecord, "withcategory") &&
                            !eventRecord?.avg_price
                              ? true
                              : false
                          }
                          isPriceTable={true}
                          indexPlusLength={{
                            rIndex: rIndex,
                            recordLength: event?.eventRecords?.length,
                          }}
                          avgPrice={eventRecord?.avg_price}
                          isDisabled={
                            isEventRecordHoldTickets &&
                            eventRecord?.quantity_available <
                              eventRecord?.listing_ticket_files?.length
                          }
                        />
                      </div>
                    )}

                    {eventRecord?.isInputChange &&
                    layout?.isTableEditable?.length === 0 ? (
                      <div className="flex items-center justify-center min-w-[12.5625rem]">
                        {/* CANCEL RECORD BUTTON */}
                        <button
                          type="button"
                          className="group flex mx-2 items-center justify-center h-6 w-6 text-white bg-white border border-gray-300 hover:bg-indigo-500 hover:border-indigo-500 transition rounded"
                          onClick={() => cancelRecord(eventRecord)}
                          id={`record-cancel-tooltip-${event?.id}-${eventRecord?.id}`}
                        >
                          <Tooltip
                            anchorId={`record-cancel-tooltip-${event?.id}-${eventRecord?.id}`}
                            content="Cancel"
                            place={rIndex === 0 ? "left" : "top"}
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                          />
                          <IKTSVG
                            className="fill-violet-500 group-hover:fill-white"
                            path={Cross}
                            svgClassName="w-[.5966rem] h-[.5966rem]"
                          />
                        </button>

                        {/* UPDATE RECORD BUTTON */}
                        <button
                          type="button"
                          className="flex mx-2 items-center justify-center h-6 w-6 text-white bg-violet-500 hover:bg-indigo-500 transition rounded"
                          onClick={(data: any) => {
                            eventRecord.loader = true;
                            updateRecord(
                              data,
                              eventRecord,
                              true,
                              eIndex,
                              rIndex
                            );
                          }}
                          id={`record-update-tooltip-${event?.id}-${eventRecord?.id}`}
                        >
                          <Tooltip
                            anchorId={`record-update-tooltip-${event?.id}-${eventRecord?.id}`}
                            content="Confirm"
                            place={rIndex === 0 ? "left" : "top"}
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 text-violet-800"
                          />
                          <IKTSVG
                            path={Check}
                            className="fill-white"
                            svgClassName="w-[.7585rem] h-[.6452rem]"
                          />
                        </button>
                      </div>
                    ) : (
                      <div className="icon-wrap flex">
                        <button
                          className={`group flex flex-auto items-center justify-center p-1 w-10 max-w-[2.5rem] h-[2.5rem] border-r ${
                            (Number(eventRecord?.quantity_available) ===
                              Number(
                                eventRecord?.listing_ticket_files?.length ||
                                  eventRecord?.mobile_links?.length
                              ) ||
                              eventRecord?.isDisabled ||
                              isEventRecordHoldTickets) &&
                            "pointer-events-none"
                          }  `}
                          type="button"
                          id={`tickets-in-hand-tooltip${eIndex}${rIndex}`}
                          onClick={() => {
                            // ticketInHandFromListings(eIndex, rIndex);

                            let target = {
                              name: "ticketsInHands",
                              checked: !eventRecord?.ticketsInHands,
                              type: "checkbox",
                            };
                            MemoizedHandleTableField(
                              { target },
                              eIndex,
                              rIndex
                            );
                          }}
                        >
                          <Tooltip
                            anchorId={`tickets-in-hand-tooltip${eIndex}${rIndex}`}
                            content={
                              eventRecord?.ticketsInHands
                                ? "Remove tickets in hand"
                                : "Tickets in hand"
                            }
                            place={
                              rIndex === 0 || rIndex === 1 ? "left" : "top"
                            }
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                            style={{ marginTop: "8px" }}
                          />
                          {eventRecord?.ticketsInHands ? (
                            <IKTSVG
                              path={handIcon}
                              svgClassName={` ${
                                Number(eventRecord?.quantity_available) ===
                                Number(
                                  eventRecord?.listing_ticket_files?.length ||
                                    eventRecord?.mobile_links?.length
                                )
                                  ? "fill-gray-400"
                                  : "fill-green-600"
                              }  w-3 group-hover:fill-indigo-500`}
                            />
                          ) : (
                            <IKTSVG
                              path={handCross}
                              svgClassName="fill-violet-500 w-3 group-hover:fill-indigo-500"
                            />
                          )}
                        </button>
                        {/* {JSON.stringify(eventRecord?.ticket_type)} */}
                        <button
                          className={`flex flex-auto items-center justify-center p-1 w-10 max-w-[2.5rem] h-[2.5rem] border-r ${
                            isEventRecordError && "border-t-rose-500"
                          } ${
                            (disabledAll || isEventRecordHoldTickets) &&
                            "pointer-events-none"
                          }`}
                          type="button"
                          id={`eTicket-upload-tooltip${event?.id}-${eventRecord?.id}`}
                          // data-bs-toggle="offcanvas"
                          // data-bs-target="#ppeRightCanvas"
                          // aria-controls="ppeRightCanvas"
                          onClick={(e: any) => {
                            layout.setLogsData(null);
                            const isUploaded = checkIsFullfillUploadExist(
                              event,
                              eventRecord
                            );
                            if (
                              eventRecord?.ticket_type?.id !== "mobile-qr" &&
                              eventRecord?.ticket_type?.id !== "pdf" &&
                              eventRecord?.ticket_type !== "eticket" &&
                              eventRecord?.ticket_type !== "mobile-link" &&
                              eventRecord?.ticket_type?.id !== "mobile-link"
                            ) {
                              e.preventDefault();
                            } else {
                              if (isUploaded?.length === 0) {
                                handleEticket(event, eventRecord);
                                setIsOpenTicketPopUp(true);
                                if (editData?.status === true) {
                                  display(false, tableRefSide?.current?.style);
                                  setEditData({ status: false });
                                }
                              } else {
                                showAlert(
                                  "Bulk upload selection can not include listings with tickets already uploaded",
                                  true,
                                  3000
                                );
                              }
                            }
                          }}
                        >
                          <Tooltip
                            anchorId={`eTicket-upload-tooltip${event?.id}-${eventRecord?.id}`}
                            // content={`Upload Ticket`}
                            content={`${
                              eventRecord?.quantity_available ===
                              (eventRecord?.listing_ticket_files?.length ||
                                eventRecord?.mobile_links?.length)
                                ? "Ticket(s) uploaded"
                                : eventRecord?.ticket_type?.id !==
                                    "mobile-qr" &&
                                  eventRecord?.ticket_type?.id !== "pdf" &&
                                  eventRecord?.ticket_type !== "eticket" &&
                                  eventRecord?.ticket_type !== "mobile-link" &&
                                  eventRecord?.ticket_type?.id !== "mobile-link"
                                ? "Not available"
                                : "Upload Ticket"
                            }`}
                            place={
                              rIndex === 0 || rIndex === 1 ? "left" : "top"
                            }
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                          />

                          <IKTSVG
                            className={`flex items-center justify-center  hover:fill-indigo-500 transition ${
                              Number(eventRecord?.quantity_available) > 0 &&
                              Number(eventRecord?.quantity_available) ===
                                Number(
                                  eventRecord?.listing_ticket_files?.length ||
                                    eventRecord?.mobile_links?.length
                                ) &&
                              (eventRecord?.ticket_type?.id === "mobile-qr" ||
                                eventRecord?.ticket_type?.id === "pdf" ||
                                eventRecord?.ticket_type === "eticket" ||
                                eventRecord?.ticket_type === "mobile-link" ||
                                eventRecord?.ticket_type?.id === "mobile-link")
                                ? "!fill-green-600"
                                : "fill-violet-500"
                            } 
                      
                          ${
                            eventRecord?.ticket_type?.id !== "mobile-qr" &&
                            eventRecord?.ticket_type?.id !== "pdf" &&
                            eventRecord?.ticket_type !== "eticket" &&
                            eventRecord?.ticket_type !== "mobile-link" &&
                            eventRecord?.ticket_type?.id !== "mobile-link"
                              ? "!fill-gray-400"
                              : ""
                          }                          
                
                          ${
                            eventRecord?.fieldErrors?.["quantity_available"] ===
                              TicketError && "!fill-rose-500"
                          }
                          `}
                            path={Upload}
                            svgClassName="w-[.8125rem] h-[.6875rem]"
                          />
                        </button>

                        {/* VIEW BUTTON - SIDEBAR */}
                        {/* <button
              className={`group flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.5rem] ${
                isEventRecordError && "border-t-rose-500"
              } border-r `}
              type="button"
              onClick={() => {
                if (
                  editData?.status === true &&
                  editData?.record?.id === eventRecord?.id
                ) {
                  setEditData({
                    status: false,
                  });
                  display(
                    false,
                    tableRefSide?.current?.style
                  );
                } else {
                  setEditData({
                    record: { ...eventRecord },
                    event: { ...event },
                    status: true,
                  });
                  setMatchIndexView({
                    pIndex: eIndex,
                    cIndex: rIndex,
                  });
                  layout.setIsEditPopUpActive(true);
                }
              }}
              id={`view${eIndex}${rIndex}`}
              // data-bs-toggle="offcanvas"
              // data-bs-target="#offcanvasScrolling"
              // aria-controls="offcanvasScrolling"
            >
              <Tooltip
                anchorId={`view${eIndex}${rIndex}`}
                content="View"
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 mt-1.5"
              />
              <IKTSVG
                className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition`}
                path={EyeSVG}
                svgClassName="w-4 h-[.6875rem]"
              />
            </button> */}
                        <button
                          className={`flex flex-auto items-center justify-center p-1 w-10 max-w-[2.5rem] h-[2.5rem] border-r ${
                            (isEventRecordError || isEventRecordBEError) &&
                            "border-t-rose-500"
                          }`}
                          type="button"
                          id="visibility_addInventory"
                          onClick={() => {
                            layout.setLogsData(null);
                            setIsOpenListingQualityPopup(true);
                          }}
                        >
                          <div
                            data-tooltip-id={`eTicket-upload-tooltip-visibility${eIndex}${rIndex}`}
                            // className={` table-meter flex gap-[.0875rem] svg-wrap visibility-case${eventRecord?.oldVisibility}-${eventRecord?.visibility}`}
                            className={` table-meter flex gap-[.0875rem] svg-wrap 
                      
                      ${
                        categoryExist(event, eventRecord, "withcategory")
                          ? colorForMeter(
                              eventRecord?.avg_price
                                ? priceCalculation(
                                    typeof eventRecord?.sell_price === "object"
                                      ? eventRecord?.sell_price?.value
                                      : eventRecord?.sell_price,
                                    eventRecord?.avg_price
                                      ? Number(eventRecord?.avg_price)
                                      : null,
                                    priceFactor
                                  )
                                : false
                            )
                          : ""
                      } 
                      
                      ${
                        layout.animationForVisibility?.eIndex === eIndex &&
                        layout.animationForVisibility?.rIndex === rIndex &&
                        layout.animationForVisibility?.flag
                          ? ` visibility-case${
                              eventRecord?.oldVisibility > 2
                                ? eventRecord?.oldVisibility - 2
                                : eventRecord?.oldVisibility
                            }-${visiblityMeterForBelowListing(
                              eventRecord?.oldVisibility,
                              eventRecord?.visibility
                            )}`
                          : `visibility-case${visiblityMeterForBelowListing(
                              eventRecord?.oldVisibility,
                              eventRecord?.visibility
                            )}`
                      }
                      
                      
                      `}
                          >
                            <span className="s_block w-[.1563rem] h-3 bg-gray-300 rounded-l-[1px]"></span>
                            <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span>
                            <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span>
                            {/* <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span> */}
                            <span className="s_block w-[.1563rem] h-3 bg-gray-300 rounded-r-[1px]"></span>
                          </div>
                          {/* <IKTSVG
                className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                path={
                  eventRecord?.visibility === 1
                    ? VisRed
                    : eventRecord?.visibility === 2
                      ? VisOrange
                      : eventRecord?.visibility === 3
                        ? VisYellow
                        : eventRecord?.visibility === 4
                          ? VishalfGreen
                          : eventRecord?.visibility === 5
                            ? VisGreen
                            : eventRecord?.visibility === 0
                              ? VisGray
                              : VisGray
                }
                svgClassName="w-4 h-4"
              /> */}
                        </button>

                        <TooltipPortal
                          id={`eTicket-upload-tooltip-visibility${eIndex}${rIndex}`}
                          content={
                            // eventRecord?.visibility === 1
                            //   ? `Listing Quality Low`
                            //   : eventRecord?.visibility === 2
                            //   ? `Listing Quality Low`
                            //   : eventRecord?.visibility === 3
                            //   ? `Listing Quality Level 1`
                            //   : eventRecord?.visibility === 4
                            //   ? `Listing Quality Level 2`
                            //   : eventRecord?.visibility === 5
                            //   ? `Listing Quality Level 3`
                            //   : eventRecord?.visibility === 6
                            //   ? `Listing Quality Level 4`
                            //   : ""
                            tooltipForListingMeter(
                              eventRecord?.visibility,
                              eventRecord?.avg_price
                                ? priceCalculation(
                                    typeof eventRecord?.sell_price === "object"
                                      ? eventRecord?.sell_price?.value
                                      : eventRecord?.sell_price,
                                    eventRecord?.avg_price
                                      ? Number(eventRecord?.avg_price)
                                      : null,
                                    priceFactor
                                  )
                                : false,
                              event
                            )
                          }
                          positionStrategy="fixed"
                          place={rIndex === 0 || rIndex === 1 ? "left" : "top"}
                          variant="light"
                          className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
                        />

                        {/* <Tooltip
                      anchorId={`eTicket-upload-tooltip-visibility${eIndex}${rIndex}`}
                      html={
                        // eventRecord?.visibility === 1
                        //   ? `Listing Quality Low`
                        //   : eventRecord?.visibility === 2
                        //   ? `Listing Quality Low`
                        //   : eventRecord?.visibility === 3
                        //   ? `Listing Quality Level 1`
                        //   : eventRecord?.visibility === 4
                        //   ? `Listing Quality Level 2`
                        //   : eventRecord?.visibility === 5
                        //   ? `Listing Quality Level 3`
                        //   : eventRecord?.visibility === 6
                        //   ? `Listing Quality Level 4`
                        //   : ""
                        tooltipForListingMeter(
                          eventRecord?.visibility,
                          eventRecord?.avg_price
                            ? priceCalculation(
                                typeof eventRecord?.sell_price === "object"
                                  ? eventRecord?.sell_price?.value
                                  : eventRecord?.sell_price,
                                eventRecord?.avg_price
                                  ? Number(eventRecord?.avg_price)
                                  : null,
                                priceFactor
                              )
                            : false,
                          event
                        )
                      }
                      place={rIndex === 0 ? "left" : "top"}
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                    /> */}
                        {/* PUBLISH & UNBLISH */}

                        <button
                          className={`relative group flex flex-auto items-center justify-center p-1 w-10 max-w-[2.5rem] h-[2.5rem] ${
                            (isEventRecordError || isEventRecordBEError) &&
                            "border-t-rose-500"
                          } group collapsed ${
                            disabledAll && "pointer-events-none"
                          } ${event?.eventRecords?.length > 15 && ""}`}
                          type="button"
                          id={`status-${event?.id}-${eventRecord?.id}`}
                          onClick={async (data: any) => {
                            if (
                              eventRecord?.status !== "yes" &&
                              (eventRecord?.listing_ticket_files?.length > 0 ||
                                eventRecord?.mobile_links?.length > 0) &&
                              Number(eventRecord?.quantity_available) !==
                                Number(
                                  eventRecord?.listing_ticket_files?.length ||
                                    eventRecord?.mobile_links?.length
                                ) &&
                              (eventRecord?.ticket_type?.id === "mobile-qr" ||
                                eventRecord?.ticket_type?.id === "pdf" ||
                                eventRecord?.ticket_type === "eticket" ||
                                eventRecord?.ticket_type?.id ===
                                  "mobile-link" ||
                                eventRecord?.ticket_type === "mobile-link")
                            ) {
                              const ifError =
                                await setLocalValidationToEventRecords(
                                  eventRecord?.id,
                                  true
                                );

                              if (ifError === false) {
                                showAlert(TicketError, true, 3000);
                              }
                            } else {
                              handleListingAction(
                                eventRecord?.status === "yes"
                                  ? "unpublish-all"
                                  : "publish-all",
                                eventRecord?.id
                              );
                            }
                          }}
                        >
                          <Tooltip
                            anchorId={`status-${event?.id}-${eventRecord?.id}`}
                            content={`${
                              eventRecord?.status === "yes"
                                ? "Unpublish"
                                : "Publish"
                            }`}
                            place={rIndex === 0 ? "left" : "top"}
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                          />
                          {eventRecord?.status === "yes" ? (
                            <>
                              <IKTSVG
                                className="group-hover:hidden flex items-center justify-center fill-green-500 group-hover:fill-indigo-500 transition"
                                path={HaxPublishGray}
                                svgClassName="w-[1rem] h-[1.155rem]"
                              />
                              <IKTSVG
                                className="hoverIcon group-hover:flex hidden items-center justify-center fill-gray-400 group-hover:fill-indigo-500 transition"
                                path={unpublish}
                                svgClassName="w-[1rem] h-[1.155rem]"
                              />
                            </>
                          ) : (
                            <>
                              <IKTSVG
                                className="hoverIcon group-hover:flex hidden items-center justify-center fill-green-500 group-hover:fill-indigo-500 transition"
                                path={HaxPublishGray}
                                svgClassName="w-[1rem] h-[1.155rem]"
                              />
                              <IKTSVG
                                className="group-hover:hidden flex items-center justify-center fill-gray-400 group-hover:fill-indigo-500 transition"
                                path={unpublish}
                                svgClassName="w-[1rem] h-[1.155rem]"
                              />
                            </>
                          )}
                        </button>

                        <button
                          className={`group flex flex-auto items-center justify-center p-1 w-10 max-w-[2.5rem] h-[2.5rem] border-l ${
                            isEventRecordError && "border-t-rose-500"
                          }  ${
                            isDateBeforeValidLogDate(eventRecord?.created_at) &&
                            !isGodAdmin
                              ? "pointer-events-none"
                              : ""
                          }`}
                          type="button"
                          id={`logs-tooltip${event?.id}-${eventRecord?.id}`}
                          disabled={
                            isDateBeforeValidLogDate(eventRecord?.created_at) &&
                            !isGodAdmin
                          }
                          onClick={(e: any) =>
                            layout.setLogsData({
                              ...eventRecord,
                              eventId: event?.id,
                            })
                          }
                        >
                          <Tooltip
                            anchorId={`logs-tooltip${event?.id}-${eventRecord?.id}`}
                            content={`Log`}
                            place={rIndex === 0 ? "left" : "top"}
                            variant="light"
                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                          />

                          <IKTSVG
                            className={`flex items-center justify-center ${
                              isDateBeforeValidLogDate(
                                eventRecord?.created_at
                              ) && !isGodAdmin
                                ? "fill-gray-500"
                                : "fill-violet-500"
                            }  group-hover:fill-indigo-500 transition`}
                            path={logIcon}
                            svgClassName="w-4 h-4"
                          />
                        </button>
                        {/** Delete Button */}
                        {/* <button
              className={` relative  flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.5rem] border-t group collapsed`}
              type="button"
              id={`delete-${eIndex}${rIndex}`}
              onClick={(data: any) =>
                setTableAlert(eventRecord?.id)
              }
            >
              <Tooltip
                anchorId={`delete-${eIndex}${rIndex}`}
                content={`Delete`}
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
              />
              <IKTSVG
                className="flex items-center justify-cente transition fill-violet-500 group-hover:fill-indigo-500"
                path={Delete}
              />
            </button> */}
                      </div>
                    )}
                  </div>
                </div>

                <div className={`${event.value === "" && "row-disabled"}`}>
                  <div
                    id={`collapsePriceExchange${eventRecord?.id}`}
                    className="accordion-collapse collapse w-full h-full border-t rounded-none"
                    aria-labelledby="bodyfour"
                  >
                    <div className="accordion-body  relative inline-flex items-center w-full justify-evenly py-7 pt-[1.9375rem] pb-[2rem] rounded-none #{!important} ">
                      <Tooltip
                        anchorId={`reset-tooltip-${eventRecord?.id}`}
                        content="Reset"
                        place="top"
                        variant="light"
                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium text-xs z-10"
                      />
                      <button
                        id={`reset-tooltip-${eventRecord?.id}`}
                        type="button"
                        className="border group mx-1 rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center"
                        onClick={() => resetPPEValues(rIndex)}
                      >
                        <IKTSVG
                          path={Refresh}
                          className="fill-violet-500 group-hover:fill-white transition"
                          svgClassName="ml-[0.44rem]"
                        />
                      </button>
                      <Tooltip
                        anchorId={`close-tooltip-${eventRecord?.id}`}
                        content="Close"
                        place="top"
                        variant="light"
                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium text-xs z-10"
                      />
                      <button
                        id={`close-tooltip-${eventRecord?.id}`}
                        type="button"
                        className="group flex mx-1 items-center justify-center h-7 w-7 border border-gray-300 hover:border-indigo-500 text-white bg-white hover:bg-indigo-500 transition rounded"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapsePriceExchange${eventRecord?.id}`}
                        aria-expanded="true"
                        aria-controls={`collapsePriceExchange${eventRecord?.id}`}
                        onClick={() => closePPEValues(rIndex)}
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-white transition"
                          path={Cross}
                        />
                      </button>
                      <Tooltip
                        anchorId={`confirm-tooltip-${eventRecord?.id}`}
                        content="Confirm"
                        place="top"
                        variant="light"
                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium text-xs z-10"
                      />
                      <button
                        id={`confirm-tooltip-${eventRecord?.id}`}
                        type="button"
                        className="border group mx-1 rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center"
                        onClick={() => confirmPPEValues(rIndex)}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapsePriceExchange${eventRecord?.id}`}
                        aria-expanded="true"
                        aria-controls={`collapsePriceExchange${eventRecord?.id}`}
                      >
                        <IKTSVG
                          path={Check}
                          className="fill-violet-500 group-hover:fill-white transition"
                          svgClassName="ml-[0.44rem]"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                {isEventRecordError ||
                isEventRecordBEError ||
                isEventRecordHoldTickets ||
                eventRecord?.processing ? (
                  <div
                    className={`
            ${isEventRecordHoldTickets && "group-hover:bg-sky-blue-300"}
            ${
              isEventRecordError ||
              (isEventRecordBEError && "row-error-new group-hover:bg-rose-550")
            }
            ${eventRecord?.processing && "row-processing"} 
            `}
                  ></div>
                ) : (
                  ""
                )}
              </React.Fragment>
            </div>
          </div>
          {isEventRecordError ||
          isEventRecordHoldTickets ||
          isALLTicketUploadedWithPublish ||
          eventRecord?.processing ||
          isEventRecordBEError ? (
            <div className={`second_row flex !p-l-[2.5rem] w-full bg-gray-100`}>
              <div
                className={`border-b ${
                  isGodAdmin ? "w-[5rem]" : "w-[2.5rem]"
                }  min-h-[1.9375rem] sticky left-0`}
              ></div>
              <div className="p-0 equal-td-height border-b flex items-start sticky left-[2.5rem]">
                <div
                  className={`error-box border-b border-l border-r ml-1.5 -mt-[1px] rounded-b mb-2 
                  ${
                    isEventRecordHoldTickets &&
                    "row-hold-new group-hover:bg-sky-blue-300"
                  }
                  ${
                    (isEventRecordError || isEventRecordBEError) &&
                    "row-error-new group-hover:bg-rose-550"
                  }
                  ${
                    isGodAdmin && eventRecord?.isLock === true
                      ? "bg-gray-300/40"
                      : "bg-white"
                  }
                `}
                >
                  {isEventRecordBEError &&
                    eventRecord?.errors?.map((error: any) => {
                      // Filter out errors that are included in INVENTORY_VALIDATE_COLUMN
                      const filteredErrors = error?.errors?.filter(
                        (e: string) =>
                          ![
                            ...INVENTORY_VALIDATE_COLUMN,
                            ...layout.deliveryType?.map((item: any) => item.id),
                          ]?.includes(e)
                      );

                      // Render only if there are non-excluded errors
                      if (filteredErrors && filteredErrors.length > 0) {
                        return (
                          <div className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500">
                            Error&nbsp;
                            <div>
                              {error.exchange && (
                                <span className="text-rose-500 text-xs inline-block font-semibold">
                                  {error.exchange}: &nbsp;
                                </span>
                              )}
                              <p className="!text-gray-600 inline font-normal">
                                {filteredErrors.join(",")}
                              </p>
                            </div>
                          </div>
                        );
                      }

                      // If no errors to render, return null or an empty fragment
                      return null;
                    })}
                  {isEventRecordError &&
                    _.size(
                      getFieldErrorColumns(eventRecord, layout.deliveryType)
                    ) > 0 && (
                      <div className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500">
                        Error:&nbsp;
                        <div>
                          <p className="text-gray-600 inline font-normal">
                            There are some errors in{" "}
                            {getFieldErrorColumns(
                              eventRecord,
                              layout.deliveryType
                            )?.join(", ")}{" "}
                            {_.size(
                              getFieldErrorColumns(
                                eventRecord,
                                layout.deliveryType
                              )
                            ) > 1
                              ? " fields"
                              : " field"}
                          </p>
                        </div>
                      </div>
                    )}

                  {isALLTicketUploadedWithPublish && (
                    <div className="px-2.5 pb-[.3125rem] pt-0 flex text-green-500">
                      Tickets uploaded:
                      <div>
                        {"   "}
                        <div className="text-gray-600 text-xs inline-block font-medium flex pl-1">
                          {"   "} Editing restricted{" "}
                          <div className="flex" data-tooltip-id="editing">
                            <IKTSVG
                              className="cursor-pointer fill-gray-400 hover:fill-indigo-500 pl-1 inline-block align-middle "
                              svgClassName="w-[.875rem] h-[.875rem]"
                              path={InfoCircle}
                            />
                            <TooltipPortal
                              id={`editing`}
                              content={Messages?.ticketRestricted}
                              className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[19rem] cursor-default "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isEventRecordHoldTickets && (
                    <div className="px-2.5 pb-[.3125rem] pt-0">
                      <div className="">
                        <p className="text-sky-blue-500 text-xs flex flex-inline font-semibold">
                          {Messages?.ticketHoldLine1_new}
                          <span className="text-gray-600 font-normal flex flex-inline">
                            &nbsp;{totalHold} of{" "}
                            {Number(eventRecord?.quantity_available) === 0
                              ? totalHold
                              : Number(totalHold) +
                                Number(eventRecord?.quantity_available)}{" "}
                            tickets on hold for 30 mins. Editing is restricted
                            <span
                              id={`${eventRecord?.id}-OnHoldMessageIcon`}
                              data-tooltip-id={`${eventRecord?.id}-OnHoldMessageIcon`}
                            >
                              <TooltipPortal
                                id={`${eventRecord?.id}-OnHoldMessageIcon`}
                                content={`${
                                  isEventRecordHoldTickets &&
                                  eventRecord?.quantity_available <
                                    eventRecord?.listing_ticket_files?.length
                                    ? Messages?.ticketHoldLine3_new
                                    : Messages?.ticketHoldLine2_new
                                }`}
                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 mt-1.5 max-w-xs text-center"
                              />
                              <IKTSVG
                                path={InfoCircle}
                                className="ml-2 cursor-pointer fill-gray-400 hover:fill-indigo-500 inline-block align-middle "
                                svgClassName="fill-gray-400 hover:fill-indigo-500 w-[.875rem] h-[.875rem]"
                                // spanId={`${eventRecord?.id}-OnHoldMessageIcon`}
                              />
                            </span>
                            {/* <Tooltip
                              anchorId={`${eventRecord?.id}-OnHoldMessageIcon`}
                              html={`${Messages?.ticketHoldLine2_new}`}
                              place="top"
                              variant="light"
                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium"
                            /> */}
                          </span>
                        </p>
                        {/* </div>

                      <div>
                        <span className="text-gray-600 inline font-normal">
                          {Messages?.heldTickets}
                        </span>{" "}
                        <br /> */}
                        {/* {isArray(eventRecord?.hold_tickets) &&
                          eventRecord?.hold_tickets.map(
                            (hold_ticket: any, hIndex: number) => {
                              return (
                                <Fragment
                                  key={
                                    "eventFieldIn" + eventRecord?.id + hIndex
                                  }
                                >
                                  <p className="text-gray-600 font-normal flex flex-inline">
                                    {hold_ticket?.exchange}:{" "}
                                    {hold_ticket?.hold_quantity}
                                  </p>
                                </Fragment>
                              );
                            }
                          )} */}
                      </div>
                    </div>
                  )}

                  {eventRecord?.processing ? (
                    <div className="p-1 h-[100%]">
                      <p className="text-gray-600 inline font-normal">
                        {Messages?.ticketProcessing}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* </tr> */}
          {/* <tr
          id={`collapsePriceExchange${eventRecord?.id}`}
          className="accordion-collapse collapse border-b"
          aria-labelledby="bodyfour"
        >
          <td className="flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[5.625rem] border-r p-1 text-center"></td>
          {Object.keys(selectedExchanges).length > 0 &&
            selectedExchanges.map((marketplace: any) => {
              const ExchangePrice = find(
                eventRecord?.ticket_exchange_prices,
                { exchange_id: marketplace.exchange_id }
              );

              const eventMarketPlace =
                eventCurrency &&
                eventCurrency.find(
                  (market: any) =>
                    market.id === marketplace.exchange_id
                );
              const getDisplayPrice = () => {
                return calculateDisplayPrice(
                  currnecyRates[
                    ExchangePrice?.sell_price_currency
                      ? ExchangePrice?.sell_price_currency
                      : "GBP"
                  ],
                  eventMarketPlace,
                  ExchangePrice?.sell_price
                );
              };

              return (
                <td
                  className="border-r"
                  colSpan={2}
                  key={marketplace?.exchange_id}
                >
                  <table className="w-full ">
                    <thead>
                      <tr>
                        <td className="px-1.5 py-1 font-medium whitespace-nowrap">
                          {getMarketName(marketplace?.title)}
                        </td>
                      </tr>
                    </thead>
                    <thead>
                      <tr className="text-gray-300">
                        <td
                          className="px-1.5 text-gray-400 font-medium whitespace-nowrap"
                          colSpan={1}
                        >
                          Proceed price
                        </td>
                        <td
                          className="px-1.5 text-gray-400 font-medium whitespace-nowrap"
                          colSpan={1}
                        >
                          Display price
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="p-1.5 font-medium w-0">
                          <PriceField
                            name={marketplace.exchange_id}
                            value={
                              ExchangePrice?.sell_price
                                ? ExchangePrice?.sell_price
                                : ""
                            }
                            currenncySymbol={
                              ExchangePrice?.sell_price_currency
                                ? ExchangePrice?.sell_price_currency
                                : ""
                            }
                            handleOnChange={(data: any) =>
                              handlePPETableChange(
                                data,
                                eIndex,
                                rIndex,
                              )
                            }
                          />
                        </td>

                        <td className="p-1.5 font-medium w-0 pointer-events-none">
                          <PriceField
                            disabled
                            name={marketplace.exchange_id}
                            value={getDisplayPrice()}
                            defaultSymbol={
                              eventCurrency?.currency
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              );
            })}
        </tr> */}
          {/* {isEventRecordError ||
        isEventRecordHoldTickets ||
        eventRecord?.processing ? (
          <tr
            className={` 
            ${isEventRecordHoldTickets && "bg-sky-blue-300"}
            ${isEventRecordError && "row-error"}
            ${eventRecord?.processing && "bg-yellow-500/10"} 
            `}
          >
            <td className="border-r border-b"></td>
            <td
              colSpan={25}
              className="p-0 equal-td-height border-b"
            >
              <EqualHeightElement
                name={`field-errors-${eventRecord?.id}`}
              >
                {isEventRecordError &&
                  eventRecord?.errors?.map((error: any) => {
                    return (
                      <div className="p-1.5">
                        <div>
                          {error.exchange && (
                            <span className="text-rose-500 text-xs inline-block font-semibold">
                              {error.exchange}: &nbsp;
                            </span>
                          )}

                          <p className="text-gray-600 inline font-normal">
                            {error?.errors &&
                            Array.isArray(error?.errors)
                              ? error?.errors?.join(",")
                              : ""}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                {isEventRecordHoldTickets && (
                  <div className="p-1.5">
                    <div>
                      <p className="text-rose-500 text-xs">
                        {Messages?.ticketHoldLine1}
                        {Messages?.ticketHoldLine2}
                      </p>
                    </div>

                    <div>
                      <span className="text-gray-600 text-xs inline-block font-semibold">
                        {Messages?.heldTickets}
                      </span>{" "}
                      <br />
                      {isArray(eventRecord?.hold_tickets) &&
                        eventRecord?.hold_tickets.map(
                          (hold_ticket: any, hIndex: number) => {
                            return (
                              <Fragment key={hIndex}>
                                <p className="text-gray-600 inline font-normal">
                                  {hold_ticket?.exchange}:{" "}
                                  {hold_ticket?.hold_quantity}
                                </p>
                              </Fragment>
                            );
                          }
                        )}
                    </div>
                  </div>
                )}
                {eventRecord?.processing ? (
                  <div className="p-1.5 h-[100%]">
                    <p className="text-gray-600 inline font-normal">
                      {Messages?.ticketProcessing}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </EqualHeightElement>
            </td>
          </tr>
        ) : (
          ""
        )} */}
        </React.Fragment>
      </div>
    );
  }
);

export default EventField;
