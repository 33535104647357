import { useContext } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { getCurrencySymbol } from "../../../helpers/Functions";
import { TXContext } from "../core/TXProvider";

const TXHeader = ({
  title,
  amount,
  children,
  svgPath,
  svgFillColor,
  bggradient = false,
  txPayLoading,
}: any) => {
  const layout = useContext(TXContext);
  const globalLayout: any = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  function isGodadminBasedCurrency(Array: any) {
    const teamArr = Array;
    if (teamArr === undefined || teamArr?.length === 0) {
      return globalLayout.currencySymbol;
    } else {
      return getCurrencySymbol(teamArr?.base_currency);
    }
  }

  return (
    <>
      <div
        className={`w-full md:w-1/2 lg2:w-1/4 mb-5 px-2.5 min-h-[11.25rem] min-w-max `}
      >
        <div
          className={`border rounded-lg px-4 py-4 h-full flex flex-wrap flex-col  ${
            bggradient &&
            "bg-gradient-to-br from-gray-100 from-50% via-gray-100/20 to-gray-100"
          } ${txPayLoading && "shimmer-effect"} `}
        >
          <div className="top_block w-full">
            <div className="top-row flex items-center justify-between w-full">
              <h3 className="text-sm15 leading-5 font-semibold">{title}</h3>
              <IKTSVG
                className={`flex items-center justify-center ${svgFillColor}  `}
                path={svgPath}
                svgClassName="w-[.9375rem] h-[.9375rem]"
              />
            </div>
            <h2 className="text-2xl md:text-3xl md:leading-9 mt-[1.375rem]">
              {isGodAdmin
                ? isGodadminBasedCurrency(layout?.TXPayFilterHandle?.team_id)
                : globalLayout.currencySymbol}
              {amount}
            </h2>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default TXHeader;
