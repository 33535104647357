const NoDataComponent = ({ isPadding = false }: any) => {
  return (
    <div className={`${isPadding ? "p-0" : "px-5"}`}>
      <div className="text-sm font-medium text-center p-2 bg-white rounded mb-[8.75rem]">
        No data available
      </div>
    </div>
  );
};

export default NoDataComponent;
