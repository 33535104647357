import Swal from "sweetalert2";

export const showAlert = async (message: any, isError: any, timer = 3000) => {
  const Toast = Swal.mixin({
    toast: true,
    width: "24.125rem",
    padding: "14px",
    customClass: {
      htmlContainer:
        "!mx-0 !pl-[.25rem] !text-[.875rem] font-medium !text-violet-800",
      timerProgressBar: isError ? (isError === 2 ? "bg-orange-500" : "bg-rose-500")  : "bg-green-600",
      icon: "border-none !mr-0 !left-0 !w-[1rem] !h-[1rem]",
      closeButton: "!text-violet-800 !text-xl !hover:text-indigo-500",
    },
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    showCancelButton: false,
    showCloseButton: true,
    closeButtonHtml:
      '<svg xmlns="http://www.w3.org/2000/svg" width="9.546" height="9.546" viewBox="0 0 9.546 9.546" class="w-2.5 h-2.5">' +
      '<g transform="translate(-1032.377 348.957) rotate(-45)">' +
      '<rect width="12" height="1.5" transform="translate(977.5 484.001) rotate(90)" fill="#0d0019" />' +
      '<rect width="12" height="1.5" transform="translate(982.75 490.751) rotate(180)" fill="#0d0019" />' +
      "</g>" +
      "</svg>",
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  let iconhtml_success =
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="w-4 h-4">' +
    '<g transform="translate(-1506 -12)">' +
    '<circle cx="8" cy="8" r="8" transform="translate(1506 12)" fill="#00D900" />' +
    '<path d="M33.6,426.467l-3.109-3.627,1.012-.867,2.1,2.452,5.138-5.929,1.007.873Z" transform="translate(1478.875 -402.266)" fill="#fff" />' +
    "</g>" +
    "</svg>";

  let iconhtml_error =
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="w-4 h-4">' +
    '<g transform="translate(-1155.5 -302)">' +
    '<rect width="16" height="16" rx="8" transform="translate(1155.5 302)" fill="#F9004B" />' +
    '<text transform="translate(1163.5 314.5)" fill="#fff" font-size="12" font-family="Inter-SemiBold, Inter" font-weight="600"><tspan x="-1.854" y="0">!</tspan></text>' +
    "</g>" +
    "</svg>";

  let iconhtml_warning =
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="w-4 h-4">' +
    '<g transform="translate(-1155.5 -302)">' +
    '<rect width="16" height="16" rx="8" transform="translate(1155.5 302)" fill="#FF6C00"/>' +
    '<text transform="translate(1163.5 314.5)" fill="#fff" font-size="12" font-family="Inter-SemiBold, Inter" font-weight="600"><tspan x="-1.854" y="0">!</tspan></text>' +
    "</g>" +
    "</svg>";

  let iconhtml_info =
    '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" class="w-4 h-4">' +
    '<g transform="translate(-1155.5 -302)">' +
    '<rect width="16" height="16" rx="8" transform="translate(1155.5 302)" fill="#6F6CFF"/>' +
    '<text transform="translate(1163.5 314.5)" fill="#fff" font-size="12" font-family="Inter-SemiBold, Inter" font-weight="600"><tspan x="-1.854" y="0">!</tspan></text>' +
    "</g>" +
    "</svg>";

  Toast.fire({
    iconHtml: isError ? (isError === 2 ? iconhtml_warning : iconhtml_error) : iconhtml_success,
    html: '<p class="">' + message + "</p></div>",
  });
};
