import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import AngleLeft from "../../../assets/media/icons/standard_icons/angle_left.svg";
import AngleRight from "../../../assets/media/icons/standard_icons/angle_right.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { NotificationsContext } from "../core/NotificationsProvider";
import ActivityCountBoxes from "./ActivityCountBoxes";
import NotificationCountBoxes from "./NotificationCountBoxes";
const TabBody = ({
  children,
  bodyId,
  isFirst,
  data,
  options,
  loading,
}: any) => {
  const layout = useContext(NotificationsContext);
  const location = useLocation();
  const [shouldRemoveState, setShouldRemoveState] = useState(false);

  // GOT DATA FROM DASHBOARD
  useEffect(() => {
    if (location && location?.state === "notification") {
      layout.setNotificationsFilterHandle({
        notification_type: {
          new_notifications: true,
        },
        page: 1,
        per_page: 20,
      });
      setShouldRemoveState(true);
    }
  }, [layout, location]);

  // CLEAR THE STATE AFTER FETCHING THE DATA
  useEffect(() => {
    if (
      shouldRemoveState &&
      layout.notificationsFilterHandle?.notification_type?.new_notifications
    ) {
      location.state = "";
      window.history.replaceState({}, document.title);
      setShouldRemoveState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout.notificationsFilterHandle, shouldRemoveState]);

  // Scrolling on arrows

  const filter_scroll: any = useRef();
  const scroll_filter = (scrollOffset: any) => {
    filter_scroll.current.scrollLeft += scrollOffset;
  };
  useEffect(() => {
    function hideArrowScroll() {
      const left_sc: any = document.getElementById("scroll_left");
      const right_sc: any = document.getElementById("scroll_right");
      const filter_scroll: any = document.getElementById("filter_scroll");
      const maxScrollLeft: any =
        filter_scroll?.scrollWidth - filter_scroll?.clientWidth;
      if (filter_scroll?.scrollLeft === 0) {
        left_sc.style.display = "none";
      } else {
        left_sc.style.display = "flex";
      }
      if (Math.round(filter_scroll.scrollLeft) >= maxScrollLeft) {
        right_sc.style.display = "none";
      } else {
        right_sc.style.display = "flex";
      }
    }
    const filter_scroll = document.getElementById("filter_scroll"); // Make sure filter_scroll is defined and references the correct element
    filter_scroll?.addEventListener("scroll", hideArrowScroll);
    hideArrowScroll();
    return () => {
      filter_scroll?.removeEventListener("scroll", hideArrowScroll); // Cleanup: Remove event listener when component unmounts
    };
  }, []);
  return (
    <div
      className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear not_content ${
        layout.tabValue === bodyId ? "block" : "hidden"
      }`}
      id={bodyId}
      role="tabpanel"
      aria-labelledby={`tabs-${bodyId}`}
      data-te-tab-active
    >
      {/*first layer*/}
      <div className="relative px-5 pt-3">
        <div
          id={`scroll_right`}
          className="arrow_slide absolute top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center right-0 bg-gray-100 rounded-l group hover:bg-indigo-500 cursor-pointer"
          onClick={() => scroll_filter(50)}
        >
          <IKTSVG
            path={AngleRight}
            svgClassName="w-1.5 h-[.5625rem]"
            className=" group-hover:fill-white"
          />
        </div>
        <div
          id={`scroll_left`}
          className="arrow_slide absolute top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center left-0 bg-gray-100 rounded-r group hover:bg-indigo-500 cursor-pointer"
          onClick={() => scroll_filter(-50)}
        >
          <IKTSVG
            path={AngleLeft}
            svgClassName="w-1.5 h-[.5625rem]"
            className=" group-hover:fill-white"
          />
        </div>
        <div className="divide-gray-200 divide-y-2">
          <div
            ref={filter_scroll}
            id={`filter_scroll`}
            className="flex flex-nowrap pt-3 -mx-2.5 overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
          >
            <ActivityCountBoxes
              name={
                layout.tabValue === "notification"
                  ? data?.total_notification > 1
                    ? "Notifications"
                    : "Notification"
                  : data?.total_activities > 1
                  ? "Activity logs"
                  : "Activity log"
              }
              isCheckbox={false}
              countValue={
                data?.total_notification
                  ? data?.total_notification?.toLocaleString()
                  : data?.total_activities?.toLocaleString()
              }
              ticketCount={undefined}
              extraProp={true}
              loading={loading}
            />
            {data?.notifications && bodyId === "notification" ? (
              <>
                <NotificationCountBoxes
                  name={
                    data?.new_notification > 1
                      ? "New notifications"
                      : "New notification"
                  }
                  isCheckbox={true}
                  countValue={data?.new_notification?.toLocaleString()}
                  postKey="new_notifications"
                  loading={loading}
                />
              </>
            ) : (
              <>
                <ActivityCountBoxes
                  name={
                    data?.new_activities > 1 ? "New activities" : "New activity"
                  }
                  isCheckbox={data?.new_activities > 0 ? true : false}
                  countValue={data?.new_activities?.toLocaleString()}
                  postKey="new_activities"
                  loading={loading}
                />
                {options?.activities?.map((item: any) => {
                  return (
                    <ActivityCountBoxes
                      name={
                        (item?.name).charAt(0).toUpperCase() +
                        item.name.slice(1)
                      }
                      isCheckbox={
                        data?.[
                          item?.name?.replaceAll(" ", "_")
                        ]?.toLocaleString() > 0
                          ? true
                          : false
                      }
                      countValue={data?.[
                        item?.name?.replaceAll(" ", "_")
                      ]?.toLocaleString()}
                      postKey={item?.id}
                      allOptions={options?.activities}
                      loading={loading}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default TabBody;
