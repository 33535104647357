import _, { size } from "lodash";
import moment from "moment";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TableVirtuoso } from "react-virtuoso";
import checkSVG from "../../../assets/media/icons/other_icons/check.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import CrossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { addCommaInNumber } from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import { SYMBOLS } from "../../../helpers/const";
import { cancelWithdrawlRequest } from "../../tx_pay/core/requests";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { TxAccountContext } from "../core/TxAccountProvider";
import { approveWithdrawalRequest } from "../core/_request";
import DataCell from "./component/DataCell";
import HeaderCell from "./component/HeaderCell";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left "
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DataTableWithdrawRequest = ({ loading, setIsLoading, refetch }: any) => {
  const layout = useContext(TxAccountContext);
  const [loader, setLoader] = useState<boolean>(false);

  const withdrawRequestList = layout.withdrawRequestList;
  let paginateData = layout.withdrawRequestMeta;

  const tableRef: any = useRef<any>();
  const stickyTableRef: any = useRef<any>();
  const [height, setHeight] = useState<any | undefined>(undefined);

  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  let timeout: any;
  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#payout-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;
      // setTimeout(() => {
      //   nomoreListingHeight = nomoreListing?.clientHeight;
      // }, 2000);

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight;
          if (nomoreListingHeight) {
            elemHeight =
              divElement.offsetHeight + theadHeight + nomoreListingHeight;
          }

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };

  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    const handleScrollTable = () => {
      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;

      const topfilterTop: any = document.getElementById("topFilters");

      const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
      // if (topValue.includes("-")) {
      // Check if the value contains a negative sign
      const currentValue = parseInt(topValue); // Extract the numeric value
      const newValue = Math.abs(currentValue); // Make it positive
      topfilterTop && (topfilterTop.style.top = `${newValue}px`); // Apply the new positive value back as a string
      // }
      const windScroll: number = window.scrollY;

      const headingOne: any =
        document.getElementById("headingOne")?.clientHeight;
      const inventoryaccordion: any =
        document.getElementById("inventoryaccordion")?.clientHeight;
      // const totleScroll = headingOne + inventoryaccordion + 16;
      // const currentTop = parseInt(topfilterTop.style.top || "0");
      const topFiltersHeight = topfilterTop.clientHeight;
      let totleScroll = 0;
      if (window.matchMedia("(max-width: 767px)").matches) {
        totleScroll = topFiltersHeight;
      } else {
        totleScroll = headingOne + inventoryaccordion + 16;
      }

      const stickyHead: any = document.getElementById("sticky-head");
      const stickyShadow: any = document.getElementById("sticky-shadow");

      if (windScroll > totleScroll) {
        EventAccordion?.classList?.add("-mt-10");
        stickyHead?.classList.remove("opacity-0");
        stickyHead?.classList.remove("h-0");
        stickyHead?.classList.add("h-[2.5625rem]");
        stickyShadow.classList.add("!block");
      } else if (windScroll < totleScroll) {
        EventAccordion?.classList?.remove("-mt-10");
        stickyHead?.classList.add("opacity-0");
        stickyHead?.classList.add("h-0");
        stickyHead?.classList.remove("h-[2.5625rem]");
        stickyShadow.classList.remove("!block");
      }
    };
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  const getColumnItems = () => {
    return layout.columnItems.filter(
      (columnItem) => columnItem.checked === true
    );
  };

  let arr: any = _.map(
    withdrawRequestList?.filter(
      (item: any) => item?.status === "Active" && !item?.is_valid_amount
    ),
    "id"
  );

  // HANDLE SELECT
  const handleSelectAll = (data: any) => {
    layout.setWithdrawRequestList((prev: any) => {
      return prev.map((current: any) => {
        return {
          ...current,
          selected: current?.status === "Active" && data?.target?.checked,
        };
      });
    });
    if (data?.target?.checked) {
      layout.setIsSelectAll({ status: "bulk" });
    } else {
      layout.setIsSelectAll({ status: "'" });
    }
  };

  // HANDLE BULK ACTION
  const bulkAction = (action: string, id: any) => {
    setLoader(true);
    if (action === "approve") {
      approveWithdrawalRequest([id])
        .then((response: any) => {
          if (response?.data?.status === false && size(response?.errors) > 0) {
            showAlert(response?.errors?.[0], true);
            setLoader(false);
          } else if (response?.data?.status) {
            showAlert(response?.message, false);
            layout.setWithdrawRequestList((prev: any) => {
              return prev.map((current: any) => {
                return {
                  ...current,
                  selected: false,
                };
              });
            });
            setLoader(false);

            setTimeout(() => {
              refetch();
            }, 200);
          }
        })
        .catch((err: any) => {
          showAlert("Something went wrong", true);
          setLoader(false);
        });
    } else if (action === "reject") {
      cancelWithdrawlRequest([id])
        .then((response: any) => {
          if (response?.data?.status === false && size(response?.errors) > 0) {
            showAlert(response?.errors?.[0], true);
            setLoader(false);
          } else if (response?.data?.status) {
            showAlert(response?.message, false);
            layout.setWithdrawRequestList((prev: any) => {
              return prev.map((current: any) => {
                return {
                  ...current,
                  selected: false,
                };
              });
            });
            setLoader(false);
            setTimeout(() => {
              refetch();
            }, 200);
          }
        })
        .catch((err: any) => {
          showAlert("Something went wrong", true);
        });
    }
  };

  let isSelectAll = layout.withdrawRequestList?.filter(
    (item: any) => item?.status === "Active"
  );

  isSelectAll =
    size(isSelectAll) > 0
      ? isSelectAll?.every((item: any) => item?.selected)
      : false;

  useEffect(() => {
    if (isSelectAll) {
      layout.setIsSelectAll({ status: "bulk" });
    }
  }, [isSelectAll]);

  let isAnyStatucActive: any = useMemo(() => {
    let isActive = layout?.withdrawRequestFilter?.statuses?.find(
      (item: any) => item?.name === "Active"
    );
    return size(layout?.withdrawRequestFilter?.statuses) === 0 &&
      size(
        layout.withdrawRequestList?.filter(
          (item: any) => item?.status === "Active"
        )
      ) > 0
      ? true
      : isActive &&
        size(
          layout.withdrawRequestList?.filter(
            (item: any) => item?.status === "Active"
          )
        ) > 0
      ? isActive
      : false;
  }, [layout?.withdrawRequestFilter?.statuses, layout.withdrawRequestList]);

  return (
    <div
      className="accordion mb-4 font-medium transition-all relative md:px-5 max-lg:!mr-0"
      id={`EventAccordion`}
    >
      <div
        id="sticky-head"
        className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none last-auto`}
        ref={stickyTableRef}
        // onScroll={handleScrollHead}
      >
        <table className="w-full text-xs text-left absolute left-0 top-0">
          <thead className="text-xs text-gray-400 ">
            <tr className="border-b bg-white">
              <th
                className={`w-[2.5rem] min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] items-center justify-center text-center sticky left-0 p-0 z-10 no-scroll checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                id="checkbox_shad"
              >
                <div
                  className={`h-full flex items-center justify-center border-r w-10`}
                >
                  <input
                    type="checkbox"
                    readOnly
                    checked={isSelectAll}
                    className={` w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 `}
                    onChange={(data) => handleSelectAll(data)}
                  />
                </div>
              </th>
              {getColumnItems().map((columnItem: any, index: number) => {
                switch (columnItem.id) {
                  case 0:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Team name"
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={true}
                          postKey="team_name"
                          padding="pl-5"
                        />
                      </React.Fragment>
                    );
                  case 30:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="TX Pay ID"
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={false}
                          postKey="team_name"
                        />
                      </React.Fragment>
                    );
                  case 35:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Amount"
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={false}
                          postKey="team_name"
                        />
                      </React.Fragment>
                    );
                  case 40:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Balance"
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={false}
                          postKey="team_name"
                        />
                      </React.Fragment>
                    );
                  case 36:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Requested date"
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={false}
                          postKey="team_name"
                        />
                      </React.Fragment>
                    );
                  case 37:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Status"
                          width="min-w-[11.5rem] w-[11.5rem]"
                          arrow={false}
                          postKey="team_name"
                        />
                      </React.Fragment>
                    );
                }
              })}

              <th className="md:sticky relative right-0 ml-auto  p-0 w-[5.0625rem] min-w-[5rem]">
                <div
                  className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white hidden"
                  id="left-shad-list"
                >
                  <button
                    className="group rounded-l  transition px-[.5625rem] py-2"
                    type="button"
                    onClick={(e: any) => scroll(-120)}
                  >
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                      path={angleLeftSVG}
                      svgClassName="w-1.5 h-[.5625rem]"
                    />
                  </button>
                  <button
                    className="group rounded-r  transition px-[.5625rem] py-2"
                    type="button"
                    onClick={(e: any) => scroll(120)}
                  >
                    <IKTSVG
                      className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                      path={angleRightSVG}
                      svgClassName="w-1.5 h-[.5625rem]"
                    />
                  </button>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <span id="sticky-shadow" className="z-10 hidden h-[0.5rem]"></span>
      <div className="accordion-item bg-white  !rounded overflow-hidden">
        {/* <EventFields /> */}
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2 last-auto"
              ref={tableRef}
              id={`tableId1`}
              //   onScroll={handleScrollVirtuoso}
              // style={{
              //   height: `${
              //     virtuosoHeight() > height ? virtuosoHeight() : height
              //   }px`,
              // }}
              style={{ height: height > 41 ? `${height}px` : "auto" }}
            >
              <TableVirtuoso
                data={withdrawRequestList}
                endReached={() => {
                  if (
                    paginateData?.current_page < paginateData?.last_page &&
                    !loading
                  ) {
                    setIsLoading(true);
                    layout?.setWithdrawRequestFilter((current: any) => {
                      let nextPage: number = paginateData?.current_page + 1;
                      return {
                        ...current,
                        page: nextPage,
                        per_page: 20,
                      };
                    });
                  }
                }}
                useWindowScroll
                overscan={200}
                totalCount={withdrawRequestList?.length}
                atBottomThreshold={100}
                id={"payout-lazyload"}
                components={{
                  Table: Table,
                  TableHead: TableHead,
                  TableRow: (props) => {
                    return (
                      <TableRow
                        {...props}
                        // hoveredId={hoveredId}
                        // setHoveredId={setHoveredId}
                        // showOrder={showOrder}
                        excludeOrders={arr}
                      />
                    );
                  },
                  TableFoot: (props) => {
                    if (
                      layout?.withdrawRequestList?.length ===
                        paginateData?.total &&
                      paginateData?.current_page > 1
                    ) {
                      return (
                        <tfoot
                          className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                          id={`NoMoreListing`}
                        >
                          <tr className="border-b bg-white">
                            <td
                              colSpan={11}
                              align="center"
                              className="h-[2.8125rem]"
                            >
                              No more items to show
                            </td>
                          </tr>
                        </tfoot>
                      );
                    } else {
                      return null;
                    }
                  },
                }}
                fixedHeaderContent={() => (
                  <tr className="border-b bg-white">
                    <th
                      className={`w-[2.5rem] min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] items-center justify-center text-center sticky left-0 p-0 z-10 no-scroll checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                      id="checkbox_shad"
                    >
                      <div
                        className={`h-full flex items-center justify-center border-r w-10`}
                      >
                        <input
                          type="checkbox"
                          readOnly
                          checked={isSelectAll}
                          className={` 
                          ${!isAnyStatucActive ? "pointer-events-none" : ""}
                          w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 `}
                          onChange={(data) => handleSelectAll(data)}
                        />
                      </div>
                    </th>
                    {getColumnItems().map((columnItem: any, index: number) => {
                      switch (columnItem.id) {
                        case 0:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Team name"
                                width="min-w-[11.5rem] w-[11.5rem]"
                                arrow={true}
                                postKey="team_name"
                                padding="pl-5"
                              />
                            </React.Fragment>
                          );
                        case 30:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="TX Pay ID"
                                width="min-w-[11.5rem] w-[11.5rem]"
                                arrow={false}
                                postKey="team_name"
                              />
                            </React.Fragment>
                          );
                        case 35:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Amount"
                                width="min-w-[11.5rem] w-[11.5rem]"
                                arrow={false}
                                postKey="team_name"
                              />
                            </React.Fragment>
                          );
                        case 40:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Balance"
                                width="min-w-[11.5rem] w-[11.5rem]"
                                arrow={false}
                                postKey="team_name"
                              />
                            </React.Fragment>
                          );
                        case 36:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Requested date"
                                width="min-w-[11.5rem] w-[11.5rem]"
                                arrow={false}
                                postKey="team_name"
                              />
                            </React.Fragment>
                          );
                        case 37:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Status"
                                width="min-w-[11.5rem] w-[11.5rem]"
                                arrow={false}
                                postKey="team_name"
                              />
                            </React.Fragment>
                          );
                      }
                    })}
                    <th className="md:sticky relative right-0 ml-auto  p-0 w-[5.0625rem] min-w-[5rem]">
                      <div
                        className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white hidden"
                        id="left-shad-list"
                      >
                        <button
                          className="group rounded-l  transition px-[.5625rem] py-2"
                          type="button"
                          onClick={(e: any) => scroll(-120)}
                        >
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                            path={angleLeftSVG}
                            svgClassName="w-1.5 h-[.5625rem]"
                          />
                        </button>
                        <button
                          className="group rounded-r  transition px-[.5625rem] py-2"
                          type="button"
                          onClick={(e: any) => scroll(120)}
                        >
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                            path={angleRightSVG}
                            svgClassName="w-1.5 h-[.5625rem]"
                          />
                        </button>
                      </div>
                    </th>
                  </tr>
                )}
                fixedFooterContent={() => {
                  return (
                    <tr className="border-b bg-white">
                      <td colSpan={0}>Hi</td>
                    </tr>
                  );
                }}
                itemContent={(sIndex: any, list: any) => {
                  const matchedView = false;
                  // showOrder?.show &&
                  // showOrder?.data &&
                  // showOrder?.data?.order_id === list.order_id;
                  return (
                    <React.Fragment key={sIndex}>
                      <td
                        className={`w-[2.5rem] min-w-[2.5rem] max-w-[2.5rem] h-[2.8125rem] text-center sticky left-0 no-scroll p-0 checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                        id="checkbox_shad"
                      >
                        <div
                          className={`h-full flex items-center justify-center bg-inherit border-r w-10 `}
                        >
                          {list?.status === "Active" && (
                            <input
                              id="checkbox-all"
                              type="checkbox"
                              className={` ${
                                list?.status === "Active"
                                  ? "cursor-pointer"
                                  : "pointer-events-none"
                              }  w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 relative`}
                              checked={list?.selected}
                              onChange={(data: any) => {
                                layout.setWithdrawRequestList((prev: any) => {
                                  return prev.map((current: any) => {
                                    if (list?.id === current?.id) {
                                      return {
                                        ...current,
                                        selected:
                                          current?.status === "Active"
                                            ? data?.target?.checked
                                            : false,
                                      };
                                    } else {
                                      return current;
                                    }
                                  });
                                });
                              }}
                            />
                          )}
                        </div>
                      </td>
                      {getColumnItems().map(
                        (columnItem: any, index: number) => {
                          switch (columnItem.id) {
                            case 0:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.team_name}
                                    classname="block"
                                    padding="pl-5"
                                    width="min-w-[9.875rem] max-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 30:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.tx_pay_id}
                                    classname="block"
                                    width="min-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 35:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={`${
                                      SYMBOLS[list?.currency]
                                    }${addCommaInNumber(list?.amount)}`}
                                    classname="block"
                                    width="min-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 40:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={`${
                                      SYMBOLS[list?.currency]
                                    }${addCommaInNumber(list?.balance)}`}
                                    classname="block"
                                    width="min-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 36:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={moment(list?.request_date).format(
                                      "DD/MM/YYYY"
                                    )}
                                    classname="block"
                                    width="min-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 37:
                              return (
                                <React.Fragment key={index}>
                                  <DataCell
                                    value={list?.status}
                                    classname="block"
                                    width="min-w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                          }
                        }
                      )}

                      <td
                        className={`md:sticky relative right-0 ml-auto p-0 icon_td  border-b`}
                      >
                        <React.Fragment key={sIndex}>
                          <div
                            id={"withdraw" + list?.id}
                            className={`
                            ${arr?.includes(list?.id) ? "!bg-rose-550" : ""}
                            ${
                              list?.selected
                                ? "bg-indigo-500 bg-opacity-[7%]"
                                : ""
                            }
                            ${
                              matchedView ? "bg-indigo-500 bg-opacity-[7%]" : ""
                            } `}
                          >
                            {list?.status === "Active" && (
                              <>
                                {list?.is_valid_amount &&
                                  list?.is_wallet_and_bank_acc_set && (
                                    <div
                                      className="flex items-center justify-center relative w-full h-full border-l"
                                      id="left-shad-list"
                                    >
                                      {/*APPROVE BUTTON */}
                                      <button
                                        className={`group flex mx-2 items-center justify-center h-6 w-6 text-white bg-white border border-gray-300 hover:bg-indigo-500 hover:border-indigo-500 transition rounded
                                      `}
                                        type="button"
                                        id={`record-view-tooltip${sIndex}`}
                                        data-tooltip-id={`add-seller-${sIndex}`}
                                        onClick={() =>
                                          bulkAction("approve", list?.id)
                                        }
                                      >
                                        <TooltipPortal
                                          id={`add-seller-${sIndex}`}
                                          content={"Approve"}
                                          className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default"
                                        />
                                        <IKTSVG
                                          className={`fill-violet-500 group-hover:fill-white`}
                                          svgClassName="w-[.7585rem] h-[.6452rem]"
                                          path={checkSVG}
                                        />
                                      </button>
                                    </div>
                                  )}

                                {/* REJECT BUTTON */}
                                <div
                                  className="flex items-center justify-center relative w-full h-full border-l"
                                  id="left-shad-list"
                                >
                                  <button
                                    className={`group flex mx-2 items-center justify-center h-6 w-6 text-white bg-white border border-gray-300 hover:bg-indigo-500 hover:border-indigo-500 transition rounded
                                 `}
                                    type="button"
                                    id={`record-view-tooltip${sIndex}`}
                                    data-tooltip-id={`reject-seller-${sIndex}`}
                                    onClick={() =>
                                      bulkAction("reject", list?.id)
                                    }
                                  >
                                    <TooltipPortal
                                      id={`reject-seller-${sIndex}`}
                                      content={"Reject"}
                                      className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default"
                                    />
                                    <IKTSVG
                                      className={`fill-violet-500 group-hover:fill-white`}
                                      svgClassName="w-[.5966rem] h-[.5966rem]"
                                      path={CrossSVG}
                                    />
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      </td>
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </div>
          <div
            id="custom-loader"
            className={`accordion bg-white font-medium pt-2.5 border-t `}
          >
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
          </div>
        </div>
      </div>
      {loader && <ProgressBardLoader LoadingText={``} secondCounter={"2"} />}
    </div>
  );
};

export default DataTableWithdrawRequest;
