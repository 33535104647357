import _ from "lodash";
import moment from "moment";
import { ListingTable } from "pages/API_connector/table/ListingTable";
import { useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import NoDataComponent from "../../../../components/tableComponent/NoDataComponent";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import { QUERIES } from "../../../../helpers/const";
import useDebounce from "../../../add_inventory/core/useDebounce";
import EventsFilters from "../../component/EventsFilters";
import { ConnectorContext } from "../../core/ConnectorProvider";
import { getClientsEvents } from "../../core/requests";
import EventsMapping from "./EventsMapping";

// get is all events are selected or not
const getAllEventsSelected = (layout: any) => {
  return layout?.clientsEvents?.length === layout?.selectedEvents?.length;
};

// handle all event selection
const handleSelectAll = (layout: any) => (e: any) => {
  if (e.target.checked) {
    const selectList = layout?.clientsEvents;
    layout?.setSelectedEvents(selectList);
  } else {
    layout?.setSelectedEvents([]);
  }
};

// handle single event selection
const handleEventSelectChange = (layout: any, list: any) => (e: any) => {
  if (e.target.checked) {
    layout?.setSelectedEvents((pre: any) => {
      return [...pre, list];
    });
  } else {
    layout?.setSelectedEvents((pre: any) => {
      return pre?.filter((event: any) => event?.id !== list?.id);
    });
  }
};

// handle event onClick
const handleShift = (
  e: any,
  index: number,
  layout: any,
  lastSelectedIndex: any,
  setLastSelectedIndex: any
) => {
  if (e.shiftKey && lastSelectedIndex !== null) {
    const startIndex = Math.min(lastSelectedIndex, index);
    const endIndex = Math.max(lastSelectedIndex, index);
    const newEvents = layout?.clientsEvents
      ?.slice(startIndex, endIndex)
      .filter((event: any) => !event?.is_mapped);

    layout?.setSelectedEvents((prev: any) => {
      return _.uniqBy([...prev, ...newEvents], "id");
    });
  } else {
    setLastSelectedIndex(index);
  }
};

const getColumns: any = (
  layout: any,
  lastSelectedIndex: any,
  setLastSelectedIndex: any
) => [
  {
    title: (
      <div className="sticky left-0 p-0 icon_td  w-8 min-w-8 pl-6">
        <div className="flex td_block items-center justify-center p-4 text-center sticky left-0 no-scroll border-b checkbox_td bg-inherit">
          <input
            id={`all-selected`}
            name="all_selected"
            type="checkbox"
            className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 relative"
            checked={getAllEventsSelected(layout)}
            onChange={handleSelectAll(layout)}
          />
        </div>
      </div>
    ),
    width: "",
    isArrow: true,
    postKey: "",
    padding: "",
    getValue: (list: any, sIndex: any) => (
      <td className="group sticky left-0 icon_td w-8 min-w-8 bg-white h-[2.8125rem] ">
        <div
          className="flex td_block items-center justify-center p-4 text-center sticky left-0 no-scroll border-b checkbox_td bg-inherit"
          id="left-shad-list"
        >
          <input
            onChange={handleEventSelectChange(layout, list)}
            name="selected"
            checked={layout?.selectedEvents.some(
              (event: any) => event?.id === list?.id
            )}
            onClick={(e) =>
              handleShift(
                e,
                sIndex,
                layout,
                lastSelectedIndex,
                setLastSelectedIndex
              )
            }
            type="checkbox"
            className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 relative"
          />
        </div>
      </td>
    ),
  },
  {
    title: "ID",
    width: "6.875rem",
    isArrow: true,
    postKey: "id",
    padding: "pl-5",
    getValue: (list: any) => list?.id,
  },
  {
    title: "Name",
    width: "24rem",
    isArrow: true,
    postKey: "name",
    padding: "pl-5",
    getValue: (list: any) => list?.name,
  },
  {
    title: "Date time",
    width: "10rem",
    isArrow: false,
    postKey: "datetime",
    padding: "pl-5",
    getValue: (list: any) =>
      list?.datetime
        ? moment(list?.datetime).format("DD/MM/YYYY HH:mm:ss")
        : null,
  },
  {
    title: "Venue",
    width: "15rem",
    isArrow: false,
    postKey: "venue",
    padding: "pl-5",
    getValue: (list: any) => list?.venue,
  },
  {
    title: "Is Mapped",
    width: "24rem",
    isArrow: false,
    postKey: "is_mapped",
    padding: "pl-5",
    getValue: (list: any) => (list?.is_mapped ? "Yes" : "No"),
  },
];

const onEndReach = (setFilters: any, paginateData: any) => () => {
  if (paginateData?.isDataFetched) return;
  setFilters((current: any) => {
    let nextPage: number = paginateData?.current_page + 1;
    return {
      ...current,
      page: nextPage,
    };
  });
};

const EventsTab = ({ data }: any) => {
  const [customEventsLoading, setCustomEventsLoading] = useState(true);
  const [lastSelectedIndex, setLastSelectedIndex] = useState<any>(null);
  const layout = useContext(ConnectorContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let filters = { ...layout.eventsFilters };
  let sorting = layout.eventsOrderState;

  let clientsEventsDataMemo: any = useMemo(() => {
    return [filters, sorting];
  }, [filters, sorting]);

  let eventsFilterData: any = useDebounce(
    JSON.stringify(clientsEventsDataMemo),
    200
  );

  // Fetch events
  const {
    isLoading: eventsLoading,
    data: eventsData,
    refetch,
  } = useQuery(
    [`${QUERIES.API_CONNECTOR_EVENTS}`, ...JSON.parse(eventsFilterData)],
    () =>
      getClientsEvents({
        id: data?.id,
        filters: filters,
        sorting: sorting,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
      onSettled: (err) => {
        setCustomEventsLoading(false);
      },
    }
  );

  // GET ORDERS FOR LISTING
  useEffect(() => {
    // AFTER LAZY LOAD
    const updateList = async () => {
      if (!eventsLoading) {
        if (eventsData?.data?.events?.length > 0) {
          layout.setClientsEvents((pre: any) => {
            if (eventsData?.meta?.current_page > 1) {
              return _.uniqBy([...pre, ...eventsData?.data?.events], "id");
            } else {
              return [...eventsData?.data?.events];
            }
          });
        } else {
          if (eventsData?.meta?.current_page <= 1) {
            layout.setClientsEvents([]);
          } else eventsData.meta.isDataFetched = true;
        }
      }
    };
    updateList();

    return () => {
      // close mapping component, reset selected events
      layout?.setEventsMappingState(false);
      layout?.setSelectedEvents([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsData, eventsLoading]);

  useEffect(() => {
    setCustomEventsLoading(true);
  }, [
    // layout?.connectorFilter?.status,
    layout?.eventsFilters?.query,
    layout?.eventsFilters?.category,
    layout?.eventsFilters?.venue,
    layout?.eventsFilters?.performer,
    layout?.eventsFilters?.isMapped,
    layout.eventsOrderState,
  ]);

  const filtersHeight =
    document.getElementById("api-connector-events-filters")?.clientHeight || 0;

  let tableHeight = 0;

  tableHeight = document.body.clientHeight - 167 - filtersHeight - 80;

  return (
    <div
      id="eventstab"
      className="bg-gray-200 min-h-[calc(100vh-10.25rem)] max-h-[calc(100vh-10.25rem)] content-block relative before:content before:absolute before:top-0 before:left-0 before:w-full before:h-16 before:-z-[1]  before:bg-gradient-to-b before:to-gray-650"
    >
      <div className="sticky top-0">
        <EventsFilters
          customEventsLoading={customEventsLoading}
          paginateData={eventsData?.meta}
          refetch={refetch}
          setCustomEventsLoading={setCustomEventsLoading}
        />
      </div>
      {customEventsLoading ? (
        <>
          {Array.from({ length: 10 }, (v, i) => (
            <div
              className={`accordion font-medium  mb-2.5 mx-5 ${
                i === 0 && "mt-5"
              } `}
              key={i}
            >
              <div
                key={i}
                className={`accordion-item bg-white !rounded overflow-hidden ${
                  eventsLoading && "shimmer-effect"
                } `}
                style={{ height: "40px" }}
              ></div>
            </div>
          ))}
        </>
      ) : layout?.clientsEvents?.length > 0 && !customEventsLoading ? (
        layout?.eventsMappingState ? (
          <EventsMapping refetch={refetch} />
        ) : (
          <div className="inner-main-content p-5 md:h-[calc(100vh-18rem)] h-auto flex flex-col">
            <ListingTable
              data={layout?.clientsEvents}
              filters={layout.eventsFilters}
              setFilters={layout.setEventsFilters}
              columns={getColumns(
                layout,
                lastSelectedIndex,
                setLastSelectedIndex
              )}
              paginateData={eventsData?.meta}
              orderState={layout.eventsOrderState}
              setOrderState={layout.setEventsOrderState}
              rowSelection={true}
              height={`${tableHeight}px`}
              isLoading={eventsLoading}
              onEndReach={onEndReach(layout.setEventsFilters, eventsData?.meta)}
              isDataFetched={eventsData?.meta?.isDataFetched ?? false}
            />
          </div>
        )
      ) : (
        <>
          <div className="p-4">
            <NoDataComponent />
          </div>
        </>
      )}
      {customEventsLoading && (
        <ProgressBardLoader
          LoadingText={"Loading Client's Events data"}
          secondCounter={2.5}
        />
      )}
    </div>
  );
};

export default EventsTab;
