/* eslint-disable jsx-a11y/anchor-is-valid */
import { size } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import Warning1 from "../../assets/media/icons/standard_icons/warning1.svg";
import { decimalNumber } from "../../helpers/Functions";
import { SYMBOLS } from "../../helpers/const";
import { secureStateWhileTabbing } from "../../pages/inventory/core/_functions";
import TooltipPortal from "../../pages/tx_trade/table/component/TooltipPortal";
import { IKTSVG } from "../ui/IKTSVG";
import { KTSVG } from "../ui/KTSVG";
import Textfield from "./Textfield";

const PriceField_copy = ({
  handleOnChange,
  value,
  label,
  placeholder,
  id,
  name,
  minWidth = "min-w-[1.87rem]",
  currenncySymbol = "",
  defaultSymbol = "GBP",
  reff,
  errorClass = "",
  errorEventIndex,
  errorMessage,
  isDisabled = false,
  handleOnBlur = () => {},
  defaultValue,
  errorIndex,
  onClick,
  isPriceField = false,
  fieldMessage = false,
  isPriceTable = false,
  handleOnFocus = () => {},
  isValidationPopupVisible,
  indexPlusLength = false,
  proceedPriceLoader = false,
  avgPrice = false,
  currrency = [],
  withLockBtnClass,
  tabTiming = 0,
  toolTipZindex = false,
  textfieldType = "text",
  tooltipDisplay = true,
}: any) => {
  let tempRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  let reffInner = reff ? reff : tempRef;
  // const symbols: any = {
  //   GBP: "£",
  //   EUR: "€",
  //   USD: "$",
  // };
  const [symbol, setSymbol] = useState(
    currenncySymbol ? currenncySymbol : defaultSymbol
  );
  const [focus, setFocus] = useState(false);
  const handleChange = (e: any) => {
    let events = { target: { name: name, value: { ...e.target, symbol } } };
    handleOnChange(events);
  };

  const handleOnblur = (e: any) => {
    let events = { target: { name: name, value: { ...e.target, symbol } } };
    handleOnBlur(events);
  };

  useEffect(() => {
    if (currenncySymbol) {
      setSymbol(currenncySymbol);
    }
  }, [currenncySymbol]);

  const handleKeyDown = (e: any, tabTime: any) => {
    tabTime > 0 && secureStateWhileTabbing(e, tabTime);
    // Allow numeric keys, backspace, delete, arrow keys, tab, and copy (Ctrl+C)
    if (
      !(
        (
          (e.key >= "0" && e.key <= "9") ||
          e.key === "Backspace" ||
          e.key === "Delete" ||
          e.key === "ArrowLeft" ||
          e.key === "ArrowRight" ||
          e.key === "Tab" ||
          (e.ctrlKey && e.key === "c") ||
          (e.ctrlKey && e.key === "a") ||
          (e.ctrlKey && e.key === "v") ||
          e.key === "."
        )
        // ALLOW BELOW CODE WHICH ALLOW ONLY ONE DOT FOR ENTER
        // (e.key === "." && e.target.value.indexOf(".") === -1)
      )
    ) {
      e.preventDefault();
    }
  };

  function colorForPrice(isPriceField: any, fieldMessage: any) {
    if (isPriceField?.withCategory) {
      if (fieldMessage === "poor") {
        return "price_high";
      }
      if (fieldMessage === "Average") {
        return "price_medium";
      }
      if (fieldMessage === "Good") {
        return "price_regular";
      }
      if (fieldMessage === "nomsg") {
        return "";
      }
      return "price_low";
    }
  }

  function errorFieldMessage(
    fieldMessage: any,
    withoutCategory: any,
    proceedPriceLoader: any
  ) {
    if (proceedPriceLoader) {
      return `Gathering price data`;
    }
    if (withoutCategory) {
      return `Include specific Section/block to see price suggestions`;
    }
    if (fieldMessage === "poor") {
      return `Not likely to sell at this price`;
    }
    if (fieldMessage === "Average") {
      return `More likely to sell at a lower price`;
    }
    if (fieldMessage === "Good") {
      return `Competitively priced and  likely to sell`;
    }
    if (fieldMessage === "nomsg") {
      return ``;
    }
    return `This price looks too low`;
    // {fieldMessage === "poor"
    // ? "Not likely to sell at this price"
    // : fieldMessage === "Average"
    // ? "More likely to sell at a lower price"
    // : fieldMessage === "Good"
    // ? "Competitively priced and  likely to sell"
    // : isPriceField?.withoutCategory
    // ? "Include specific Section/block to see price suggestions"
    // : fieldMessage === "nomsg"
    // ? ""
    // : "This price looks too low"}
  }

  let currencyValue = size(currrency) > 0 ? currrency : SYMBOLS;
  if (name !== "penaltyValue") {
    delete currencyValue?.PER;
  }
  // const currencyValue =
  //   currrency?.length > 0
  //     ? Object.fromEntries(
  //         Object.entries(SYMBOLS).filter(([key, symbol]) => {
  //           console.log(symbol);
  //           return currrency?.includes(symbol);
  //         })
  //       )
  //     : SYMBOLS;

  return (
    <div
      className={`dropdown ${withLockBtnClass} relative flex ${
        errorClass && "error-field"
      } ${isDisabled && `pointer-events-none`} ${
        !isValidationPopupVisible &&
        !proceedPriceLoader &&
        colorForPrice(isPriceField, fieldMessage)
      } `}
    >
      {!isValidationPopupVisible &&
        (isPriceField?.withoutCategory || isPriceField?.withCategory) && (
          <div
            className={`price_suggation ${
              indexPlusLength?.recordLength === 1 && "single_row"
            }  absolute  -left-[.4375rem] -right-[.4375rem] shadow-3xl rounded-[.625rem] bg-white  z-[2] pb-[.4375rem]  px-[.4375rem] pt-11 text-xxs flex-wrap 
            ${isPriceTable && "pt-9"}  ${
              indexPlusLength?.recordLength !== 1 &&
              (indexPlusLength?.recordLength - 1 === indexPlusLength?.rIndex
                ? "-bottom-[.4375rem] z-[2] !pt-[.4375rem] !pb-9"
                : "-top-[.4375rem]  ")
            }   `}
          >
            <span className="block leading-3 text-center">
              {errorFieldMessage(
                fieldMessage,
                isPriceField?.withoutCategory,
                proceedPriceLoader
              )}
            </span>
            {avgPrice && (
              <span className="block leading-3 text-center text-gray-500">
                {id === "proceedValue" ? "Current lowest" : "Current lowest"}:{" "}
                {SYMBOLS[symbol]}
                {decimalNumber(avgPrice)}
              </span>
            )}
          </div>
        )}
      <a
        className={`
        ${currenncySymbol && "pointer-events-none "}
        ${
          focus && "!border-indigo-500 !text-indigo-500"
        }  price_field ${minWidth} min-h-[1.875rem] flex items-center justify-center dropdown-toggle font-medium text-sm rounded-l border border-r-0 border-gray-300 transition duration-150 ease-in-out whitespace-nowrap text-violet-800 cursor-pointer bg-white ${
          isDisabled && `!bg-gray-100 border-none`
        } z-[1] ${(isPriceField?.isFocused || isPriceField?.focus) && "z-[2]"} 
        ${size(currrency) > 0 && !SYMBOLS[symbol] ? "text-xs" : ""}  
        ${isOpen ? "show" : ""}
        
        `}
        id={`dropdownMenuButton${id}`}
        // data-bs-toggle="dropdown"
        // aria-expanded="false"
        onClick={() => setIsOpen((current: boolean) => !current)}
      >
        {size(currrency) > 0
          ? SYMBOLS[symbol]
            ? SYMBOLS[symbol]
            : symbol
          : SYMBOLS[symbol]}
      </a>

      <ul
        className={`${
          isOpen ? "show" : ""
        } dropdown-menu min-w-max absolute hidden bg-white z-10 list-none text-left rounded-sm shadow-md mt-1 m-0 bg-clip-padding border-none overflow-hidden top-full`}
        aria-labelledby={`dropdownMenuButton${id}`}
      >
        {currencyValue &&
          Object.keys(currencyValue).map((item, index): any => {
            return (
              <React.Fragment key={index}>
                <li
                  className="text-center dropdown-item text-sm py-1 px-2.5 block w-full whitespace-nowrap bg-transparent cursor-pointer hover:bg-indigo-500/10 first:text-indigo-500 transition"
                  onClick={() => {
                    setIsOpen(false);
                    setSymbol(item);
                    if (reffInner.current !== null) {
                      let events = {
                        target: {
                          name: name,
                          value: {
                            name,
                            value: reffInner.current.value,
                            symbol: item,
                            symbolValue: currencyValue[item],
                          },
                        },
                      };
                      handleOnChange(events);
                    }
                  }}
                >
                  {currencyValue[item]}
                </li>
              </React.Fragment>
            );
          })}
      </ul>
      <Textfield
        name={name}
        type={textfieldType}
        reff={reffInner}
        label={label}
        defaultValue={defaultValue}
        value={value}
        id={id}
        placeholder={placeholder}
        isPriceField={true}
        isPriceFieldFocused={isPriceField?.isFocused || isPriceField?.focus}
        passFocus={(data: any) => {
          if (data) {
            setFocus(true);
          } else {
            setFocus(false);
          }
          handleOnFocus(data);
        }}
        handleOnChange={(data: any) => handleChange(data)}
        isDisabled={isDisabled}
        handleOnBlur={(data: any) => handleOnblur(data)}
        onKeyDown={(e: any) => handleKeyDown(e, tabTiming)}
        onClick={onClick}
      />

      {errorMessage && errorClass !== "" && tooltipDisplay && (
        <>
          <TooltipPortal
            id={`error-${errorEventIndex}`}
            content={errorMessage}
            className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium  text-xs z-[999999999] max-w-[9rem] cursor-default !text-rose-500"
          />
          <div
            id={`error-${errorEventIndex}`}
            data-tooltip-id={`error-${errorEventIndex}`}
            className="absolute top-1/2 -translate-y-1/2 right-3 "
          >
            <IKTSVG
              className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
              path={Warning1}
            />
          </div>
        </>
      )}
      {proceedPriceLoader && (
        <div className="absolute top-1/2 -translate-y-1/2 right-3 z-[33]">
          <KTSVG
            className="flex items-center justify-center fill-violet-500 transition animate-spin"
            path="other_icons/process.svg"
          />
        </div>
      )}
    </div>
  );
};

export default PriceField_copy;
