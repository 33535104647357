/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import _, { isArray } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Virtuoso } from "react-virtuoso";
import AngleLeft from "../../../../assets/media/icons/standard_icons/angle_left.svg";
import AngleRight from "../../../../assets/media/icons/standard_icons/angle_right.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { deliveryTypeOrders } from "../../../../helpers/const";
import { AddInventoryContext } from "../../core/AddInventoryProvider";
import ETicketUpload from "../../widgets/ETicketUpload";
import EventHeaderCell from "./EventHeaderCell";
import ItemContent from "./ItemContent";
import Viewmobile from "./Viewmobile";

const VirtuosoEventField = React.memo(
  ({
    event,
    eIndex,
    handleTableField,
    handlePPETableChange,
    list,
    setList,
    setIsOpenListingQualityPopup,
  }: any) => {
    const tableRef: any = useRef();
    const headerRef: any = useRef(null);
    const virtuosoRef = useRef<any>(null);
    const layout = useContext(AddInventoryContext);
    const coreLayout = useContext(LayoutContext);
    let target_currency = coreLayout.currencyLabel;
    const isTableEditable: [] = layout.isTableEditable;
    const [triggered, setTriggered] = useState(false);
    const [disabledRow, setDisabledRow] = useState(false);
    const [focusedRow, setFocusedRow] = useState<any>(null);
    const [activeField, setActiveField] = useState("");

    let isDisabledListing =
      _.size(layout?.isTableEditable) > 0 || layout.isDeleteActive;

    let forRowDisabled: any;
    let seletedDeliveryTypes: any = [];
    let deliveryTypeExist: any = 0;
    let paperTypeExist: any = false;

    event?.eventRecords?.map((eventRecord: any) => {
      eventRecord?.ticketType?.id === "paper" && (paperTypeExist = true);
      seletedDeliveryTypes = [
        ...seletedDeliveryTypes,
        ...eventRecord?.deliveryType?.map((dT: any) => dT.type),
      ];
    });

    seletedDeliveryTypes = _.uniq(seletedDeliveryTypes);
    deliveryTypeExist = seletedDeliveryTypes.length;

    const firstSelectedIndex = event?.eventRecords?.findIndex(
      (record: any) => record.selected === true
    );

    const debouncedHandleScrollHeader = useCallback((e: any) => {
      let top = Number(document.getElementById(`${e?.id}lazyLoad`)?.scrollTop);
      const scrollLeft = headerRef.current.scrollLeft;
      virtuosoRef.current.scrollTo({
        left: scrollLeft,
      });
      virtuosoRef.current.scrollBy({
        top: top,
      });
    }, []);

    // Handle the scroll event for the Virtuoso component
    const handleScrollVirtuoso = (event: any) => {
      const scrollLeft = event.target.scrollLeft;
      headerRef.current.scrollLeft = scrollLeft;
    };

    const handleFocus = (rowId: any) => {
      setFocusedRow(rowId);
    };

    const handleBlur = () => {
      setFocusedRow(null);
    };

    // * Faster version
    // const MemoizedHandleTableField = useMemo(() => {
    //   const handleTableField = (data: any, eIndex: number, rIndex: number) => {
    //     let tempEvents = [...events];
    //     // If table is not edit-able
    //     if (isTableEditable.length === 0) {
    //       tempEvents[eIndex]["eventRecords"][rIndex][data.target.name] =
    //         data.target.value !== undefined && Array.isArray(data.target.value)
    //           ? data.target.value
    //           : typeof data.target.value === "object"
    //           ? data.target.value
    //           : typeof data.target.value === "string" &&
    //             data.target.type !== "checkbox"
    //           ? data.target.value
    //           : data.target.type === "checkbox"
    //           ? data.target.checked
    //           : data.target.value;

    //       //for the category change
    //       if (data.target.name === "category") {
    //         tempEvents[eIndex]["eventRecords"][rIndex]["section"] = null;
    //       }

    //       //for the eticket change
    //       if (data.target.name === "ticketType") {
    //         tempEvents[eIndex]["eventRecords"][rIndex]["sub_ticket_type"] =
    //           data.target.value?.subtypes?.[0];
    //         //for delivery type field
    //         if (data.target.value?.id !== "paper") {
    //           tempEvents[eIndex]["eventRecords"][rIndex]["deliveryType"] = [];
    //         }
    //       }

    //       // ticketFiles

    //       // VISIBILITY LOGIC
    //       let visibility: number = 0;
    //       let fieldCount: number = 8;
    //       //  let fieldCount: number =  data.target.name === "ticketType" &&
    //       // data?.target?.value?.id === "eticket" &&
    //       // _.size(
    //       //   tempEvents[eIndex]["eventRecords"][rIndex]?.["ticketFiles"]
    //       // ) === 0
    //       //   ? 11
    //       //   : 10;

    //       // TICKET TYPE
    //       if (
    //         tempEvents[eIndex]["eventRecords"][rIndex]?.["ticketType"] &&
    //         _.size(tempEvents[eIndex]["eventRecords"][rIndex]?.["ticketType"]) >
    //           0
    //       ) {
    //         visibility += 5 / fieldCount;
    //       }

    //       // QUANTITY
    //       if (tempEvents[eIndex]["eventRecords"][rIndex]?.["quantity"]) {
    //         visibility += 5 / fieldCount;
    //       }

    //       // SPLITYPE
    //       if (
    //         tempEvents[eIndex]["eventRecords"][rIndex]?.["splitType"] &&
    //         _.size(tempEvents[eIndex]["eventRecords"][rIndex]?.["splitType"]) >
    //           0
    //       ) {
    //         visibility += 5 / fieldCount;
    //       }

    //       // CATEGORY
    //       if (
    //         tempEvents[eIndex]["eventRecords"][rIndex]?.["category"] &&
    //         _.size(tempEvents[eIndex]["eventRecords"][rIndex]?.["category"]) > 0
    //       ) {
    //         visibility += 5 / fieldCount;
    //       }

    //       // SECTION
    //       if (
    //         tempEvents[eIndex]["eventRecords"][rIndex]?.["section"] &&
    //         _.size(tempEvents[eIndex]["eventRecords"][rIndex]?.["section"]) > 0
    //       ) {
    //         visibility += 5 / fieldCount;
    //       }

    //       // ROW
    //       if (tempEvents[eIndex]["eventRecords"][rIndex]?.["row"]) {
    //         visibility += 5 / fieldCount;
    //       }

    //       // PROCEED PRICE
    //       if (
    //         tempEvents[eIndex]["eventRecords"][rIndex]?.["proceedValue"]?.value
    //       ) {
    //         visibility += 5 / fieldCount;
    //       }

    //       // TICKET IN HAND
    //       if (tempEvents[eIndex]["eventRecords"][rIndex]?.["ticketsInHands"]) {
    //         visibility += 5 / fieldCount;
    //       }

    //       const currentVisibility =
    //         tempEvents[eIndex]["eventRecords"][rIndex]["visibility"];
    //       const oldVisibility =
    //         currentVisibility === Math.round(visibility)
    //           ? tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"]
    //           : currentVisibility;

    //       tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"] =
    //         oldVisibility;
    //       tempEvents[eIndex]["eventRecords"][rIndex]["visibility"] =
    //         Math.round(visibility);
    //       setList(tempEvents);
    //       // If table is edit-able
    //     } else {
    //       const tempEvents = (events: Array<Event>) => {
    //         return events.map((event, eventIndex) => {
    //           return {
    //             ...event,
    //             eventRecords: Array.isArray(event["eventRecords"])
    //               ? event["eventRecords"].map((eventRecord: any) => {
    //                   if (
    //                     eventRecord.selected === true &&
    //                     eventIndex === eIndex
    //                   ) {
    //                     layout.setTableDataEditChanging((pre: any) => {
    //                       const conditions: any = {
    //                         "01fg98zqgrtfe7avqvv575e970": "Local",
    //                         "01fnv9ja884j9empp7a4z663wj": "National",
    //                         "01fnv9gwfd8wmnn27aw196pj37": "International",
    //                         sub_ticket_type: "Sub ticket type",
    //                       };
    //                       const newName = conditions[data.target.name] || null;
    //                       if (newName && pre.indexOf(newName) === -1) {
    //                         return [...pre, newName];
    //                       } else if (
    //                         !newName &&
    //                         pre.indexOf(data.target.name) === -1
    //                       ) {
    //                         return [...pre, data.target.name];
    //                       }
    //                       return pre;
    //                     });

    //                     //for the category change
    //                     let section: any = {};
    //                     if (data.target.name === "category") {
    //                       section.section = "";
    //                     }
    //                     let final = {
    //                       ...eventRecord,
    //                       [data.target.name]:
    //                         data.target.value !== undefined &&
    //                         Array.isArray(data.target.value)
    //                           ? data.target.value
    //                           : typeof data.target.value === "object"
    //                           ? data.target.value
    //                           : typeof data.target.value === "string" &&
    //                             data.target.type !== "checkbox"
    //                           ? data.target.value
    //                           : data.target.type === "checkbox"
    //                           ? data.target.checked
    //                           : data.target.value,
    //                       ...section,
    //                     };

    //                     // VISIBILITY LOGIC
    //                     let visibility: number = 0;
    //                     let fieldCount: number = 8;
    //                     // let fieldCount: number =
    //                     //   final?.ticketType?.id === "eticket" &&
    //                     //   _.size(final?.ticketFiles) === 0
    //                     //     ? 11
    //                     //     : 10;

    //                     if (
    //                       final?.ticketType &&
    //                       _.size(final?.ticketType) > 0
    //                     ) {
    //                       visibility += 5 / fieldCount;
    //                     }

    //                     // SUB TICKET TYPE
    //                     // if (
    //                     //   final?.ticketFiles &&
    //                     //   _.size(final?.ticketFiles) > 0
    //                     // ) {
    //                     //   visibility += 5 / fieldCount;
    //                     // } else if (
    //                     //   _.size(final?.ticketFiles) === 0 &&
    //                     //   final?.ticketType?.id === "eticket"
    //                     // ) {
    //                     //   fieldCount = 11;
    //                     // } else if (
    //                     //   _.size(final?.ticketFiles) === 0 &&
    //                     //   final?.ticketType?.id !== "eticket"
    //                     // ) {
    //                     //   fieldCount = 10;
    //                     //   visibility += 5 / fieldCount;
    //                     // }

    //                     // QUANTITY
    //                     if (final?.quantity) {
    //                       visibility += 5 / fieldCount;
    //                     }
    //                     if (final?.splitType && _.size(final?.splitType) > 0) {
    //                       visibility += 5 / fieldCount;
    //                     }

    //                     if (final?.category && _.size(final?.category) > 0) {
    //                       visibility += 5 / fieldCount;
    //                     }
    //                     if (final?.section && _.size(final?.section) > 0) {
    //                       visibility += 5 / fieldCount;
    //                     }
    //                     if (final?.row) {
    //                       visibility += 5 / fieldCount;
    //                     }
    //                     if (final?.proceedValue?.value) {
    //                       visibility += 5 / fieldCount;
    //                     }
    //                     if (final?.ticketsInHands) {
    //                       visibility += 5 / fieldCount;
    //                     }

    //                     visibility = Math.round(visibility);

    //                     return {
    //                       ...final,
    //                       visibility: visibility && visibility,
    //                       oldVisibility:
    //                         visibility && final?.visibility === visibility
    //                           ? final?.oldVisibility
    //                           : visibility && final?.visibility,
    //                     };
    //                   } else {
    //                     return {
    //                       ...eventRecord,
    //                     };
    //                   }
    //                 })
    //               : [],
    //           };
    //         });
    //       };
    //       setList(tempEvents([...events]));
    //     }
    //   };

    //   return handleTableField;
    // }, [events, isTableEditable, layout]);

    // Table horizontal scroll
    const scroll = (scrollOffset: number, eventId: string) => {
      const load = document.getElementById(
        `${eventId}lazyLoad`
      ) as HTMLElement | null;
      const loadHead = document.getElementById(
        `loadHead${eventId}`
      ) as HTMLElement | null;

      if (load && loadHead) {
        load.scrollLeft += scrollOffset;
        loadHead.scrollLeft += scrollOffset;
      } else {
      }
    };

    // ALL CHECKBOX SELECT
    const handleSelectAll = (data: any, eIndex: number) => {
      // const events: Array<Event> = list;
      // const tempEvents = (events: Array<Event>) => {
      //   return events.map((event, eInnerIndex) => {
      //     return {
      //       ...event,
      //       eventRecords: Array.isArray(event["eventRecords"])
      //         ? eIndex === eInnerIndex
      //           ? event["eventRecords"].map((eventRecord: EventFieldModel) => {
      //               return {
      //                 ...eventRecord,
      //                 selected: data.target.checked,
      //               };
      //             })
      //           : event["eventRecords"]
      //         : [],
      //     };
      //   });
      // };
      // setList(tempEvents([...events]));
      setList((pre: any) => {
        return pre?.map((event: any, eInnerIndex: number) => {
          return {
            ...event,
            eventRecords: event["eventRecords"]?.map(
              (eventRecord: any, rIndex: number) => {
                if (eIndex === eInnerIndex) {
                  return {
                    ...eventRecord,
                    selected: data.target.checked,
                  };
                } else {
                  return eventRecord;
                }
              }
            ),
          };
        });
      });
    };

    // HANDLE ETICKET FUNCTION
    const handleEticket = (event: any, eventRecord: any) => {
      //eventRecord.selected = true;
      setList((prev: any) => {
        return prev.map((inEvent: any) => {
          if (inEvent?.id === event?.id) {
            return {
              ...inEvent,
              eventRecords: isArray(inEvent["eventRecords"])
                ? inEvent["eventRecords"].map((inEventRecord: any) => {
                    if (inEventRecord?.card === eventRecord?.card) {
                      return {
                        ...inEventRecord,
                        selected: true,
                      };
                    } else {
                      return inEventRecord;
                    }
                  })
                : [],
            };
          } else {
            return inEvent;
          }
        });
      });
      layout.setTempEvent(list);
      layout.setETicketDataField((currentData: any) => {
        let eventSelected = list
          .map((event: any) => {
            return {
              ...event,
              eventRecords: Array.isArray(event["eventRecords"])
                ? event["eventRecords"].filter((inEventRecord: any) => {
                    return (
                      (inEventRecord?.selected === true ||
                        eventRecord?.card === inEventRecord?.card) &&
                      (inEventRecord?.ticketType?.id === "eticket" ||
                        inEventRecord?.ticketType?.id === "mobile-link" ||
                        inEventRecord?.ticketType?.id === "pdf" ||
                        inEventRecord?.ticketType?.id === "mobile-qr")
                    );
                  })
                : [],
            };
          })
          .filter((event: any) => {
            return (
              Array.isArray(event["eventRecords"]) &&
              event["eventRecords"].length > 0
            );
          });
        return {
          btnEvent:
            eventSelected && eventSelected.length > 0
              ? _.map(eventSelected, "id")
              : _.map(event, "id"),
          isOpen: true,
        };
      });
    };

    let allChecked = event["eventRecords"]?.every((item: any) =>
      item.selected === true ? true : false
    );
    // SHIFT ALL SELECT
    // const handleShift = (e: any, rIndex: any) => {
    //   let firstIndex = event.eventRecords.findIndex(
    //     (first: any, index: any) => first.selected && index
    //   );

    //   if (e.shiftKey && e.target.checked) {
    //     event.eventRecords.forEach((record: any, index: any) => {
    //       if (
    //         (index >= firstIndex && index <= rIndex) ||
    //         (index >= rIndex && index <= firstIndex)
    //       ) {
    //         record.selected = true;
    //       } else {
    //         record.selected = false;
    //       }
    //     });
    //   }
    // };

    const getDisplayPriceCurrency = useMemo(() => {
      return (exchangeId: any, eventIndex: any) => {
        let findCurrency = event?.event_currencies?.find(
          (currency: any) => currency.id === exchangeId
        );

        if (findCurrency === undefined) {
          findCurrency = event?.event_currencies?.find(
            (currency: any) => currency.id === 14
          );
        }
        return findCurrency?.currency;
      };
    }, [event]);

    const resetPPEValues = (rIndex: any) => {
      setList((currentValue: any) => {
        currentValue[eIndex]["eventRecords"][rIndex]["ppeExchanges"] = [];
        return [...currentValue];
      });
    };

    const setPPE = (rIndex: any) => {
      setList((currentValue: any) => {
        currentValue[eIndex]["eventRecords"][rIndex]["ppe"] = true;
        return [...currentValue];
      });
    };

    const unSetPPE = (rIndex: any) => {
      setList((currentValue: any) => {
        currentValue[eIndex]["eventRecords"][rIndex]["ppeExchanges"] = [];
        currentValue[eIndex]["eventRecords"][rIndex]["ppe"] = false;
        return [...currentValue];
      });
    };

    const scrollContainer = document.getElementById(`${event.id}lazyLoad`);
    let prevScrollTop = 0;
    let down: any = document.querySelectorAll(".bottom_load");
    let up: any = document.querySelectorAll(".top_load");

    // SCROLL FOR LOADING
    scrollContainer?.addEventListener("scroll", function () {
      const currentScrollTop = scrollContainer.scrollTop;
      if (currentScrollTop > prevScrollTop) {
        // scrolling down
        up?.forEach((item: any) => {
          item?.classList.add("hidden");
        });
        down?.forEach((item: any) => {
          item?.classList.remove("hidden");
        });
      } else if (currentScrollTop < prevScrollTop) {
        // scrolling up
        up?.forEach((item: any) => {
          item?.classList.remove("hidden");
        });
        down?.forEach((item: any) => {
          item?.classList.add("hidden");
        });
      }
      prevScrollTop = currentScrollTop;
    });

    useEffect(() => {
      const element = document.getElementById("tableId1");
      if (element) {
        const elem1 = document.querySelectorAll("#left-shad-list");
        const elem2 = document.querySelectorAll("#checkbox_shad");
        element?.firstChild?.addEventListener("scroll", () => {
          const elementScrollLeft = element.scrollLeft;
          const elementScrollRight =
            element.scrollWidth - element.clientWidth - elementScrollLeft;

          // console.log(elementScrollLeft, elementScrollRight);

          elem1.forEach((element: any, index: any) => {
            elementScrollRight === 0
              ? element.classList.remove("left-shad")
              : element.classList.add("left-shad");
          });
          elem2.forEach((element: any, index: any) => {
            elementScrollLeft === 0
              ? element.classList.remove("shar-right")
              : element.classList.add("shar-right");
          });
        });
      }

      document.removeEventListener("click", allDocumentEventHadler);
      document.body.addEventListener("click", allDocumentEventHadler, true);

      function allDocumentEventHadler(e: any) {
        if (e.target.tagName.toUpperCase() == "INPUT") {
          setTimeout(() => {
            setActiveField("");
          }, 100);
        }
      }
    }, [event]);

    const errorListingCount = useMemo(() => {
      return event?.eventRecords?.filter((item: any) => {
        return (
          (item?.errors &&
            item?.errors?.commonErrors &&
            _.size(item?.errors?.commonErrors) > 0 &&
            Object.keys(item?.errors)?.length > 0) ||
          _.includes(["failed", "partPublished"], item?.status)
        );
      })?.length;
    }, [event]);

    const marketplaceListingError = useMemo(() => {
      return event?.eventRecords?.filter((item: any) => {
        return (
          item?.marketplaces &&
          _.size(item.marketplaces) > 0 &&
          item.marketplaces.some(
            (marketplace: any) =>
              marketplace.error && marketplace.error.length > 0
          )
        );
      })?.length;
    }, [event]);

    const getPublishedListingCount = useMemo(() => {
      return event.eventRecords.filter(
        (eventRecord: any) => eventRecord.status === "published"
      ).length;
    }, [event.eventRecords]);

    const getNotPublishedListing = useMemo(() => {
      return event.eventRecords.filter(
        (eventRecord: any) => eventRecord.status !== "published"
      );
    }, [event.eventRecords]);

    useEffect(() => {
      const scrollToRow = async () => {
        if (
          layout.virtuosoRecordIndex.virtuosoId !== null &&
          layout.virtuosoRecordIndex.eventId === event.id
        ) {
          await virtuosoRef.current.scrollToIndex({
            index: layout.virtuosoRecordIndex.virtuosoId,
            align: "center",
            behavior: "smooth",
          });
          layout.setVirtuosoRecordIndex({});
        }
      };

      scrollToRow();
    }, [layout.virtuosoRecordIndex.virtuosoId]);

    return (
      <>
        <div
          // id={`EventCollapseBody${eIndex}`}
          id={`${
            layout.isPublish === false
              ? `EventCollapseBody${event?.id}`
              : `TableBodyPublish${event?.id}`
          }`}
          className={`border-b`}
          aria-labelledby="bodyOne"
          data-te-collapse-item
          data-te-collapse-show
        >
          {event?.eventRecords && event?.eventRecords.length > 0 && (
            <div className="accordion-body relative">
              <div
                ref={tableRef}
                className="scroll-smooth inventory-table overflow-auto scrollbar-thumb-transparent scrollbar-thumb-rounded-full rounded-b scrollbar-none noscroll-x"
                id={`tableId1`}
              >
                <div
                  //  className="w-full text-xs text-left min-w-[87.5rem] inv_table"
                  className="w-full text-xs text-left inv_table overflow-auto"
                >
                  {/* <div className="text-xs text-gray-400"> */}
                  <div
                    className="border-b text-xs text-gray-400 flex tableHead overflow-auto scrollbar-none bg-white"
                    id={`loadHead${event?.id}`}
                    ref={headerRef}
                    onScroll={() => {
                      let scrollLeftf = Number(
                        document.getElementById(`loadHead${event?.id}`)
                          ?.scrollLeft
                      );
                      if (_.size(event?.eventRecords) > 0) {
                        document
                          .getElementById(`${event?.id}lazyLoad`)
                          ?.scrollTo({
                            left: scrollLeftf,
                          });
                      } else {
                        debouncedHandleScrollHeader(event);
                      }
                    }}
                  >
                    <div
                      className="flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] text-center !sticky  left-0 p-0 z-10 no-scroll checkbox_td"
                      id={`checkbox_shad`}
                    >
                      <label
                        htmlFor={`all-${event?.id}`}
                        className={`${
                          (disabledRow ||
                            _.size(layout?.isTableEditable) > 0 ||
                            layout.isDeleteActive) &&
                          "pointer-events-none !border-gray-300"
                        } ${
                          layout.isPublish &&
                          "pointer-events-none !border-gray-300"
                        } min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] absolute cursor-pointer
                          top-0 p-1 border-r w-10 flex items-center justify-center`}
                      ></label>
                      <input
                        type="checkbox"
                        id={`all-${event?.id}`}
                        className={`${
                          (disabledRow ||
                            _.size(layout?.isTableEditable) > 0 ||
                            layout.isDeleteActive) &&
                          "pointer-events-none opacity-50"
                        } w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0`}
                        checked={allChecked}
                        onChange={(data) => handleSelectAll(data, eIndex)}
                      />
                    </div>
                    <EventHeaderCell
                      title="Ticket type"
                      arrow={true}
                      event={event}
                      postKey="ticketType"
                      minWidth={`7.625rem`}
                      setList={setList}
                    />
                    {paperTypeExist && (
                      <EventHeaderCell
                        title="Delivery type"
                        arrow={false}
                        minWidth={`7.875rem`}
                      />
                    )}

                    {paperTypeExist &&
                      isArray(seletedDeliveryTypes) &&
                      _.orderBy(seletedDeliveryTypes, deliveryTypeOrders, [
                        "asc",
                      ]).map((selected: any) => {
                        return (
                          <EventHeaderCell
                            title={selected}
                            arrow={false}
                            minWidth={`7.625rem`}
                          />
                        );
                      })}

                    {/* <EventHeaderCell
                      title="Sub ticket type"
                      arrow={false}
                      minWidth={`7.625rem`}
                    /> */}
                    <EventHeaderCell
                      title="Quantity"
                      arrow={true}
                      event={event}
                      postKey={"quantity"}
                      minWidth={`7.625rem`}
                      mobWidth={`max-md:min-w-[4.8125rem]`}
                      setList={setList}
                    />
                    <EventHeaderCell
                      title="Split type"
                      arrow={false}
                      minWidth={`7.625rem`}
                      setList={setList}
                    />
                    <EventHeaderCell
                      title="Sell in multiple"
                      arrow={false}
                      minWidth={`7.625rem`}
                      mobWidth={`max-md:min-w-[6.25rem]`}
                      setList={setList}
                    />
                    <EventHeaderCell
                      title="Max display ..."
                      arrow={true}
                      event={event}
                      postKey={"maxDisplayQuantity"}
                      minWidth={`7.625rem`}
                      setList={setList}
                    />
                    <EventHeaderCell
                      title="Category"
                      arrow={true}
                      event={event}
                      postKey="category"
                      minWidth={`13.25rem`}
                      mobWidth={`max-md:min-w-[11.25rem]`}
                      setList={setList}
                    />
                    <EventHeaderCell
                      title="Section/block"
                      arrow={true}
                      event={event}
                      postKey="section"
                      minWidth={`13.25rem`}
                      mobWidth={`max-md:min-w-[11.25rem]`}
                      setList={setList}
                    />
                    <EventHeaderCell
                      title="Row"
                      arrow={true}
                      event={event}
                      postKey="row"
                      minWidth={`7.625rem`}
                      mobWidth={`max-md:min-w-[6.25rem]`}
                      setList={setList}
                    />
                    <EventHeaderCell
                      title="First seat"
                      arrow={true}
                      postKey="firstSeat"
                      event={event}
                      minWidth={`7.625rem`}
                      mobWidth={`max-md:min-w-[6.25rem]`}
                      setList={setList}
                    />
                    <EventHeaderCell
                      title="Face value"
                      arrow={true}
                      event={event}
                      postKey={"faceValue"}
                      minWidth={`7.625rem`}
                      setList={setList}
                    />
                    <EventHeaderCell
                      title="Proceed price"
                      arrow={true}
                      event={event}
                      postKey={"proceedValue"}
                      minWidth={`7.625rem`}
                      setList={setList}
                    />

                    <EventHeaderCell
                      title="Benefits"
                      arrow={false}
                      minWidth={`13.25rem`}
                      setList={setList}
                      mobWidth={`max-md:min-w-[8.75rem]`}
                    />
                    <EventHeaderCell
                      title="Restrictions"
                      arrow={false}
                      minWidth={`13.25rem`}
                      setList={setList}
                      mobWidth={`max-md:min-w-[8.75rem]`}
                    />
                    {/* <EventHeaderCell
                      title="In hand"
                      arrow={false}
                      minWidth={`3.625rem`}
                      setList={setList}
                    /> */}
                    <EventHeaderCell
                      title="Date to ship"
                      arrow={false}
                      minWidth={`8.25rem`}
                      setList={setList}
                    />

                    <div
                      // className="sticky right-0 p-0  border-l min-w-[7.5625rem] bg-white"
                      className={`md:sticky right-0 p-0 border-l bg-white ml-auto 
                      ${
                        event?.eventRecords?.length > 15
                          ? "min-w-[7.8125rem] "
                          : "min-w-[7.5rem] max-md:min-w-[9.375rem]"
                      }`}
                    >
                      <div
                        className={`py-2 px-4 flex justify-end h-[2.5rem] left-shad `}
                        id={`left-shad-list`}
                      >
                        <button
                          className="group rounded-l  hover: transition px-[.5625rem] py-2 max-md:opacity-0 max-md:pointer-events-none"
                          id="leftArrow"
                          type="button"
                          onClick={(e: any) => scroll(-120, event?.id)}
                        >
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                            path={AngleLeft}
                            svgClassName="w-1.5 h-[.5625rem]"
                          />
                        </button>
                        <button
                          className="group rounded-r  transition px-[.5625rem] py-2 max-md:opacity-0 max-md:pointer-events-none"
                          id="rightArrow"
                          type="button"
                          onClick={(e: any) => scroll(120, event?.id)}
                        >
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                            path={AngleRight}
                            svgClassName="w-1.5 h-[.5625rem]"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}

                  {/* LOADERS */}
                  {/* <div
                    className={`absolute bottom-6 left-0 right-0 m-auto text-center text-sm14 bottom_load`}
                  >
                    Loading...
                  </div>
                  <div
                    className={`absolute top-16 left-0 right-0 m-auto text-center text-sm14 -z[1] top_load`}
                  >
                    Loading...
                  </div> */}
                  {/* Shimmer Loading */}
                  <div className="absolute bg-white left-0 top-0 w-full h-full gap-y-2.5 flex flex-wrap mr-1 z-[-1] overflow-hidden scrollbar-none">
                    {Array.from({ length: 10 }, (v, i) => (
                      <div className="bg-gray-100 rounded mr-1 h-10 w-full shimmer-effect "></div>
                    ))}
                  </div>

                  {/* VIRTUOSO */}
                  <Virtuoso
                    data={event?.eventRecords}
                    id={event.id + "lazyLoad"}
                    increaseViewportBy={20}
                    followOutput={true}
                    ref={virtuosoRef}
                    onScroll={handleScrollVirtuoso}
                    totalCount={
                      getPublishedListingCount
                        ? getPublishedListingCount
                        : event?.eventRecords?.length
                    }
                    className="tableBody no-scroll"
                    style={{
                      height:
                        event?.eventRecords?.length - getPublishedListingCount >
                        15
                          ? 53.6 + `vh`
                          : (event?.eventRecords?.length -
                              getPublishedListingCount) *
                              2.55 +
                            (errorListingCount + marketplaceListingError) *
                              1.9875 +
                            `rem`,
                    }}
                    itemContent={(rIndex: any, eventRecord: any) => {
                      if (coreLayout?.isMobile) {
                        return (
                          <Viewmobile
                            event={event}
                            eIndex={eIndex}
                            rIndex={rIndex}
                            tempEventRecord={eventRecord}
                            isTableEditable={isTableEditable}
                            target_currency={target_currency}
                            forRowDisabled={forRowDisabled}
                            seletedDeliveryTypes={seletedDeliveryTypes}
                            deliveryTypeExist={deliveryTypeExist}
                            paperTypeExist={paperTypeExist}
                            triggered={triggered}
                            firstSelectedIndex={firstSelectedIndex}
                            focusedRow={focusedRow}
                            list={list}
                            setList={setList}
                            setPPE={setPPE}
                            resetPPEValues={resetPPEValues}
                            handleFocus={handleFocus}
                            handleBlur={handleBlur}
                            handleEticket={handleEticket}
                            unSetPPE={unSetPPE}
                            getDisplayPriceCurrency={getDisplayPriceCurrency}
                            setTriggered={setTriggered}
                            handlePPETableChange={handlePPETableChange}
                            setIsOpenListingQualityPopup={
                              setIsOpenListingQualityPopup
                            }
                          />
                        );
                      } else {
                        return (
                          <ItemContent
                            event={event}
                            eIndex={eIndex}
                            rIndex={rIndex}
                            tempEventRecord={eventRecord}
                            isTableEditable={isTableEditable}
                            target_currency={target_currency}
                            forRowDisabled={forRowDisabled}
                            seletedDeliveryTypes={seletedDeliveryTypes}
                            deliveryTypeExist={deliveryTypeExist}
                            paperTypeExist={paperTypeExist}
                            triggered={triggered}
                            firstSelectedIndex={firstSelectedIndex}
                            focusedRow={focusedRow}
                            list={list}
                            setList={setList}
                            setPPE={setPPE}
                            resetPPEValues={resetPPEValues}
                            handleFocus={handleFocus}
                            handleBlur={handleBlur}
                            handleEticket={handleEticket}
                            unSetPPE={unSetPPE}
                            getDisplayPriceCurrency={getDisplayPriceCurrency}
                            setTriggered={setTriggered}
                            handlePPETableChange={handlePPETableChange}
                            setIsOpenListingQualityPopup={
                              setIsOpenListingQualityPopup
                            }
                          />
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {layout.eTicketDataField?.isOpen &&
          layout.eTicketDataField?.btnEvent.includes(event?.id) && (
            <ETicketUpload list={list} setList={setList} />
          )}
      </>
    );
  }
);

export default VirtuosoEventField;
