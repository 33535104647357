export const SearchIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.207"
    height="15.207"
    viewBox="0 0 15.207 15.207"
    {...props}
  >
    <path
      d="M4,4,8,8"
      transform="translate(6.5 6.5)"
      fill="none"
      stroke="#41007f"
      stroke-width="2"
    />
    <g fill="none" stroke="#41007f" stroke-width="2">
      <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
      <circle cx="6.5" cy="6.5" r="5.5" fill="none" />
    </g>
  </svg>
);
