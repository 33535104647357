/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _, { isArray, size } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactS3Client from "react-aws-s3-typescript";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDropzone } from "react-dropzone";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import Clear from "../../../assets/media/icons/other_icons/Clear.svg";
import CloneSVG from "../../../assets/media/icons/other_icons/Clone.svg";
import Delete from "../../../assets/media/icons/other_icons/Delete.svg";
import Eye from "../../../assets/media/icons/other_icons/Eye.svg";
import Upload from "../../../assets/media/icons/other_icons/Upload.svg";
import mobileSVG from "../../../assets/media/icons/other_icons/mobile.svg";
import pin from "../../../assets/media/icons/other_icons/pin.svg";
import Process from "../../../assets/media/icons/other_icons/process.svg";
import Collapse from "../../../assets/media/icons/standard_icons/Collapse.svg";
import AngleLeft from "../../../assets/media/icons/standard_icons/angle_left.svg";
import AngleRight from "../../../assets/media/icons/standard_icons/angle_right.svg";
import Calendar from "../../../assets/media/icons/standard_icons/calendar.svg";
import ClockOutline from "../../../assets/media/icons/standard_icons/clock-outline.svg";

import moment from "moment";
import {
  default as Cross,
  default as remove,
} from "../../../assets/media/icons/standard_icons/cross.svg";
import Geo from "../../../assets/media/icons/standard_icons/geo_icon.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import {
  MobileInsturction,
  base64TOURL,
  checkingValidUrlForBoth,
  getExtension,
  isValidUrl,
} from "../../../helpers/AssetHelpers";
import { crc32 } from "../../../helpers/Functions";
import Loader from "../../../helpers/Loader";
import { showAlert } from "../../../helpers/ShowAlert";
import { MAX_FILES, PDFFailedLoad, S3_CONFIG } from "../../../helpers/const";
import {
  TicketError,
  copy,
  generateUniqueId,
} from "../../add_inventory/core/_functions";
import MobileUrlLink from "../../add_inventory/widgets/MobileUrlLink";
import UploadFileInstruction from "../../add_inventory/widgets/UploadFileInstruction";
import { InventoryContext } from "../core/InventoryProvider";
import {
  setEventRecordPayload,
  visibilityMeterCalculationForInventory,
} from "../core/_functions";
import { UpdateInventory, getInventoryRecordListing } from "../core/_request";

window.Buffer = window.Buffer || require("buffer").Buffer;

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

//Styled Component for Styling the Assigned List
const List = styled.li`
  background-color: ${(props) =>
    // @ts-ignore
    props?.checkedState || props?.assigned ? "" : "white"};
`;

const ETicketUpload = ({ popUpOpen, closeHandle, list, setList }: any) => {
  const [files, setFiles] = useState<any>([]);
  const [pdfView, setPdfView] = useState<boolean>(false);
  const [filePreview, setFilePreview] = useState<any>();
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [ticketFiles, setTicketFiles] = useState<any>([]);
  const [checkedState, setCheckedState] = useState(false);
  const [loading, isLoading] = useState(false);
  const layout = useContext(InventoryContext);
  const s3Upload = new ReactS3Client(S3_CONFIG);
  const closeIconRef: any = useRef(null);
  const [collapse, setCollapse] = useState(true);
  const [hoverFile, setHoverFile] = useState<any>("");
  const [pdfCurrentIndex, setPdfCurrentIndex] = useState<any>();
  const [mobileLinkResponse, setMobileLinkResponse] = useState<any>();
  const [isCopied, setIsCopied] = useState<any>({
    id: "",
    value: "",
  });

  const eventRef = useRef<any>();

  // @ts-ignore
  let customS3 = s3Upload?.config;
  useEffect(() => {
    //setTimeout(() => {
    setList((prev: any) => {
      const tempEvents = prev.map((event: any) => {
        return {
          ...event,
          eventRecords: Array.isArray(event["eventRecords"])
            ? event["eventRecords"].map((inEventRecord: any) => {
                const tickets = ["eticket", "pdf", "mobile-qr", "mobile-link"];
                const ticketType =
                  typeof inEventRecord?.ticket_type === "object"
                    ? inEventRecord?.ticket_type?.id
                    : inEventRecord?.ticket_type;

                if (
                  inEventRecord?.selected === true &&
                  tickets?.includes(ticketType)
                ) {
                  let ticketFiles =
                    inEventRecord?.listing_ticket_files?.length > 0
                      ? inEventRecord?.listing_ticket_files?.map(
                          (listingTicketFile: any, index: any) => {
                            if (listingTicketFile?.is_url === 1) {
                              return {
                                preview: listingTicketFile?.file,
                                id: generateUniqueId(),
                                path: listingTicketFile?.file.substr(
                                  listingTicketFile?.file.lastIndexOf("/") + 1
                                ),
                                alreadySave: 1,
                                index: index,
                              };
                            } else {
                              return {
                                preview: base64TOURL(listingTicketFile?.file),
                                path:
                                  listingTicketFile?.file.substring(10, 0) +
                                  ".pdf",
                                id: generateUniqueId(),
                                alreadySave: 1,
                                index: index,
                              };
                            }
                          }
                        )
                      : inEventRecord?.mobile_links?.length > 0
                      ? inEventRecord?.mobile_links?.map(
                          (obj: any, index: any) => {
                            if (obj?.android && obj?.ios) {
                              return {
                                mobileLink: obj,
                                isValid: true,
                                id: generateUniqueId(),
                                // path: obj?.substr(obj.lastIndexOf("/") + 1),
                                alreadySave: 1,
                                index: index,
                                isValidAndroid: true,
                                isValidIos: true,
                              };
                            }
                            return {
                              mobileLink: obj,
                              isValid: true,
                              id: generateUniqueId(),
                              path: obj?.substr(obj.lastIndexOf("/") + 1),
                              alreadySave: 1,
                              index: index,
                            };
                          }
                        )
                      : [];
                  const difference =
                    inEventRecord?.quantity_available - ticketFiles?.length;
                  // Add empty objects based on the difference
                  for (let i = 0; i < Math.min(difference, MAX_FILES); i++) {
                    ticketFiles.push({});
                  }
                  let pkPass: any;
                  if (inEventRecord?.mobile_links?.length > 0) {
                    const hasPkpassExtension =
                      inEventRecord?.mobile_links?.some((link: any) =>
                        typeof link !== "object"
                          ? link?.endsWith(".pkpass")
                          : ""
                      );
                    pkPass = hasPkpassExtension ? true : false;
                  }
                  let separate_links: any;
                  if (inEventRecord?.mobile_links?.length > 0) {
                    const hasPkpassExtension =
                      inEventRecord?.mobile_links?.some(
                        (obj: any) => obj?.android && obj?.ios
                      );
                    separate_links = hasPkpassExtension ? true : false;
                  }
                  return {
                    ...inEventRecord,
                    ticketFiles: ticketFiles,
                    pkPass: pkPass,
                    separate_links: separate_links,
                  };
                } else {
                  return inEventRecord;
                }
              })
            : undefined,
        };
      });
      return tempEvents;
    });
    //}, 3000);
  }, [layout.eTicketDataField]);

  //GET COUNT OF UPLOADED LISTING
  const getUploadedListing = () => {
    let count = 0;
    let totalListing = 0;
    let isAllUploadedFromBE: any = false;
    let totalUploadedListing: any = 0;

    list?.forEach((event: any, eventIndex: number) => {
      event["eventRecords"]?.map((eventRecord: any, eventRecordIndex: any) => {
        if (
          (eventRecord?.selected &&
            eventRecord?.ticketType?.id === "eticket") ||
          (eventRecord?.selected && eventRecord?.ticket_type === "eticket") ||
          (eventRecord?.selected && eventRecord?.ticket_type?.id === "pdf") ||
          (eventRecord?.selected &&
            eventRecord?.ticket_type?.id === "mobile-qr") ||
          (eventRecord?.selected &&
            (eventRecord?.ticket_type?.id === "mobile-link" ||
              eventRecord?.ticket_type === "mobile-link"))
        ) {
          totalListing++;
          if (
            Number(eventRecord?.quantity_available) ===
            (eventRecord?.listing_ticket_files?.length ||
              eventRecord?.mobile_links?.length)
          ) {
            totalUploadedListing++;
            isAllUploadedFromBE = true;
          } else {
            isAllUploadedFromBE = false;
          }

          const ticketFiles = eventRecord?.ticketFiles || {};
          const nonEmptyTicketFiles = Object.values(ticketFiles).filter(
            (obj: any) => JSON.stringify(obj) !== "{}"
          );
          const mobileLinkUploaded = nonEmptyTicketFiles?.every((obj: any) =>
            eventRecord?.pkpass
              ? obj?.path && obj?.isValid && obj?.mobileLink
              : obj?.isValid && obj?.mobileLink
          );

          const nonEmptyFilesCount = nonEmptyTicketFiles?.length;
          const requiredQuantity = eventRecord?.quantity_available || 0;

          const mobileType =
            eventRecord?.ticket_type?.id || eventRecord?.ticket_type;
          if (
            mobileType === "mobile-link" &&
            mobileLinkUploaded &&
            nonEmptyFilesCount >= requiredQuantity &&
            nonEmptyFilesCount > 0
          ) {
            count++;
          } else if (
            mobileType !== "mobile-link" &&
            nonEmptyFilesCount >= requiredQuantity &&
            nonEmptyFilesCount > 0
          ) {
            count++;
          }

          // list?.[eventIndex]?.eventRecords[eventRecordIndex]?.ticketFiles &&
          //   Object.keys(
          //     list?.[eventIndex]?.eventRecords[eventRecordIndex]?.ticketFiles
          //   ).length &&
          //   Object.keys(
          //     list?.[eventIndex]?.eventRecords[
          //       eventRecordIndex
          //     ]?.ticketFiles?.filter((obj: any) => JSON.stringify(obj) !== "{}")
          //   ).length >=
          //     (eventRecord?.quantity_available
          //       ? eventRecord?.quantity_available
          //       : 0) &&
          //   Object.keys(
          //     list?.[eventIndex]?.eventRecords[
          //       eventRecordIndex
          //     ]?.ticketFiles?.filter((obj: any) => JSON.stringify(obj) !== "{}")
          //   ).length > 0 &&
          //   count++;
        }
      });
    });
    //return [count, totalListing, isAllUploadedFromBE];
    return [
      count,
      totalListing,
      totalUploadedListing === totalListing ? true : false,
    ];
  };

  const uniqueTicketTypes = useMemo(() => {
    if (!list || !Array.isArray(list)) return [];
    const ticketTypesSet = new Set();

    list.forEach((event: any) => {
      if (event?.eventRecords && Array.isArray(event.eventRecords)) {
        event.eventRecords.forEach((record: any) => {
          // if (record?.selected === true && record.ticket_type) {
          if (
            record?.selected &&
            !record?.isInputChange &&
            (record?.ticketType?.id === "eticket" ||
              record?.ticket_type === "eticket" ||
              record?.ticket_type?.id === "pdf" ||
              record?.ticket_type?.id === "mobile-qr" ||
              record?.ticket_type?.id === "mobile-link" ||
              record?.ticket_type === "mobile-link")
          ) {
            const ticketTypeId =
              record?.sub_ticket_type === "mobile-qr"
                ? record?.sub_ticket_type
                : record.ticket_type?.id || record.ticket_type;
            ticketTypesSet.add(ticketTypeId);
          }
        });
      }
    });
    // getUploadedListing()[2]
    return Array.from(ticketTypesSet)?.map((item: any) => {
      return {
        heading:
          item === "mobile-link" && getUploadedListing()[2]
            ? "Mobile Link/PKPASS secured for delivery"
            : item === "mobile-link"
            ? "Mobile Link/PKPASS upload instructions"
            : getUploadedListing()[2]
            ? "Tickets secured for delivery"
            : `${
                item === "mobile-qr" ? "Mobile Screenshot" : "E-ticket"
              } delivery instructions`,
        listing: getUploadedListing()[2]
          ? null
          : item === "mobile-link"
          ? MobileInsturction
          : [
              "Use this window to upload individual tickets for each e-ticket order (PDF format)",
              "Drag and drop each file - ensuring any designated seat matches the ticket",
              "Confirm all tickets are uploaded by clicking the green 'confirm' button",
            ],
        type: item === "mobile-link" ? "Mobile" : "E-Ticket",
        margin: getUploadedListing()[2],
        extra: getUploadedListing()[2]
          ? `These tickets are secured for delivery and will sell faster on the Tixstock Network. ${
              item === "mobile-link"
                ? ""
                : "You can preview the uploaded tickets by clicking the eye icon."
            } To change the uploaded tickets, you must unpublish the listing. if you require assistance, please contact 
            <a href="mailto:support@tixstock.com" class="text-indigo-500 hover:text-violet-800">
              support@tixstock.com
            </a>
          `
          : "",
      };
    });
  }, [list]);

  /**
   * All Functions to handle the PDF's
   */
  const { getRootProps, getInputProps, open } = useDropzone({
    maxSize: 5244899, // 5MB in bytes
    onDrop: (acceptedFiles, rejectedFiles) => {
      const uniqueCodes = Array.from(
        new Set(
          rejectedFiles.flatMap((item: any) =>
            item.errors.map((error: any) =>
              error.code === "file-too-large"
                ? "Please upload less then 5MB"
                : "Invalid File Type"
            )
          )
        )
      );
      if (uniqueCodes && uniqueCodes.length > 0) {
        showAlert(uniqueCodes?.join(", <br/>"), true);
      }

      setFiles([
        ...files,
        ...acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: generateUniqueId(),
          })
        ),
      ]);
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [],
      "vnd.apple.pkpass": [".pkpass", "pkpass"],
    },
  });

  var evnIndex: any;
  var eveRecordIndex: any;

  /**
   * Function to Handle File Upload from Right side Component
   */
  const handleRightUpload = (eventIndex: any, eventRecordIndex: any) => {
    evnIndex = eventIndex;
    eveRecordIndex = eventRecordIndex;
    rightSideUpload.open();
  };

  /**
   * Right Side Upload
   */
  const rightSideUpload = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => {
      const uniqueCodes = Array.from(
        new Set(
          rejectedFiles.flatMap((item: any) =>
            item.errors.map((error: any) => {
              return error.code === "file-too-large"
                ? "Please upload less then 5MB"
                : error.code === "too-many-files"
                ? "Please select only one file at time"
                : "Invalid File Type";
            })
          )
        )
      );
      if (uniqueCodes && uniqueCodes.length > 0) {
        showAlert(uniqueCodes?.join(", <br/>"), true);
      }

      setList((prev: any) => {
        return prev.map((event: any, inEventIndex: any) => {
          if (inEventIndex === evnIndex) {
            return {
              ...event,
              eventRecords: event["eventRecords"]
                ? event["eventRecords"]?.map(
                    (eventRecord: any, inEventRecordIndex: any) => {
                      // Object.assign(eventRecord, {
                      //   id: generateUniqueId(),
                      // });
                      if (inEventRecordIndex === eveRecordIndex) {
                        let ticket = {
                          ...eventRecord,
                          ...(eventRecord?.ticketFiles
                            ? {
                                ticketFiles: [
                                  ...eventRecord?.ticketFiles,
                                  ...acceptedFiles?.map((file) =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      id: generateUniqueId(),
                                      assigned: 1,
                                    })
                                  ),
                                ],
                              }
                            : {
                                ticketFiles: [
                                  ...acceptedFiles?.map((file) =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      id: generateUniqueId(),
                                      assigned: 1,
                                    })
                                  ),
                                ],
                              }),
                        };
                        return ticket;
                      } else {
                        return eventRecord;
                      }
                    }
                  )
                : [],
            };
          } else {
            return event;
          }
        });
      });
      setFiles([
        ...files,
        ...acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: generateUniqueId(),
            assigned: 1,
          })
        ),
      ]);
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [],
      "vnd.apple.pkpass": [".pkpass", "pkpass"],
    },
    maxFiles: 1,
    maxSize: 5244899, // 5MB in bytes,
  });

  /**
   * Function to view PDF
   * @param file
   */
  const pdfViewer = (file: any) => {
    setPageNumber(1);
    setFilePreview(file);
    setPdfView(true);
  };

  /**
   * Function for Deleting PDF
   * @param file
   * @param index
   */
  const deletePdf = (file: any, index: number) => {
    setFiles((currentPDF: any) =>
      currentPDF.filter(
        (item: any, filterIndex: number) => index !== filterIndex
      )
    );
  };

  /**
   *  On document load success
   *
   * @param param0
   */
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  /**
   * Drag n Drop Functionality (a little function to help us with reordering the result)
   * @param list
   * @param startIndex
   * @param endIndex
   * @returns
   */
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const uploadMobileLinkAWS = async (
    eventIndex: any,
    eventRecordIndex: any,
    arrItemIndex: any,
    finalData: any,
    dropData: any
  ) => {
    let ticketData = finalData;

    let additionalConfig = {
      ...customS3,
      dirName: `ticket-files/mobile-links/2`,
    };

    const s3Upload = new ReactS3Client(additionalConfig);

    try {
      const data = await s3Upload.uploadFile(dropData);
      setMobileLinkResponse((current: any) =>
        current && size(current) > 0
          ? [
              ...current,
              {
                eventIndex,
                eventRecordIndex,
                arrItemIndex,
                ticketData,
                url: data?.location,
              },
            ]
          : [
              {
                eventIndex,
                eventRecordIndex,
                arrItemIndex,
                ticketData,
                url: data?.location,
              },
            ]
      );
    } catch (error) {
      console.error("Error uploading file to AWS S3:", error);
    }
  };

  /**
   * Moves an item from one list to another list.
   * @param source
   * @param destination
   * @param droppableSource
   * @param droppableDestination
   * @returns
   */
  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};

    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const markAssignedZero = (id: any) => {
    files.map((file: any, index: any) => {
      if (file?.id === id) {
        file.assigned = 0;
      }
    });
  };

  const deletePDFFromFiles = (id: any) => {
    markAssignedZero(id);
  };

  var dragArea = document.querySelector<HTMLElement>("#drag_here");

  /**
   * Event Of Drag End
   * @param result
   * @returns
   */
  let onDragEnd = (result: any) => {
    if (dragArea) {
      dragArea.style.borderColor = "inherit";
      dragArea?.classList.remove("!border-green-500");
    }
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (destination.droppableId === "eventTicketUploadedFiles") {
        const items = reorder(files, source.index, destination.index);
        setFiles(items);
      } else {
        const items = reorder(ticketFiles, source.index, destination.index);
        setList((prev: any) => {
          return prev.map((event: any, eventIndex: number) => {
            return {
              ...event,
              eventRecords: event["eventRecords"].map((eventRecord: any) => {
                if (eventRecord.ticketFiles) {
                  return {
                    ...eventRecord,
                    ticketFiles: reorder(
                      eventRecord?.ticketFiles,
                      source.index,
                      destination.index
                    ),
                  };
                } else {
                  return {
                    ...eventRecord,
                  };
                }
              }),
            };
          });
        });
      }
    } else if (destination.droppableId === "eventTicketUploadedFiles") {
      // drag from right to left
      let sourceIndex = source.droppableId.split("-");

      let sourceFiles =
        list?.[sourceIndex[0]]["eventRecords"][sourceIndex[1]]?.ticketFiles;

      sourceFiles.map((file: any, index: any) => {
        if (source.index === index) {
          file.assigned = 0;
        }
      });

      let updatedSourceFiles = sourceFiles.filter(
        (file: any, index: any) => source.index !== index
      );
      setList((prev: any) => {
        return prev.map((event: any, eventIndex: number) => {
          if (parseInt(sourceIndex[0]) === eventIndex) {
            return {
              ...event,
              eventRecords: event["eventRecords"].map(
                (eventRecord: any, evnetRecordIndex: number) => {
                  if (parseInt(sourceIndex[1]) === evnetRecordIndex) {
                    return {
                      ...eventRecord,
                      ticketFiles: updatedSourceFiles,
                    };
                  } else {
                    return eventRecord;
                  }
                }
              ),
            };
          } else {
            return event;
          }
        });
      });
    } else if (source.droppableId === "eventTicketUploadedFiles") {
      let destinationIndexs = destination.droppableId.split("-");
      let destinationFiles =
        list?.[destinationIndexs?.[0]]?.["eventRecords"]?.[
          destinationIndexs?.[1]
        ]?.ticketFiles?.[destinationIndexs[2]];

      const move_result = move(
        files,
        isArray(destinationFiles) ? destinationFiles : [],
        source,
        destination
      );

      const markAssigned = () => {
        files.map((file: any, index: any) => {
          if (source.index === index) {
            file.assigned = 1;
          }
        });
      };
      // LEFT TO RIGHT
      setList((prev: any) => {
        return prev.map((event: any, eIndex: number) => {
          if (parseInt(destinationIndexs?.[0]) === eIndex) {
            return {
              ...event,
              eventRecords: event["eventRecords"]
                ? event["eventRecords"].map(
                    (eventRecord: any, rIndex: number) => {
                      if (parseInt(destinationIndexs?.[1]) === rIndex) {
                        return {
                          ...eventRecord,
                          ticketFiles: eventRecord?.ticketFiles?.map(
                            (arrItem: any, arrIndex: number) => {
                              // MOBILE LINK
                              if (
                                parseInt(destinationIndexs[2]) === arrIndex &&
                                (eventRecord?.ticket_type?.id ===
                                  "mobile-link" ||
                                  eventRecord?.ticket_type === "mobile-link") &&
                                getExtension(
                                  move_result[destination.droppableId]?.[0]
                                    ?.path
                                ) === "pkpass"
                              ) {
                                arrItem && deletePDFFromFiles(arrItem?.id);
                                markAssigned();
                                let final = {
                                  ...move_result[destination.droppableId]?.[0],
                                };
                                uploadMobileLinkAWS(
                                  eIndex,
                                  rIndex,
                                  arrItem,
                                  final,
                                  move_result[destination.droppableId]?.[0]
                                );
                                return final;
                              }
                              // MOBILE / ETICKET
                              else if (
                                (eventRecord?.ticket_type?.id === "pdf" ||
                                  eventRecord?.ticket_type?.id === "eticket" ||
                                  eventRecord?.ticket_type?.id ===
                                    "mobile-qr" ||
                                  eventRecord?.ticket_type === "pdf" ||
                                  eventRecord?.ticket_type === "eticket" ||
                                  eventRecord?.ticket_type === "mobile-qr") &&
                                parseInt(destinationIndexs[2]) === arrIndex &&
                                move_result[destination.droppableId]?.[0]
                                  ?.type === "application/pdf"
                              ) {
                                // FILE REPLACE
                                arrItem && deletePDFFromFiles(arrItem?.id);
                                markAssigned();
                                return move_result[
                                  destination.droppableId
                                ]?.[0];
                              } else {
                                return arrItem;
                              }
                            }
                          ),
                        };
                      } else {
                        return eventRecord;
                      }
                    }
                  )
                : [],
            };
          } else {
            return event;
          }
        });
      });
    } else {
      let sourceIndexs = source.droppableId.split("-");
      let sourceFiles =
        list?.[sourceIndexs?.[0]]?.["eventRecords"][sourceIndexs?.[1]]
          ?.ticketFiles;

      let destinationIndexs = destination.droppableId.split("-");
      let destinationFiles =
        list?.[destinationIndexs?.[0]]?.["eventRecords"][destinationIndexs?.[1]]
          ?.ticketFiles;

      const move_result = move(
        isArray(sourceFiles) ? sourceFiles : [],
        isArray(destinationFiles) ? destinationFiles : [],
        source,
        destination
      );

      const updatedEvents = list.map((event: any, eventIndex: number) => {
        if (parseInt(sourceIndexs?.[0]) === eventIndex) {
          return {
            ...event,
            eventRecords: event["eventRecords"].map(
              (eventRecord: any, evnetRecordIndex: number) => {
                if (parseInt(sourceIndexs?.[1]) === evnetRecordIndex) {
                  return {
                    ...eventRecord,
                    ticketFiles: move_result[source.droppableId],
                  };
                } else if (
                  parseInt(destinationIndexs?.[1]) === evnetRecordIndex
                ) {
                  return {
                    ...eventRecord,
                    ticketFiles: move_result[destination.droppableId],
                  };
                } else {
                  return eventRecord;
                }
              }
            ),
          };
        } else if (parseInt(destinationIndexs?.[0]) === eventIndex) {
          return {
            ...event,
            eventRecords: event["eventRecords"].map(
              (eventRecord: any, evnetRecordIndex: number) => {
                if (parseInt(destinationIndexs?.[1]) === evnetRecordIndex) {
                  return {
                    ...eventRecord,
                    ticketFiles: move_result[destination.droppableId],
                  };
                }
              }
            ),
          };
        } else if (parseInt(destinationIndexs?.[0]) === eventIndex) {
          return {
            ...event,
            eventRecords: event["eventRecords"].map(
              (eventRecord: any, evnetRecordIndex: number) => {
                if (parseInt(destinationIndexs?.[1]) === evnetRecordIndex) {
                  return {
                    ...eventRecord,
                    ticketFiles: move_result[destination.droppableId],
                  };
                } else if (parseInt(sourceIndexs?.[1]) === evnetRecordIndex) {
                  return {
                    ...eventRecord,
                    ticketFiles: move_result[source.droppableId],
                  };
                } else {
                  return eventRecord;
                }
              }
            ),
          };
        } else {
          return event;
        }
      });

      setList(updatedEvents);
    }
    // for (var i = 0; i < dragArea.length; i++) {
    //   dragArea[i].style.borderColor = "inherit";
    // }
  };

  /**
   * Function for Deleting PDF's from Right Side Component
   * @param file
   * @param index
   * @param eventRecordIndex
   * @param eventIndex
   */
  const deletePdfRightSide = (
    file: any,
    index: any,
    eventRecordIndex: number,
    eventIndex: number
  ) => {
    setList((prev: any) => {
      return list.map((event: any, inEventIndex: number) => {
        if (inEventIndex === eventIndex) {
          return {
            ...event,
            eventRecords: event.eventRecords.map(
              (eventRecord: any, inEventRecordIndex: any) => {
                if (inEventRecordIndex === eventRecordIndex) {
                  return {
                    ...eventRecord,
                    // ticketFiles: eventRecord.ticketFiles.filter(
                    //   (ticketFile: any, ticketFileIndex: any) => {
                    //     if (ticketFileIndex !== index) {
                    //       return ticketFiles;
                    //     }
                    //   }
                    // ),
                    ticketFiles:
                      eventRecord?.quantity_available <
                      eventRecord.ticketFiles?.length
                        ? eventRecord.ticketFiles?.filter(
                            (ticketFile: any, ticketFileIndex: any) => {
                              if (ticketFileIndex !== index) {
                                return ticketFiles;
                              }
                            }
                          )
                        : eventRecord.ticketFiles?.map(
                            (ticketFile: any, ticketFileIndex: any) => {
                              if (ticketFileIndex === index) {
                                return {};
                              } else {
                                return ticketFile;
                              }
                            }
                          ),
                  };
                } else {
                  return eventRecord;
                }
              }
            ),
          };
        } else {
          return event;
        }
      });
    });
    // setFiles((prev: any) => {
    //   return prev.map((inFile: any) => {
    //     if (inFile.id === file.id) {
    //       return {
    //         ...inFile,
    //         assigned: 0,
    //       };
    //     } else {
    //       return inFile;
    //     }
    //   });
    // });
    markAssignedZero(file?.id);
  };

  /**
   * Handle Event of Cancel
   */
  // Handle Event Cancel/Confirm
  const handleEventCancel = () => {
    setFiles([]);
    setPdfView(false);
    layout.setETicketDataField({
      btnEvent: {},
      isOpen: false,
    });
    setList((current: any) => {
      return layout.tempEvent?.map((event: any) => {
        return {
          ...event,
          eventRecords: event?.eventRecords?.map((eventRecord: any) => {
            return {
              ...eventRecord,
              selected: false,
            };
          }),
        };
      });
    });
  };

  /**
   * Upload ETicket Fiels to AWS
   * @param eticketFiles
   * @returns
   */
  const uploadETickets = async (eticketFiles: any) => {
    return await Promise.all(
      eticketFiles.map(async (eticketFile: any) => {
        //CHECK ALREADY UPLOADED OR NOT
        if (!eticketFile?.awsFile) {
          try {
            const res = await s3Upload.uploadFile(eticketFile);
            return {
              ...eticketFile,
              awsFile: res?.key,
            };
          } catch (exception) {
            /* handle the exception */
            console.warn(exception);
            return eticketFile;
          }
        } else {
          return eticketFile;
        }
      })
    );
  };

  /**
   * Update Event Record
   * @param event
   * @param eventRecord
   * @param onlyUpdate
   */
  const updateRecord = async (
    event: any,
    eventRecord: any,
    onlyUpdate: Boolean = false,
    ticketUploaded = false
  ) => {
    eventRecord.status = onlyUpdate
      ? eventRecord.status
      : eventRecord.status === "no"
      ? "yes"
      : "no";

    const response = await UpdateInventory(
      setEventRecordPayload(
        event,
        { ...eventRecord, price_per_exchange: 0, ticketUploaded } //REMOVE PPE CHANGE
      )
    ).then((response: any) => {
      // if (_.size(response?.errors) > 0) {
      //   showAlert(response?.errors?.quantity_available, true);
      //   return 0;
      // }

      let responseData = response?.data?.payload?.post_data;
      responseData &&
        showAlert(response.message, response?.data?.status ? false : true);

      return response;
    });

    return response;
  };

  /**
   * Handle Confirm Event
   */

  const handleEventConfirm = async () => {
    isLoading(true);

    let events: any = _.cloneDeep(list);

    let apiResposne: any = "";

    await Promise.all(
      events.map(async (event: any, eIndex: number) => {
        if (layout.eTicketDataField?.btnEvent.includes(event?.id)) {
          return await Promise.all(
            event["eventRecords"].map(
              async (eventRecord: any, rIndex: number) => {
                if (
                  eventRecord?.selected &&
                  (eventRecord?.ticketType?.id === "eticket" ||
                    eventRecord?.ticket_type?.id === "eticket" ||
                    eventRecord?.ticket_type?.id === "pdf" ||
                    eventRecord?.ticket_type?.id === "mobile-qr" ||
                    eventRecord?.ticket_type === "eticket" ||
                    eventRecord?.ticket_type === "mobile-link" ||
                    eventRecord?.ticket_type?.id === "mobile-link")
                ) {
                  console.log(eventRecord);
                  //upload ticketfiles to AWS
                  let uploadedTickets: any = eventRecord?.ticketFiles.filter(
                    (ticketFile: any) =>
                      !ticketFile?.awsFile && !ticketFile?.alreadySave
                  );

                  // AWS FILE UPLOAD
                  if (isArray(uploadedTickets) && uploadedTickets.length > 0) {
                    uploadedTickets = await uploadETickets(uploadedTickets);
                  }
                  events[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
                    uploadedTickets;
                  events[eIndex]["eventRecords"][rIndex]["ticketsInHands"] =
                    true;

                  const tickeUploaded =
                    Number(eventRecord?.quantity_available) ===
                      eventRecord?.ticketFiles?.filter(
                        (obj: any) => JSON.stringify(obj) !== "{}"
                      )?.length &&
                    (eventRecord?.visibility === 5 ||
                      eventRecord?.visibility === 4);

                  // UPDATE RECORD
                  apiResposne = await updateRecord(
                    events?.[eIndex],
                    events?.[eIndex]["eventRecords"][rIndex],
                    true,
                    tickeUploaded
                  );

                  // GET INVENTORY DATA TO UPDATE STATE
                  let responseData: any = await getInventoryRecordListing({
                    id: event?.id,
                    listing_id: eventRecord?.id,
                  }).then((response) => {
                    return response?.data;
                  });

                  if (responseData?.[0]) {
                    let visibility: any = tickeUploaded
                      ? 6
                      : visibilityMeterCalculationForInventory(
                          events[eIndex],
                          events[eIndex]["eventRecords"][rIndex],
                          layout.ticketType
                        );

                    events[eIndex]["eventRecords"][rIndex] = responseData?.[0];
                    if (
                      !events[eIndex]["eventRecords"][rIndex]?.hasOwnProperty(
                        "benefits"
                      )
                    ) {
                      events[eIndex]["eventRecords"][rIndex]["benefits"] = [];
                    }
                    events[eIndex]["eventRecords"][rIndex]["ticketsInHands"] =
                      true;
                    events[eIndex]["eventRecords"][rIndex]["oldVisibility"] = 0;
                    events[eIndex]["eventRecords"][rIndex]["visibility"] =
                      visibility;
                  }
                }
                return eventRecord;
              }
            )
          );
        } else {
          return event;
        }
      })
    );
    // ticketFiles
    // CLOSE ETICET MODULE

    // CHECK ERROR IF EXIST
    if (_.size(apiResposne?.errors) > 0) {
      isLoading(false);
      showAlert(apiResposne?.errors?.quantity_available, true);
      return 0;
    }

    setList(events);
    layout.setETicketDataField({
      btnEvent: {},
      isOpen: false,
    });

    events = events?.map(async (event: any) => {
      return {
        ...event,
        eventRecords: event?.eventRecords?.map(async (eventRecord: any) => {
          let visibility = visibilityMeterCalculationForInventory(
            event,
            eventRecord,
            layout.ticketType
          );
          // let visibility: number = 0;
          // let fieldCount: number = 8;
          // // let fieldCount: number =
          // //   (eventRecord?.ticket_type === "eticket" ||
          // //     eventRecord?.ticket_type?.id === "eticket") &&
          // //   _.size(eventRecord?.listing_ticket_files) > 0
          // //     ? 9
          // //     : 8;

          // // TICKET TYPE - 1
          // if (
          //   eventRecord?.ticket_type &&
          //   _.size(eventRecord?.ticket_type) > 0
          // ) {
          //   visibility += 5 / fieldCount;
          // }

          // // TICKET TYPE - ETICKET - 2
          // // if (
          // //   _.size(eventRecord?.listing_ticket_files) > 0 ||
          // //   _.size(eventRecord?.ticketFiles) > 0
          // // ) {
          // //   visibility += 5 / fieldCount;
          // // } else if (
          // //   ((_.size(eventRecord?.listing_ticket_files) === 0 ||
          // //     _.size(eventRecord?.ticketFiles) === 0) &&
          // //     eventRecord?.ticket_type === "eticket") ||
          // //   eventRecord?.ticket_type?.id === "eticket"
          // // ) {
          // //   fieldCount = 98;
          // // } else if (
          // //   (_.size(eventRecord?.["ticketFiles"]) === 0 &&
          // //     eventRecord?.ticket_type !== "eticket") ||
          // //   eventRecord?.ticket_type?.id !== "eticket"
          // // ) {
          // //   fieldCount = 8;
          // //   visibility += 5 / fieldCount;
          // // }

          // // QUANTITY - 2
          // if (eventRecord?.quantity_available) {
          //   visibility += 5 / fieldCount;
          // }

          // // SPLIT TYPE - 4
          // if (eventRecord?.split_type) {
          //   visibility += 5 / fieldCount;
          // }

          // // CATEGORY -5
          // if (eventRecord?.category) {
          //   visibility += 5 / fieldCount;
          // }
          // // SECTION - 6
          // if (eventRecord?.section) {
          //   visibility += 5 / fieldCount;
          // }

          // // ROW - 7
          // if (eventRecord?.row) {
          //   visibility += 5 / fieldCount;
          // }
          // // FIRST_SEAT - 8
          // if (eventRecord?.first_seat) {
          //   visibility += 5 / fieldCount;
          // }

          // // TICKET IN HAND - 9
          // if (eventRecord?.["ticketsInHands"]) {
          //   visibility += 5 / fieldCount;
          // }

          // visibility = Math.round(visibility);

          return {
            ...eventRecord,
            selected: false,
            visibility: visibility,

            // visibility:
            //   eventRecord?.ticket_type &&
            //   eventRecord?.quantity_available &&
            //   eventRecord?.split_type &&
            //   eventRecord?.quantity_display &&
            //   eventRecord?.category &&
            //   eventRecord?.section &&
            //   eventRecord?.row &&
            //   eventRecord?.first_seat &&
            //   eventRecord.benefits?.length > 0 &&
            //   eventRecord?.ticketsInHands &&
            //   (eventRecord?.ticket_type !== "eticket"
            //     ? true
            //     : eventRecord?.ticketFiles?.length > 0 ||
            //       eventRecord?.listing_ticket_files?.length > 0)
            //     ? 5
            //     : eventRecord?.ticket_type &&
            //       eventRecord?.quantity_available &&
            //       eventRecord?.split_type &&
            //       eventRecord?.quantity_display &&
            //       eventRecord?.category &&
            //       eventRecord?.section &&
            //       eventRecord?.row &&
            //       eventRecord?.first_seat &&
            //       eventRecord.benefits?.length > 0 &&
            //       eventRecord?.ticketsInHands
            //     ? 4
            //     : eventRecord?.ticket_type &&
            //       eventRecord?.quantity_available &&
            //       eventRecord?.split_type &&
            //       eventRecord?.quantity_display &&
            //       eventRecord?.category &&
            //       eventRecord?.section &&
            //       eventRecord?.row &&
            //       eventRecord?.first_seat &&
            //       eventRecord.benefits?.length > 0
            //     ? 3
            //     : eventRecord?.ticket_type &&
            //       eventRecord?.quantity_available &&
            //       eventRecord?.split_type &&
            //       eventRecord?.quantity_display &&
            //       eventRecord?.category &&
            //       eventRecord?.section
            //     ? 2
            //     : eventRecord?.ticket_type &&
            //       eventRecord?.quantity_available &&
            //       eventRecord?.split_type &&
            //       eventRecord?.quantity_display &&
            //       eventRecord?.category
            //     ? 1
            //     : 0,
          };
        }),
      };
    });

    //setList(events);
    //layout.setTempEvent(events)
    //CLOSE IMAGE UPLOAD POPUP
    setTimeout(() => {
      closeIconRef?.current?.click();
      closeHandle();
      isLoading(false);
    }, 1500);
  };

  const [disableBtn, setDisabledBtn] = useState(true);

  // btnDisabled

  useEffect(() => {
    const { eTicketDataField } = layout;
    function areAllFalse(arr: any) {
      if (arr) return arr?.every((element: any) => element === false);
    }

    if (list && Array.isArray(eTicketDataField?.btnEvent)) {
      let sumQuantityAvailable = "";
      let sumTicketFilesLength = "";

      const status = list.map((event: any) => {
        if (eTicketDataField.btnEvent.includes(event.id)) {
          const selectedArray = event.eventRecords?.filter(
            (obj: any) =>
              obj?.selected &&
              (obj?.ticket_type === "eticket" ||
                obj?.ticketType?.id === "eticket")
          );

          sumQuantityAvailable = selectedArray?.reduce((sum: any, obj: any) => {
            return sum + (obj?.quantity_available || 0);
          }, 0);
          sumTicketFilesLength = selectedArray?.reduce((sum: any, obj: any) => {
            return sum + (obj?.ticketFiles?.length || 0);
          }, 0);

          setDisabledBtn(
            sumQuantityAvailable === sumTicketFilesLength ? false : true
          );
          // return !event.eventRecords?.some(
          //   (obj: any) => obj.selected && obj.ticketFiles?.length > 0
          // );
        }
        // return false;
      });
      // if (status?.length > 0) {
      //   const final = areAllFalse(status);

      // }
    }
  }, [layout?.events, layout?.eTicketDataField]);

  const onDragStart = () => {
    // for (var i = 0; i < dragArea.length; i++) {

    if (dragArea) {
      // dragArea.style.borderColor = "#00D900 !important";
      dragArea?.classList.add("!border-green-500");
    }
    // }
  };

  useEffect(() => {
    if (size(mobileLinkResponse) > 0) {
      setList((current: any) => {
        return current?.map((event: any, eventIndex: number) => {
          let mobileEventIndex = mobileLinkResponse?.find(
            (item: any) => item?.eventIndex === eventIndex
          );

          if (eventIndex === mobileEventIndex?.eventIndex) {
            return {
              ...event,
              eventRecords: event?.eventRecords?.map(
                (record: any, recordIndex: number) => {
                  let eventRecordIndex = mobileLinkResponse?.find(
                    (item: any) => item?.eventRecordIndex === recordIndex
                  );

                  if (eventRecordIndex) {
                    return {
                      ...record,
                      ticketFiles: record?.ticketFiles?.map(
                        (item: any, itemIndex: number) => {
                          let ticketID = mobileLinkResponse?.find(
                            (ticketItem: any) =>
                              ticketItem?.ticketData?.id === item?.id
                          );
                          if (ticketID?.ticketData?.id) {
                            setMobileLinkResponse((current: any) => {
                              return current?.filter(
                                (mobileResponse: any) =>
                                  mobileResponse?.ticketData?.id !== item?.id
                              );
                            });
                            return {
                              ...item,
                              mobileLink: ticketID?.url,
                              isValid: true,
                            };
                          } else return item;
                        }
                      ),
                    };
                  } else return record;
                }
              ),
            };
          } else return event;
        });
      });
    }
  }, [mobileLinkResponse]);

  useEffect(() => {
    var popup = document.querySelector("#ppeRightCanvas");
    var popup_contain = popup?.classList.contains("vis");
    var popup_contain_hid = popup?.classList.remove("vis");
    var body = document.querySelector("body");
    if (popup_contain) {
      body?.classList.add("overflow-hidden");
    }
    return () => {
      body?.classList?.remove("overflow-hidden");
    };
  }, []);

  function getCategoryValue(record: any, mainEvent: any) {
    return record?.category?.id
      ? record?.category
      : mainEvent?.categories &&
          mainEvent?.categories.find(
            (category: any) => Number(category.id) === Number(record?.category)
          );
  }

  function getSectionValue(record: any, mainEvent: any) {
    return record?.section && record?.section?.id
      ? record?.section
      : record?.section &&
          mainEvent?.categories &&
          Array.isArray(mainEvent?.categories) &&
          mainEvent?.categories
            .find(
              (category: any) =>
                Number(category.id) === Number(record?.category)
            )
            ?.sections?.find(
              (item: any) => Number(item?.id) === Number(record?.section)
            );
  }

  // HANDLE PKPASS
  const handlePKPASS = (e: any, eventIndex: any, recordIndex: any) => {
    let uncheckedFileIds: any[] = [];
    setList((prev: any) => {
      return prev?.map((inEvent: any, eIndex: any) => {
        if (eIndex === eventIndex) {
          return {
            ...inEvent,
            eventRecords: inEvent?.eventRecords?.map(
              (record: any, rIndex: any) => {
                if (rIndex === recordIndex) {
                  if (!e?.target?.checked) {
                    const updatedTicketFiles = record?.ticketFiles?.map(
                      (obj: any) => obj.id
                    );
                    uncheckedFileIds.push(...updatedTicketFiles);
                  }
                  if (e?.target?.checked) {
                    if (record?.separate_links) {
                      const ticketFilesCopy = record?.ticketFiles;
                      const alreadyUploaded = record?.pkPassCopy?.filter(
                        (item: any) => item.alreadySave == 1
                      );
                      const fresh = !(alreadyUploaded?.length > 0);
                      return {
                        ...record,
                        pkPass: e?.target?.checked,
                        separate_links: false,
                        separate_links_copy: record?.separate_links,
                        saperateLinksCopy: ticketFilesCopy,
                        deleteExistingFiles: fresh,
                        ticketFiles:
                          record?.pkPassCopy ||
                          record?.ticketFiles?.map((obj: any) => ({})),
                      };
                    } else {
                      const ticketFilesCopy = record?.ticketFiles;
                      const alreadyUploaded = record?.pkPassCopy?.filter(
                        (item: any) => item.alreadySave == 1
                      );
                      const fresh = !(alreadyUploaded?.length > 0);
                      return {
                        ...record,
                        pkPass: e?.target?.checked,
                        separate_links: false,
                        separate_links_copy: record?.separate_links,
                        mobileLinksCopy: ticketFilesCopy,
                        deleteExistingFiles: fresh,
                        ticketFiles:
                          record?.pkPassCopy ||
                          record?.ticketFiles?.map((obj: any) => ({})),
                      };
                    }
                  } else {
                    if (record?.separate_links_copy === true) {
                      const ticketFilesCopy = record?.ticketFiles;
                      const alreadyUploaded = record?.saperateLinksCopy?.filter(
                        (item: any) => item.alreadySave == 1
                      );

                      const fresh = !(alreadyUploaded?.length > 0);
                      return {
                        ...record,
                        pkPass: e?.target?.checked,
                        separate_links: record?.separate_links_copy
                          ? record?.separate_links_copy
                          : false,
                        pkPassCopy: ticketFilesCopy,
                        deleteExistingFiles: fresh,
                        ticketFiles:
                          record?.saperateLinksCopy ||
                          record?.ticketFiles?.map((obj: any) => ({})),
                      };
                    } else {
                      const ticketFilesCopy = record?.ticketFiles;
                      const alreadyUploaded = record?.mobileLinksCopy?.filter(
                        (item: any) => item.alreadySave == 1
                      );

                      const fresh = !(alreadyUploaded?.length > 0);
                      return {
                        ...record,
                        pkPass: e?.target?.checked,
                        separate_links: record?.separate_links_copy
                          ? record?.separate_links_copy
                          : false,
                        pkPassCopy: ticketFilesCopy,
                        deleteExistingFiles: fresh,
                        ticketFiles:
                          record?.mobileLinksCopy ||
                          record?.ticketFiles?.map((obj: any) => ({})),
                      };
                    }
                  }
                } else {
                  return record;
                }
              }
            ),
          };
        } else {
          return inEvent;
        }
      });
    });
    if (!e?.target?.checked && uncheckedFileIds.length > 0) {
      setFiles((prevFiles: any) =>
        prevFiles?.map((file: any, index: any) => {
          return {
            ...file,
            assigned: uncheckedFileIds.includes(file?.id) ? 0 : file?.assigned,
          };
        })
      );
    }
  };

  const handleSeparateLinks = (e: any, eventIndex: any, recordIndex: any) => {
    setList((prev: any) => {
      return prev?.map((inEvent: any, eIndex: any) => {
        if (eIndex === eventIndex) {
          return {
            ...inEvent,
            eventRecords: inEvent?.eventRecords?.map(
              (record: any, rIndex: any) => {
                if (rIndex === recordIndex) {
                  const updatedTicketFiles =
                    record?.ticketFiles?.length > 0
                      ? record?.ticketFiles?.map(() => ({}))
                      : record?.ticketFiles;

                  if (e?.target?.checked) {
                    const ticketFilesCopy = record?.ticketFiles;
                    const alreadyUploaded = record?.saperateLinksCopy?.filter(
                      (item: any) => item.alreadySave == 1
                    );
                    const fresh = !(alreadyUploaded?.length > 0);
                    return {
                      ...record,
                      separate_links: e?.target?.checked,
                      mobileLinksCopy: ticketFilesCopy,
                      deleteExistingFiles: fresh,
                      ticketFiles:
                        record?.saperateLinksCopy ||
                        record?.ticketFiles?.map((obj: any) => ({})),
                    };
                  } else {
                    const ticketFilesCopy = record?.ticketFiles;
                    const alreadyUploaded = record?.mobileLinksCopy?.filter(
                      (item: any) => item.alreadySave == 1
                    );

                    const fresh = !(alreadyUploaded?.length > 0);
                    return {
                      ...record,
                      separate_links: e?.target?.checked,
                      saperateLinksCopy: ticketFilesCopy,
                      deleteExistingFiles: fresh,
                      ticketFiles:
                        record?.mobileLinksCopy ||
                        record?.ticketFiles?.map((obj: any) => ({})),
                    };
                  }
                } else {
                  return record;
                }
              }
            ),
          };
        } else {
          return inEvent;
        }
      });
    });
  };

  const handleMobileLinkRemove = (
    eventId: any,
    recordId: any,
    ticketFileIndex: any
  ) => {
    setList((current: any) => {
      return current?.map((events: any, eventIndex: number) => {
        if (eventIndex === eventId) {
          return {
            ...events,
            eventRecords: events?.eventRecords?.map(
              (record: any, recordIndex: number) => {
                if (recordIndex === recordId) {
                  return {
                    ...record,
                    ticketFiles: record?.ticketFiles?.map(
                      (ticket: any, ticketIndex: number) => {
                        if (ticketIndex === ticketFileIndex) {
                          deletePDFFromFiles(ticket?.id);
                          delete ticket?.isValid;
                          return {};
                        } else return ticket;
                      }
                    ),
                  };
                } else return record;
              }
            ),
          };
        } else return events;
      });
    });
  };
  // MOBILE LINK HANDLE
  const handleMobileLinkChange = (
    eventId: any,
    recordId: any,
    event: any,
    isConfirm: any,
    isDelete: any,
    ticketFileIndex: any,
    ticketType: any
  ) => {
    const mobileLinkId: any = `mobile-link-${recordId}-${ticketFileIndex}`;
    const mobileLinkElement: any = document.getElementById(
      mobileLinkId
    ) as HTMLInputElement | null;

    const mobileLinkId_separate: any = `mobile-link-${recordId}-${ticketFileIndex}-separate`;
    const mobileLinkElement_separate: any = document.getElementById(
      mobileLinkId_separate
    ) as HTMLInputElement | null;

    setList((current: any) => {
      return current?.map((events: any, eventIndex: number) => {
        if (eventIndex === eventId) {
          return {
            ...events,
            eventRecords: events?.eventRecords?.map(
              (record: any, recordIndex: number) => {
                if (recordIndex === recordId) {
                  let ticketFiles = record?.ticketFiles?.map(
                    (ticket: any, ticketIndex: number) => {
                      if (ticketIndex === ticketFileIndex) {
                        if (isDelete) {
                          if (record?.separate_links) {
                            delete ticket?.isValid;
                            if (ticketType === "android") {
                              delete ticket?.isValidAndroid;
                              if (mobileLinkElement) {
                                mobileLinkElement.value = "";
                              }
                              const final = {
                                ...ticket,
                                mobileLink: {
                                  ...ticket?.mobileLink,
                                  android: "",
                                },
                              };
                              return final;
                            } else if (ticketType === "ios") {
                              delete ticket?.isValidIos;
                              if (mobileLinkElement_separate) {
                                mobileLinkElement_separate.value = "";
                              }
                              const final = {
                                ...ticket,
                                mobileLink: {
                                  ...ticket?.mobileLink,
                                  ios: "",
                                },
                              };
                              return final;
                            }
                            deletePDFFromFiles(ticket?.id);
                            if (
                              !ticket?.mobileLink?.android &&
                              !ticket?.mobileLink?.ios
                            ) {
                              return {};
                            }
                            // return ticket;
                          } else {
                            delete ticket?.isValid;
                            deletePDFFromFiles(ticket?.id);
                            if (mobileLinkElement) {
                              mobileLinkElement.value = "";
                            }
                            return {};
                          }
                        } else if (isConfirm) {
                          if (record?.separate_links) {
                            return {
                              ...ticket,
                              isValidAndroid: isValidUrl(
                                ticket?.mobileLink?.android
                              ),
                              isValidIos: isValidUrl(ticket?.mobileLink?.ios),
                              isValid: checkingValidUrlForBoth(
                                ticket?.mobileLink
                              ),
                            };
                          }
                          return {
                            ...ticket,
                            isValid: isValidUrl(ticket?.mobileLink),
                          };
                        } else {
                          delete ticket?.isValid;
                          const mobileLinkData = {
                            android:
                              ticketType === "android"
                                ? event?.target?.value
                                : ticket?.mobileLink?.android || null,
                            ios:
                              ticketType === "ios"
                                ? event?.target?.value
                                : ticket?.mobileLink?.ios || null,
                          };

                          return {
                            ...ticket,
                            mobileLink: record?.separate_links
                              ? mobileLinkData
                              : event?.target?.value,
                          };
                        }
                      } else return ticket;
                    }
                  );
                  let final = {
                    ...record,

                    ticketFiles: ticketFiles,
                    isFull: ticketFiles?.every((item: any) => item?.isValid),
                  };
                  return final;
                } else return record;
              }
            ),
          };
        } else return events;
      });
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div
        className={` show  upload-ticket-popup fixed bottom-0 top-[3.75rem] right-0 md:w-[calc(100%-4rem)] w-full shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium z-50  ${
          popUpOpen ? "vis" : "hid"
        }`}
        // tabIndex={-1}
        id="ppeRightCanvas"
        // aria-labelledby="ppeRightCanvasLabel"
      >
        <div className="relative flex flex-col max-w-[93rem] md:w-[95%] h-full bg-white ml-auto">
          <div className="offcanvas-header flex items-center justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              {getUploadedListing()[2] ? (
                "Tickets uploaded: Editing restricted"
              ) : (
                <>
                  {/* E-ticket: Upload{" "}
                  {layout.eTicketDataField?.btnEvent?.length > 1 && "to"}{" "}
                  {getUploadedListing()[1]} listing
                  {layout.eTicketDataField?.btnEvent?.length > 1 &&
                    ` across to ${layout.eTicketDataField?.btnEvent?.length} events`} */}
                  Upload tickets
                </>
              )}
            </h5>
            <button
              ref={closeIconRef}
              type="button"
              className="flex items-center justify-center w-9 border-l box-content h-full group"
              // data-bs-dismiss="offcanvas"
              // aria-label="Close"
              onMouseDown={(data: any) => {
                if (!loading) {
                  handleEventCancel();
                  closeHandle();
                }
              }}
            >
              <IKTSVG
                className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path={Cross}
              />
            </button>
          </div>
          {/* PDF VIEWER */}
          <div className="offcanvas-body h-full w-full flex flex-col lg2:flex-row overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
            {pdfView ? (
              <div
                className={`lg2:border-r w-full lg2:h-full lg2:w-1/2 p-4 md:p-5 flex items-center !justify-start flex-col lg2:scrollbar-thin lg2:scrollbar-thumb-violet-800 lg2:scrollbar-thumb-rounded-full`}
              >
                <div className="border rounded w-full">
                  <div className="flex w-full border-b min-h-[2.5rem]">
                    <div className="flex items-center justify-between w-full px-4 py-1.5 ">
                      <div className="text-xxs md:text-xs">
                        {/* {filePreview.name} */}
                        Preview:{" "}
                        {filePreview.index
                          ? `ticket-${filePreview.index + 1}.pdf`
                          : filePreview.name}
                      </div>
                      {numPages > 1 && (
                        <div className="flex">
                          <button
                            className={`group rounded-l  hover: transition px-[.5625rem] py-2 ${
                              pageNumber === 1 && "pointer-events-none"
                            }`}
                            id="leftArrow"
                            type="button"
                            onClick={(data: any) =>
                              setPageNumber(
                                (count: any) => count !== 1 && count - 1
                              )
                            }
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={AngleLeft}
                              svgClassName="w-1.5 h-[.5625rem]"
                            />
                          </button>
                          <button
                            className={`group rounded-r  transition px-[.5625rem] py-2 ${
                              numPages === pageNumber && "pointer-events-none"
                            }`}
                            id="rightArrow"
                            type="button"
                            onClick={(data: any) =>
                              setPageNumber(
                                (count: any) => count !== numPages && count + 1
                              )
                            }
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={AngleRight}
                              svgClassName="w-1.5 h-[.5625rem]"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                    <button
                      className="flex items-center justify-center border-l w-11 group"
                      type="button"
                      onClick={(data: any) => {
                        setPdfView(false);
                        setPdfCurrentIndex(false);
                      }}
                    >
                      <IKTSVG
                        svgClassName="h-5 w-5"
                        className="flex items-center justify-center fill-violet-800 group-hover:fill-indigo-500 transition"
                        path={Clear}
                      />
                    </button>
                  </div>
                  <div className="uploaded-file-preview overflow-hidden">
                    <Document
                      file={filePreview.preview}
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading={
                        <IKTSVG
                          className="flex items-center justify-center mr-2 mt-5 fill-violet-500 transition animate-spin"
                          path={Process}
                          svgClassName="w-[1.0625rem] h-[1.0625rem]"
                        />
                      }
                      error={
                        filePreview?.path?.split(".").pop() === "pdf" ? (
                          PDFFailedLoad
                        ) : (
                          <img src={filePreview?.preview} />
                        )
                      }
                    >
                      <Page pageNumber={pageNumber} pageIndex={pageNumber} />
                    </Document>
                  </div>
                  <div className="ml-auto p-2 w-8 text-xs text-gray-400">
                    {pageNumber}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`lg2:border-r w-full lg2:w-1/2 p-4 md:p-5 flex  flex-col ${
                  files && files.length > 0 && "!justify-start"
                }`}
              >
                {getUploadedListing()[2] === false && (
                  <div
                    {...getRootProps({ className: "dropzone" })}
                    className={`flex flex-col items-center justify-center text-center ${
                      files && files.length > 0 ? "h-20" : "h-20"
                    } w-full min-h-[5rem] border border-dashed border-violet-500/10 rounded bg-violet-300/50 text-xs`}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-2.5">
                      Drag files here or click to upload
                    </div>
                    <button
                      type="button"
                      onClick={open}
                      className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 px-2.5 py-1"
                    >
                      <IKTSVG
                        className="fill-white mr-2"
                        path={Upload}
                        svgClassName="w-[.875rem] h-[.75rem]"
                      />
                      Upload
                    </button>
                  </div>
                )}

                {!(files && files.length > 0) &&
                  uniqueTicketTypes?.map((item: any) => {
                    return (
                      <UploadFileInstruction
                        fulfilIcon={getUploadedListing()[2] ? true : false}
                        data={{
                          heading: item?.heading,
                          listing: item?.listing,
                          type: item?.type,
                          margin: item?.margin,
                          extra: item?.extra,
                        }}
                      />
                    );
                  })}

                {files && files.length > 0 && (
                  <aside className="w-full mt-5">
                    <div className="flex flex-wrap gap-1 mb-5">
                      <h4 className="text-sm flex-auto">
                        {files.length} file uploaded{" "}
                        {/* Tickets uploaded,{" "} */}
                        {files.filter((file: any) => file.assigned).length}{" "}
                        assigned
                      </h4>
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs">Show assigned</span>
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            value=""
                            className="sr-only peer"
                            checked={checkedState}
                            onChange={() => setCheckedState(!checkedState)}
                          />
                          <div className="w-7 h-3 bg-gray-200 peer-checked:bg-green-600/40 rounded-full transition-all peer peer-checked:after:translate-x-full peer-checked:after:bg-green-600 after:content-[''] after:absolute after:-top-0.5 after:-left-0.5 after:bg-gray-400 after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100"></div>
                        </label>
                      </div>
                    </div>
                    {/* LEFT INNER DRAG  */}
                    <Droppable
                      droppableId="eventTicketUploadedFiles"
                      key="eventTicketUploadedFiles"
                    >
                      {(provided: any, snapshot: any) => (
                        <ul
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {files.map((file: any, index: number) => {
                            return (
                              (file?.assigned !== 1 || checkedState) && (
                                <Draggable
                                  key={index}
                                  draggableId={`${file.path}${index}`}
                                  index={index}
                                  isDragDisabled={file.assigned ? true : false}
                                >
                                  {(provided: any, snapshot: any) => {
                                    if (snapshot?.isDragging) {
                                      eventRef.current.classList.add(
                                        "max-lg2:mt-[30px]"
                                      );
                                    } else
                                      eventRef.current.classList.remove(
                                        "max-lg2:mt-[30px]"
                                      );

                                    return (
                                      // @ts-ignore
                                      <List checkedState={checkedState}>
                                        <span
                                          key={index}
                                          className={`${
                                            file?.id === hoverFile
                                              ? "flex bg-violet-50 border border-indigo-500 rounded gap-x-2 px-2 py-1 mb-2.5 transition"
                                              : file.assigned === 1
                                              ? "flex   bg-white border border-green-600 hover:border-green-600 rounded gap-x-2 px-2 py-1 mb-2.5 transition"
                                              : "flex bg-gray-100/50 hover:bg-violet-50 border hover:border-indigo-500 rounded gap-x-2 px-2 py-1 mb-2.5 transition"
                                          }`}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          isdragging={snapshot.isdragging}
                                        >
                                          <span
                                            className="text-xs flex-auto truncate"
                                            title={file?.path}
                                          >
                                            {" "}
                                            {file?.path}{" "}
                                          </span>
                                          <span className="flex s-center gap-x-2">
                                            {getExtension(file?.path) !==
                                              "pkpass" && (
                                              <>
                                                <button
                                                  type="button"
                                                  onClick={() =>
                                                    pdfViewer(file)
                                                  }
                                                  id={`View-${index}`}
                                                >
                                                  <IKTSVG
                                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                                    path={Eye}
                                                    svgClassName="w-[1.0625rem] h-[.74431rem]"
                                                  />
                                                </button>
                                                <Tooltip
                                                  anchorId={`View-${index}`}
                                                  content="View"
                                                  place="top"
                                                  variant="light"
                                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                />
                                              </>
                                            )}

                                            <button
                                              type="button"
                                              onClick={() =>
                                                deletePdf(file, index)
                                              }
                                              id={`delete-${index}`}
                                            >
                                              <IKTSVG
                                                className="fill-violet-500 hover:fill-indigo-500 transition"
                                                path={Delete}
                                                svgClassName="w-[.6875rem] h-[.843748rem]"
                                              />
                                            </button>
                                            <Tooltip
                                              anchorId={`delete-${index}`}
                                              content="Delete"
                                              place="top"
                                              variant="light"
                                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                            />
                                          </span>
                                        </span>
                                      </List>
                                    );
                                  }}
                                </Draggable>
                              )
                            );
                          })}
                        </ul>
                      )}
                    </Droppable>
                  </aside>
                )}
              </div>
            )}

            <div
              className="w-full lg2:w-1/2 px-4 py-2 lg2:py-4 md:overflow-y-auto md:scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
              ref={eventRef}
            >
              {/* Rirght side Header */}
              {layout.eTicketDataField?.isOpen &&
                Array.isArray(layout.eTicketDataField?.btnEvent) &&
                list.map((event: any, eventIndex: any) => {
                  if (layout.eTicketDataField?.btnEvent.includes(event?.id)) {
                    return (
                      <React.Fragment key={eventIndex}>
                        <div
                          className="flex accordion-header event__header bg-violet-700 rounded text-white mb-2.5 title-accordion"
                          id=""
                          data-te-collapse-init
                          data-te-target={`#eTicketEvent${eventIndex}`}
                          aria-expanded="true"
                          aria-controls={`eTicketEvent${eventIndex}`}
                        >
                          <div className="flex w-full">
                            <div className="flex flex-wrap md:flex-nowrap flex-auto max-md:max-w-[calc(100%-2.5rem)]">
                              <div
                                className="flex flex-auto items-center leading-4 md:w-auto md:max-w-[12.5rem] w-full py-2 md:py-3 px-4 text-sm md:border-r border-white/20 ellips-text"
                                title={event && event.name}
                              >
                                <p title={event.name}>{event && event.name}</p>
                              </div>
                              <div className="flex w-full md:w-auto md:py-3 px-4 max-md:flex-wrap max-md:gap-y-2 max-md:pb-2">
                                <div className="flex items-center pr-4 text-xs">
                                  <IKTSVG
                                    className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
                                    path={Calendar}
                                    svgClassName="w-[.7375rem] h-[.8125rem]"
                                  />
                                  {event &&
                                    moment(event.date).format(
                                      "ddd, DD MMM YYYY"
                                    )}
                                </div>
                                <div className="flex items-center pr-4 text-xs">
                                  <IKTSVG
                                    className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
                                    path={ClockOutline}
                                    svgClassName="w-[.8125rem] h-[.8125rem]"
                                  />
                                  {event && event.time}
                                </div>
                                <div className="flex items-center flex-auto text-xs max-md:w-full">
                                  <IKTSVG
                                    className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
                                    path={Geo}
                                    svgClassName="w-[.8438rem] h-[.8438rem]"
                                  />
                                  {event && event.address}
                                </div>
                              </div>
                            </div>
                            <div className="flex ml-auto">
                              <Tooltip
                                anchorId={`collapseIcon-${eventIndex}`}
                                content={`${collapse ? `Collapse` : "Expand"}`}
                                place="top"
                                variant="light"
                                className="!bg-white !opacity-100 !py-0.5 !px-1 !text-xxs font-medium  z-10 mt-3 "
                              />
                              <button
                                className="collapse-icon h-full group relative flex items-center p-3 px-4 text-base text-gray-800 text-left border-l border-white/20 !transition focus:outline-none [&[aria-expanded='false'] > .icon ]:rotate-180"
                                type="button"
                                id={`collapseIcon-${eventIndex}`}
                                data-te-collapse-init
                                data-te-target={`#eTicketEvent${eventIndex}`}
                                aria-expanded="true"
                                aria-controls={`eTicketEvent${eventIndex}`}
                                onClick={() =>
                                  setCollapse(collapse ? false : true)
                                }
                              >
                                <IKTSVG
                                  className="flex items-center justify-center fill-white transition group-[[data-te-collapse-collapsed]]:rotate-180"
                                  path={Collapse}
                                  svgClassName="w-[.625rem] h-[.6188rem]"
                                />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div
                          id={`eTicketEvent${eventIndex}`}
                          className="accordion-collapse collapse show"
                          data-te-collapse-item
                          data-te-collapse-show
                        >
                          {/* {JSON.stringify(event["eventRecords"])} */}
                          {/* RIGHT INNER DRAG */}
                          {event["eventRecords"] &&
                            event["eventRecords"]?.map(
                              (eventRecord: any, eventRecordIndex: number) => {
                                const tickets = [
                                  "eticket",
                                  "pdf",
                                  "mobile-qr",
                                  "mobile-link",
                                ];
                                const ticketType =
                                  typeof eventRecord?.ticket_type === "object"
                                    ? eventRecord?.ticket_type?.id
                                    : eventRecord?.ticket_type;

                                if (
                                  eventRecord?.selected === true &&
                                  tickets?.includes(ticketType)
                                ) {
                                  const isAllFilesUploaded =
                                    list?.[eventIndex]?.eventRecords?.[
                                      eventRecordIndex
                                    ]?.ticketFiles &&
                                    Object.keys(
                                      list?.[eventIndex]?.eventRecords?.[
                                        eventRecordIndex
                                      ]?.ticketFiles
                                    ).length &&
                                    Object.keys(
                                      list?.[eventIndex]?.eventRecords?.[
                                        eventRecordIndex
                                      ]?.ticketFiles?.filter((obj: any) => {
                                        if (obj?.mobileLink) {
                                          return obj?.isValid;
                                        } else {
                                          return JSON.stringify(obj) !== "{}";
                                        }
                                      })
                                    ).length >=
                                      (eventRecord?.quantity_available
                                        ? eventRecord?.quantity_available
                                        : 0) &&
                                    Object.keys(
                                      list?.[eventIndex]?.eventRecords?.[
                                        eventRecordIndex
                                      ]?.ticketFiles?.filter((obj: any) => {
                                        if (obj?.mobileLink) {
                                          return obj?.isValid;
                                        } else {
                                          return JSON.stringify(obj) !== "{}";
                                        }
                                      })
                                    ).length > 0
                                      ? true
                                      : false;

                                  let ticketFileIndex =
                                    eventRecord?.ticketFiles?.findIndex(
                                      (file: any) => !file?.path
                                    );

                                  const getIsDropDisabled = () => {
                                    if (
                                      eventRecord?.ticket_type === "mobile-link"
                                    ) {
                                      return eventRecord?.pkPass ? false : true;
                                    } else {
                                      return isAllFilesUploaded;
                                    }
                                  };

                                  // isDropDisabled={isAllFilesUploaded || ticketFile?.alreadySave === 1}

                                  // isDropDisabled={
                                  //   ticketFile?.path &&
                                  //   ticketFile?.mobileLink &&
                                  //   ticketFile.hasOwnProperty("isValid") &&
                                  //   ticketFile?.isValid ? true : false
                                  // }
                                  if (!eventRecord?.isInputChange) {
                                    return (
                                      <Droppable
                                        droppableId={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                        key={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                        isDropDisabled={getIsDropDisabled()}
                                      >
                                        {(provided: any, snapshot: any) => {
                                          return (
                                            <div
                                              ref={provided.innerRef}
                                              // id="drag_here"
                                              className="col-span-full"
                                            >
                                              <div
                                                className="drag_items flex items-center gap-2"
                                                key={ticketFileIndex}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <div
                                                  className={`md:grid max-md:flex max-md:flex-wrap grid-cols-[25%_1fr_11%_11%] md:grid-cols-[1fr_19%_1fr_19%_6%] border ${
                                                    snapshot.isDraggingOver
                                                      ? "border-green-500"
                                                      : "border-violet-500/10"
                                                  } rounded w-full text-xs
                                              ${
                                                isAllFilesUploaded
                                                  ? `!bg-green-600 !bg-opacity-[7%] !border-green-700`
                                                  : `bg-gray-100/50 border-solid`
                                              } ${
                                                    eventRecord?.fieldErrors?.[
                                                      "quantity_available"
                                                    ] === TicketError
                                                      ? "bg-rose-500 bg-opacity-[7%] border-rose-700"
                                                      : ""
                                                  }   `}
                                                  key={eventRecordIndex}
                                                >
                                                  {/* LISTING ID */}
                                                  <div className="p-3.5 col-span-full md:col-span-1 max-md:w-1/2 max-md:py-2 max-md:border-b max-md:border-r">
                                                    <div className="text-gray-400">
                                                      Listing ID
                                                    </div>
                                                    <div>
                                                      {crc32(eventRecord?.id)}
                                                    </div>
                                                  </div>
                                                  {/* QUANTITY */}
                                                  <div className="p-3.5 md:border-t-0 max-md:w-1/2 max-md:py-2 max-md:border-b">
                                                    <div className="text-gray-400">
                                                      Quantity
                                                    </div>
                                                    <div>
                                                      {eventRecord?.quantity_available
                                                        ? eventRecord?.quantity_available
                                                        : "0"}
                                                    </div>
                                                  </div>

                                                  {/* TICKET QUANTITY */}
                                                  <div className="p-3.5 col-span-full md:col-span-1 max-md:w-full max-md:py-2 max-md:border-b">
                                                    <div className="text-gray-400">
                                                      Ticket details
                                                    </div>
                                                    <div>
                                                      {(() => {
                                                        const categoryName =
                                                          getCategoryValue(
                                                            eventRecord,
                                                            event
                                                          )?.name;
                                                        const sectionName =
                                                          getSectionValue(
                                                            eventRecord,
                                                            event
                                                          )?.name;

                                                        return categoryName
                                                          ? `${categoryName}, ${
                                                              sectionName ||
                                                              "N/A"
                                                            }`
                                                          : "N/A";
                                                      })()}
                                                    </div>
                                                  </div>

                                                  {/* ROW */}
                                                  <div className="p-3.5  md:border-t-0 max-md:w-[calc(100%-2.5rem)] max-md:py-2 ">
                                                    <div className="text-gray-400">
                                                      Row (Seat)
                                                    </div>
                                                    <div>
                                                      {eventRecord?.row
                                                        ? eventRecord?.row
                                                        : "0"}{" "}
                                                      (
                                                      {eventRecord?.first_seat
                                                        ? eventRecord?.first_seat
                                                        : "0"}
                                                      )
                                                    </div>
                                                  </div>

                                                  {/* TICKET TYPE - ICON */}
                                                  <div
                                                    className={`flex items-center justify-center border-t md:border-t-0 border-l border-solid  p-3.5 ${
                                                      isAllFilesUploaded
                                                        ? `!border-green-700`
                                                        : `border-solid`
                                                    }${
                                                      eventRecord
                                                        ?.fieldErrors?.[
                                                        "quantity_available"
                                                      ] === TicketError &&
                                                      "border-rose-700"
                                                    } max-md:w-10 max-md:py-2 max-md:border-t-0`}
                                                  >
                                                    <Tooltip
                                                      anchorId={`upload${eventIndex}${eventRecordIndex}`}
                                                      html={`<div>
                                           <p class='text-center'>
                                           ${
                                             eventRecord?.ticket_type?.id ===
                                               "mobile-link" ||
                                             eventRecord?.ticket_type ===
                                               "mobile-link"
                                               ? "Mobile link/PKPASS"
                                               : (eventRecord?.ticket_type
                                                   ?.id ||
                                                   eventRecord?.sub_ticket_type) ===
                                                 "mobile-qr"
                                               ? "Mobile Screenshot"
                                               : "E-ticket"
                                           }
                                           </P>
                                           <p class='text-gray-500'>
                                           ${
                                             list?.[eventIndex]?.eventRecords?.[
                                               eventRecordIndex
                                             ]?.ticketFiles &&
                                             Object.keys(
                                               list?.[eventIndex]
                                                 ?.eventRecords?.[
                                                 eventRecordIndex
                                               ]?.ticketFiles
                                             )?.filter(
                                               (obj: any) =>
                                                 JSON.stringify(obj) !== "{}"
                                             ).length > 0
                                               ? list?.[
                                                   eventIndex
                                                 ]?.eventRecords?.[
                                                   eventRecordIndex
                                                 ]?.ticketFiles?.filter(
                                                   (obj: any) =>
                                                     JSON.stringify(obj) !==
                                                     "{}"
                                                 ).length
                                               : 0
                                           } of ${
                                                        eventRecord?.quantity_available
                                                          ? eventRecord?.quantity_available
                                                          : 0
                                                      } assigned 
                                           </p>
                                            </div>`}
                                                      place="top"
                                                      variant="light"
                                                      className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                                    />
                                                    {/*  */}
                                                    <button
                                                      className=""
                                                      type="button"
                                                      disabled={
                                                        isAllFilesUploaded ||
                                                        eventRecord?.quantity_available <=
                                                          0
                                                      }
                                                      id={`upload${eventIndex}${eventRecordIndex}`}
                                                    >
                                                      <IKTSVG
                                                        className={`${
                                                          isAllFilesUploaded
                                                            ? `!fill-green-600 hover:fill-green-500 transition`
                                                            : `fill-violet-500 hover:fill-indigo-500 transition`
                                                        } `}
                                                        path={
                                                          eventRecord
                                                            ?.ticket_type
                                                            ?.id ===
                                                            "mobile-link" ||
                                                          eventRecord?.ticket_type ===
                                                            "mobile-link"
                                                            ? mobileSVG
                                                            : (eventRecord
                                                                ?.ticket_type
                                                                ?.id ||
                                                                eventRecord?.sub_ticket_type) ===
                                                              "mobile-qr"
                                                            ? pin
                                                            : pin
                                                        }
                                                        svgClassName="w-[.6875rem] h-[.875rem]"
                                                      />
                                                    </button>
                                                  </div>
                                                  {/* END --------------------- */}

                                                  <>
                                                    {/* BODY */}
                                                    {(eventRecord?.ticketType
                                                      ?.id === "eticket" ||
                                                      eventRecord?.ticket_type
                                                        ?.id === "eticket" ||
                                                      eventRecord?.ticket_type
                                                        ?.id === "pdf" ||
                                                      eventRecord?.ticket_type
                                                        ?.id === "mobile-qr" ||
                                                      eventRecord?.ticket_type ===
                                                        "eticket") && (
                                                      <div
                                                        className={`px-3.5 py-2.5 flex flex-col gap-y-2.5 col-span-full w-full border-t border-solid ${
                                                          isAllFilesUploaded
                                                            ? `!border-green-700`
                                                            : `border-solid`
                                                        } ${
                                                          eventRecord
                                                            ?.fieldErrors?.[
                                                            "quantity_available"
                                                          ] === TicketError &&
                                                          "border-rose-700"
                                                        }
                                            
                                            `}
                                                      >
                                                        {list?.[
                                                          eventIndex
                                                        ]?.eventRecords?.[
                                                          eventRecordIndex
                                                        ]?.ticketFiles?.map(
                                                          (
                                                            ticketFile: any,
                                                            ticketFileIndex: any
                                                          ) => {
                                                            const currentIndex =
                                                              ticketFileIndex ===
                                                              pdfCurrentIndex;
                                                            let isPathExist =
                                                              ticketFile?.path;

                                                            return (
                                                              <div className="flex items-center">
                                                                <span className="mr-2">
                                                                  Ticket{" "}
                                                                  {ticketFileIndex +
                                                                    1}
                                                                </span>
                                                                <div className="single_drag flex flex-wrap col-span-full items-center flex-1 ">
                                                                  <div
                                                                    className={`${
                                                                      isAllFilesUploaded &&
                                                                      "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition"
                                                                    } ${
                                                                      isPathExist &&
                                                                      "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition"
                                                                    } border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition ${
                                                                      currentIndex
                                                                        ? "!border-indigo-500 !bg-violet-50"
                                                                        : ""
                                                                    }`}
                                                                  >
                                                                    <span
                                                                      className={`${
                                                                        isAllFilesUploaded &&
                                                                        "!text-black !inline-flex items-center justify-between w-full"
                                                                      }  ${
                                                                        isPathExist &&
                                                                        "!text-black !inline-flex items-center justify-between w-full"
                                                                      } 
                                                                      flex justify-between   text-sm12 font-medium text-gray-400 block `}
                                                                    >
                                                                      {isPathExist
                                                                        ? `ticket-${
                                                                            ticketFileIndex +
                                                                            1
                                                                          }.${getExtension(
                                                                            isPathExist
                                                                          )}`
                                                                        : " Drag file here"}

                                                                      {isPathExist && (
                                                                        <span className="flex items-center gap-x-2">
                                                                          <Tooltip
                                                                            anchorId={`${eventIndex}-${eventRecordIndex}`}
                                                                            content="View"
                                                                            place="top"
                                                                            variant="light"
                                                                            className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                                                          />
                                                                          <button
                                                                            onClick={() => {
                                                                              pdfViewer(
                                                                                ticketFile
                                                                              );

                                                                              setPdfCurrentIndex(
                                                                                ticketFileIndex
                                                                              );
                                                                            }}
                                                                            id={`${eventIndex}-${eventRecordIndex}`}
                                                                          >
                                                                            <KTSVG
                                                                              className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                              path="other_icons/Eye.svg"
                                                                              svgClassName="w-[1.0625rem] h-[.681813rem]"
                                                                            />
                                                                          </button>
                                                                          <Tooltip
                                                                            anchorId={`delete${eventIndex}${eventRecordIndex}`}
                                                                            content="Delete"
                                                                            place="top"
                                                                            variant="light"
                                                                            className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                                                          />
                                                                          {ticketFile?.alreadySave !==
                                                                            1 && (
                                                                            <>
                                                                              <button
                                                                                className=""
                                                                                onClick={() =>
                                                                                  deletePdfRightSide(
                                                                                    ticketFile,
                                                                                    ticketFileIndex,
                                                                                    eventRecordIndex,
                                                                                    eventIndex
                                                                                  )
                                                                                }
                                                                                id={`delete${eventIndex}${eventRecordIndex}`}
                                                                              >
                                                                                {/* <KTSVG
                                                                              className="fill-violet-500 hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                                                              path="standard_icons/cross.svg"
                                                                            /> */}
                                                                                <IKTSVG
                                                                                  className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                                  path={
                                                                                    remove
                                                                                  }
                                                                                  svgClassName="w-[.6875rem] h-[.8438rem]"
                                                                                />
                                                                              </button>
                                                                              <Tooltip
                                                                                anchorId={`delete${eventIndex}${eventRecordIndex}`}
                                                                                content="Delete"
                                                                                place="top"
                                                                                variant="light"
                                                                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                              />
                                                                            </>
                                                                          )}
                                                                        </span>
                                                                      )}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            );
                                                          }
                                                        )}
                                                      </div>
                                                    )}

                                                    {/* PKPASS - MOBILE LINK */}
                                                    {(eventRecord?.ticket_type
                                                      ?.id === "mobile-link" ||
                                                      eventRecord?.ticket_type ===
                                                        "mobile-link") && (
                                                      <>
                                                        {/* PKPASS TOGGLE */}
                                                        <div className="px-1.5 col-span-full w-full border-t border-solid flex  flex-wrap gap-2.5 py-2.5">
                                                          <div className="flex items-center px-2">
                                                            <span className="font-medium mr-4">
                                                              Separate links for
                                                              Android/iOS
                                                            </span>
                                                            <div>
                                                              <label
                                                                className={`${
                                                                  eventRecord
                                                                    ?.mobile_links
                                                                    ?.length ===
                                                                    eventRecord
                                                                      ?.ticketFiles
                                                                      ?.length &&
                                                                  "pointer-events-none"
                                                                }  relative inline-flex items-center cursor-pointer false [&amp;.disabled>div:after]:bg-gray-400 [&amp;.disabled]:pointer-events-none false ${
                                                                  eventRecord?.pkPass &&
                                                                  "pointer-events-none  "
                                                                }`}
                                                              >
                                                                <input
                                                                  type="checkbox"
                                                                  name={`separate_mobile_link-${eventIndex}-${eventRecordIndex}`}
                                                                  className={`sr-only peer   `}
                                                                  checked={
                                                                    eventRecord?.separate_links
                                                                  }
                                                                  onChange={(
                                                                    e: any
                                                                  ) =>
                                                                    handleSeparateLinks(
                                                                      e,
                                                                      eventIndex,
                                                                      eventRecordIndex
                                                                    )
                                                                  }
                                                                  disabled={
                                                                    eventRecord
                                                                      ?.mobile_links
                                                                      ?.length ===
                                                                    eventRecord
                                                                      ?.ticketFiles
                                                                      ?.length
                                                                  }
                                                                />
                                                                <div
                                                                  className={`bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8  ${
                                                                    eventRecord?.pkPass &&
                                                                    "pointer-events-none after:!bg-gray-400 "
                                                                  }`}
                                                                ></div>
                                                              </label>
                                                            </div>
                                                          </div>

                                                          <div className="flex items-center px-2  ">
                                                            <span className="font-medium mr-4">
                                                              PKPASS
                                                            </span>
                                                            <div>
                                                              <label
                                                                className={`relative inline-flex items-center cursor-pointer ${
                                                                  (getUploadedListing()[2] ||
                                                                    eventRecord
                                                                      ?.mobile_links
                                                                      ?.length ===
                                                                      eventRecord
                                                                        ?.ticketFiles
                                                                        ?.length) &&
                                                                  "pointer-events-none"
                                                                } false [&amp;.disabled>div:after]:bg-gray-400 [&amp;.disabled]:pointer-events-none false`}
                                                              >
                                                                <input
                                                                  type="checkbox"
                                                                  name={`pkPass-${eventIndex}-${eventRecordIndex}`}
                                                                  className="sr-only peer"
                                                                  checked={
                                                                    eventRecord?.pkPass
                                                                  }
                                                                  onChange={(
                                                                    e: any
                                                                  ) =>
                                                                    handlePKPASS(
                                                                      e,
                                                                      eventIndex,
                                                                      eventRecordIndex
                                                                    )
                                                                  }
                                                                  disabled={
                                                                    Boolean(
                                                                      getUploadedListing()[2]
                                                                    ) ||
                                                                    eventRecord
                                                                      ?.mobile_links
                                                                      ?.length ===
                                                                      eventRecord
                                                                        ?.ticketFiles
                                                                        ?.length
                                                                  }
                                                                />
                                                                <div className="bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8"></div>
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        {/* PKPASS - CONDITION (DRAG / TEXT) */}
                                                        {eventRecord?.pkPass ? (
                                                          <>
                                                            <div className="px-1.5 col-span-full w-full border-t border-solid flex flex-col flex-wrap gap-2.5 py-2.5">
                                                              {list?.[
                                                                eventIndex
                                                              ]?.eventRecords?.[
                                                                eventRecordIndex
                                                              ]?.ticketFiles?.map(
                                                                (
                                                                  ticketFile: any,
                                                                  ticketFileIndex: any
                                                                ) => {
                                                                  return (
                                                                    <div className="px-2 drag_items flex items-center gap-2">
                                                                      <span>
                                                                        {" "}
                                                                        Ticket{" "}
                                                                        {ticketFileIndex +
                                                                          1}
                                                                      </span>
                                                                      <div className="single_drag flex flex-wrap col-span-full items-center flex-1 ">
                                                                        <div
                                                                          className={`  ${
                                                                            ticketFile?.path &&
                                                                            !ticketFile?.mobileLink
                                                                              ? "min-h-[1.5rem] border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition border-green-500"
                                                                              : ticketFile?.path &&
                                                                                ticketFile?.mobileLink
                                                                              ? "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition  border rounded bg-white px-2 py-[3px] flex-1 "
                                                                              : "min-h-[1.5rem] border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition"
                                                                          } ${
                                                                            ticketFile
                                                                              ?.ticketFiles
                                                                              ?.path &&
                                                                            !ticketFile.hasOwnProperty(
                                                                              "isValid"
                                                                            ) &&
                                                                            "border-1 !border-dashed !border-green-500"
                                                                          }   `}
                                                                        >
                                                                          <span
                                                                            className={`text-sm12 font-medium block ${
                                                                              ticketFile?.path &&
                                                                              !ticketFile?.mobileLink
                                                                                ? ""
                                                                                : ticketFile?.path &&
                                                                                  ticketFile?.mobileLink
                                                                                ? "!text-black !inline-flex items-center justify-between w-full falsetext-sm12 font-medium"
                                                                                : "text-gray-400"
                                                                            }`}
                                                                          >
                                                                            {ticketFile?.path &&
                                                                            !ticketFile.hasOwnProperty(
                                                                              "isValid"
                                                                            ) ? (
                                                                              <div className="flex items-center flex-nowrap">
                                                                                <span className="flex-none mr-2.5">
                                                                                  Generating
                                                                                  URL
                                                                                </span>
                                                                                <span className="flex-1">
                                                                                  <div className="page-loader">
                                                                                    <div className="loader-stripe bg-gray-200 rounded w-full h-2">
                                                                                      <div
                                                                                        className="load-line h-full bg-green-500"
                                                                                        style={{
                                                                                          animation: ` loaderAnim 2s linear forwards`,
                                                                                        }}
                                                                                      ></div>
                                                                                    </div>
                                                                                  </div>
                                                                                </span>
                                                                              </div>
                                                                            ) : ticketFile?.path &&
                                                                              ticketFile?.mobileLink ? (
                                                                              <>
                                                                                {ticketFile?.mobileLink
                                                                                  ?.split(
                                                                                    "/"
                                                                                  )
                                                                                  .pop()}
                                                                                <span className="flex s-center gap-x-2 ">
                                                                                  <button
                                                                                    type="button"
                                                                                    id={`copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                    onClick={() => {
                                                                                      let copiedValues =
                                                                                        copy(
                                                                                          ticketFile?.mobileLink
                                                                                        );
                                                                                      setIsCopied(
                                                                                        {
                                                                                          id: `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`,
                                                                                          value:
                                                                                            copiedValues,
                                                                                        }
                                                                                      );
                                                                                      setTimeout(
                                                                                        () => {
                                                                                          setIsCopied(
                                                                                            {
                                                                                              id: "",
                                                                                              value:
                                                                                                "",
                                                                                            }
                                                                                          );
                                                                                        },
                                                                                        200
                                                                                      );
                                                                                    }}
                                                                                  >
                                                                                    <IKTSVG
                                                                                      className={`flex items-center justify-center transition ${
                                                                                        isCopied.value ===
                                                                                          ticketFile?.mobileLink &&
                                                                                        isCopied.id ===
                                                                                          `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`
                                                                                          ? "fill-green-600  hover:fill-green-600"
                                                                                          : "fill-violet-500 hover:fill-indigo-500"
                                                                                      }`}
                                                                                      path={
                                                                                        CloneSVG
                                                                                      }
                                                                                      svgClassName="w-4 h-[11px]"
                                                                                    />
                                                                                    <Tooltip
                                                                                      anchorId={`copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                      content="Copy"
                                                                                      place="top"
                                                                                      variant="light"
                                                                                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                                    />
                                                                                  </button>
                                                                                  {ticketFile?.alreadySave !==
                                                                                  1 ? (
                                                                                    <button
                                                                                      type="button"
                                                                                      className="h-[0.5625rem]"
                                                                                      id={`delete-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                      onClick={(
                                                                                        e: any
                                                                                      ) =>
                                                                                        handleMobileLinkRemove(
                                                                                          eventIndex,
                                                                                          eventRecordIndex,
                                                                                          ticketFileIndex
                                                                                        )
                                                                                      }
                                                                                    >
                                                                                      <IKTSVG
                                                                                        className="fill-violet-500 hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                                                                        path={
                                                                                          remove
                                                                                        }
                                                                                      />
                                                                                      <Tooltip
                                                                                        anchorId={`delete-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                        content="Unassign"
                                                                                        place="top"
                                                                                        variant="light"
                                                                                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                                      />
                                                                                    </button>
                                                                                  ) : null}
                                                                                </span>
                                                                              </>
                                                                            ) : (
                                                                              "Drop PKPASS file here"
                                                                            )}
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <MobileUrlLink
                                                            isOpen={{
                                                              event: list,
                                                            }}
                                                            eventIndex={
                                                              eventIndex
                                                            }
                                                            eventRecordIndex={
                                                              eventRecordIndex
                                                            }
                                                            handleMobileLinkChange={
                                                              handleMobileLinkChange
                                                            }
                                                            setIsCopied={
                                                              setIsCopied
                                                            }
                                                            copy={copy}
                                                            isCopied={isCopied}
                                                            eventRecord={
                                                              eventRecord
                                                            }
                                                          />
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }}
                                      </Droppable>
                                    );
                                  }
                                }
                              }
                            )}
                        </div>
                      </React.Fragment>
                    );
                  }
                })}
            </div>
          </div>

          <div className="inline-flex items-center border-t py-2.5 px-5 text-sm13 justify-end w-full">
            {loading ? (
              <>
                <KTSVG
                  className="flex items-center justify-center fill-violet-500 transition animate-spin"
                  path="other_icons/process.svg"
                />
                &nbsp;&nbsp;
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
                  // data-bs-dismiss="offcanvas"
                  // aria-label="Close"
                  onMouseDown={!loading && handleEventCancel}
                  onClick={() => !loading && closeHandle()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`${
                    // disableBtn &&
                    (getUploadedListing()[0] !== getUploadedListing()[1] ||
                      getUploadedListing()[2]) &&
                    "pointer-events-none !border-0 !text-gray-400 !bg-gray-100"
                  }  border border-green-500 bg-green-500 text-white hover:text-white   rounded hover:border hover:border-indigo-500 transition p-0.5 px-2.5 hover:bg-indigo-500 `}
                  onClick={handleEventConfirm}
                >
                  Confirm
                </button>
              </>
            )}
            {/* <span className="inline-block my-0.5">
              Upload to {getUploadedListing()[0]}/{getUploadedListing()[1]}{" "}
              listings
            </span> */}
          </div>
        </div>
      </div>
      <div className="offcanvas-backdrop fade show"></div>
      {loading && <Loader />}
    </DragDropContext>
  );
};

export default ETicketUpload;
