import { useContext, useState } from "react";
import { Tooltip } from "react-tooltip";
import refreshSVG from "../../../assets/media/icons/other_icons/refresh.svg";
import crossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import SearchSVG from "../../../assets/media/icons/standard_icons/search-violet.svg";
import DropDownField from "../../../components/formComponent/DropDownField";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { addCommaInNumber } from "../../../helpers/Functions";
import { ConnectorContext } from "../core/ConnectorProvider";

const ConnectorFilters = ({
  connectorLoading,
  // options,
  refetchClients,
  paginateData,
}: any) => {
  const layout = useContext(ConnectorContext);
  const [query, setQuery] = useState("");

  const options = layout.clientsOptionsData;

  // Function to get index of dragged Filter item
  const getFilterIndex = (id: any) => {
    let index = layout?.draggedItems?.findIndex((item: any) => item.id === id);
    return index + 1;
  };

  const getFilterIndexForCheckbox = (id: any) => {
    let index = layout?.filterItems?.findIndex((item: any) => item.id === id);
    return index;
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    layout.setConnectorFilter((currentVal: any) => {
      return {
        ...currentVal,
        status: undefined,
        query: undefined,
        page: 1,
      };
    });
    setQuery("");
  };

  return (
    <div>
      <div
        className="flex flex-wrap border-b gap-y-2.5 py-4 px-5 bg-white"
        id={`filters_block`}
      >
        <div className={`w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%] px-[.3125rem]`}>
          {/* SEARCH BAR */}
          <div
            className={`relative flex-1 w-full md:mb-0 mb-2 rounded ${
              connectorLoading && "shimmer-effect"
            } `}
          >
            <>
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <button type="button">
                  <IKTSVG
                    className="fill-violet-500"
                    svgClassName="h-3.5 w-3.5"
                    path={SearchSVG}
                  />
                </button>
              </div>
              <input
                type="text"
                name="query"
                id="simple-search"
                placeholder="Search client"
                className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                onKeyDown={(event: any) => {
                  if (event?.target?.value && event.key === "Enter") {
                    event.preventDefault();
                    layout.setConnectorFilter((pre: any) => {
                      return {
                        ...pre,
                        query: event?.target?.value,
                        page: 1,
                      };
                    });
                  }
                }}
                onChange={(e) => setQuery(e.target.value)}
                value={query}
              />
            </>
          </div>
        </div>
        {layout.filterItems?.[`${getFilterIndexForCheckbox(1)}`]?.checked && (
          <div
            className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
              1
            )}   `}
          >
            <div className={`${connectorLoading && "shimmer-effect"} rounded`}>
              <DropDownField
                name="status"
                // classNamePrefix={"form_multiDropDown"}
                placeholder="Status"
                options={options?.statuses}
                value={layout.connectorFilter?.status}
                handleOnChange={(data: any) =>
                  layout.setConnectorFilter((currentValue: any) => {
                    return {
                      ...currentValue,
                      [data?.target?.name]: data?.target?.value,
                      page: 1,
                    };
                  })
                }
                //   options={options?.venues?.map((item: any) => {
                //     return { id: item.venue_id, name: item.venue };
                //   })}
                isClearable={true}
              />
            </div>
          </div>
        )}
      </div>
      {/*third layer*/}
      <div
        className="flex flex-wrap customStyle relative bg-white"
        id="report_filter"
      >
        <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center">
          {connectorLoading ? (
            <div className="shimmer-effect inline-block ">
              <span className="opacity-0">
                {paginateData?.total > 0
                  ? `${paginateData?.total} results`
                  : "0 results"}
              </span>
            </div>
          ) : (
            <>
              {paginateData?.total > 0
                ? `${addCommaInNumber(paginateData?.total)} results`
                : "0 result"}
            </>
          )}

          {/* {paginateData?.total > 0
            ? `${paginateData?.total} results`
            : "0 result"} */}
        </div>
        <div
          className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full min-h-[3.375rem]
        "
        >
          {connectorLoading ? (
            <div className="flex gap-5">
              <div
                className={`flex !rounded overflow-hidden ${
                  connectorLoading && "shimmer-effect"
                }`}
                style={{ height: "1.625rem", width: "9.375rem" }}
              >
                &nbsp;
              </div>
            </div>
          ) : (
            <>
              {(layout.connectorFilter?.status ||
                layout.connectorFilter?.query) && (
                <button
                  type="button"
                  className={`group border text-center bg-white rounded min-w-[1.5rem] w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition   ${
                    connectorLoading && "shimmer-effect"
                  } 
              `}
                  onClick={handleRefresh}
                  id="reset"
                >
                  <Tooltip
                    anchorId={`reset`}
                    content={`Reset`}
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />
                  <IKTSVG
                    path={refreshSVG}
                    className="fill-violet-500 group-hover:fill-white transition"
                    svgClassName="ml-[.313rem] w-3 h-3"
                  />
                </button>
              )}

              {/* QUERY */}
              {layout.connectorFilter?.query && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                  {layout.connectorFilter?.query}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      layout.setConnectorFilter((currentVal: any) => {
                        return {
                          ...currentVal,
                          query: undefined,
                          page: 1,
                        };
                      });
                      setQuery("");
                    }}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}

              {/* STATUS */}
              {layout.connectorFilter?.status && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                  {layout.connectorFilter?.status?.name}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      layout.setConnectorFilter((currentVal: any) => {
                        return {
                          ...currentVal,
                          status: undefined,
                          page: 1,
                        };
                      });
                    }}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {/* <Pagination paginateData={paginateData} /> */}
      </div>
    </div>
  );
};

export default ConnectorFilters;
