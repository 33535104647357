import { CollapseIcon } from "assets/media/icons/other_icons/CollapseIcon";
import { RefreshIcon } from "assets/media/icons/other_icons/RefreshIcon";
import { LayoutContext } from "components/core/LayoutProvider";
import DatePickerField from "components/formComponent/DatePickerField";
import MultiDropDownField from "components/formComponent/MultiDropDownField";
import MultiSelect from "components/formComponent/MultiSelect";
import { ClearButton } from "components/ui/buttons/ClearButton";
import {
  addCommaInNumber,
  getDefaultPaginationFilters,
  groupTeamMemberCommon,
} from "helpers/Functions";
import { useAlgoliaSearch } from "hooks/useAlgoliaSearch";
import { size } from "lodash";
import { ConnectorContext } from "pages/API_connector/core/ConnectorProvider";
import { convertIntoFormatDate } from "pages/API_connector/core/functions";
import { useContext, useMemo, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";

const handleInputChange =
  (algoliaDebounce: any, setInputChange: any, setAlgoliaOptions: any) =>
  (data: any, e: any) => {
    if (["menu-close", "set-value", "input-blur"].includes(e.action)) {
      if (!data) {
        setInputChange((current: any) => {
          return current;
        });
      }
    } else {
      setInputChange(() => {
        if (algoliaDebounce !== data) {
          setAlgoliaOptions([]);
        }
        return data;
      });
    }
  };

const handleKeyDown = (setOrderFilters: any) => (e: any) => {
  if (e?.target?.value && e.key === "Enter") {
    e.preventDefault();
    setOrderFilters((currentValue: any) => {
      return {
        ...currentValue,
        keywords: e?.target?.value,
        page: 1,
      };
    });
  }
};

const onSearch =
  (setInputChange: any, setAlgoliaOptions: any) => (data: any) => {
    if (data?.target?.id !== "search") {
      setInputChange("");
      setAlgoliaOptions([]);
    }
  };

const handleCommonOnChange = (setOrderFilters: Function) => (event: any) => {
  setOrderFilters((currentValue: any) => {
    return {
      ...currentValue,
      [event?.target?.name]: event?.target?.value,
      page: 1,
    };
  });
};

export const OrderFilters = (props: any) => {
  const { isLoading: orderIsLoading, paginateData, ordersFiltersRef } = props;
  const [inputChange, setInputChange] = useState("");
  const [isToggle, setIsToggle] = useState(false);

  const teamMemberSelectedRef = useRef<any>();

  const { orderFilters, setOrderFilters } = useContext(ConnectorContext);
  const { allowedAccess } = useContext(LayoutContext);

  const { isLoading, algoliaDebounce, algoliaOptions, setAlgoliaOptions }: any =
    useAlgoliaSearch({ inputChange });

  const teamMemberOptions = useMemo(() => {
    if (size(allowedAccess?.teams) > 0) {
      const options = groupTeamMemberCommon(allowedAccess?.teams);
      return options;
    }

    return [];
  }, [allowedAccess?.teams]);

  const totalResults = useMemo(
    () =>
      paginateData?.total > 0
        ? `${addCommaInNumber(paginateData?.total)} results`
        : "0 result",
    [paginateData?.total]
  );

  const isEventAvailable = useMemo(
    () => orderFilters?.event_id && size(orderFilters?.event_id) > 0,
    [orderFilters?.event_id]
  );

  const isEventDateRangeAvailable = useMemo(
    () =>
      orderFilters?.eventDateRange &&
      orderFilters?.eventDateRange?.startDate !== undefined &&
      orderFilters?.eventDateRange?.endDate !== undefined,
    [orderFilters?.eventDateRange]
  );

  const iOrderDateRangeAvailable = useMemo(
    () =>
      orderFilters?.orderDateRange &&
      orderFilters?.orderDateRange?.startDate !== undefined &&
      orderFilters?.orderDateRange?.endDate !== undefined,
    [orderFilters?.orderDateRange]
  );

  const isTeamMemberAvailable = useMemo(
    () => orderFilters?.team_member && size(orderFilters?.team_member) > 0,
    [orderFilters?.team_member]
  );

  const isKeywordAvailable = useMemo(
    () => orderFilters?.keywords && size(orderFilters?.keywords) > 0,
    [orderFilters?.keywords]
  );

  return (
    <div
      className="flex flex-col border-b bg-white w-full"
      ref={ordersFiltersRef}
    >
      <div
        className="topFilters max-md:static md:sticky sticky-element top-[60px] z-[12] border border-red inv_topfilter max-md:pt-[2.375rem]"
        id="api-connector-listing-filters-acc"
      >
        <div className="accordion font-medium max-md:fixed max-md:top-[3.75rem] max-md:left-0 max-md:w-full max-md:z-30 bg-white">
          <div className="accordion-item !rounded-none bg-white border-b border-gray-200">
            <div
              className="flex  justify-between accordion-header mb-0"
              id="headingOne"
            >
              <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2"></div>
              <div>
                <button
                  className="collapse-icon group h-full group relative rounded-tr flex items-center py-4 px-[.9375rem] text-base text-gray-800 text-left bg-white border-l transition focus:outline-none group ml-auto [&[aria-expanded='false'] > .icon ]:rotate-180 "
                  type="button"
                  data-te-collapse-init
                  aria-expanded="true"
                  data-te-target={`#inventoryaccordion`}
                  aria-controls="inventoryaccordion"
                  onClick={() => setIsToggle(!isToggle)}
                >
                  <div className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180">
                    <CollapseIcon className="w-[.5625rem] h-[.5625rem]" />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* collapsable filters */}
        <div
          className="border-b "
          id="inventoryaccordion"
          data-te-collapse-item
          data-te-collapse-show
        >
          <div className="px-5 pt-3 pr-2.5 relative">
            <span className="text-sm15 font-semibold">Overview</span>
            <div
              className="divide-gray-200 divide-y-2 scroll_container"
              id={`scroll_container`}
            >
              <div
                id={`filter_scroll`}
                className="filter_scroll flex flex-nowrap pt-3 -mx-2.5 -ml-5 whitespace-nowrap overflow-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-violet-800 123"
              >
                <div
                  className={`w-full sm:w-1/2 lg:w-1/3 mb-5 px-2.5  ${
                    orderIsLoading && "shimmer-effect"
                  } `}
                >
                  <div
                    className="w-full  "
                    title={"Search event or order ID or marketplace ID"}
                  >
                    <MultiSelect
                      name="event_id"
                      options={algoliaOptions}
                      isLoading={isLoading}
                      handleOnChange={handleCommonOnChange(setOrderFilters)}
                      handleKeyDown={handleKeyDown(setOrderFilters)}
                      value={orderFilters?.event_id}
                      inputValue={inputChange}
                      handleInputChange={handleInputChange(
                        algoliaDebounce,
                        setInputChange,
                        setAlgoliaOptions
                      )}
                      onSearch={onSearch(setInputChange, setAlgoliaOptions)}
                      classNamePrefix="inventory_select"
                      className="searchbar-wrap z-[3]"
                      placeholder={"Search event or order ID or marketplace ID"}
                    />
                  </div>
                </div>
                <div className={`w-full sm:w-1/2 lg:w-1/3 mb-5 px-2.5`}>
                  <div
                    className={`${orderIsLoading && "shimmer-effect"} rounded`}
                  >
                    <DatePickerField
                      name="eventDateRange"
                      placeholder="Event date"
                      disabledKeyboardNavigation={false}
                      value={orderFilters?.eventDateRange ?? undefined}
                      isDateRange={true}
                      handleOnChange={handleCommonOnChange(setOrderFilters)}
                      format="dd/MM/yy"
                    />
                  </div>
                </div>
                <div className={`w-full sm:w-1/2 lg:w-1/3 mb-5 px-2.5`}>
                  <div
                    className={`${orderIsLoading && "shimmer-effect"} rounded`}
                  >
                    <DatePickerField
                      name="orderDateRange"
                      placeholder="Transaction date"
                      disabledKeyboardNavigation={false}
                      value={orderFilters?.orderDateRange ?? undefined}
                      isDateRange={true}
                      handleOnChange={handleCommonOnChange(setOrderFilters)}
                      format="dd/MM/yy"
                    />
                  </div>
                </div>
                <div className={`w-full sm:w-1/2 lg:w-1/3 mb-5 px-2.5`}>
                  <div
                    className={`${orderIsLoading && "shimmer-effect"} rounded`}
                  >
                    <MultiDropDownField
                      name="team_member"
                      classNamePrefix={"form_multiDropDown"}
                      placeholder="Team members"
                      value={orderFilters?.team_member}
                      handleOnChange={handleCommonOnChange(setOrderFilters)}
                      options={teamMemberOptions}
                      isMenuPortalTarget={orderIsLoading ? false : true}
                      selectedRef={teamMemberSelectedRef}
                      isMultiSelection={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* collapsable filters ---- ends */}

        {/* filter results  */}
        <div className="flex items-center justify-center sm:justify-start px-5 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start">
          <div
            className="flex flex-wrap customStyle relative bg-white max-sm:justify-between"
            id="report_filter"
          >
            <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)]">
              {orderIsLoading ? (
                <div className="shimmer-effect inline-block ">
                  <span className="opacity-0">{totalResults}</span>
                </div>
              ) : (
                totalResults
              )}
            </div>
          </div>
          {orderIsLoading ? (
            <div className="flex gap-5">
              <div
                className={`flex !rounded overflow-hidden ${
                  orderIsLoading && "shimmer-effect"
                }`}
                style={{ height: "1.625rem", width: "9.375rem" }}
              >
                &nbsp;
              </div>
            </div>
          ) : (
            <>
              {(orderFilters?.keywords ||
                isEventDateRangeAvailable ||
                isEventAvailable ||
                isTeamMemberAvailable ||
                isKeywordAvailable) && (
                <button
                  type="button"
                  className={`group border text-center bg-white rounded min-w-[1.5rem] w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition   ${
                    orderIsLoading && "shimmer-effect"
                  } 
              `}
                  onClick={() => {
                    setInputChange("");
                    setOrderFilters(getDefaultPaginationFilters());
                  }}
                  id="reset"
                >
                  <Tooltip
                    anchorId={`reset`}
                    content={`Reset`}
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />

                  <div className="fill-violet-500 group-hover:fill-white transition">
                    <RefreshIcon className="ml-[.313rem] w-3 h-3" />
                  </div>
                </button>
              )}

              {/* ALGOLIA SELECTED - VALUES */}
              {isEventAvailable &&
                orderFilters?.event_id.map((user: any, index: number) => {
                  return (
                    <ClearButton
                      key={index}
                      name={user?.name}
                      isLoading={orderIsLoading}
                      onClick={(e: any) => {
                        setOrderFilters((currentVal: any) => {
                          let event_id = currentVal?.event_id?.filter(
                            (user: any, indexNumber: number) =>
                              index !== indexNumber
                          );
                          return {
                            ...currentVal,
                            event_id: event_id,
                            page: 1,
                          };
                        });
                      }}
                    />
                  );
                })}

              {/* EVENT DATE RANGE */}
              {isEventDateRangeAvailable && (
                <ClearButton
                  name={`${convertIntoFormatDate(
                    orderFilters?.eventDateRange?.startDate
                  )} - 
                  ${convertIntoFormatDate(
                    orderFilters?.eventDateRange?.endDate
                  )}`}
                  isLoading={orderIsLoading}
                  onClick={(e: any) => {
                    setOrderFilters((current: any) => {
                      return {
                        ...current,
                        eventDateRange: "",
                      };
                    });
                  }}
                />
              )}

              {/* ORDER DATE RANGE */}
              {iOrderDateRangeAvailable && (
                <ClearButton
                  name={`${convertIntoFormatDate(
                    orderFilters?.orderDateRange?.startDate
                  )} - 
                  ${convertIntoFormatDate(
                    orderFilters?.orderDateRange?.endDate
                  )}`}
                  isLoading={orderIsLoading}
                  onClick={(e: any) => {
                    setOrderFilters((current: any) => {
                      return {
                        ...current,
                        orderDateRange: "",
                      };
                    });
                  }}
                />
              )}

              {/* TEAM MEMBER */}
              {isTeamMemberAvailable &&
                orderFilters?.team_member.map((user: any, index: number) => {
                  return (
                    <ClearButton
                      key={index}
                      name={user?.name}
                      isLoading={orderIsLoading}
                      onClick={(e: any) => {
                        setOrderFilters((currentVal: any) => {
                          let team_member = currentVal?.team_member?.filter(
                            (user: any, indexNumber: number) =>
                              index !== indexNumber
                          );
                          return {
                            ...currentVal,
                            team_member: team_member,
                            page: 1,
                          };
                        });
                      }}
                    />
                  );
                })}

              {/* keywords clear filter button */}
              {isKeywordAvailable && (
                <ClearButton
                  name={orderFilters?.keywords}
                  isLoading={orderIsLoading}
                  onClick={(e: any) => {
                    setOrderFilters((current: any) => {
                      return {
                        ...current,
                        keywords: "",
                      };
                    });
                  }}
                />
              )}
            </>
          )}
        </div>
        {/* filter results ---- ends */}
      </div>
    </div>
  );
};
