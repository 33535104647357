import { Fragment, useContext, useEffect, useMemo, useState } from "react";
// import SearchSVG from "../../../assets/media/icons/standard_icons/search-violet.svg";
import Checkbox from "components/formComponent/Checkbox";
import { ClearButton } from "components/ui/buttons/ClearButton";
import { showAlert } from "helpers/ShowAlert";
import { size } from "lodash";
import { Tooltip } from "react-tooltip";
import refreshSVG from "../../../assets/media/icons/other_icons/refresh.svg";
import crossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import SearchSVG from "../../../assets/media/icons/standard_icons/search-violet.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { ConnectorContext } from "../core/ConnectorProvider";
import { unMapEvents } from "../core/requests";

const MAP_KEYS = {
  map: "mappedEvents",
  unMap: "unMappedEvents",
};

const MAP_UNMAP_BUTTONS = [
  { key: MAP_KEYS.map, title: "Unmap" },
  { key: MAP_KEYS.unMap, title: "Map" },
];

const handleEventsUnmap =
  (key: any, refetch: any, layout: any, setCustomEventsLoading: any) =>
  async () => {
    if (key === MAP_KEYS.unMap) {
      layout?.setEventsMappingState(true);
      return;
    }
    let mappedEvents = layout?.selectedEvents.filter(
      (item: any) => item?.is_mapped
    );

    if (size(mappedEvents) === 0) return;

    let events = mappedEvents.map((item: any) => ({
      tixstock_event_id: item?.event_id,
      external_event_id: item?.id,
    }));

    setCustomEventsLoading(true);
    unMapEvents({
      id: layout?.connectorViewState?.data?.id,
      events,
    })
      ?.then((res) => {
        if (res?.data?.status) {
          refetch();
          showAlert("Events mapped successfully", false);
        }
        layout?.setEventsMappingState(false);
        layout?.setSelectedEvents([]);
        layout?.setMappedEvents([]);
      })
      .catch((err) => {
        showAlert("Something went wrong!", false);
      })
      .finally(() => {
        setCustomEventsLoading(false);
      });
  };

const EventsFilters = (props: any) => {
  const { customEventsLoading, paginateData, refetch, setCustomEventsLoading } =
    props;

  const layout = useContext(ConnectorContext);
  const [total, setTotal] = useState(paginateData?.total);
  const [query, setQuery] = useState("");
  const [category, setCategory] = useState("");
  const [venue, setVenue] = useState("");
  const [performer, setPerformer] = useState("");

  const selectedEvents: any = useMemo(
    () => ({
      [MAP_KEYS.map]:
        layout?.selectedEvents.filter((item) => item?.is_mapped).length ?? 0,
      [MAP_KEYS.unMap]:
        layout?.selectedEvents.filter((item) => !item?.is_mapped).length ?? 0,
    }),
    [layout?.selectedEvents]
  );

  useEffect(() => {
    if (paginateData) {
      setTotal(paginateData?.total);
    }
  }, [paginateData]);

  const handleRefresh = () => {
    layout.setEventsFilters((currentVal: any) => {
      return {
        ...currentVal,
        status: undefined,
        query: undefined,
        category: undefined,
        venue: undefined,
        performer: undefined,
        isMapped: false,
        page: 1,
      };
    });
    setQuery("");
    setCategory("");
    setVenue("");
    setPerformer("");
  };

  return (
    <div id="api-connector-events-filters">
      <div
        className="flex flex-wrap border-b gap-y-2.5 py-4 px-5 bg-white w-full"
        id={`filters_block`}
      >
        <div className={`!w-full flex gap-4`}>
          {/* SEARCH BAR */}
          <div
            className={`relative flex-1 w-full md:mb-0 mb-2 rounded ${
              customEventsLoading && "shimmer-effect"
            } `}
          >
            <>
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <button type="button">
                  <IKTSVG
                    className="fill-violet-500"
                    svgClassName="h-3.5 w-3.5"
                    path={SearchSVG}
                  />
                </button>
              </div>
              <input
                type="text"
                name="query"
                id="simple-search"
                placeholder="Search event"
                className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                onKeyDown={(event: any) => {
                  if (event?.target?.value && event.key === "Enter") {
                    event.preventDefault();
                    layout.setEventsFilters((current: any) => {
                      return {
                        ...current,
                        query: event.target.value,
                        page: 1,
                      };
                    });
                  }
                }}
                onChange={(e) => setQuery(e.target.value)}
                value={query}
              />
            </>
          </div>

          <div
            className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem]  ${
              customEventsLoading && "shimmer-effect"
            } `}
          >
            {/* is event mapped */}

            <Checkbox
              id="mapped_id"
              label="Is Mapped"
              name="isMapped"
              labelclass="text-gray-500"
              checked={layout.eventsFilters?.isMapped ?? false}
              tooltip={false}
              handleOnChange={(data: any) => {
                layout.setEventsFilters((currentValue: any) => {
                  return {
                    ...currentValue,
                    [data?.target?.name]: data?.target?.checked,
                    page: 1,
                  };
                });
              }}
            />
          </div>

          {/* CATEGORY */}
          <div
            className={`relative flex-1 w-full md:mb-0 mb-2 rounded ${
              customEventsLoading && "shimmer-effect"
            } `}
          >
            <>
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <button type="button">
                  <IKTSVG
                    className="fill-violet-500"
                    svgClassName="h-3.5 w-3.5"
                    path={SearchSVG}
                  />
                </button>
              </div>
              <input
                type="text"
                name="query"
                id="simple-search"
                placeholder="Search category"
                className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                onKeyDown={(event: any) => {
                  if (event?.target?.value && event.key === "Enter") {
                    event.preventDefault();
                    layout.setEventsFilters((current: any) => {
                      return {
                        ...current,
                        category: event.target.value,
                        page: 1,
                      };
                    });
                  }
                }}
                onChange={(e) => setCategory(e.target.value)}
                value={category}
              />
            </>
          </div>

          {/* VENUE */}
          <div
            className={`relative flex-1 w-full md:mb-0 mb-2 rounded ${
              customEventsLoading && "shimmer-effect"
            } `}
          >
            <>
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <button type="button">
                  <IKTSVG
                    className="fill-violet-500"
                    svgClassName="h-3.5 w-3.5"
                    path={SearchSVG}
                  />
                </button>
              </div>
              <input
                type="text"
                name="query"
                id="simple-search"
                placeholder="Search venue"
                className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                onKeyDown={(event: any) => {
                  if (event?.target?.value && event.key === "Enter") {
                    event.preventDefault();
                    layout.setEventsFilters((current: any) => {
                      return {
                        ...current,
                        venue: event.target.value,
                        page: 1,
                      };
                    });
                  }
                }}
                onChange={(e) => setVenue(e.target.value)}
                value={venue}
              />
            </>
          </div>

          {/* Performer */}
          <div
            className={`relative flex-1 w-full md:mb-0 mb-2 rounded ${
              customEventsLoading && "shimmer-effect"
            } `}
          >
            <>
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <button type="button">
                  <IKTSVG
                    className="fill-violet-500"
                    svgClassName="h-3.5 w-3.5"
                    path={SearchSVG}
                  />
                </button>
              </div>
              <input
                type="text"
                name="query"
                id="simple-search"
                placeholder="Search performer"
                className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                onKeyDown={(event: any) => {
                  if (event?.target?.value && event.key === "Enter") {
                    event.preventDefault();
                    layout.setEventsFilters((current: any) => {
                      return {
                        ...current,
                        performer: event.target.value,
                        page: 1,
                      };
                    });
                  }
                }}
                onChange={(e) => setPerformer(e.target.value)}
                value={performer}
              />
            </>
          </div>
        </div>
      </div>
      <div
        className="flex flex-wrap customStyle relative bg-white"
        id="report_filter"
      >
        {total === 0 && layout?.clientsEvents?.length > 0 ? null : (
          <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center">
            {customEventsLoading ? (
              <div className="shimmer-effect inline-block ">
                <span className="opacity-0">
                  {paginateData?.total > 0
                    ? `${paginateData?.total} results`
                    : "0 results"}
                </span>
              </div>
            ) : (
              <>{total > 0 ? `${total} results` : "0 result"}</>
            )}

            {/* {paginateData?.total > 0
            ? `${paginateData?.total} results`
            : "0 result"} */}
          </div>
        )}

        <div
          className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full min-h-[3.375rem]
        "
        >
          {customEventsLoading ? (
            <div className="flex gap-5">
              <div
                className={`flex !rounded overflow-hidden ${
                  customEventsLoading && "shimmer-effect"
                }`}
                style={{ height: "1.625rem", width: "9.375rem" }}
              >
                &nbsp;
              </div>
            </div>
          ) : (
            <>
              {(layout?.eventsFilters?.query ||
                layout?.eventsFilters?.category ||
                layout?.eventsFilters?.venue ||
                layout?.eventsFilters?.performer ||
                layout.eventsFilters?.isMapped) && (
                <button
                  type="button"
                  className={`group border text-center bg-white rounded min-w-[1.5rem] w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition   ${
                    customEventsLoading && "shimmer-effect"
                  } 
              `}
                  onClick={handleRefresh}
                  id="reset"
                >
                  <Tooltip
                    anchorId={`reset`}
                    content={`Reset`}
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />
                  <IKTSVG
                    path={refreshSVG}
                    className="fill-violet-500 group-hover:fill-white transition"
                    svgClassName="ml-[.313rem] w-3 h-3"
                  />
                </button>
              )}

              {layout?.selectedEvents?.length > 0 && (
                <Fragment>
                  {MAP_UNMAP_BUTTONS.map(({ key, title }) => (
                    <button
                      key={key}
                      type="button"
                      className={`${
                        selectedEvents[key] === 0
                          ? "pointer-events-none bg-gray-100 text-gray-400"
                          : "text-white hover:text-white bg-violet-500 hover:bg-indigo-500"
                      } group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
                      onClick={handleEventsUnmap(
                        key,
                        refetch,
                        layout,
                        setCustomEventsLoading
                      )}
                      disabled={selectedEvents[key] === 0}
                    >
                      {selectedEvents[key]} {title} events
                    </button>
                  ))}

                  <p className="text-xs ">
                    {layout?.selectedEvents?.length} events selected
                  </p>
                </Fragment>
              )}

              {/* QUERY */}
              {layout?.eventsFilters?.query && (
                <div className="flex py-1 text-xs px-2.5 border rounded-full my-0.5">
                  {layout?.eventsFilters?.query}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      layout.setEventsFilters((currentVal: any) => {
                        return {
                          ...currentVal,
                          query: undefined,
                          page: 1,
                        };
                      });
                      setQuery("");
                    }}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}

              {/* EVENT DATE RANGE */}
              {layout.eventsFilters?.isMapped && (
                <ClearButton
                  name="Is Mapped"
                  isLoading={customEventsLoading}
                  onClick={() => {
                    layout.setEventsFilters((current: any) => ({
                      ...current,
                      isMapped: false,
                    }));
                  }}
                />
              )}

              {/* Category */}
              {layout?.eventsFilters?.category && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                  {layout?.eventsFilters?.category}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      layout.setEventsFilters((currentVal: any) => {
                        return {
                          ...currentVal,
                          category: undefined,
                          page: 1,
                        };
                      });
                      setCategory("");
                    }}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}
              {/* Venue */}
              {layout?.eventsFilters?.venue && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                  {layout?.eventsFilters?.venue}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      layout.setEventsFilters((currentVal: any) => {
                        return {
                          ...currentVal,
                          venue: undefined,
                          page: 1,
                        };
                      });
                      setVenue("");
                    }}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}

              {/* Performer */}
              {layout?.eventsFilters?.performer && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                  {layout?.eventsFilters?.performer}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      layout.setEventsFilters((currentVal: any) => {
                        return {
                          ...currentVal,
                          performer: undefined,
                          page: 1,
                        };
                      });
                      setPerformer("");
                    }}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {/* <Pagination paginateData={paginateData} /> */}
      </div>
    </div>
  );
};

export default EventsFilters;
