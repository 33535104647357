/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragSVG from "../../../assets/media/icons/other_icons/dragg.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import { INVENTORY_FILTER_ITEMS } from "../../../helpers/const";
import { InventoryContext } from "../core/InventoryProvider";

const DraggableFilters = ({ refetch }: any) => {
  const layout = useContext(InventoryContext);
  const globalLayout = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
  const access = globalLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );
  // a little function to help us with reordering the result
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items: any = reorder(
        layout.filterItems,
        source.index,
        destination.index
      );
      layout.setFilterItems([...items]);
      let index = items.findIndex((item: any) => item.id === 1);
      layout.setDraggedItems(items);
      setTimeout(() => {
        refetch();
      }, 100);
    }
  };

  //Function For Filter Checkbox Handle
  const handleChange = (e: any) => {
    layout.setFilterItems((prev: any) => {
      let updatedArray =
        Array.isArray(prev) &&
        prev.map((item: any) => {
          if (item.name === e.target.name) {
            return {
              ...item,
              checked: e.target.checked,
            };
          } else {
            return item;
          }
        });
      return updatedArray;
    });
    setTimeout(() => {
      refetch();
    }, 100);
  };

  //Function For Handling Search
  const handleFilterSearch = (e: any) => {
    let searchResult = layout.filterItems.filter((element) =>
      element.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    layout.setFilterItems((prev: any) => {
      let updatedArray =
        Array.isArray(prev) &&
        prev.map((item: any) => {
          if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return {
              ...item,
              searched: true,
            };
          } else {
            return {
              ...item,
              searched: false,
            };
          }
        });
      return updatedArray;
    });
  };

  // Save Draggable Filter Data to Local Storage
  const saveInventoryFilter = () => {
    localStorage.setItem(
      "saveInventoryFilter",
      JSON.stringify(layout.filterItems)
    );
  };

  useEffect(() => {
    // saveInventoryFilter();
  }, [layout.filterItems]);

  let filterItemsLength: any;
  filterItemsLength = layout.filterItems.length * 30;

  return (
    <>
      <div className="ba_search p-2.5 border-b">
        <input
          type="text"
          placeholder="Search filters"
          id="search"
          className="rounded bg-violet-100/5 border-violet-100/20 w-full h-[1.875rem] text-sm12 px-2.5 py-2"
          onChange={handleFilterSearch}
        />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any, snapshot: any) => (
            <div
              className="cb-block border-b px-2.5 py-2"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <ul className={`min-h-[${filterItemsLength}px]`}>
                {Array.isArray(layout.filterItems) &&
                  layout.filterItems?.map((item: any, index: any) => (
                    <li
                      className="font-medium hover:text-indigo-500"
                      key={`li-${item.id}`}
                    >
                      {item.searched && (
                        <Draggable
                          draggableId={item.name}
                          key={`drag-${item.id}`}
                          index={index}
                        >
                          {(provided: any, snapshot: any) => (
                            <div
                              className={`singe_cb flex justify-between items-center ${
                                index === layout.filterItems.length - 1
                                  ? ""
                                  : "mb-3"
                              }`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {/* <div className="inline-flex items-center text-violet-800transition font-medium rounded">
                          <input type="checkbox" className="w-3 h-3 cursor-pointer text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            checked={item.checked}
                            id={item.id}
                            name={item.name}
                            onChange={handleChange}
                          />
                          <label htmlFor={item.id} className="ml-2 text-sm12 font-medium dark:text-gray-300 cursor-pointer" > {item.name}
                          </label>
                        </div>
                        <div className="drag_icon" {...provided.dragHandleProps}>
                          <KTSVG className="min-w-[0.8125rem] max-w-[0.8125rem] flex items-center justify-center fill-gray-400 group-hover:fill-white  transition" path="other_icons/dragg.svg" />
                        </div> */}
                              <div className="inline-flex items-center text-violet-800transition font-medium rounded">
                                <div
                                  className="drag_icon"
                                  {...provided.dragHandleProps}
                                >
                                  <IKTSVG
                                    className="min-w-[0.8125rem] max-w-[0.8125rem] flex items-center justify-center fill-gray-400 group-hover:fill-white  transition"
                                    path={DragSVG}
                                  />
                                </div>
                                <label
                                  htmlFor={item.id}
                                  className="ml-2 text-sm12 font-medium dark:text-gray-300 cursor-pointer"
                                >
                                  {" "}
                                  {item.name}
                                </label>
                              </div>
                              <input
                                type="checkbox"
                                className="w-3 h-3 cursor-pointer text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                checked={item.checked}
                                id={item.id}
                                name={item.name}
                                onChange={handleChange}
                              />
                            </div>
                          )}
                        </Draggable>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </Droppable>
        <div className="restore-btn px-2.5 py-2">
          <button
            type="button"
            className="group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
            onClick={() => {
              // layout.setFilterItems(INVENTORY_FILTER_ITEMS);
              layout.setFilterItems(
                isGodAdmin
                  ? INVENTORY_FILTER_ITEMS
                  : userSpecific
                  ? INVENTORY_FILTER_ITEMS?.filter(
                      (obj: any) =>
                        obj.name !== "All teams" && obj.name !== "Team members"
                    )
                  : INVENTORY_FILTER_ITEMS?.filter(
                      (obj: any) => obj.name !== "All teams"
                    )
              );
              layout.setDraggedItems([]);
              setTimeout(() => {
                refetch();
              }, 100);
            }}
          >
            <KTSVG
              className="min-w-4 max-w-4 mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
              path="other_icons/refresh.svg"
            />
            Restore defaults
          </button>
        </div>
      </DragDropContext>
    </>
  );
};

export default DraggableFilters;
