import { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { storeFields } from "../../../components/core/requests";
import { outSideClick } from "../../../helpers/AssetHelpers";
import { existInLocalstorage } from "../../../helpers/Functions";
import { QUERIES } from "../../../helpers/const";
import { AddInventoryContext } from "../core/AddInventoryProvider";
import KeyboardFilterDrag from "../widgets/KeyboardFilterDrag";

const HeaderfilterContainer = () => {
  const layout = useContext(AddInventoryContext);
  const globalLayout = useContext(LayoutContext);

  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveKeyboardItems;

  let payload: any = [
    {
      key: `saveKeyboardItems`,
      value: layout.keyboardItems,
    },
  ];

  //ADDING & REMOVING LISTENERS FOR OUTSIDE CLICK FOR DROPDOWNS
  useEffect(() => {
    document.addEventListener("mousedown", (e: any) =>
      outSideClick(e, "saveKeyboardItems")
    );
    return () => {
      document.removeEventListener("mousedown", (e: any) =>
        outSideClick(e, "saveKeyboardItems")
      );
    };
  }, []);

  //   STORE FILTER ARRAY
  const { refetch } = useQuery(
    [`${QUERIES.STORE_FIELD}`],
    () => storeFields(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onError: (err) => {},
      onSettled(data, error) {
        // console.log(data);
      },
    }
  );
  useEffect(() => {
    if (layout.keyboardItems && existInLocalstorage("saveKeyboardItems")) {
      layout.setKeyboardItems(existInLocalstorage("saveKeyboardItems"));
      setTimeout(() => {
        refetch();
        localStorage.removeItem("saveKeyboardItems");
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (isFilterExistInDB) {
      layout.setKeyboardItems(isFilterExistInDB);
      layout.setDraggedItems(isFilterExistInDB);
    }
  }, [isFilterExistInDB]);

  return (
    <div className="ba_item relative">
      <span
        className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer hover:text-indigo-500"
        data-te-collapse-init
        data-te-collapse-collapsed
        data-te-target="#saveKeyboardItems"
        aria-expanded="false"
        aria-controls="saveKeyboardItems"
      >
        Keyboard
      </span>
      <div
        id="saveKeyboardItems"
        className="!visible ba_dd absolute top-full bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden !z-60"
        data-te-collapse-item
        aria-labelledby="saveKeyboardItems"
        data-te-parent="#filters"
      >
        <KeyboardFilterDrag refetch={refetch} />
      </div>
    </div>
  );
};

export default HeaderfilterContainer;
