export const display = (isOpen: boolean) => {
    var body = document.querySelector("body");
    var html = document.querySelector("html");
    var pop_width =
      document.getElementsByClassName("pay-detail")[0]?.clientWidth;
    if (isOpen) {
      // @ts-ignore
      document.getElementById("TXAccordion").style.marginRight = `${
        pop_width - 20
      }px`;
      // document.body.style.overflow = 'hidden';
      body?.classList.add("scrollbar-none");
      html?.classList.add("scrollbar-none");
    } else if (!isOpen) {
      // @ts-ignore
      document.getElementById("TXAccordion").style.marginRight = "0px";
      // document.body.style.overflow = 'unset';
      body?.classList.add("scrollbar-none");
      html?.classList.add("scrollbar-none");
    }
  };