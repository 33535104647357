/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _, { isArray } from "lodash";
import moment from "moment";
import React, { useContext, useMemo, useRef, useState } from "react";
import ReactS3Client from "react-aws-s3-typescript";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import TablePopup from "../../../../components/tableComponent/TablePopup";
import { KTSVG } from "../../../../components/ui/KTSVG";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import {
  priceCalculationWithNumber,
  removeCommaFromNumber,
} from "../../../../helpers/Functions";
import { S3_CONFIG } from "../../../../helpers/const";
import { AddInventoryContext } from "../../core/AddInventoryProvider";
import { getPercentage, visiblityCountForPayload } from "../../core/_functions";
import { UpdateInventory, inventoryBlukAdd } from "../../core/_requsets";
import VirtuosoEventField from "./VirtuosoEventField";

const PublishHeader = React.memo(
  ({
    event,
    eIndex,
    handleTableField,
    eventError,
    list,
    setList,
    handlePPETableChange,
    handleSelectAll,
    setIsOpenListingQualityPopup,
  }: any) => {
    const layout = useContext(AddInventoryContext);
    const globalLayout = useContext(LayoutContext);
    const priceFactor = Number(
      globalLayout?.allowedAccess?.price_suggestion_factor
    );
    const btnRef: any = useRef(null);
    const [removeMarketAlertConfirm, setRemoveMarketAlertConfirm] =
      useState(false);
    const [removeMarketAlert, setRemoveMarketAlert] = useState(false);
    const [removeMarketAlertMsg, setRemoveMarketAlertMsg] = useState("");
    const s3Upload = new ReactS3Client(S3_CONFIG);

    const getEventRecordPecentage = useMemo(() => {
      let totalListing: Number = event.eventRecords.length;
      let completedListing: Number = event.eventRecords.filter(
        (eventRecord: any) => eventRecord.status === "published"
      ).length;
      let partPublishedListing = event.eventRecords.filter(
        (eventRecord: any) => eventRecord.status === "partPublished"
      ).length;

      return parseInt(
        //@ts-ignore
        getPercentage(completedListing + partPublishedListing / 2, totalListing)
      );
    }, [event.eventRecords]);

    const getPublishedListingCount = useMemo(() => {
      return event.eventRecords.filter(
        (eventRecord: any) => eventRecord.status === "published"
      ).length;
    }, [event.eventRecords]);

    const getPartPublishedListingCount = useMemo(() => {
      return event.eventRecords.filter(
        (eventRecord: any) => eventRecord.status === "partPublished"
      ).length;
    }, [event.eventRecords]);

    const getFailedListingCount = useMemo(() => {
      return event.eventRecords.filter(
        (eventRecord: any) => eventRecord.status === "failed"
      ).length;
    }, [event.eventRecords]);

    /**
     * SET LOCAL VALDATION EVENT RECORDS
     */

    const setLocalValidationToEventRecords = async () => {
      let success = true;
      setList((events: any) => {
        return events.map((inEvent: any) => {
          if (inEvent.id === event.id) {
            return {
              ...inEvent,
              eventRecords: event["eventRecords"].map((eventRecord: any) => {
                let fieldErrors: any = {};

                !eventRecord.category?.id &&
                  inEvent?.categories &&
                  Object.keys(inEvent?.categories).length > 0 &&
                  (fieldErrors = {
                    ...fieldErrors,
                    category: "Category value field is required",
                  });
                // !eventRecord.section?.id &&
                //   eventRecord?.category?.sections &&
                //   Object.keys(eventRecord?.category?.sections).length > 0 &&
                //   (fieldErrors = {
                //     ...fieldErrors,
                //     section: "Section value field is required",
                //   });
                !eventRecord.splitType?.id &&
                  (fieldErrors = {
                    ...fieldErrors,
                    splitType: "Split Type value field is required",
                  });
                !eventRecord.MULTIPLES &&
                  eventRecord.splitType.id === "MULTIPLES" &&
                  (fieldErrors = {
                    ...fieldErrors,
                    MULTIPLES: "Sell in Multiple Field is required",
                  });
                !eventRecord.faceValue?.value &&
                  (fieldErrors = {
                    ...fieldErrors,
                    face_value: "Face value field is required",
                  });
                !eventRecord.quantity &&
                  (fieldErrors = {
                    ...fieldErrors,
                    quantity_available: "Quantity field is required",
                  });
                !eventRecord.proceedValue?.value &&
                  (fieldErrors = {
                    ...fieldErrors,
                    sell_price: "Proceed value field is required",
                  });
                !eventRecord.ticketType?.id &&
                  (fieldErrors = {
                    ...fieldErrors,
                    ticket_type: "Ticket type field is required",
                  });
                !eventRecord.sub_ticket_type?.id &&
                  eventRecord.ticketType.id === "eticket" &&
                  (fieldErrors = {
                    ...fieldErrors,
                    sub_ticket_type: "Sub Ticket type Field is required",
                  });

                eventRecord?.ticketType?.id === "paper" &&
                  eventRecord?.deliveryType?.length > 0 &&
                  eventRecord?.deliveryType?.map((singleDelivery: any) => {
                    (!eventRecord?.[singleDelivery?.id] ||
                      Number(eventRecord?.[singleDelivery?.id]) <= 0) &&
                      (fieldErrors = {
                        ...fieldErrors,
                        [singleDelivery?.id]:
                          singleDelivery?.id +
                          " field is required more than zero",
                      });
                  });

                Object.keys(fieldErrors).length > 0 && (success = false);
                return {
                  ...eventRecord,
                  errors: fieldErrors,
                };
              }),
            };
          } else {
            return inEvent;
          }
        });
      });
      return success;
    };

    /**
     *  Upload ETicket Fiels to AWS
     * */
    const uploadETickets = async (eticketFiles: any) => {
      return await Promise.all(
        eticketFiles.map(async (eticketFile: any) => {
          //CHECK ARLEADY UPLOADED OR NOT
          if (!eticketFile?.awsFile) {
            try {
              const res = await s3Upload.uploadFile(eticketFile);
              return {
                ...eticketFile,
                awsFile: res?.key,
              };
            } catch (exception) {
              /* handle the exception */
              // console.warn(exception);
              return eticketFile;
            }
          } else {
            return eticketFile;
          }
        })
      );
    };

    /**
     *  GEt REQUEST JSON
     * */
    const getRequestJSONData = async () => {
      const inventoryData: any = [];
      let ppeExchanges: any = [];

      //SET PENDING STATUS ID TO EVENT RECORDS
      const events = list.map(async (inEvent: any) => {
        if (inEvent.id === event.id) {
          return {
            ...event,
            eventRecords: inEvent["eventRecords"].map(
              async (eventRecord: any, rIndex: number) => {
                if (!eventRecord?.listingId) {
                  const ticketTypeId = eventRecord?.ticketType?.id;

                  const ticket_type =
                    ticketTypeId === "pdf" || ticketTypeId === "mobile-qr"
                      ? "eticket"
                      : ticketTypeId;

                  const listing_quality = visiblityCountForPayload(
                    eventRecord?.visibility
                  );
                  const price_quality = priceCalculationWithNumber(
                    eventRecord?.proceedValue?.value,
                    eventRecord?.avg_price,
                    priceFactor
                  );

                  //upload ticketfiles to AWS
                  let uploadedTickets: any = eventRecord?.ticketFiles?.filter(
                    (obj: any) => JSON.stringify(obj) !== "{}"
                  );
                  if (
                    isArray(uploadedTickets) &&
                    uploadedTickets.length > 0 &&
                    eventRecord?.ticketType?.id !== "mobile-link"
                  ) {
                    uploadedTickets = await uploadETickets(
                      eventRecord?.ticketFiles
                    );
                  }
                  // events[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
                  //   uploadedTickets;
                  const mobile_links = _.map(
                    eventRecord?.ticketFiles,
                    "mobileLink"
                  )?.filter((link) => link !== undefined);
                  const uploadedTicketsUpdate = uploadedTickets?.filter(
                    (obj: any) => !obj?.mobileLink
                  );

                  inventoryData.push({
                    delivery_options: eventRecord?.deliveryType
                      .map((delivery: any) => {
                        return (
                          eventRecord?.[delivery?.id] !== "" && {
                            id: delivery?.id,
                            price: eventRecord?.[delivery?.id],
                          }
                        );
                      })
                      .filter((delivery: any) => delivery?.price),
                    name: event?.name,
                    card: eventRecord?.card,
                    event_reference_id: eventRecord?.card,
                    event_id: event?.id,
                    exchanges: _.map(eventRecord?.marketplaces, "exchange_id"),
                    ticket_type: ticket_type,
                    sub_ticket_type:
                      ticket_type === "eticket"
                        ? eventRecord?.ticketType?.id
                        : undefined,
                    sell_price_currency: eventRecord?.proceedValue?.symbol,
                    sell_price: eventRecord?.proceedValue?.value
                      ? removeCommaFromNumber(eventRecord?.proceedValue?.value)
                      : "",
                    price_per_exchange: eventRecord?.ppe ? 1 : 0,
                    ticket_exchange_prices: ppeExchanges,
                    face_value_currency: eventRecord?.faceValue?.symbol,
                    face_value: removeCommaFromNumber(
                      eventRecord?.faceValue?.value
                    ),
                    quantity_available: eventRecord?.quantity,
                    quantity_display: eventRecord?.maxDisplayQuantity,
                    category:
                      event?.categories &&
                      Object.keys(event?.categories).length > 0
                        ? eventRecord?.category?.id
                        : undefined,
                    section:
                      eventRecord?.category?.sections &&
                      Object.keys(eventRecord?.category?.sections).length > 0
                        ? eventRecord?.section?.id
                        : undefined,
                    row: eventRecord?.row,
                    first_seat: eventRecord?.firstSeat,
                    split_type: eventRecord?.splitType?.id
                      ? eventRecord?.splitType?.id
                      : "",
                    split_quantity:
                      eventRecord?.splitType?.id &&
                      eventRecord?.splitType?.id === "MULTIPLES"
                        ? parseInt(eventRecord?.MULTIPLES)
                        : undefined,
                    restrictions: _.map(eventRecord?.restrictions, "id"),
                    benefits: _.map(eventRecord?.benefits, "id"),
                    in_hand: eventRecord?.ticketsInHands ? "1" : "0",
                    date_to_ship: eventRecord?.dateToShip
                      ? moment(eventRecord?.dateToShip).format("DD.MM.YYYY")
                      : "",
                    ticketFiles: eventRecord?.ticketFiles,
                    ticket_files: _.map(uploadedTicketsUpdate, "awsFile"),
                    mobile_links: mobile_links,
                    listing_quality: listing_quality,
                    price_quality: price_quality,
                  });

                  return {
                    ...eventRecord,
                    status: "pending",
                  };
                } else {
                  return eventRecord;
                }
              }
            ),
            loader: true,
          };
        } else {
          return inEvent;
        }
      });

      //setList([...events]);

      return inventoryData;
    };

    const [publishLoader, setPublishLoader] = useState(false);

    const setValidationToEventRecords = (errors: any) => {
      setList((events: any) => {
        return events.map((event: any) => {
          return {
            ...event,
            loader: false,
            eventRecords: event["eventRecords"].map((eventRecord: any) => {
              let fieldErrors: any = errors?.[eventRecord?.card]
                ? errors?.[eventRecord?.card]
                : eventRecord?.errors;

              return {
                ...eventRecord,
                errors: fieldErrors,
                loader: false,
              };
            }),
          };
        });
      });
    };

    const handleEventRecordsPublishLive = async () => {
      // Fire local validation
      setPublishLoader(true);
      if (!(await setLocalValidationToEventRecords())) {
        setPublishLoader(false);
        return true;
      } else {
        // FIND FAILED RECORDS WHICH NOT ADD
        const failedRecords = event.eventRecords.filter(
          (eventRecord: any) => !eventRecord?.listingId
        );

        const chunkSize = 10; // Adjust chunk size as needed

        const processChunks = async (
          records: any,
          chunkSize: any,
          callback: any
        ) => {
          for (let i = 0; i < records.length; i += chunkSize) {
            const chunk = records.slice(i, i + chunkSize);
            await callback(chunk); // Ensure each chunk is processed sequentially
          }
        };

        const handleFailedRecords = async (records: any) => {
          const inventoryData = await getRequestJSONData();
          const publishInventoryFinalData = {
            publish: "1",
            listings: inventoryData,
          };

          const inventoryAddRes = await inventoryBlukAdd(
            publishInventoryFinalData
          );

          if (inventoryAddRes?.data?.errors) {
            setValidationToEventRecords(inventoryAddRes?.data?.errors);
          }
        };

        const handleUpdateRecords = async (records: any) => {
          for (const updateRecord of records) {
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay for each record
            const ticketTypeId = updateRecord?.ticketType?.id;
            const ticket_type =
              ticketTypeId === "pdf" || ticketTypeId === "mobile-qr"
                ? "eticket"
                : ticketTypeId;

            const listing_quality = visiblityCountForPayload(
              updateRecord?.visibility
            );
            const price_quality = priceCalculationWithNumber(
              removeCommaFromNumber(updateRecord?.proceedValue?.value),
              updateRecord?.avg_price,
              priceFactor
            );

            let uploadedTickets = updateRecord?.ticketFiles?.filter(
              (obj: any) => JSON.stringify(obj) !== "{}"
            );
            if (
              Array.isArray(uploadedTickets) &&
              uploadedTickets.length > 0 &&
              updateRecord?.ticketType?.id !== "mobile-link"
            ) {
              uploadedTickets = await uploadETickets(updateRecord?.ticketFiles);
            }
            const mobile_links = _.map(
              updateRecord?.ticketFiles,
              "mobileLink"
            )?.filter((link) => link !== undefined);
            const uploadedTicketsUpdate = uploadedTickets?.filter(
              (obj: any) => !obj?.mobileLink
            );

            let inputs = {
              delivery_options: updateRecord?.deliveryType
                .map((delivery: any) => {
                  return (
                    updateRecord?.[delivery?.id] !== "" && {
                      id: delivery?.id,
                      price: updateRecord?.[delivery?.id],
                    }
                  );
                })
                .filter((delivery: any) => delivery?.price),
              listing_id: updateRecord.listingId,
              status: "yes",
              name: event.name,
              event_id: event.id,
              exchanges: _.map(updateRecord?.marketplaces, "exchange_id"),
              ticket_type: ticket_type,
              sub_ticket_type:
                ticket_type === "eticket"
                  ? updateRecord?.ticketType?.id
                  : undefined,
              split_quantity:
                updateRecord?.splitType?.id &&
                updateRecord?.splitType?.id === "MULTIPLES"
                  ? parseInt(updateRecord?.MULTIPLES)
                  : 0,
              in_hand: updateRecord?.ticketsInHands ? "1" : "0",
              sell_price_currency: updateRecord.proceedValue?.symbol,
              sell_price: removeCommaFromNumber(
                updateRecord?.proceedValue?.value
              ),
              price_per_exchange: updateRecord?.ppe ? 1 : 0,
              ticket_exchange_prices: [],
              face_value_currency: updateRecord?.faceValue?.symbol,
              face_value: removeCommaFromNumber(updateRecord?.faceValue?.value),
              quantity_available: updateRecord?.quantity,
              quantity_display: updateRecord?.maxDisplayQuantity,
              category:
                event?.categories && Object.keys(event?.categories).length > 0
                  ? updateRecord?.category?.id
                  : undefined,
              section:
                updateRecord?.category?.sections &&
                Object.keys(updateRecord?.category?.sections).length > 0
                  ? updateRecord?.section?.id
                  : undefined,
              row: updateRecord?.row,
              first_seat: updateRecord?.firstSeat,
              split_type: updateRecord?.splitType?.id
                ? updateRecord?.splitType?.id
                : "",
              date_to_ship: updateRecord?.dateToShip
                ? moment(updateRecord?.dateToShip).format("DD.MM.YYYY")
                : "",
              restrictions: _.map(updateRecord?.restrictions, "id"),
              benefits: _.map(updateRecord?.benefits, "id"),
              mobile_links: mobile_links,
              listing_quality: listing_quality,
              price_quality: price_quality,
              ticket_files: _.map(uploadedTicketsUpdate, "awsFile"),
            };

            const updateInventoryRes = await UpdateInventory(inputs);

            if (
              updateInventoryRes?.errors &&
              updateInventoryRes.errors.length > 0
            ) {
              // set errors for event record after calling the update event record api.
              setList((events: any) => {
                return events.map((inEvent: any) => {
                  if (inEvent.id === event.id) {
                    let tempEvevntRecords = inEvent["eventRecords"].map(
                      (eventRecord: any) => {
                        if (
                          updateRecord?.listingId === eventRecord?.listingId
                        ) {
                          return {
                            ...eventRecord,
                            errors: {
                              commonErrors: updateInventoryRes.errors,
                            },
                          };
                        } else {
                          return eventRecord;
                        }
                      }
                    );
                    return {
                      ...inEvent,
                      eventRecords: tempEvevntRecords,
                      loader: false,
                    };
                  } else {
                    return inEvent;
                  }
                });
              });
            }
          }
        };

        if (failedRecords.length > 0) {
          setList((events: any) => {
            return events.map((inEvent: any) => {
              if (inEvent.id === event.id) {
                return {
                  ...inEvent,
                  loader: true,
                };
              } else {
                return inEvent;
              }
            });
          });

          await processChunks(failedRecords, chunkSize, handleFailedRecords);
        }

        // FIND FAILED RECORDS WHICH ADDED IN DB
        const updateRecords = event.eventRecords.filter(
          (eventRecord: any) =>
            eventRecord?.listingId &&
            (eventRecord?.status === "failed" ||
              eventRecord?.status === "partPublished")
        );

        if (updateRecords.length > 0) {
          setList((events: any) => {
            return events.map((inEvent: any) => {
              if (inEvent.id === event.id) {
                return {
                  ...inEvent,
                  loader: true,
                };
              } else {
                return inEvent;
              }
            });
          });

          await processChunks(updateRecords, chunkSize, handleUpdateRecords);
        }
      }

      setPublishLoader(false);
    };

    const handleRemoveAffectedMarketPlace = () => {
      let countMarketPlaces = 0;
      let countListing = 0;
      let findEventRecords: any = [];
      list.map((event: any) => {
        event?.eventRecords.map((eventRecord: any) => {
          if (
            eventRecord?.status === "failed" ||
            eventRecord?.status === "partPublished"
          ) {
            let error = false;
            eventRecord?.marketplaces.map((marketplace: any) => {
              if (marketplace?.error && marketplace?.error.length > 0) {
                countMarketPlaces++;
                error = true;
              }
            });
            error && countListing++;
          }
        });
      });

      if (countMarketPlaces > 0) {
        setRemoveMarketAlert(true);
        setRemoveMarketAlertMsg(
          `Are you sure you want to remove ${countMarketPlaces} affected markeplaces from ${countListing} listing${
            countListing > 1 ? "s" : ""
          }?`
        );
        setRemoveMarketAlertConfirm(true);
      } else {
        setRemoveMarketAlert(true);
        setRemoveMarketAlertMsg(
          "There are no affected marketplaces in the listings."
        );
        setRemoveMarketAlertConfirm(false);
      }
    };

    const confirmRemoveAffectedMarketPlace = () => {
      setList((events: any) => {
        return events.map((inEvent: any) => {
          if (inEvent.id === event.id) {
            return {
              ...inEvent,
              eventRecords: event["eventRecords"].map((eventRecord: any) => {
                if (
                  eventRecord?.status === "failed" ||
                  eventRecord?.status === "partPublished"
                ) {
                  return {
                    ...eventRecord,
                    marketplaces: eventRecord["marketplaces"].filter(
                      (marketplace: any) =>
                        !(marketplace?.error && marketplace?.error.length > 0)
                    ),
                  };
                } else {
                  return eventRecord;
                }
              }),
            };
          } else {
            return inEvent;
          }
        });
      });
    };

    return (
      <>
        {publishLoader && (
          <ProgressBardLoader
            LoadingText={
              "We're processing your request, please do not close this window"
            }
            secondCounter={5}
          />
        )}
        {removeMarketAlert && (
          <TablePopup
            btnRef={btnRef}
            isConfirm={removeMarketAlertConfirm}
            message={removeMarketAlertMsg}
            cancelBtn={(data: any) => setRemoveMarketAlert(data)}
            confirmBtn={() => {
              layout.setConfirmRowDelete(true);
              setTimeout(() => {
                confirmRemoveAffectedMarketPlace();
              }, 500);
            }}
          />
        )}

        <div
          // id={`EventCollapseBody${eIndex}`}
          id={`${
            layout.isPublish === false
              ? `EventCollapseBody${event?.id}`
              : `EventCollapseBodyTwo${event?.id}`
          }`}
          className="bottom_acc accordion-collapse collapse show "
          aria-labelledby="bodyOne"
          data-te-collapse-item
          data-te-collapse-show
        >
          <div className="accordion-body  relative border-b !rounded-none">
            <div className="flex flex-wrap w-full -mb-[.0625rem]">
              <div className="w-full md:w-1/2 lg:w-1/3 xl:w-[354.992px] flex flex-col px-5 py-4 text-sm sm:border-r border-b">
                <div className="w-full flex justify-between  items-center mb-1">
                  <div className="text-3xl ">{getEventRecordPecentage}%</div>
                  {event.loader && (
                    <KTSVG
                      className="flex items-center justify-center fill-violet-500 transition animate-spin"
                      path="other_icons/process.svg"
                    />
                  )}
                  {getEventRecordPecentage === 100 && (
                    <div
                      className={`flex items-center bg-green-500 text-white text-xxs font-semibold px-2 py-0.5 rounded dark:bg-red-900 dark:text-red-300 leading-[1.6]`}
                    >
                      Complete
                    </div>
                  )}
                  {getEventRecordPecentage < 100 && event.loader === false && (
                    <div
                      className={`flex items-center bg-rose-500 text-white text-xxs font-semibold px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300`}
                    >
                      Marketplace errors
                    </div>
                  )}
                </div>
                <div className="flex items-center text-xs text-gray-500">
                  <div className="mr-2">Published</div>
                  <div className="w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700">
                    <div
                      className={`${
                        event.loader === false
                          ? getEventRecordPecentage === 100
                            ? `bg-green-500`
                            : `bg-rose-500`
                          : `bg-indigo-500`
                      } rounded-full h-full transition-all delay-2s `}
                      style={{ width: getEventRecordPecentage + "%" }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 lg:w-1/3 xl:w-[264.96px] flex  flex-col px-4 xl:pb-3 pb-4 xl:pt-3 pt-3 text-sm sm:border-r border-b">
                <div className="text-3xl mb-1">
                  {event.eventRecords && event.eventRecords.length}
                </div>
                <div className="text-xs text-gray-500 ">Listings</div>
              </div>

              <div className="w-full md:w-1/2 lg:w-1/3 xl:w-[264.96px] flex  flex-col px-4 xl:pb-3 pb-4 xl:pt-3 pt-3 text-sm sm:border-r border-b">
                <div className="flex justify-between items-center mb-1">
                  <div className="text-3xl ">{getPublishedListingCount}</div>
                  <KTSVG
                    className="flex items-center justify-center fill-green-500 transition"
                    path="other_icons/hax-Publish-Gray.svg"
                  />
                </div>
                <div className="text-xs text-gray-500">Published</div>
              </div>

              {getPartPublishedListingCount > 0 && (
                <div className="w-full md:w-1/2 lg:w-1/3 xl:w-[264.96px] flex  flex-col px-4 xl:pb-3 pb-4 xl:pt-3 pt-3 text-sm sm:border-r border-b">
                  <div className="flex justify-between items-center mb-1">
                    <div className="text-3xl ">
                      {getPartPublishedListingCount}
                    </div>
                    <KTSVG
                      className="flex items-center justify-center fill-orange-500 transition"
                      path="other_icons/hax-Publish-Gray.svg"
                    />
                  </div>
                  <div className="text-xs text-gray-500">Part-Published</div>
                </div>
              )}

              {getFailedListingCount > 0 && (
                <div className="w-full md:w-1/2 lg:w-1/3 xl:w-[264.96px] flex flex-col px-4 xl:pb-3 pb-4 xl:pt-3 pt-3 text-sm md:border-r border-b">
                  <div className="flex justify-between items-center mb-1">
                    <div className="text-3xl ">{getFailedListingCount}</div>
                    <KTSVG
                      className="flex items-center justify-center fill-rose-500 transition"
                      path="standard_icons/warning1.svg"
                    />
                  </div>
                  <div className="text-xs text-gray-500">Failed</div>
                </div>
              )}
            </div>
          </div>

          {/* Table Body */}
          {getEventRecordPecentage < 100 && (
            <>
              <VirtuosoEventField
                event={event}
                eIndex={eIndex}
                handleTableField={handleTableField}
                list={list}
                setList={setList}
                handlePPETableChange={handlePPETableChange}
                handleSelectAll={handleSelectAll}
                setIsOpenListingQualityPopup={setIsOpenListingQualityPopup}
              />

              {/* Footer Buttons */}
              {event?.eventRecords && event?.eventRecords?.length !== 0 && (
                <div className="accordion-footer py-2 px-3 ">
                  <div
                    className={`flex flex-wrap justify-end w-full ${
                      event?.loader && `pointer-events-none`
                    }`}
                  >
                    <button
                      id={`errorId${eIndex}`}
                      type="button"
                      className={`${
                        !event.hideError && "hidden"
                      } accordion-collapse border hover:text-white bg-white hover:bg-indigo-500 transition font-medium !rounded text-sm13 px-2.5 py-0.5 m-1`}
                      data-te-collapse-init
                      data-te-target={`#TableBodyPublish${event?.id}`}
                      aria-expanded="true"
                      aria-controls={`TableBodyPublish${event?.id}`}
                      onClick={(e: any) => eventError(event, eIndex, false)}
                    >
                      Hide errors
                    </button>
                    {/* <div className="m-1">
                  <button
                    type="button"
                    className="text-rose-500 hover:text-white bg-white hover:bg-rose-500 border hover:border-rose-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                    onClick={handleRemoveAffectedMarketPlace}
                  >
                    Remove affected marketplace(s)
                  </button>
                </div> */}
                    {event.hideError === false ? (
                      <button
                        type="button"
                        className={`accordion-collapse border border-green-600 text-white hover:text-green-600 bg-green-600 hover:bg-white transition font-semibold !rounded text-sm13 px-2.5 py-0.5 m-1`}
                        data-te-collapse-init
                        data-te-target={`#TableBodyPublish${event?.id}`}
                        aria-expanded="true"
                        aria-controls={`TableBodyPublish${event?.id}`}
                        onClick={(e: any) => eventError(event, eIndex, true)}
                      >
                        Review & fix errors
                      </button>
                    ) : (
                      <button
                        type="button"
                        className={`uppercase accordion-collapse border border-green-600 text-white hover:text-green-600 bg-green-600 hover:bg-white transition font-semibold !rounded text-sm13 px-2.5 py-0.5 m-1`}
                        onClick={handleEventRecordsPublishLive}
                      >
                        Publish Live
                      </button>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
);

export default PublishHeader;
