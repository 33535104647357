import { useContext } from "react";
import VariableSVG from "../../../assets/media/icons/menu_icons/variable.svg";
import AuthenticationSVG from "../../../assets/media/icons/menu_icons/authentication.svg";
import TriggerSVG from "../../../assets/media/icons/menu_icons/trigger.svg";
import Expand from "../../../assets/media/icons/other_icons/Expand.svg";
import cross from "../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { ConnectorContext } from "../core/ConnectorProvider";
import NavTitle from "./NavtTitle";

const LeftPanel = () => {
  const layout = useContext(ConnectorContext);
  const sideTabOption = layout.sideOption;

  return (
    <>
      <div
        className={`event-btn absolute top-[2.1875rem] -left-[2.1875rem] -rotate-90 origin-bottom z-[5]`}
        onClick={() => layout.setCanvasToggle((current: any) => !current)}
      >
        {!layout.canvasToggle && (
          <button
            type="button"
            className="group text-center rounded-b bg-[#5020A6] hover:bg-indigo-500 text-white  hover:border-indigo-500 transition flex items-center text-sm13"
          >
            <span className="px-[.5625rem]">Configuration</span>
            <IKTSVG
              path={Expand}
              className="fill-white transition w-[1.875rem] border-l border-white/10 h-[1.875rem] flex items-center justify-center"
              svgClassName="w-[.5625rem] h-[.5625rem]"
            />
          </button>
        )}
      </div>

      {/* BODY */}
      <div
        className={`${
          layout.canvasToggle ? "ml-0" : "-ml-[24rem]"
        } z-[11] transition-all duration-300 multi-collapse shadow-3xl max-w-[24rem] lg2:w-[20%] w-full bg-white md:max-h-[calc(100vh-7.75rem)] max-h-[calc(100vh-3.75rem)] lg2:relative  md:top-0 -top-[3.75rem] left-0 flex flex-col`}
        id="algolia-trade-list"
      >
        <div
          className={`search-panel-event event-search-block p-5 gap-2.5 flex flex-wrap  bg-white relative`}
        >
          <div className="w-full mb-[.375rem] mt-[.3125rem] flex items-center justify-between">
            <p className="text-sm15 font-semibold">Configuration</p>
            <div className="icons flex items-center">
              <div
                className="crossIcon"
                data-tooltip-id="left-panel-close"
                onClick={() =>
                  layout.setCanvasToggle((current: any) => !current)
                }
              >
                <TooltipPortal
                  id="left-panel-close"
                  content="Close"
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium text-xs z-[9999]"
                />
                <IKTSVG
                  path={cross}
                  className="cursor-pointer hover:fill-indigo-500 fill-violet-500"
                  svgClassName="w-2.5 h-2.5"
                />
              </div>
            </div>
          </div>

          {/* OPTIONS */}
          <div className="sidebar w-full bg-white  lg2:top-0 sticky  z-20">
            <div
              className="lg2:!h-auto lg2:sticky lg2:top-20 lg2:block  absolute left-0 w-full top-full  z-20 bg-white"
              id="collapseOne5"
              data-te-collapse-item
              data-te-collapse-collapsed
              aria-labelledby="headingOne5"
            >
              <NavTitle
                title="Variables"
                imgPath={VariableSVG}
                selectedOption={sideTabOption}
              />
              <NavTitle
                title="Authentication"
                imgPath={AuthenticationSVG}
                selectedOption={sideTabOption}
              />
              <NavTitle
                title="Triggers"
                imgPath={TriggerSVG}
                selectedOption={sideTabOption}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftPanel;
