/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import { TableVirtuoso } from "react-virtuoso";
import EyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import UploadSVG from "../../../assets/media/icons/other_icons/Upload_new.svg";
import LockIconOpen from "../../../assets/media/icons/other_icons/lock-open-solid.svg";
import WarningIcon from "../../../assets/media/icons/standard_icons/warning.svg";

import LockIcon from "../../../assets/media/icons/other_icons/lock-solid.svg";
import logIcon from "../../../assets/media/icons/other_icons/logSvg.svg";
import LeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import RightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import Warning1 from "../../../assets/media/icons/standard_icons/warning1.svg";

import { LOG_TAB_KEYS } from "../../../components/ExpandableList/constants/constants";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  decimalNumber,
  handleScroll,
  handleShift,
  isChildInViewport,
  isDateBeforeValidLogDate,
  isSameOrderId,
  scrollToVisible,
  ticketTypeRenameForSalesReport,
} from "../../../helpers/Functions";
import { isMobile, SALES_COLUMN_ADMIN, SYMBOLS } from "../../../helpers/const";
import { LogDetailsPopUp } from "../../inventory/widgets/LogDetails/LogDetailsPopUp";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { SalesContext } from "../core/SalesProvider";
import { display } from "../core/_functions";
import {
  getInstructionLetterTemplateList,
  getSalesLogListing,
} from "../core/_requests";
import ETicketUpload from "../widget/ETicketUpload";
import OrderDetail from "../widget/OrderDetail";
import DataCell from "./component/DataCell";
import HeaderCell from "./component/HeaderCell";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  min-w-[87.5rem]  "
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 !static"
    style={{
      ...style,
    }}
  />
);

const DataTable = ({
  data,
  tabValue,
  refetch,
  isGodAdmin,
  paginateData,
  salesLoading = true,
  optionsData,
}: any) => {
  const layout = useContext(SalesContext);
  const globalLayout = useContext(LayoutContext);
  const [logsData, setLogsData] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(LOG_TAB_KEYS.ORDER);

  const [showOrder, setShowOrder] = useState<any>({
    show: false,
    data: "",
  });
  const [flag, setFlag] = useState<boolean>(false);
  const [instructionLetterTemplates, setInstructionLetterTemplates] =
    useState<any>({});
  const tableRef: any = useRef();
  const ref: any = useRef<any>(null);
  const canvasRef: any = useRef<any>();

  const accordionRef: any = useRef<any>();

  // CHECK CONDITION IF CHECKBOX CHECKED
  const isSelectAll = layout.isSelectAll;

  const stickyTableRef: any = useRef();
  const virtuosoRef: any = useRef();

  const [hoveredId, setHoveredId] = useState(null);

  // Function to handle mouse enter
  const handleMouseEnter = (id: React.SetStateAction<null>) => {
    setHoveredId(id);
  };

  // Function to handle mouse leave
  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const isRowHighlighted = useCallback(
    (list: any) =>
      isSameOrderId(showOrder?.data, list) || isSameOrderId(logsData, list),
    [showOrder, logsData?.order_id]
  );

  //useEffect for template listing set

  useEffect(() => {
    getInstructionLetterTemplateList({ order_by: "title", get_all: true }).then(
      (res: any) => {
        setInstructionLetterTemplates(res.data);
      }
    );
  }, []);

  // TABLE SCROLL HANDLE
  // const scroll = (scrollOffset: any) => {
  //   tableRef.current.scrollLeft += scrollOffset;
  // };
  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  // HANDLE CHECK
  const handleCheck = (e: any, id: string) => {
    layout.setOrders((orders: any) => {
      return orders.map((order: any) => {
        if (order.order_id === id) {
          return {
            ...order,
            selected: e === true ? true : e?.target?.checked,
          };
        } else {
          return order;
        }
      });
    });
  };

  // Select All Events
  const handleSelectAll = (data: any) => {
    layout.setOrders((orders: any) => {
      return orders.map((order: any) => {
        if (order?.is_txtrade_pending_order === true) {
          return {
            ...order,
            selected: false,
          };
        }
        if (order?.is_legacy_order === "false" && isGodAdmin === false) {
          layout.setIsSelectAll(data.target.checked);
          return {
            ...order,
            selected: data.target.checked,
          };
        } else if (order?.is_legacy_order === "false" && isGodAdmin) {
          layout.setIsSelectAll(data.target.checked);
          if (order?.isLock === false) {
            return {
              ...order,
              selected: data.target.checked,
            };
          } else {
            return {
              ...order,
              selected: false,
            };
          }
        } else {
          return { ...order };
        }
      });
    });
  };

  const handleOnUnLockAll = (e: any) => {
    // console.log(e.target.checked, "e");
    layout.setOrders((orders: any) => {
      layout.setIsSelectAll(false);
      return orders.map((order: any) => {
        return {
          ...order,
          isLock: e.target.checked ? false : true,
          // selected: order?.selected ? order?.selected : false,
          selected: false,
        };
      });
    });
  };

  const handlesingleUnLock = (e: any, sIndex: any) => {
    layout.setOrders((orders: any) => {
      return orders.map((order: any, index: any) => {
        if (sIndex === index) {
          return {
            ...order,
            isLock: !order?.isLock,
            selected: false,
          };
        } else {
          return {
            ...order,
          };
        }
      });
    });
  };

  // HANDLE ORDER VIEW PAGE
  const handleOrderView = (list: any, index: number) => {
    if (
      list?.order_id &&
      showOrder?.data?.order_id &&
      list?.order_id === showOrder?.data?.order_id
    ) {
      display(false);
      setShowOrder({
        show: false,
        data: "",
        index: "",
      });
    } else {
      setShowOrder({
        show: true,
        data: list,
        index: index,
      });
    }
  };

  // MAKE CHECKBOX CHECKED FOR
  const getChecked = (e: any, id: any) => {
    data?.forEach((item: any) => {
      if (item?.order_id === id) {
        item.selected = e;
      }
    });
  };

  // HANDLE SINGLE UPLOAD
  const handleSingleUpload = () => {
    layout.setTicketUploadData("");
    layout.setOrders((currentOrder: any) => {
      let finalResult = currentOrder?.map((orders: any) => {
        return {
          ...orders,
          selected: false,
        };
      });
      return finalResult;
    });
    refetch();
  };

  const getColumnValue = isGodAdmin
    ? layout.columnItems
    : layout.columnItems?.filter(
        (obj: any) => !SALES_COLUMN_ADMIN?.includes(obj?.name)
      );

  const getColumnItems = () => {
    return getColumnValue?.filter((columnItem) => columnItem.checked === true);
  };

  // Save Draggable Column Data to Local Storage
  const salesTableColumn = () => {
    localStorage.setItem(
      "salesTableColumn",
      JSON.stringify(layout.columnItems)
    );
  };

  useEffect(() => {
    // salesTableColumn();
  }, [layout.columnItems]);

  const uploadEticket = (list: any) => {
    if (!flag) {
      setShowOrder({
        show: false,
        data: "",
      });
      let acc = accordionRef?.current?.style;
      acc.marginRight = "0px";
      getChecked(true, list.order_id);
      layout.setTicketUploadData(list);
    }
  };

  // SMOOTH SCROLL IN SALES-PAGE
  useEffect(() => {
    const isInViewport = isChildInViewport(`sales-tr-${showOrder?.index}`);

    if (!isInViewport) {
      scrollToVisible(`sales-tr-${showOrder?.index}`);
    }
  }, [showOrder?.index]);

  // change tab value
  useEffect(() => {
    if (tabValue) {
      setShowOrder({
        show: false,
        data: "",
      });
    }
  }, [tabValue]);

  // change tab value
  useEffect(() => {
    if (layout.salesFilterHandle.page === 1) {
      setShowOrder({
        show: false,
        data: "",
      });
      display(false);
    }
  }, [layout.salesFilterHandle]);

  // CALCULATE THE HEIGHT OF TABLE VIRTOUSO
  const [height, setHeight] = useState<any | undefined>(undefined);
  const [virtHeight, setVirtHeight] = useState<any>();

  let timeout: any;
  const nomoreListing = document.getElementById("NoMoreListing");

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#sales-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight + 1;
          if (nomoreListingHeight) {
            elemHeight =
              divElement.offsetHeight + theadHeight + nomoreListingHeight;
          }

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };

  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      const divElement: any = document.querySelector("#sales-lazyload");
      const elemHeight = divElement?.offsetHeight;
      setVirtHeight(elemHeight);
    }, 300);
  }, [data, salesLoading, layout?.salesFilterHandle]);

  // RE-RENDER HEIGHT ON RESIZING RESOLUTION
  useEffect(() => {
    renderCall();
  }, [
    globalLayout?.htmlFontSize,
    data?.length === paginateData?.total && paginateData?.current_page > 1,
    nomoreListing,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (globalLayout?.htmlFontSize) {
        renderCall();
      }
    }, 1000);
  }, [globalLayout?.htmlFontSize]);
  const [customLoader, setCustomLoader] = useState<any>(false);

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData, customLoader]);

  useEffect(() => {
    if (data?.length > 0)
      setTimeout(() => {
        setCustomLoader(true);
      }, 1000);
  }, [data]);
  const isAllunLock = data && data?.every((obj: any) => obj?.isLock === false);

  const isSomeOfAllunLock =
    data && data?.some((obj: any) => obj?.isLock === false);

  useEffect(() => {
    const element = document.getElementById("tableId1");
    if (element) {
      const elem1 = document.querySelectorAll("#left-shad-list");
      const elem2 = document.querySelectorAll("#checkbox_shad");

      element.addEventListener("scroll", () => {
        const elementScrollLeft = element.scrollLeft;
        const elementScrollRight =
          element.scrollWidth - element.clientWidth - elementScrollLeft;

        elem1.forEach((element: any, index: any) => {
          elementScrollRight === 0
            ? element.classList.remove("left-shad")
            : element.classList.add("left-shad");
        });
      });
    }
  });

  useEffect(() => {
    const handleScrollTable = () => {
      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;

      const topfilterTop: any = document.getElementById("topFilters");

      const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
      // if (topValue.includes("-")) {
      // Check if the value contains a negative sign
      const currentValue = parseInt(topValue); // Extract the numeric value
      const newValue = Math.abs(currentValue); // Make it positive

      if (topfilterTop?.style) {
        topfilterTop.style.top = `${newValue}px`; // Apply the new positive value back as a string
      }
      // }
      const windScroll: number = window.scrollY;

      const headingOne: any =
        document.getElementById("headingOne")?.clientHeight;
      const inventoryaccordion: any =
        document.getElementById("inventoryaccordion")?.clientHeight;

      const salesFilter =
        document.getElementById("salesFilter")?.offsetHeight || 0;
      // const totleScroll = headingOne + inventoryaccordion + 16;
      // const currentTop = parseInt(topfilterTop.style.top || "0");
      let totleScroll = 0;
      if (window.matchMedia("(max-width: 767px)").matches) {
        totleScroll = headingOne + inventoryaccordion + salesFilter + 20;
      } else {
        totleScroll = headingOne + inventoryaccordion + 16;
      }

      const stickyHead: any = document.getElementById("sticky-head");
      //code to adjust sticky header based on filter chip Area scrollbar
      const filterChipArea = document.querySelector(
        ".filterChipArea"
      ) as HTMLElement;
      let filterChipAreaWidth = 0;
      let width = 0;

      function getBodyScrollbarWidth() {
        const filterChipArea = document.querySelector(
          ".filterChipArea"
        ) as HTMLElement;
        // Creating a div element
        const scrollDiv = document.createElement("div");
        scrollDiv.style.width = "100px";
        scrollDiv.style.height = "100px";
        scrollDiv.style.overflow = "scroll";
        scrollDiv.style.position = "absolute";
        scrollDiv.style.top = "-9999px"; // Hide the div off-screen

        // Append the div to the document body
        filterChipArea?.appendChild(scrollDiv);
        // Calculate the body scrollbar width
        const scrollbarWidth =
          filterChipArea?.offsetHeight - filterChipArea?.clientHeight;
        // Remove the div from the document
        filterChipArea?.removeChild(scrollDiv);
        return scrollbarWidth;
      }
      const bodyScrollbarWidth = getBodyScrollbarWidth();

      if (
        filterChipArea &&
        filterChipArea.children.length > 0 &&
        bodyScrollbarWidth !== 0
      ) {
        width = filterChipArea.scrollWidth;
        filterChipAreaWidth = filterChipArea.offsetWidth;
      }
      if (width > filterChipAreaWidth && width != 0) {
        // stickyHead?.classList.remove("top-[14.625rem]");
        stickyHead?.classList.add("sticky-header-scroll");
        // stickyHead?.classList.remove("xl:top-[12.375rem]");
        // stickyHead?.classList.remove("xl:top-[12.625rem]");
      }

      const filters_block =
        document.getElementById("filters_block")?.offsetHeight || 0;
      // const salesFilter =
      //   document.getElementById("salesFilter")?.offsetHeight || 0;
      const HeaderHeight: any = document.querySelector("header")?.clientHeight;
      const topPosition = filters_block + salesFilter + HeaderHeight;
      const stickyShadow: any = document.getElementById("sticky-shadow");
      if (windScroll > totleScroll) {
        EventAccordion?.classList?.add("-mt-10");
        stickyHead?.classList.remove("opacity-0");
        stickyHead?.classList.remove("h-0");
        stickyHead?.classList.add("h-[2.5625rem]");
        stickyShadow.classList.add("!block");
      } else if (windScroll < totleScroll) {
        EventAccordion?.classList?.remove("-mt-10");
        stickyHead?.classList.add("opacity-0");
        stickyHead?.classList.add("h-0");
        stickyHead?.classList.remove("h-[2.5625rem]");
        stickyShadow.classList.remove("!block");
      }
    };
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  /*On scroll virtuoso list scroll stickyhead*/
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef?.current?.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };
  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef?.current?.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };

  const nomore = document.getElementById("NoMoreListing");

  // auto disabled left right table button
  useEffect(() => {
    const element = document.getElementById("tableId1");
    const stickyHead = document.getElementById("sticky-head");
    const scrollHandler = () => handleScroll(element, stickyHead);
    if (element) {
      element.removeEventListener("scroll", scrollHandler);
      element.addEventListener("scroll", scrollHandler);
      scrollHandler(); // Initial check
      return () => {
        element.removeEventListener("scroll", scrollHandler);
      };
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById("tableId1");
    const stickyHead = document.getElementById("sticky-head");
    const scrollHandler = () => handleScroll(element, stickyHead);
    setTimeout(() => {
      scrollHandler();
    }, 1000);
  }, [showOrder, logsData]);

  // display;

  useEffect(() => {
    display(true);
  }, [globalLayout.htmlFontSize]);

  return (
    <>
      <div
        className="accordion mb-4 font-medium transition-all relative md:px-5 max-lg:!mr-0"
        id={`EventAccordion`}
        ref={accordionRef}
      >
        {/* <div className="absolute bg-white left-0 top-0 w-full h-full gap-y-2.5 flex flex-wrap mr-1 z-[0] overflow-hidden scrollbar-none flex-col">
          {Array.from({ length: data?.length }).map((key, index) => (
            <div
              className="bg-gray-100 rounded mr-1 h-[1.875rem] w-full shimmer-effect"
              key={index}
            ></div>
          ))}
        </div> */}
        <div
          id="sticky-head"
          className={`sticky sales-report-sticky-head z-10 opacity-0 h-0 overflow-auto scrollbar-none bg-white ${
            isGodAdmin ? "top-[14.625rem]" : "top-[12.1875rem]"
          }`}
          ref={stickyTableRef}
          onScroll={handleScrollHead}
        >
          <table className="w-full text-xs text-left  min-w-[87.5rem] absolute left-0 top-0 w-full">
            <thead className="text-xs text-gray-400 ">
              <tr className="border-b bg-white">
                {tabValue === "to_be_fulfilled" && (
                  <th
                    className={`${
                      isGodAdmin
                        ? " min-w-[5rem] max-w-[5rem] flex !sticky left-0 bg-white z-10"
                        : "min-w-[2.5rem] max-w-[2.5rem]"
                    }  h-[2.5rem] items-center justify-center text-center sticky left-0 p-0 z-10 no-scroll checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                    id="checkbox_shad"
                  >
                    {/* <div className="h-full w-10 flex items-center justify-center border-r"> */}
                    {isGodAdmin && (
                      <label
                        className="lock-icon relative w-10 h-full border-r cursor-pointer"
                        htmlFor={`LockRow`}
                      >
                        <input
                          id={`LockRow`}
                          type="checkbox"
                          className="invisible absolute "
                          onClick={(e: any) => {
                            handleOnUnLockAll(e);
                          }}
                        />
                        <img
                          src={isAllunLock === false ? LockIcon : LockIconOpen}
                          alt="LockIcon"
                          className="!w-[.9375rem] !h-[.9375rem]"
                        />
                      </label>
                    )}
                    {/* </div> */}
                    <label
                      className={`h-full flex items-center justify-center border-r cursor-pointer
                            ${isGodAdmin ? "w-10" : "w-full"} `}
                      htmlFor="headCheck"
                    >
                      <input
                        id="headCheck"
                        type="checkbox"
                        checked={isSelectAll}
                        readOnly
                        className={`${
                          isGodAdmin &&
                          isSomeOfAllunLock === false &&
                          "pointer-events-none"
                        }  w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 `}
                        onChange={(data) => handleSelectAll(data)}
                      />
                    </label>
                  </th>
                )}

                {getColumnItems().map((columnItem: any) => {
                  switch (columnItem.id) {
                    case 0:
                      return (
                        <HeaderCell
                          title="Team name"
                          width="md:min-w-[9.375rem] min-w-[8.375rem]"
                          arrow={false}
                          padding={tabValue !== "to_be_fulfilled" && "pl-5"}
                          postKey="team_name"
                        />
                      );
                    case 1:
                      return (
                        <HeaderCell
                          title="Team member"
                          width="md:min-w-[9.375rem] min-w-[7.625rem]"
                          arrow={false}
                          padding={tabValue !== "to_be_fulfilled"}
                          postKey="user_name"
                        />
                      );
                    case 2:
                      return (
                        <HeaderCell
                          title="Order ID"
                          width="md:min-w-[6.875rem] min-w-[5.625rem]"
                          arrow={false}
                          padding={tabValue !== "to_be_fulfilled"}
                          postKey="order_id"
                        />
                      );
                    case 3:
                      return (
                        <HeaderCell
                          title="Order date"
                          width="md:min-w-[10.875rem] min-w-[6.25rem]"
                          arrow={true}
                          // padding={tabValue !== "to_be_fulfilled" && "pl-5"}
                          postKey="order_date_time"
                        />
                      );

                    case 4:
                      return (
                        <HeaderCell
                          // title="Order value"
                          title={isGodAdmin ? "Listing value" : "Order value"}
                          width="min-w-[6.875rem]"
                          arrow={true}
                          postKey="transaction_value"
                        />
                      );

                    case 7:
                      return (
                        <HeaderCell
                          title="Event"
                          width="md:min-w-[15.125rem] min-w-[12.5rem]"
                          arrow={true}
                          postKey="event_description"
                        />
                      );
                    case 8:
                      return (
                        <HeaderCell
                          title="Venue"
                          width="min-w-[11.375rem]"
                          arrow={true}
                          postKey="address"
                        />
                      );
                    // case 6:
                    //   return (
                    //     <HeaderCell
                    //       title="Marketplace"
                    //       width="min-w-[9.375rem]"
                    //       arrow={false}
                    //       postKey="marketplace"
                    //     />
                    //   );
                    case 9:
                      return (
                        <HeaderCell
                          title="Event date"
                          width="min-w-[6.875rem]"
                          arrow={true}
                          postKey="event_date"
                        />
                      );
                    case 10:
                      return (
                        <HeaderCell
                          title="Ticket details"
                          width="md:min-w-[15.125rem] min-w-[12.5rem]"
                          arrow={true}
                          postKey="seating_location"
                        />
                      );
                    case 11:
                      return (
                        <HeaderCell
                          title="Qty"
                          width="md:min-w-[6.875rem] min-w-[4.375rem]"
                          arrow={true}
                          postKey="ticket_quantity"
                        />
                      );
                    case 12:
                      return (
                        <HeaderCell
                          title="Ticket type"
                          width="min-w-[6.875rem]"
                          arrow={true}
                          postKey="ticket_type"
                        />
                      );
                    case 13:
                      return (
                        <HeaderCell
                          title="Order status"
                          width="min-w-[7rem]"
                          arrow={true}
                          postKey="order_status"
                        />
                      );
                    case 14:
                      return (
                        isGodAdmin && (
                          <HeaderCell
                            title="Internal order status"
                            width="min-w-[10.875rem]"
                            arrow={false}
                            postKey="order_status"
                          />
                        )
                      );
                    case 15:
                      return (
                        <HeaderCell
                          title="Days to event"
                          width="min-w-[6.875rem]"
                          arrow={true}
                          postKey="days_to_event"
                        />
                      );
                    case 16:
                      return (
                        <HeaderCell
                          title="Marketplace"
                          width="min-w-[15.125rem]"
                          arrow={true}
                          postKey="marketplace"
                        />
                      );
                    case 17:
                      return (
                        <HeaderCell
                          title="Marketplace ID"
                          width="md:min-w-[14.4375rem] min-w-[13.125rem]"
                          arrow={false}
                          // postKey="marketplace"
                        />
                      );
                  }
                })}
                {tabValue === "to_be_fulfilled" && globalLayout?.isMobile && (
                  <th className="w-10 max-md:min-w-[2.5rem] md:w-[5.0625rem] md:hidden"></th>
                )}
                <th
                  className={`relative md:sticky right-0 ml-auto p-0 max-md:min-w-[5rem]  ${
                    tabValue === "to_be_fulfilled"
                      ? "md:w-[7.5625rem] md:min-w-[7.5625rem]"
                      : "md:w-[5.0625rem] md:min-w-[5.0625rem]"
                  }`}
                >
                  <div
                    className={`py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white max-md:hidden`}
                    id="left-shad-list"
                  >
                    <button
                      className="group rounded-l  hover: transition px-[.5625rem] py-2"
                      id="leftArrow"
                      type="button"
                      onClick={() => scroll(-120)}
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={LeftSVG}
                        svgClassName="w-[.375rem] h-[.5625rem]"
                      />
                    </button>
                    <button
                      className="group rounded-r  transition px-[.5625rem] py-2"
                      id="rightArrow"
                      type="button"
                      onClick={() => scroll(120)}
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={RightSVG}
                        svgClassName="w-[.375rem] h-[.5625rem]"
                      />
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <span id="sticky-shadow" className="z-10 hidden "></span>
        <div className="accordion-item bg-white !rounded overflow-hidden">
          <div
            id="EventCollapseBody"
            className="accordion-collapse collapse show"
            aria-labelledby="EventCollapseBody"
          >
            <div className="accordion-body relative">
              <div
                className={`inventory-table sales-table overflow-auto scrollbar-none scrollbar-thumb-violet-800
                 scrollbar-thumb-rounded-full hover-style2 last-auto
                
                 
                 `}
                ref={tableRef}
                onScroll={handleScrollVirtuoso}
                // style={{
                //   height: height > 41 ? height : "auto",
                // }}
                style={{
                  height: height > 41 ? `${height}px` : "auto",
                }}
                id={`tableId1`}
              >
                <TableVirtuoso
                  // context={{ randomHeights: randomHeights }}
                  data={data}
                  endReached={() => {
                    if (paginateData?.current_page < paginateData?.last_page) {
                      layout?.setSalesFilterHandle((current: any) => {
                        let nextPage: number = paginateData?.current_page + 1;
                        return {
                          ...current,
                          page: nextPage,
                          per_page: 20,
                        };
                      });
                    }
                  }}
                  useWindowScroll
                  overscan={200}
                  totalCount={data?.length}
                  id={"sales-lazyload"}
                  components={{
                    Table: Table,
                    TableHead: TableHead,
                    TableRow: (props) => {
                      return (
                        <TableRow
                          {...props}
                          hoveredId={hoveredId}
                          setHoveredId={setHoveredId}
                          isGodAdmin={isGodAdmin}
                          tabValue={tabValue}
                          isRowHighlighted={isRowHighlighted}
                        />
                      );
                    },
                    TableFoot: (props) => {
                      if (
                        data?.length === paginateData?.total &&
                        paginateData?.current_page > 1
                      ) {
                        return (
                          <tfoot
                            className={` text-center text-sm13 py-3 px-2.5 bg-white  border-t`}
                            id={`NoMoreListing`}
                          >
                            <tr className="border-b bg-white">
                              <td
                                colSpan={11}
                                align="center"
                                className="h-[2.8125rem]"
                              >
                                No more items to show
                              </td>
                            </tr>
                          </tfoot>
                        );
                      } else {
                        return null;
                      }
                    },
                    // ScrollSeekPlaceholder,
                  }}
                  // scrollSeekConfiguration={{
                  //   enter: (velocity) => Math.abs(velocity) > 300,
                  //   exit: (velocity) => {
                  //     const shouldExit = Math.abs(velocity) < 10;
                  //     if (shouldExit) {
                  //       // setVisibleRange(["-", "-"]);
                  //     }
                  //     return shouldExit;
                  //   },
                  //   // change: (_velocity, { startIndex, endIndex }) => setVisibleRange([startIndex, endIndex])
                  // }}
                  fixedHeaderContent={() => (
                    <tr className="border-b bg-white">
                      {tabValue === "to_be_fulfilled" && (
                        <th
                          className={`${
                            isGodAdmin
                              ? " min-w-[5rem] max-w-[5rem] flex !sticky left-0 bg-white z-10"
                              : "min-w-[2.5rem] max-w-[2.5rem]"
                          }  h-[2.5rem] items-center justify-center text-center sticky left-0 p-0 z-10 no-scroll checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                          id="checkbox_shad"
                        >
                          {/* <div className="h-full w-10 flex items-center justify-center border-r"> */}
                          {isGodAdmin && (
                            <label
                              className="lock-icon relative w-10 h-full border-r cursor-pointer"
                              htmlFor={`LockRow11`}
                            >
                              <input
                                id={`LockRow11`}
                                type="checkbox"
                                className="invisible absolute "
                                onClick={(e: any) => {
                                  handleOnUnLockAll(e);
                                }}
                              />
                              <img
                                src={
                                  isAllunLock === false
                                    ? LockIcon
                                    : LockIconOpen
                                }
                                alt="LockIcon"
                                className="!w-[.9375rem] !h-[.9375rem]"
                              />
                            </label>
                          )}
                          {/* </div> */}
                          <label
                            className={`h-full flex items-center justify-center border-r cursor-pointer
                            ${isGodAdmin ? "w-10" : "w-full"} `}
                            htmlFor="headCheck"
                          >
                            <input
                              id="headCheck"
                              type="checkbox"
                              checked={isSelectAll}
                              readOnly
                              className={`${
                                isGodAdmin &&
                                isSomeOfAllunLock === false &&
                                "pointer-events-none"
                              }  w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 `}
                              onChange={(data) => handleSelectAll(data)}
                            />
                          </label>
                        </th>
                      )}

                      {getColumnItems().map((columnItem: any) => {
                        switch (columnItem.id) {
                          case 0:
                            return (
                              <HeaderCell
                                title="Team name"
                                width="md:min-w-[9.375rem] min-w-[8.375rem]"
                                arrow={false}
                                padding={
                                  tabValue !== "to_be_fulfilled" && "pl-5"
                                }
                                postKey="team_name"
                              />
                            );
                          case 1:
                            return (
                              <HeaderCell
                                title="Team member"
                                width="md:min-w-[9.375rem] min-w-[7.625rem]"
                                arrow={false}
                                padding={tabValue !== "to_be_fulfilled"}
                                postKey="user_name"
                              />
                            );
                          case 2:
                            return (
                              <HeaderCell
                                title="Order ID"
                                width="md:min-w-[6.875rem] min-w-[5.625rem]"
                                arrow={false}
                                padding={tabValue !== "to_be_fulfilled"}
                                postKey="order_id"
                              />
                            );
                          case 3:
                            return (
                              <HeaderCell
                                title="Order date"
                                width="md:min-w-[10.875rem] min-w-[6.25rem]"
                                arrow={true}
                                // padding={
                                //   tabValue !== "to_be_fulfilled" && "pl-5"
                                // }
                                postKey="order_date_time"
                              />
                            );

                          case 4:
                            return (
                              <HeaderCell
                                // title="Order value"
                                title={
                                  isGodAdmin ? "Listing value" : "Order value"
                                }
                                width="min-w-[6.875rem]"
                                arrow={true}
                                postKey="transaction_value"
                              />
                            );

                          case 7:
                            return (
                              <HeaderCell
                                title="Event"
                                width="md:min-w-[15.125rem] min-w-[12.5rem]"
                                arrow={true}
                                postKey="event_description"
                              />
                            );
                          case 8:
                            return (
                              <HeaderCell
                                title="Venue"
                                width="min-w-[11.375rem]"
                                arrow={true}
                                postKey="address"
                              />
                            );
                          // case 6:
                          //   return (
                          //     <HeaderCell
                          //       title="Marketplace"
                          //       width="min-w-[9.375rem]"
                          //       arrow={false}
                          //       postKey="marketplace"
                          //     />
                          //   );
                          case 9:
                            return (
                              <HeaderCell
                                title="Event date"
                                width="min-w-[6.875rem]"
                                arrow={true}
                                postKey="event_date"
                              />
                            );
                          case 10:
                            return (
                              <HeaderCell
                                title="Ticket details"
                                width="md:min-w-[15.125rem] min-w-[12.5rem]"
                                arrow={true}
                                postKey="seating_location"
                              />
                            );
                          case 11:
                            return (
                              <HeaderCell
                                title="Qty"
                                width="md:min-w-[6.875rem] min-w-[4.375rem]"
                                arrow={true}
                                postKey="ticket_quantity"
                              />
                            );
                          case 12:
                            return (
                              <HeaderCell
                                title="Ticket type"
                                width="min-w-[6.875rem]"
                                arrow={true}
                                postKey="ticket_type"
                              />
                            );
                          case 13:
                            return (
                              <HeaderCell
                                title="Order status"
                                width="min-w-[7rem]"
                                arrow={true}
                                postKey="order_status"
                              />
                            );
                          case 14:
                            return (
                              isGodAdmin && (
                                <HeaderCell
                                  title="Internal order status"
                                  width="min-w-[6.875rem]"
                                  arrow={false}
                                  postKey="order_status"
                                />
                              )
                            );
                          case 15:
                            return (
                              <HeaderCell
                                title="Days to event"
                                width="min-w-[6.875rem]"
                                arrow={true}
                                postKey="days_to_event"
                              />
                            );
                          case 16:
                            return (
                              <HeaderCell
                                title="Marketplace"
                                width="min-w-[15.125rem]"
                                arrow={true}
                                postKey="marketplace"
                              />
                            );
                          case 17:
                            return (
                              <HeaderCell
                                title="Marketplace ID"
                                width="md:w-[14.4375rem] w-[13.125rem]"
                                arrow={false}
                                // postKey="marketplace"
                              />
                            );
                        }
                      })}
                      {tabValue === "to_be_fulfilled" && (
                        <th className="w-10 max-md:min-w-[2.5rem] md:w-[7.5625rem] md:hidden"></th>
                      )}
                      <th
                        className={`relative md:sticky right-0 ml-auto p-0 max-md:min-w-[2.5rem]  ${
                          tabValue === "to_be_fulfilled"
                            ? "md:w-[7.5625rem] md:min-w-[7.5625rem]"
                            : "md:w-[5.0625rem] md:min-w-[5.0625rem]"
                        }`}
                      >
                        <div
                          className={`py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white max-md:hidden`}
                          id="left-shad-list"
                        >
                          <button
                            className="group rounded-l  hover: transition px-[.5625rem] py-2"
                            id="leftArrow"
                            type="button"
                            onClick={() => scroll(-120)}
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={LeftSVG}
                              svgClassName="w-[.375rem] h-[.5625rem]"
                            />
                          </button>
                          <button
                            className="group rounded-r  transition px-[.5625rem] py-2"
                            id="rightArrow"
                            type="button"
                            onClick={() => scroll(120)}
                          >
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                              path={RightSVG}
                              svgClassName="w-[.375rem] h-[.5625rem]"
                            />
                          </button>
                        </div>
                      </th>
                      {isMobile && (
                        <th className="md:hidden sticky right-0 ml-auto p-0 max-md:min-w-[2.5rem] w-10 "></th>
                      )}
                    </tr>
                  )}
                  fixedFooterContent={() => {
                    return (
                      <tr className="border-b bg-white">
                        <td colSpan={0}>Hi</td>
                      </tr>
                    );
                  }}
                  itemContent={(sIndex: any, list: any) => {
                    let matchedView = isRowHighlighted(list);
                    let viewHighlighted = isSameOrderId(showOrder?.data, list);
                    let logHighlighted = isSameOrderId(logsData, list);
                    return (
                      <React.Fragment key={sIndex}>
                        {tabValue === "to_be_fulfilled" && (
                          <td
                            className={`${
                              isGodAdmin
                                ? " min-w-[5rem] max-w-[5rem] flex"
                                : "min-w-[2.5rem] max-w-[2.5rem]"
                            } h-[2.8125rem] text-center sticky left-0 no-scroll p-0 checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%] ${
                              isGodAdmin && list?.isLock === true
                                ? "bg-gray-100"
                                : ""
                            }`}
                            id="checkbox_shad"
                          >
                            {list?.is_legacy_order == "false" && (
                              <>
                                {isGodAdmin && (
                                  <label
                                    className={`lock-icon relative w-10 h-full border-r bg-inherit cursor-pointer pointer-events-auto ${
                                      isGodAdmin && list?.isLock === true
                                        ? "bg-gray-100 #{!important}"
                                        : ""
                                    }`}
                                    htmlFor={`LockRow-${sIndex}`}
                                  >
                                    <input
                                      id={`LockRow-${sIndex}`}
                                      type="checkbox"
                                      className="invisible absolute pointer-events-auto"
                                      onClick={(e: any) => {
                                        handlesingleUnLock(e, sIndex);
                                      }}
                                    />
                                    <img
                                      src={
                                        list?.isLock === true
                                          ? LockIcon
                                          : LockIconOpen
                                      }
                                      className={`pointer-events-auto !h-[.9375rem] ${
                                        list?.isLock === true
                                          ? "!w-[.7188rem]"
                                          : "!w-[.8438rem]"
                                      }`}
                                      alt="LockIcon"
                                    />
                                  </label>
                                )}
                                {/* </div> */}
                                <label
                                  className={`h-full flex items-center justify-center bg-inherit border-r cursor-pointer ${
                                    isGodAdmin && list?.isLock === true
                                      ? "bg-gray-100 #{!important}"
                                      : ""
                                  } 
                                      ${isGodAdmin ? "w-10" : "w-full"} ${
                                    list?.is_txtrade_pending_order &&
                                    "pointer-events-none"
                                  } `}
                                  htmlFor={`checkbox-${sIndex}`}
                                >
                                  {!list?.is_txtrade_pending_order && (
                                    <input
                                      id={`checkbox-${sIndex}`}
                                      type="checkbox"
                                      //readOnly
                                      className={`  cursor-pointer w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 relative`}
                                      checked={list.selected}
                                      onChange={(e: any) =>
                                        handleCheck(e, list.order_id)
                                      }
                                      onClick={(e) =>
                                        handleShift(e, sIndex, data)
                                      }
                                    />
                                  )}
                                </label>
                              </>
                            )}
                          </td>
                        )}
                        {getColumnItems().map((columnItem: any) => {
                          switch (columnItem.id) {
                            case 0:
                              return (
                                <DataCell
                                  padding={
                                    tabValue !== "to_be_fulfilled" && "pl-5"
                                  }
                                  value={list?.team_name}
                                  classname="block"
                                  width="md:w-[7.75rem] w-[6.75rem] "
                                />
                              );
                            case 1:
                              return (
                                <DataCell
                                  padding={tabValue !== "to_be_fulfilled"}
                                  value={list?.user_name}
                                  classname="block"
                                  width="md:w-[7.75rem] w-[6.875rem] "
                                />
                              );
                            case 2:
                              return (
                                <DataCell
                                  padding={tabValue !== "to_be_fulfilled"}
                                  value={list?.order_id}
                                  classname="block"
                                  width="md:w-[5.25rem] w-[4.875rem]"
                                />
                              );

                            case 3:
                              return (
                                <DataCell
                                  // padding={
                                  //   tabValue !== "to_be_fulfilled" && "pl-5"
                                  // }
                                  value={moment(list?.order_date_time).format(
                                    "DD/MM/YYYY"
                                  )}
                                  classname="block"
                                  width="md:w-[10.125rem] w-[5.5rem]"
                                />
                              );
                            case 4:
                              return (
                                <DataCell
                                  value={`${
                                    SYMBOLS?.[list?.ticket_currency]
                                  }${decimalNumber(list?.transaction_value)}`}
                                  classname="block"
                                  width="w-[6.125rem]"
                                />
                              );
                            case 7:
                              return (
                                <DataCell
                                  value={list?.event_description}
                                  width="md:w-[14.375rem] w-[11.75rem]"
                                  noCapitalize={"yes"}
                                />
                              );
                            case 8:
                              return (
                                <DataCell
                                  value={list?.address}
                                  width="w-[10.625rem]"
                                />
                              );

                            case 9:
                              return (
                                <DataCell
                                  value={moment(list?.event_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                />
                              );
                            case 10:
                              return (
                                <DataCell
                                  value={list?.seating_location}
                                  width="md:w-[14.375rem] w-[11.75rem]"
                                />
                              );
                            case 11:
                              return (
                                <DataCell
                                  value={
                                    list?.ticket_quantity
                                      ? list?.ticket_quantity
                                      : "0"
                                  }
                                  width="md:w-[6.125rem] w-[3.625rem]"
                                />
                              );
                            case 12:
                              return (
                                <DataCell
                                  value={ticketTypeRenameForSalesReport(
                                    list?.ticket_type,
                                    list?.sub_ticket_type
                                  )}
                                  width="w-[6.125rem]"
                                  noCapitalize={"yes"}
                                />
                              );
                            case 13:
                              return (
                                <DataCell
                                  value={list?.order_status}
                                  width="w-[6.25rem]"
                                />
                              );
                            case 14:
                              return (
                                isGodAdmin && (
                                  <DataCell
                                    value={
                                      list?.internal_order_status
                                        ? list?.internal_order_status
                                        : "-"
                                    }
                                    width="w-[10.125rem]"
                                  />
                                )
                              );
                            case 15:
                              return (
                                <DataCell
                                  value={list?.time_to_event}
                                  width="w-[6.125rem]"
                                />
                              );
                            case 16:
                              return (
                                <>
                                  {/* MARKETPLACE */}

                                  <DataCell
                                    value={list?.marketplace}
                                    width="w-[14.4375rem]"
                                  />
                                </>
                              );
                            case 17:
                              return (
                                <>
                                  {/* MARKETPLACE */}

                                  <DataCell
                                    value={list?.external_order_id}
                                    width="md:w-[14.4375rem] w-[12.375rem]"
                                  />
                                </>
                              );
                          }
                        })}

                        {/* <td className="sticky right-0 ml-auto p-0 icon_td">
                          <React.Fragment key={`${sIndex}`}>
                            <div
                              // className={`border-l bg-white inner_td
                              className={` bg-white border-l left-shad  inner_td 

                                  
                                   ${
                                     list.selected && !matchedView
                                       ? "bg-indigo-500 bg-opacity-[7%]"
                                       : ""
                                   }`}
                              // onMouseEnter={() => {
                              //   setHoveredId(list.order_id);
                              // }}
                              // onMouseLeave={() => setHoveredId(null)}
                            >
                              <div
                                // className={`flex left-shad relative h-full  ${
                                className={`flex  relative h-full  ${
                                  list.order_id === hoveredId &&
                                  !matchedView &&
                                  !list.selected
                                    ? "bg-indigo-500 bg-opacity-[7%]"
                                    : ""
                                } `}
                                id="left-shad-list"
                              >
                                {list?.is_legacy_order == "true" && (
                                  <button className="absolute top-1/2 -translate-y-1/2 right-full text-white text-xxs bg-violet-500 px-[.4375rem] py-1 leading-3 rounded-l whitespace-nowrap cursor-default">
                                    Legacy order
                                  </button>
                                )}

                                {tabValue === "to_be_fulfilled" && (
                                  <button
                                    className={`group flex my-button items-center justify-center p-1 md:p-1 w-8 md:w-10 border-r ${
                                      matchedView &&
                                      "bg-indigo-500 bg-opacity-[7%]"
                                    }`}
                                    type="button"
                                    id={`record-upload-tooltip${sIndex}`}
                                    ref={ref}
                                    onClick={(e: any) => {
                                      list?.is_legacy_order == "false"
                                        ? uploadEticket(list)
                                        : handleOrderView(list, sIndex);
                                    }}
                                  >
                                    <IKTSVG
                                      className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition"
                                      path={
                                        list?.is_legacy_order == "false"
                                          ? UploadSVG
                                          : Warning1
                                      }
                                      svgClassName={
                                        list?.is_legacy_order == "true"
                                          ? "w-3.5 h-3.5"
                                          : ""
                                      }
                                    />
                                    <Tooltip
                                      anchorId={`record-upload-tooltip${sIndex}`}
                                      content={`${
                                        list?.is_legacy_order == "false"
                                          ? `Upload Ticket`
                                          : `Fulfill via marketplace`
                                      }`}
                                      place="top"
                                      variant="light"
                                      className="bg-white !py-0.5 !px-1.5 !text-xs font-medium z-10 mt-1.5"
                                    />
                                  </button>
                                )}

                                
                                <button
                                  className={`group my-buttonflex items-center justify-center p-1 md:p-1 w-8 md:w-10  
                                      ${
                                        matchedView &&
                                        "bg-indigo-500 bg-opacity-[7%]"
                                      }
                                      ${
                                        tabValue !== "to_be_fulfilled" && ""
                                      } md:flex-auto flex-1 `}
                                  className={`group flex my-button items-center justify-center p-1 md:p-1 w-8 md:w-11 h-[2.813rem] border-b border-r ${
                                    matchedView &&
                                    "bg-indigo-500 bg-opacity-[7%]"
                                  } 
                                  `}
                                  type="button"
                                  id={`record-view-tooltip${sIndex}`}
                                  onClick={() => {
                                    handleOrderView(list, sIndex);
                                  }}
                                >
                                  <Tooltip
                                    anchorId={`record-view-tooltip${sIndex}`}
                                    content={`${
                                      showOrder?.data?.order_id === hoveredId
                                        ? "Close"
                                        : "View"
                                    }`}
                                    place="top"
                                    variant="light"
                                    className="bg-white !py-0.5 !px-1.5 !text-xs font-medium z-10 mt-1.5"
                                  />
                                  <IKTSVG
                                    className={`flex items-center justify-center ${
                                      matchedView
                                        ? "fill-indigo-500"
                                        : "fill-violet-500"
                                    } ${
                                      matchedView
                                        ? "group-hover:fill-indigo-500"
                                        : "group-hover:fill-indigo-500"
                                    } transition`}
                                    path={EyeSVG}
                                  />
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </td> */}
                        {tabValue === "to_be_fulfilled" &&
                          globalLayout?.isMobile && (
                            <td
                              className={`flex w-10 min-h-[2.8125rem] h-[2.8125rem] md:hidden`}
                            >
                              <div className="left-shad flex h-full w-full">
                                {list?.is_txtrade_pending_order ? (
                                  <>
                                    <button
                                      className={`group flex my-button items-center justify-center p-1 md:p-1 w-10 border-l `}
                                      type="button"
                                      data-tooltip-id={`infoTooltip3-${sIndex}`}
                                    >
                                      <IKTSVG
                                        path={WarningIcon}
                                        className="fill-rose-500 ml-1"
                                        svgClassName="w-4 h-4"
                                      />
                                      <TooltipPortal
                                        id={`infoTooltip3-${sIndex}`}
                                        content={"Attendee details pending"}
                                        className="text-center !bg-white !opacity-100  !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className={`group flex my-button items-center justify-center p-1 md:p-1 w-10 border-l `}
                                    type="button"
                                    id={`record-upload-tooltip${sIndex}`}
                                    ref={ref}
                                    onClick={(e: any) => {
                                      setLogsData(null);
                                      list?.is_legacy_order == "false"
                                        ? uploadEticket(list)
                                        : handleOrderView(list, sIndex);
                                    }}
                                  >
                                    <IKTSVG
                                      className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition"
                                      path={
                                        list?.is_legacy_order == "false"
                                          ? UploadSVG
                                          : Warning1
                                      }
                                      svgClassName={
                                        list?.is_legacy_order == "true"
                                          ? "w-3.5 h-3.5"
                                          : "w-3.5 h-3"
                                      }
                                    />
                                    <Tooltip
                                      anchorId={`record-upload-tooltip${sIndex}`}
                                      content={`${
                                        list?.is_legacy_order == "false"
                                          ? list?.ticket_type === "mobile"
                                            ? "Confirm transfer"
                                            : list?.ticket_type === "eticket" ||
                                              list?.ticket_type === "PDF"
                                            ? list?.ticket_quantity > 1
                                              ? "Fulfill tickets"
                                              : "Fulfill ticket"
                                            : list?.ticket_type ===
                                              "mobile-link"
                                            ? "Confirm mobile link"
                                            : list?.ticket_type === "paper" ||
                                              list?.ticket_type ===
                                                "season-cards"
                                            ? "Confirm shipping"
                                            : `Upload Ticket`
                                          : `Fulfill via marketplace`
                                      }`}
                                      place={`${sIndex === 0 ? "left" : "top"}`}
                                      variant="light"
                                      className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                                        sIndex === 0 ? "mt-0" : "mt-1.5"
                                      }`}
                                    />
                                  </button>
                                )}
                              </div>
                            </td>
                          )}
                        <td
                          className={`relative md:sticky right-0 ml-auto p-0 icon_td w-10 ${
                            tabValue === "to_be_fulfilled"
                              ? ""
                              : "max-md:min-w-[2.5rem]"
                          }`}
                        >
                          <React.Fragment key={`${sIndex}`}>
                            <div
                              className={`${
                                list.selected && !matchedView
                                  ? "bg-indigo-500 bg-opacity-[7%]"
                                  : ""
                              } ${
                                matchedView && "bg-indigo-500 bg-opacity-[7%]"
                              }
                              ${
                                isGodAdmin &&
                                list?.isLock === true &&
                                tabValue === "to_be_fulfilled" &&
                                "pointer-events-none !bg-gray-100"
                              }
                              
                              `}
                            >
                              <div
                                className={`flex ${
                                  tabValue === "to_be_fulfilled"
                                    ? ""
                                    : "left-shad"
                                } relative w-full h-full border-l`}
                                id="left-shad-list"
                              >
                                {list?.is_legacy_order == "true" && (
                                  <button className="absolute top-1/2 -translate-y-1/2 right-full text-white text-xxs bg-violet-500 px-[.4375rem] py-1 leading-3 rounded-l whitespace-nowrap cursor-default">
                                    Legacy order
                                  </button>
                                )}
                                {tabValue === "to_be_fulfilled" &&
                                list?.is_txtrade_pending_order ? (
                                  <>
                                    <button
                                      className={`group flex my-button items-center justify-center p-1 md:p-1 w-10 border-l `}
                                      type="button"
                                      data-tooltip-id={`infoTooltip3-${sIndex}`}
                                    >
                                      <IKTSVG
                                        path={WarningIcon}
                                        className="fill-rose-500 ml-1"
                                        svgClassName="w-4 h-4"
                                      />
                                      <TooltipPortal
                                        id={`infoTooltip3-${sIndex}`}
                                        content={"Attendee details pending"}
                                        className="text-center !bg-white !opacity-100  !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
                                      />
                                    </button>
                                  </>
                                ) : (
                                  tabValue === "to_be_fulfilled" &&
                                  !list?.is_txtrade_pending_order && (
                                    <button
                                      className={`group max-md:hidden flex my-button items-center justify-center p-1 md:p-1 w-10 border-r `}
                                      type="button"
                                      id={`record-upload-tooltip${sIndex}`}
                                      ref={ref}
                                      onClick={(e: any) => {
                                        setLogsData(null);
                                        list?.is_legacy_order == "false"
                                          ? uploadEticket(list)
                                          : handleOrderView(list, sIndex);
                                      }}
                                    >
                                      <IKTSVG
                                        className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition"
                                        path={
                                          list?.is_legacy_order == "false"
                                            ? UploadSVG
                                            : Warning1
                                        }
                                        svgClassName={
                                          list?.is_legacy_order == "true"
                                            ? "w-3.5 h-3.5"
                                            : "w-3.5 h-3"
                                        }
                                      />
                                      <Tooltip
                                        anchorId={`record-upload-tooltip${sIndex}`}
                                        content={`${
                                          list?.is_legacy_order == "false"
                                            ? list?.ticket_type === "mobile"
                                              ? "Confirm transfer"
                                              : list?.ticket_type ===
                                                  "eticket" ||
                                                list?.ticket_type === "PDF"
                                              ? list?.ticket_quantity > 1
                                                ? "Fulfill tickets"
                                                : "Fulfill ticket"
                                              : list?.ticket_type ===
                                                "mobile-link"
                                              ? "Confirm mobile link"
                                              : list?.ticket_type === "paper" ||
                                                list?.ticket_type ===
                                                  "season-cards"
                                              ? "Confirm shipping"
                                              : `Upload Ticket`
                                            : `Fulfill via marketplace`
                                        }`}
                                        place={`${
                                          sIndex === 0 ? "left" : "top"
                                        }`}
                                        variant="light"
                                        className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                                          sIndex === 0 ? "mt-0" : "mt-1.5"
                                        }`}
                                      />
                                    </button>
                                  )
                                )}
                                <button
                                  className={`group my-button flex items-center justify-center p-1 md:p-1 w-8 md:w-10 border-r md:flex-auto flex-1 ${
                                    isDateBeforeValidLogDate(
                                      list?.order_date_time
                                    ) && !isGodAdmin
                                      ? "pointer-events-none"
                                      : ""
                                  }`}
                                  disabled={
                                    isDateBeforeValidLogDate(
                                      list?.order_date_time
                                    ) && !isGodAdmin
                                  }
                                  type="button"
                                  id={`record-logs-tooltip${sIndex}`}
                                  onClick={() => {
                                    setActiveTab(LOG_TAB_KEYS.ORDER);
                                    setLogsData(list);
                                    setShowOrder({
                                      show: false,
                                      data: "",
                                      index: "",
                                    });
                                  }}
                                >
                                  <Tooltip
                                    anchorId={`record-logs-tooltip${sIndex}`}
                                    content={`Log`}
                                    place={`${sIndex === 0 ? "left" : "top"}`}
                                    variant="light"
                                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                                      sIndex === 0 ? "mt-0" : "mt-1.5"
                                    }`}
                                  />
                                  <IKTSVG
                                    className={`flex items-center justify-center ${
                                      isDateBeforeValidLogDate(
                                        list?.order_date_time
                                      ) && !isGodAdmin
                                        ? "fill-gray-500"
                                        : logHighlighted
                                        ? "fill-indigo-500"
                                        : "fill-violet-500"
                                    } ${
                                      logHighlighted
                                        ? "group-hover:fill-indigo-500"
                                        : "group-hover:fill-indigo-500"
                                    } transition`}
                                    path={logIcon}
                                    svgClassName="w-4 h-4"
                                  />
                                </button>
                                {/* VIEW ORDER */}
                                <button
                                  className={`group my-button max-md:hidden flex items-center justify-center p-1 md:p-1 w-8 md:w-10  
                                      
                                      ${
                                        tabValue !== "to_be_fulfilled" && ""
                                      } md:flex-auto flex-1 `}
                                  type="button"
                                  id={`record-view-tooltip${sIndex}`}
                                  onClick={() => {
                                    setLogsData(null);
                                    handleOrderView(list, sIndex);
                                  }}
                                >
                                  <Tooltip
                                    anchorId={`record-view-tooltip${sIndex}`}
                                    content={`${
                                      showOrder?.data?.order_id === hoveredId
                                        ? "Close"
                                        : "View"
                                    }`}
                                    place={`${sIndex === 0 ? "left" : "top"}`}
                                    variant="light"
                                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                                      sIndex === 0 ? "mt-0" : "mt-1.5"
                                    }`}
                                  />
                                  <IKTSVG
                                    className={`flex items-center justify-center ${
                                      viewHighlighted
                                        ? "fill-indigo-500"
                                        : "fill-violet-500"
                                    } ${
                                      viewHighlighted
                                        ? "group-hover:fill-indigo-500"
                                        : "group-hover:fill-indigo-500"
                                    } transition`}
                                    svgClassName="w-4 h-[.6875rem]"
                                    path={EyeSVG}
                                  />
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </td>
                        {isMobile && (
                          <td
                            className={`sticky right-0 ml-auto p-0 icon_td max-md:min-w-[2.5rem] md:hidden `}
                          >
                            <div className="-ml-px border-l w-full">
                              <button
                                className={`group my-button flex items-center justify-center p-1 md:p-1 w-full md:w-10 ${
                                  tabValue !== "to_be_fulfilled" && ""
                                } md:flex-auto flex-1 `}
                                type="button"
                                id={`record-view-tooltip${sIndex}`}
                                onClick={() => {
                                  setLogsData(null);
                                  handleOrderView(list, sIndex);
                                }}
                              >
                                <Tooltip
                                  anchorId={`record-view-tooltip${sIndex}`}
                                  content={`${
                                    showOrder?.data?.order_id === hoveredId
                                      ? "Close"
                                      : "View"
                                  }`}
                                  place={`${sIndex === 0 ? "left" : "top"}`}
                                  variant="light"
                                  className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                                    sIndex === 0 ? "mt-0" : "mt-1.5"
                                  }`}
                                />
                                <IKTSVG
                                  className={`flex items-center justify-center ${
                                    viewHighlighted
                                      ? "fill-indigo-500"
                                      : "fill-violet-500"
                                  } ${
                                    viewHighlighted
                                      ? "group-hover:fill-indigo-500"
                                      : "group-hover:fill-indigo-500"
                                  } transition`}
                                  svgClassName="w-4 h-[.6875rem]"
                                  path={EyeSVG}
                                />
                              </button>
                            </div>
                          </td>
                        )}
                        {/* </tr> */}
                      </React.Fragment>
                    );
                  }}
                />

                {/* ORDER VIEW PAGE */}
                {showOrder?.show && (
                  <OrderDetail
                    canvasRef={canvasRef}
                    show={showOrder}
                    ticketUploadData={layout.ticketUploadData}
                    tabValue={tabValue}
                    handleShow={(data: any) => {
                      setShowOrder(data);
                    }}
                    handleOrder={(data: any) => {
                      uploadEticket(data);
                    }}
                    setShowOrder={setShowOrder}
                    isGodAdmin={isGodAdmin}
                    optionsData={optionsData}
                    refetchListing={refetch}
                  />
                )}

                {layout.ticketUploadData && (
                  <ETicketUpload
                    data={layout.ticketUploadData}
                    handleUploadTicket={() => {
                      handleSingleUpload();
                    }}
                    optionsData={optionsData}
                    instructionLetterTemplates={instructionLetterTemplates}
                    setInstructionLetterTemplates={
                      setInstructionLetterTemplates
                    }
                  />
                )}

                {logsData && (
                  <LogDetailsPopUp
                    logsData={logsData}
                    setLogsData={setLogsData}
                    request={getSalesLogListing(logsData)}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                )}
              </div>
            </div>

            <div
              id="custom-loader"
              className={`accordion bg-white font-medium pt-2.5  border relative z-[1] `}
            >
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
              ></div>
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataTable;
