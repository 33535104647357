import Cookies from "js-cookie";
import PageTitle from "../../components/core/PageTitle";
import { grettingMessage } from "../../helpers/Functions";
import Dashboard from "./DashBoard";
import { DashboardProvider } from "./core/DashboardProvider";

const DashboardWrapper = () => {
  var authDataString = Cookies.get("authData") as any;
  authDataString = authDataString && JSON.parse(authDataString);
  //Gretting Message
  let greet = grettingMessage();

  const userFirstName = authDataString?.name as string;
  const firstName = authDataString?.firstname as string;
  return (
    <>
      <DashboardProvider>
        <PageTitle
          title={`${greet}, ${firstName ? firstName : userFirstName}`}
        />
        <Dashboard />
      </DashboardProvider>
    </>
  );
};

export default DashboardWrapper;
