import {
  addCommaInNumber,
  decimalNumber,
  getCurrencySymbol,
} from "../../../helpers/Functions";
import FilterCountBox from "./FilterCountBox";

const PayoutsFilterCountBoxsWrapper = ({
  payoutsOverDetails,
  loading,
}: any) => {
  
  const value = `${getCurrencySymbol(
    payoutsOverDetails?.currency
  )}${decimalNumber(payoutsOverDetails?.pay_out_value)}`;


  return (
    <>
      <FilterCountBox
        value={payoutsOverDetails?.pay_out_value ? value : "0"}
        label="Pay out value"
        isCheckbox={false}
        loading={loading}
      />
      <FilterCountBox
        value={
          payoutsOverDetails?.statement
            ? addCommaInNumber(payoutsOverDetails?.statement)
            : "0"
        }
        label="Statements"
        isCheckbox={false}
        loading={loading}
      />
    </>
  );
};

export default PayoutsFilterCountBoxsWrapper;
