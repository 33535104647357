/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _, { debounce, isArray, size } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SVG from "react-inlinesvg";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Collapse from "../../assets/media/icons/other_icons/Collapse.svg";
import { default as refreshSVG } from "../../assets/media/icons/other_icons/refresh.svg";

import close from "../../assets/media/icons/other_icons/Clear.svg";
import stubhub from "../../assets/media/icons/other_icons/Stubhub.svg";
import uploadIcon from "../../assets/media/icons/other_icons/Upload.svg";
import checkIcon from "../../assets/media/icons/other_icons/check.svg";
import fieldIcon from "../../assets/media/icons/other_icons/fields.svg";
import handIcon from "../../assets/media/icons/other_icons/hand-icon.svg";
import infoIcon from "../../assets/media/icons/other_icons/info.svg";
import Plus from "../../assets/media/icons/other_icons/plus.svg";
import Refresh from "../../assets/media/icons/other_icons/refresh.svg";
import ticketped from "../../assets/media/icons/other_icons/ticketped.svg";
import ticombo from "../../assets/media/icons/other_icons/ticombo.svg";
import txnetwork from "../../assets/media/icons/other_icons/tx-network.svg";
import Calendar from "../../assets/media/icons/standard_icons/calendar.svg";
import ClockOutline from "../../assets/media/icons/standard_icons/clock-outline.svg";
import Cross from "../../assets/media/icons/standard_icons/cross.svg";
import GeoIcon from "../../assets/media/icons/standard_icons/geo_icon.svg";
import { LayoutContext } from "../../components/core/LayoutProvider";
import PageTitle from "../../components/core/PageTitle";
import Checkbox from "../../components/formComponent/Checkbox";
import DatePickerField from "../../components/formComponent/DatePickerField";
import DropDownField from "../../components/formComponent/DropDownField";
import MultiDropDownField from "../../components/formComponent/MultiDropDownField";
import PriceField from "../../components/formComponent/PriceField";
import Textfield from "../../components/formComponent/Textfield";
import { IKTSVG } from "../../components/ui/IKTSVG";
import { KTSVG } from "../../components/ui/KTSVG";
import ProgressBardLoader from "../../components/ui/ProgressBardLoader";
import { extractOptions, splitTypeOptions } from "../../helpers/AssetHelpers";
import {
  colorForMeter,
  decimalNumber,
  handleKeyDown,
  priceCalculation,
  removeSelectAll,
  ticketTypeRename,
  uniqueByNameWithPriority,
} from "../../helpers/Functions";
import { showAlert } from "../../helpers/ShowAlert";
import {
  ADD_INVENTORY_KEYBOARD_ITEMS,
  TIMEFRAMES,
  deliveryTypeOrders,
  performerFilters,
} from "../../helpers/const";
import useScrollIntoViewOnFocus from "../../hooks/useScrollIntoViewOnFocus";
import useDebounce from "../add_inventory/core/useDebounce";
import ListingQualityPopup from "../inventory/widgets/ListingQualityPopup";
import TooltipPortal from "../tx_trade/table/component/TooltipPortal";
import HeaderfilterContainer from "./component/HeaderfilterContainer";
import { AddInventoryContext } from "./core/AddInventoryProvider";
import {
  calculateDisplayPrice,
  checkIfClonetoNewActive,
  convertDate,
  convertTime,
  footer_dynamic,
  generateUniqueId,
  get2DaysBeforeDateOfEvent,
  keyBoardVisiblityDynamicMessage,
  keyBoardVisiblityDynamicMessage1,
  maxFileCondition,
  ticketsInHandValueAssigned,
  visibilityMeterCalculationForKeyBoard,
  visiblityMeter,
} from "./core/_functions";
import { Messages } from "./core/_messages";
import {
  fetchMarketplacePriceWithSection,
  getEventDetails,
  getInventoryOptions,
  inventoryAlgoliaSearch,
} from "./core/_requsets";
import SearchField from "./helpers/SearchField/SearchField";
import EventTable from "./table/EventTable";
import PerformerTable from "./table/PerformerTable";
import EditInventoryMobileView from "./table/components/EditAddInventoryMobileView";
import ETicketUploadKeyboard from "./widgets/ETicketUploadKeyboard";
import PusherSetup from "./widgets/PusherSetup";
import RequestEvent from "./widgets/RequestEvent";

const AddInventory = React.memo(({ list, setList, virtuosoRef }: any) => {
  useScrollIntoViewOnFocus();
  let PPE_Enabled;
  const [eventOrPerformerId, setEventOrPerformerId] = useState<any>("");
  // const [triggered, layout.setTriggered] = useState<boolean>(false);
  const layout = useContext(AddInventoryContext);
  const coreLayout = useContext(LayoutContext);
  const [debouncedLoader, setDebouncedLoader] = useState(false);

  const conversation_rate = coreLayout?.conversationRate;
  const priceFactor = Number(
    coreLayout?.allowedAccess?.price_suggestion_factor
  );

  let target_currency = coreLayout.currencyLabel;
  const triggered = layout.triggered;
  const [priceFiledFocused, setPriceFieldFocused] = useState<any>();

  const events = list;
  const performer = layout.performer;
  const [PPE, setPPE] = useState(Boolean);
  const isTableEditable: [] = layout.isTableEditable;
  let searchedValue = layout.searchFieldValue;

  const location = useLocation();
  let isDisabledListing =
    _.size(layout?.isTableEditable) > 0 || layout.isDeleteActive;

  const [defaultTicketType, setDefaultTicketType] = useState<any>(
    //   {
    //   id: "eticket",
    //   name: "E-Ticket",
    //   subtypes: [],
    // }
    null
  );
  const [isOpenListingQualityPopup, setIsOpenListingQualityPopup] =
    useState<any>(false);
  const defaultSplitType = {
    id: "ANY",
    name: "No Preference",
  };
  const [ticketType, setTicketType] = useState<any>(defaultTicketType);
  const [subTicketType, setSubTicketType] = useState<any>(ticketType);

  const [initialKeyboardField, setInitialKeyboardField] = useState<any>({
    id: "",
    name: "",
    date: "",
    time: "",
    address: "",
    hideError: false,
    eventRecords: [
      {
        errorInAnyFields: false,
        marketplaces: [],
        ticketType: defaultTicketType,
        sub_ticket_type: defaultTicketType?.subtypes?.[0],
        selected: false,
        newRow: true,
        splitType: defaultSplitType,
        MULTIPLES: "",
        maxDisplayQuantity: "",
        category: {},
        section: {},
        row: "",
        firstRow: "",
        firstSeat: "",
        faceValue: {},
        proceedValue: {},
        ppe: false,
        ticketsInHands: false,
        dateToShip: null,
        benefits: [],
        restrictions: [],
        deliveryType: [],
        quantity: "",
        visibility: 0,
        oldVisibility: 0,
      },
    ],
  });
  // const initialKeyboardField = {
  //   id: "",
  //   name: "",
  //   date: "",
  //   time: "",
  //   address: "",
  //   hideError: false,
  //   eventRecords: [
  //     {
  //       errorInAnyFields: false,
  //       marketplaces: [],
  //       ticketType: defaultTicketType,
  //       sub_ticket_type: defaultTicketType?.subtypes,
  //       selected: false,
  //       newRow: true,
  //       splitType: defaultSplitType,
  //       MULTIPLES: "",
  //       maxDisplayQuantity: "",
  //       category: {},
  //       section: {},
  //       row: "",
  //       firstRow: "",
  //       firstSeat: "",
  //       faceValue: {},
  //       proceedValue: {},
  //       ppe: false,
  //       ticketsInHands: false,
  //       dateToShip: null,
  //       benefits: [],
  //       restrictions: [],
  //       deliveryType: [],
  //       quantity: "",
  //       visibility: 0,
  //       oldVisibility: 0,
  //     },
  //   ],
  // };
  // const [keyboardField, setKeyboardField] = useState<any>(initialKeyboardField);
  const keyboardField = layout.keyboardField;
  const [section, setSection] = useState<any>([]);
  const [sellMultipleSplitType, setSellMultipleSplitType] = useState<any>([]);
  const [paginateData, setPaginateData] = useState<any>("");
  // const [id, setId] = useState<any>("");
  const [id, setId] = useState<any>({
    id: "",
    indexId: "",
  });
  const [searchFieldValue, setSearchedValue] = useState<any>("");
  const [customLoader, setCustomLoader] = useState<boolean>(false);
  const [addlisitngBtnDisabled, setAddListingBtnDisabled] = useState<any>();
  const debouncedSearch = useDebounce(searchFieldValue, 600);
  const [hoverInfo, setHoverInfo] = useState<any>(false);

  const ref: any = useRef();
  const benefitRef: any = useRef();
  const restrictionRef: any = useRef();
  const spittingRef: any = useRef();
  const multipleRef: any = useRef();
  const ticketTypeRef: any = useRef();
  const deliveryTypeRef: any = useRef();
  const categoryRef: any = useRef();
  const sectionRef: any = useRef();
  const quantityRef: any = useRef();
  const displayQuantityRef: any = useRef();
  const rowRef: any = useRef();
  const firstSeatRef: any = useRef();
  const faceValueRef: any = useRef();
  const proceedValueRef: any = useRef();

  const [saveAsBtnText, setSaveAsBtnText] = useState<String>("Save as Draft");
  const [enabled, setEnabled] = useState<boolean>(false);

  const [action, setAction] = useState<string>("");

  const [checkExchange, setCheckExchange] = useState<any>();

  const bulkActionRef: any = useRef(null);

  const [algoliaOptions, setAlgoliaOptions] = useState<any>();

  // NEED TO DISABLED
  let isDisabled: boolean = false;
  if (
    _.size(layout?.isTableEditable) > 0 ||
    layout.isDeleteActive ||
    !layout.searchFieldValue?.id
  ) {
    isDisabled = true;
  } else {
    isDisabled = false;
  }

  // NEED TO DISABLED for add all events
  let isDiabled2: boolean = false;
  if (
    _.size(layout?.isTableEditable) > 0 ||
    layout.isDeleteActive ||
    checkIfClonetoNewActive(list)
  ) {
    isDiabled2 = true;
  } else {
    isDiabled2 = false;
  }

  // PRELOAD IMAGES STATES
  const [areImagesLoaded, setAreImagesLoaded] = useState<any>(false);
  const svgImageUrls = [stubhub, ticombo, ticketped, txnetwork];

  // PRELOAD IMAGES WHEN PAGE LOADS
  useEffect(() => {
    // Preload the images
    const imagePromises = svgImageUrls.map((url) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = url;
        image.onload = resolve;
        image.onerror = reject;
      });
    });
    Promise.all(imagePromises)
      .then(() => setAreImagesLoaded(true))
      .catch((error) => {
        // console.error("Error preloading images:", error);
        setAreImagesLoaded(false);
      });
  }, []);

  const { isLoading, isError, data, error, refetch, status } = useQuery(
    [
      "inventory-search",
      paginateData,
      layout.performerKeyboardField,
      debouncedSearch.per_page,
      debouncedSearch.id,
      debouncedSearch.value,
      action,
      checkExchange,
    ],
    () =>
      inventoryAlgoliaSearch(
        checkExchange,
        layout.eventOrPerformer,
        id,
        paginateData,
        searchFieldValue,
        layout.performerKeyboardField,
        action,
        layout.searchFieldValue
      ),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // enabled: enabled,
      enabled: debouncedSearch ? true : false,
      onError: (err) => {},
    }
  );

  // const keyboardEvent = layout.keyboardField;
  const keyboardEvent = React.useMemo(
    () => layout.searchFieldValue,
    [layout.searchFieldValue?.id]
  );
  const { isLoading: priceLoader } = useQuery(
    ["proceedprice-api", keyboardEvent],
    () => fetchMarketplacePriceWithSection(keyboardEvent),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // enabled: enabled,
      enabled:
        layout.searchFieldValue?.id &&
        layout.keyboardField?.id &&
        !layout.keyboardField?.markeInsightsForAvgPrice
          ? true
          : false,
      onError: (err) => {},
      onSettled: (data, err) => {
        layout.setKeyboardField((pre: any) => {
          return {
            ...pre,
            markeInsightsForAvgPrice: data?.data?.results?.sections,
          };
        });
        setProceedPriceLoader((prevState: any) => ({
          ...prevState,
          status: false,
          count: 1,
        }));
        if (list?.length > 0) {
          setList((pre: any) => {
            const newList = pre?.map((obj: any) => {
              if (
                obj?.id === keyboardField?.id &&
                !obj.markeInsightsForAvgPrice
              ) {
                return {
                  ...obj,
                  markeInsightsForAvgPrice: data?.data?.results?.sections,
                };
              } else {
                return {
                  ...obj,
                };
              }
            });

            return newList;
          });
        }
      },
    }
  );

  useEffect(() => {
    if (data && data?.length > 0) {
      setAlgoliaOptions(data);
    }
  }, [data]);

  useEffect(() => {
    document.addEventListener("mousedown", outSideClick);
    return () => {
      document.removeEventListener("mousedown", outSideClick);
    };
  }, []);

  let InventoryData = data && data;
  let defaultKeyboardItemState = ADD_INVENTORY_KEYBOARD_ITEMS;

  /**
   * EVENT HANDLING OF SEARCH STRING
   * @param searchValue
   */
  const debouncedChangeHandler = useMemo(() => {
    return _.debounce((searchValue: any) => {
      setSearchedValue({
        value: searchValue,
        id: "normal",
      });
      setEventOrPerformerId(null);
    }, 300);
  }, []);

  const outSideClick = (e: any) => {
    if (!bulkActionRef?.current?.contains(e.target)) {
      let bulkAction: any = document.querySelectorAll(
        'span[data-te-target="#bulkaction"]:not([data-te-collapse-collapsed])'
      )?.[0];
      if (bulkAction) {
        bulkAction.click();
      }
    }
  };

  // IF RECORDS NOT AVAILABLE IN PERFORMER/VENUE - ALERT MSG
  useEffect(() => {
    let hitsExists =
      action === "searchByPerformerId" &&
      InventoryData &&
      Array.isArray(InventoryData) &&
      InventoryData.length > 0 &&
      InventoryData.filter((hits: any) => hits);
    if (
      action === "searchByPerformerId" &&
      hitsExists &&
      hitsExists?.[0] &&
      hitsExists?.[0]?.hits.length === 0
    ) {
      showAlert("Records are not available", true);
    }
  }, [InventoryData, action]);

  const existInLocalstorage = (name: string) => {
    return localStorage.getItem(name)
      ? JSON.parse(localStorage.getItem(name) as string)
      : null;
  };

  // CHECK IF DATA NOT EXISTS IN LOCALSTORAGE
  // useEffect(() => {
  //   if (list && list.length === 0) {
  //     localStorage.removeItem("events");
  //   }
  // }, [list]);

  // CHECK IF DATA EXISTS IN LOCALSTORAGE
  useEffect(() => {
    if (
      existInLocalstorage("events") &&
      existInLocalstorage("events").length > 0 &&
      !layout.isPublish
    ) {
      let existEvent = existInLocalstorage("events");
      layout.setSearchFieldValue(existEvent?.[0]);

      setEnabled(true);
      setList((current: any) => {
        // return current.map((event: any) => {
        //   if (existEvent?.[0].id === current.id) {
        //     return {
        //       ...current,
        //       id: existEvent?.[0].id,
        //     };
        //   } else {
        //     return {
        //       ...event,
        //     };
        //   }
        // });
        return existEvent;
      });
      layout.setKeyboardField((curr: any) => {
        return {
          ...initialKeyboardField,
          id: existEvent?.[0].id,
          categories: existEvent?.[0]?.categories,
          event_exchanges: existEvent?.[0]?.event_exchanges,
          event_currencies: existEvent?.[0]?.event_currencies,
          selected_event_exchanges: existEvent?.[0]?.selected_event_exchanges,
        };
      });
      layout.setTriggered(true);

      setTimeout(() => {
        resetKeyboardFieldsValues();
      }, 100);
    } else if (layout.isPublish) {
      layout.setTriggered(false);
      localStorage.removeItem("events");
    }
  }, [layout.isPublish]);

  // LOADING_DOM_CONTENT
  let isLoadingContent: any = null;

  // if (
  //   existInLocalstorage("events") &&
  //   existInLocalstorage("events").length > 0 &&
  //   !layout.isPublish &&
  //   document.readyState === "complete"
  // ) {
  //   setTimeout(() => {
  //     isLoadingContent = null;
  //   }, 3000);
  // } else if (document.readyState === "interactive") {
  //   isLoadingContent = <ProgressBardLoader secondCounter={3} />;
  // }

  useEffect(() => {
    //GET INVENTORY OPTIONS
    getInventoryOptions().then((res) => {
      // IF LOGIN USER IS NOT CONNECTED WITH PREFERRED PARTNER
      if (res?.data?.data?.is_connected_to_preferred_partners === 0) {
        let exchanges = res?.data?.data?.connected_exchanges;
        exchanges &&
          Array.isArray(exchanges) &&
          _.size(exchanges) > 0 &&
          setCheckExchange(exchanges);
      } else {
        setCheckExchange("");
      }

      const tempEticket = res?.data?.data?.ticket_types?.reduce(
        (acc: any, current: any) => {
          if (current?.id === "eticket") {
            // If the current element is "eticket", extract subtypes
            const updatedSubtypes = current?.subtypes?.map((subtype: any) => {
              // Check if the subtype's name is "PDF E-Ticket" and rename it to "E-Ticket"
              if (subtype?.name === "PDF E-Ticket") {
                return {
                  ...subtype,
                  name: "E-ticket",
                };
              }
              return subtype;
            });

            acc.push(...updatedSubtypes);
          } else {
            acc.push({
              ...current,
              name:
                current?.name.charAt(0).toUpperCase() + current?.name.slice(1),
            });
          }
          return acc;
        },
        []
      );

      const newRenameTicketType = tempEticket?.map((obj: any) => {
        return {
          ...obj,
          name: ticketTypeRename(obj?.id),
        };
      });

      let temp = {
        id: "mobile-link",
        name: "mobile-link",
        subtypes: Array(2),
      };

      layout.setTicketType(newRenameTicketType ? newRenameTicketType : []);

      let temDefaultTicketType: any = null;
      setDefaultTicketType(temDefaultTicketType);
      setTicketType(temDefaultTicketType);
      setSubTicketType(temDefaultTicketType);
      setInitialKeyboardField((current: any) => {
        return {
          ...current,
          eventRecords: current?.eventRecords?.map((records: any) => {
            return {
              ...records,
              ticketType: temDefaultTicketType,
              sub_ticket_type: temDefaultTicketType?.subtypes?.[0],
            };
          }),
        };
      });
      layout.setKeyboardField((current: any) => {
        return {
          ...current,
          eventRecords: current?.eventRecords?.map((records: any) => {
            return {
              ...records,
              ticketType: temDefaultTicketType,
              sub_ticket_type: temDefaultTicketType?.subtypes?.[0],
            };
          }),
        };
      });
      layout.setDeliveryType(res?.data?.data?.delivery_options);

      // let tempSplitType = res?.data?.data?.split_types.map((item: any) => ({
      //   ...item,
      //   name:
      //     item?.id === "ANY"
      //       ? "No Preference"
      //       : item?.id === "NOSINGLES"
      //       ? "Avoid Leaving One"
      //       : item.name.charAt(0).toUpperCase() +
      //         item.name.slice(1).toLowerCase(),
      // }));

      layout.setSplitType(splitTypeOptions(res?.data?.data?.split_types));
      layout.setBenefits(res?.data?.data?.benefits);
      layout.setRestrictions(res?.data?.data?.restrictions);
      layout.setCurrencyRates(res?.data?.data?.currency_rates);

      if (
        isArray(coreLayout.cloneEvents) &&
        coreLayout.cloneEvents.length > 0
      ) {
        setList(coreLayout.cloneEvents);
        layout.setTriggered(true);
        setEnabled(true);
        setTimeout(() => {
          eventCheckBox(coreLayout.cloneEvents?.[0], 0, {
            target: { checked: true },
          });
          coreLayout.setCloneEvents([]);
        }, 1000);
      }
    });
  }, []);

  const setPerformerData = () => {
    // ALGOLIA
    // layout.setPerformer([
    //   ...InventoryData[0].hits.map((item: any) => {
    //     let final = {
    //       ...item,
    //       selected: false,
    //       event_id: id?.id,
    //     };
    //     return final;
    //   }),
    // ]);
    layout.setPerformer([
      ...InventoryData[0].hits.map((item: any) => {
        // BUTTON CODE
        let existingPerformer = layout.tempPerformer.find(
          (p: any) => p.id === item.id
        );
        let selected = existingPerformer ? existingPerformer.selected : false;

        let final = {
          ...item,
          selected: false, // Preserve the selected state from existingPerformer or set it to false initially.
          event_id: id?.id,
        };
        return final;
      }),
    ]);

    layout.setperformerPaginationData(InventoryData[0]);
  };

  // IF THERE ARE NO RECORDS FOUND IN ALGOLIA DB
  useEffect(() => {
    if (
      InventoryData &&
      InventoryData?.[0]?.hits &&
      InventoryData?.[0].hits.length === 0
    ) {
      // showAlert("Records are not found", true, 2000);
      layout.setPerformer([]);
      layout.setPerformerKeyboardField(performerFilters);
      setAction("performer");
    }
  }, [InventoryData]);

  // UPDATE STATE FOR ALGOLIA RECORDS
  useEffect(() => {
    if (
      InventoryData &&
      InventoryData[0].hits &&
      InventoryData[0].hits.length > 0
    ) {
      if (
        layout.eventOrPerformer === "Performer/venue" &&
        action === "searchByPerformerId"
      ) {
        setPerformerData();
        setTimeout(() => {
          setAction("performer");
        }, 2000);
      } else if (action === "performerKeyboard") {
        setPerformerData();
        setTimeout(() => {
          setAction("performer");
        }, 2000);
      } else if (action === "paginatePerformer") {
        setPerformerData();
        setTimeout(() => {
          setAction("performer");
        }, 2000);
      }
    }
  }, [InventoryData?.data, layout.eventOrPerformer, InventoryData, action]);

  // HANDLE ALGOLIA PAGINATION
  const performerPaginateHandle = (data: any) => {
    let page = parseInt(data?.selected) + 1;
    setAction("paginatePerformer");
    setPaginateData((pre: any) => {
      return {
        id: id && id,
        per_page: data?.target?.value
          ? data?.target?.value
          : layout.performerPaginationData?.hitsPerPage,
        page: !Number.isNaN(page) ? page - 1 : 0,
      };
    });
  };

  const PerformerKeyboardHandle = (data: any) => {
    setAction("performerKeyboard");
    layout.setPerformerKeyboardField((performerValue: any) => {
      return {
        ...performerValue,
        [data.target.name]: data.target.value,
      };
    });
  };

  /**
   * HANDLE CLICK IN EVENT SEARCH INPUT
   */

  const handleSearchClick = (e: any, ref: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.innerText === "Event") {
      // EVENT THERE IS NO EVENT SET SEARCFIELD AND TRIGGED - FALSE
      if (_.size(events) === 0) {
        layout.setTriggered(false);
        layout.setSearchFieldValue("");
      } else if (_.size(events) > 0) {
        // IF THERE IS AN EVENT -> UPDATE SEARCHFIELD AND KEYBOARD STATES
        setList((events: any) => {
          return events?.map((event: any, index: number) => {
            if (index === 0 && event?.id) {
              layout.setSearchFieldValue(event);
              let defaultDateToShip = get2DaysBeforeDateOfEvent(event?.date);
              layout.setKeyboardField({
                ...event,
                eventRecords: keyboardField["eventRecords"]?.map(
                  (item: any) => {
                    return {
                      ...item,
                      dateToShip: defaultDateToShip,
                    };
                  }
                ),
              });
              return {
                ...event,
                selected: true,
              };
            } else {
              return {
                ...event,
              };
            }
          });
        });
      }
      layout.setEventOrPerformer("Event");
    } else if (e.target.innerText === "Performer/venue") {
      layout.setSearchFieldValue("");
      layout.setEventOrPerformer("Performer/venue");
    }
    // CLEAR ICON
    if (
      (ref.current !== undefined && e.target.id !== "search_btn") ||
      e?.target?.id === "clear_btn"
    ) {
      ref.current.clearValue();
      setSearchedValue("");
      layout.setSearchFieldValue("");
      if (layout.eventOrPerformer === "Performer/venue") {
        layout.setPerformer([]);
        setAction("performer");
      }
    }
  };
  /**
   * HANDLE EVENT SELECTION OF EVENT TYPE OF SEARCH
   */
  const handleEventSelect = async (data: any) => {
    if (layout.eventOrPerformer === "Event") {
      let eventDetails: any;
      setSection([]);
      if (keyboardField?.id === "" || keyboardField?.id !== data?.id) {
        setCustomLoader(true);

        eventDetails = await getEventDetails(data.id, "").then((res) => {
          return res?.data?.data[0];
        });

        if (data?.setKeyboard) {
          layout.setTriggered(true);
          layout.setSearchFieldValue({
            ...eventDetails,
            date: moment(eventDetails?.date).format("ddd, DD MMM YYYY"),
            time: eventDetails?.time,
            address: eventDetails?.address,
          });
        }
        setCustomLoader(false);

        // GET 2 DAYS SUBTRACTED DATE OF AN EVENT AND APPEND IN THE DATE FILTER KEYBOARD
        let defaultDateToShip = get2DaysBeforeDateOfEvent(eventDetails?.date);
        layout.setKeyboardField({
          ...eventDetails,
          selected: false,
          hideError: true,
          eventRecords: layout.keyboardField["eventRecords"]?.map(
            (item: any) => {
              return {
                ...item,
                dateToShip: defaultDateToShip,
              };
            }
          ),
        });
        resetKeyboardFieldsValues({ dateToShip: defaultDateToShip });
      }
    } else if (layout.eventOrPerformer === "Performer/venue") {
      let indexFound = Number(
        _.compact(
          InventoryData &&
            InventoryData?.map(
              (hits: any, index: number) =>
                hits?.hits?.find((item: any) => item.id === data?.id) && index
            )
        )
      );

      setId({
        id: data?.id,
        indexId: indexFound,
      });

      layout.setPerformerKeyboardField(performerFilters);
      setAction("searchByPerformerId");
      // setTimeout(() => {
      //   setAction("performer");
      // }, 2000);
    }
  };

  /**
   * HANDLE EVENT SELECTION FROM THE RADIO BUTTON IN TABLE
   */
  const eventCheckBox = (event: any, eIndex: number, e: any) => {
    if (!coreLayout?.cloneEvents || _.size(coreLayout?.cloneEvents) === 0) {
      resetKeyboardFieldsValues();
    }
    setList((currentEvent: any) => {
      const finalResult = currentEvent.map((events: any) => {
        if (event.id === events.id) {
          layout.setSearchFieldValue({ ...events });
          let defaultDateToShip = get2DaysBeforeDateOfEvent(events?.date);
          let myInventoryCloneSubTicket =
            coreLayout?.cloneEvents?.[0]?.extraTicketType?.[0]?.subtypes?.[0];
          let myInventoryCloneTicket =
            coreLayout?.cloneEvents?.[0]?.extraTicketType?.[0];
          layout.setKeyboardField({
            ...events,
            eventRecords: initialKeyboardField["eventRecords"]?.map(
              (item: any) => {
                return {
                  ...item,
                  ...(myInventoryCloneTicket && {
                    ticketType: myInventoryCloneTicket,
                  }),
                  ...(myInventoryCloneSubTicket && {
                    sub_ticket_type: myInventoryCloneSubTicket,
                  }),
                  dateToShip: defaultDateToShip,
                };
              }
            ),
          });
          setSection([]);
          return {
            ...events,
            selected: e.target.checked,
          };
        } else {
          return {
            ...events,
            selected: false,
          };
        }
      });

      if (!layout.addAllEvents) {
        layout.setSearchFieldValue({ ...event, eIndex });
        // setKeyboardField({ ...event, eIndex });
        setSection([]);
      }
      //  else {
      //   const foundResult = finalResult.find((event: any) => event.selected);
      //   if (foundResult) {
      //     layout.setSearchFieldValue({ ...foundResult });
      //     setKeyboardField({ ...foundResult });
      //     setSection([]);
      //   }
      // }
      return finalResult;
    });
  };

  /*
   * HANDLE EVENT ERROR BUTTON FROM THE PUBLISH TABLE
   */
  const eventError = (event: any, eIndex: number, e: any) => {
    setList((currentEvent: any) => {
      const finalResult = currentEvent.map((events: any) => {
        if (event.id === events.id) {
          return {
            ...events,
            hideError: e,
          };
        } else {
          return events;
        }
      });
      return finalResult;
    });
  };

  /**
   * RESET KEYBOARD FIELD VALUES
   */

  const resetKeyboardFieldsValues = (data: any = {}) => {
    //ticketTypeRef.current.clearValue();
    setTicketType(defaultTicketType);
    setSubTicketType(defaultTicketType);
    deliveryTypeRef &&
      deliveryTypeRef?.current &&
      deliveryTypeRef?.current.clearValue();
    spittingRef?.current?.clearValue();
    restrictionRef?.current?.clearValue();
    categoryRef?.current?.clearValue();
    sectionRef?.current?.clearValue();
    benefitRef?.current?.clearValue();
    quantityRef?.current?.value && (quantityRef.current.value = "");
    multipleRef?.current?.value && (multipleRef.current.value = "");
    displayQuantityRef?.current?.value &&
      (displayQuantityRef.current.value = "");
    firstSeatRef?.current?.value && (firstSeatRef.current.value = "");
    rowRef?.current?.value && (rowRef.current.value = "");
    faceValueRef?.current?.value && (faceValueRef.current.value = "");
    proceedValueRef?.current?.value && (proceedValueRef.current.value = "");
    setPPE(false);
    setSection([]);
    setSellMultipleSplitType([]);
    layout.setKeyboardField((prev: any) => {
      return {
        ...prev,
        eventRecords: initialKeyboardField["eventRecords"]?.map((item: any) => {
          return {
            ...item,
            ...(data?.dateToShip && { dateToShip: data?.dateToShip }),
          };
        }),
      };
    });
  };

  const [flag, setFlag] = useState<any>(false);
  const [flagFor5, setFlagFor5] = useState<any>(false);

  // function updateTicketFiles(existingFiles: any, newQuantity: any) {
  //   const currentQuantity = existingFiles?.length;

  //   if (newQuantity === currentQuantity) {
  //     return existingFiles; // No change in quantity, return existing array
  //   } else if (newQuantity > currentQuantity) {
  //     // Add new objects to the array
  //     return [
  //       ...existingFiles,
  //       ...Array.from({ length: newQuantity - currentQuantity }, () => ({})),
  //     ];
  //   } else {
  //     // Remove objects from the end of the array
  //     return existingFiles?.slice(0, newQuantity);
  //   }
  // }

  function updateTicketFiles(existingFiles: any, newQuantity: any) {
    const currentQuantity = existingFiles?.length;

    if (newQuantity === currentQuantity) {
      return existingFiles; // No change in quantity, return existing array
    } else if (newQuantity > currentQuantity) {
      // Add new objects to the array without removing existing ones
      return [
        ...existingFiles,
        ...Array.from({ length: newQuantity - currentQuantity }, () => ({})),
      ];
    } else {
      // Return the existing files up to the new quantity
      return existingFiles?.slice(0, newQuantity);
    }
  }
  /**
   * HANDLE ON CHANGE OF KEYBOARD FIELDS
   */
  // * Faster and better version
  const handleKeyboardChange = useMemo(() => {
    return debounce((data) => {
      layout.setKeyboardField((prevKeyboardField: any) => {
        // TICKETFILES EMPTY OBJECT ON QUNTITY CHANGE
        // let quantity: any = "";
        // if (data.target.name === "quantity") {
        //   quantity =
        //     data?.target?.name === "quantity"
        //       ? parseInt(data.target.value, 10)
        //       : 0;
        // }

        let final = {
          ...prevKeyboardField,

          eventRecords: [
            {
              ...prevKeyboardField["eventRecords"][0],

              [data.target.name]:
                data.target.value !== undefined &&
                Array.isArray(data.target.value)
                  ? data.target.value
                  : typeof data.target.value === "object"
                  ? data.target.value
                  : typeof data.target.value === "string"
                  ? data.target.value
                  : data.target.checked,
              ...(data.target.name === "ticketType"
                ? {
                    sub_ticket_type: data.target.value?.subtypes?.[0],

                    ticketFiles: Array.from({
                      length: maxFileCondition(
                        prevKeyboardField["eventRecords"][0]?.quantity
                      ),
                    })?.map(() => ({})),
                  }
                : {}),
              ...(data.target.name === "ticketType" &&
              data.target.value !== "paper"
                ? { deliveryType: [] }
                : {}),
            },
          ],
        };

        return {
          ...final,
          eventRecords: final?.eventRecords?.map((item: any) => {
            let visibility: any = visibilityMeterCalculationForKeyBoard(
              final,
              item,
              events
            );
            const quantity = item?.quantity
              ? Number(item?.quantity)
              : item?.ticketFiles?.length;

            let ticketFiles =
              Number(quantity) > 0
                ? updateTicketFiles(
                    item.ticketFiles,
                    Number(quantity) > 500 ? 500 : Number(quantity)
                  )
                : [];

            return {
              ...item,
              row: item?.row ? item?.row : rowRef?.current?.value,
              ticketFiles: ticketFiles,
              MULTIPLES:
                item?.splitType?.id === "MULTIPLES" ? item?.MULTIPLES : "",
              faceValue:
                item?.faceValue &&
                (item?.faceValue?.value === undefined ||
                  item?.faceValue?.value === "0.00")
                  ? {
                      name: "faceValue",
                      value: decimalNumber(
                        Number(faceValueRef?.current?.value)
                      ),
                      symbol: item?.proceedValue?.symbol,
                    }
                  : item?.faceValue,
              ...(item?.ticketsInHands
                ? { dateToShip: new Date() }
                : {
                    dateToShip: item?.dateToShip,
                    //  get2DaysBeforeDateOfEvent(
                    //   prevKeyboardField?.date
                    // ),
                  }),
              oldVisibility:
                item?.visibility === visibility
                  ? item?.oldVisibility
                  : item?.visibility,
              visibility: visibility,
            };
          }),
        };
      });
      // UPDATE SECTION OPTIONS
      if (data.target.name === "category") {
        sectionRef.current.clearValue();

        if (layout.addAllEvents && events?.length > 1) {
          const temCategory = data.target.value?.name;
          let sectionsIntersection: any[] = [];

          // GET INTERSECTION OF SECTIONS
          events?.forEach((event: any) => {
            event?.categories?.forEach((category: any) => {
              if (category?.name === temCategory) {
                if (!sectionsIntersection.length) {
                  // Initialize with the sections of the first event in the category
                  sectionsIntersection.push(...category.sections);
                } else {
                  // Find the intersection of sections with the current event
                  sectionsIntersection = sectionsIntersection.filter(
                    (section: any) =>
                      category.sections.some(
                        (s: any) => s.name === section.name
                      )
                  );
                }
              }
            });
          });

          setSection(sectionsIntersection);
        } else {
          setSection(data.target.value?.sections);
        }
      }
      // SHOW SUB TICKET TYPE
      if (data.target.name === "ticketType") {
        setSubTicketType(data.target.value);
      }
      if (data.target.name === "splitType") {
        setSellMultipleSplitType(data.target.value);
      }
    }, 300);
  }, [events, layout.addAllEvents]);

  //CALCULATE THE DISPLAY PRICE
  const handlePPEKeyboardChange = (data: any, currency: any) => {
    let displayPrice: any = "";
    let value = data.target.value.value;
    let currentMarketPlace =
      keyboardField.event_currencies &&
      keyboardField.event_currencies.find(
        (market: any) => market.id === data.target.name
      );
    let currnecyRates = layout.currencyRates[data?.target?.value?.symbol];

    displayPrice = calculateDisplayPrice(
      currnecyRates,
      currentMarketPlace,
      value
    );

    layout.setKeyboardField((prev: any) => {
      let ppeExchanges = prev?.["ppeExchanges"] ? prev["ppeExchanges"] : [];
      ppeExchanges[data.target.name] = {
        proceed_price: data.target.value.value,
        display_price: displayPrice,
        currency: currency,
      };
      return {
        ...prev,
        ppeExchanges,
      };
    });
  };

  const getProccedPrice = useMemo(() => {
    return (exchangeId: any) => {
      return keyboardField?.ppeExchanges &&
        keyboardField?.ppeExchanges[exchangeId] &&
        keyboardField?.ppeExchanges[exchangeId]?.proceed_price
        ? keyboardField?.ppeExchanges[exchangeId]?.proceed_price
        : "";
    };
  }, [keyboardField.ppeExchanges]);

  const getDisplayPrice = useMemo(() => {
    return (exchangeId: any) => {
      return keyboardField?.ppeExchanges &&
        keyboardField?.ppeExchanges[exchangeId] &&
        keyboardField?.ppeExchanges[exchangeId]?.display_price
        ? keyboardField?.ppeExchanges[exchangeId]?.display_price
        : "";
    };
  }, [keyboardField]);

  const getDisplayPriceCurrency = useMemo(() => {
    return (exchangeId: any) => {
      let findCurrency = keyboardField?.event_currencies?.find(
        (currency: any) => currency.id === exchangeId
      );

      if (findCurrency === undefined) {
        findCurrency = keyboardField?.event_currencies?.find(
          (currency: any) => currency.id === 14
        );
      }
      return findCurrency?.currency;
    };
  }, [keyboardField]);

  /**
   * HANDLE ADD INVENTORY ACTION OF KEYBOARD
   */

  // console.log(layout?.keyboardField?.eventRecords?.[0], "keyboard");

  // * Better & Faster Version
  const handleAddInventory = useCallback(async () => {
    //set PPE values to event record.
    setAddListingBtnDisabled(true);
    layout.setKeyboardField((currentValue: any) => {
      if (currentValue["eventRecords"] && currentValue["eventRecords"][0]) {
        currentValue["eventRecords"][0]["ppeExchanges"] =
          currentValue?.ppeExchanges && PPE ? currentValue?.ppeExchanges : [];
        currentValue["eventRecords"][0]["ppe"] = PPE;
        currentValue["eventRecords"][0]["card"] = generateUniqueId();
        // SET AUTO MARKETPLACES ACCORDING TO EVENT
        currentValue["eventRecords"][0]["marketplaces"] = isArray(
          currentValue?.event_exchanges
        )
          ? currentValue?.event_exchanges?.filter((event_exchange: any) =>
              currentValue?.selected_event_exchanges?.includes(
                event_exchange?.exchange_id
              )
            )
          : [];
        return currentValue;
      } else {
        return currentValue;
      }
    });

    setTimeout(async () => {
      if (layout.eventOrPerformer === "Event") {
        setList((currentValue: any) => {
          // EVENTS ARE EMPTY
          if (_.isEmpty(currentValue)) {
            let clonedKeyboard = _.cloneDeep(keyboardField);
            let tempCloned = {
              ...clonedKeyboard,
              name: layout.searchFieldValue?.name,
              time: layout.searchFieldValue?.time,
              address: layout.searchFieldValue?.address,
              date: layout.searchFieldValue?.date,
            };
            let final = clonedKeyboard?.name ? clonedKeyboard : tempCloned;
            let eventRecords = final?.eventRecords?.[0];
            eventRecords = {
              ...eventRecords,
              ticketsInHands: ticketsInHandValueAssigned(
                eventRecords?.quantity,
                eventRecords?.ticketFiles,
                eventRecords?.ticketsInHands
              ),
            };

            return [{ ...final, eventRecords: [eventRecords] }];
          }
          //AT LEAST ON EVENT EXIST
          else if (Array.isArray(currentValue)) {
            let existingRecord = _.findIndex(
              currentValue,
              (value: any) => {
                return value.id === keyboardField.id;
              },
              0
            );
            // IF TOGGLE SELECT ALL
            if (layout.addAllEvents) {
              let tempValue = [...currentValue];
              let finalResult = tempValue.map((event: any) => {
                let tempKeyboardField: any = _.cloneDeep(
                  keyboardField["eventRecords"]
                );
                tempKeyboardField[0]["card"] = generateUniqueId();
                // SET AUTO MARKKETPLACES ACCORDING TO EVENT
                tempKeyboardField[0]["marketplaces"] = isArray(
                  event?.event_exchanges
                )
                  ? event?.event_exchanges?.filter((event_exchange: any) =>
                      event?.selected_event_exchanges?.includes(
                        event_exchange?.exchange_id
                      )
                    )
                  : [];

                return {
                  ...event,
                  eventRecords: [
                    ...tempKeyboardField?.map(
                      (item: any, keyboardIndex: number) => {
                        const category = event?.categories?.filter(
                          (category: any) =>
                            category?.name === item?.category?.name
                        );

                        const section = category?.[0]?.sections?.filter(
                          (section: any) =>
                            item?.section?.name === section?.name
                        )?.[0];

                        // GET DATE 2 DAYS BEFORE EVENT
                        let defaultDateToShip = get2DaysBeforeDateOfEvent(
                          event?.date
                        );

                        return {
                          ...item,
                          faceValue: {
                            ...item?.faceValue,
                            value:
                              item?.faceValue?.value !==
                              faceValueRef?.current?.value
                                ? faceValueRef?.current?.value
                                : item?.faceValue?.value,
                          },
                          proceedValue: {
                            ...item?.proceedValue,
                            value:
                              item?.proceedValue?.value !==
                              proceedValueRef?.current?.value
                                ? decimalNumber(proceedValueRef?.current?.value)
                                : decimalNumber(item?.proceedValue?.value),
                          },
                          row:
                            item?.row !== rowRef?.current?.value
                              ? rowRef?.current?.value
                              : item?.row,
                          firstSeat:
                            item?.firstSeat !== firstSeatRef?.current?.value
                              ? firstSeatRef?.current?.value
                              : item?.firstSeat,
                          category: _.cloneDeep(category?.[0]),
                          ...(_.size(section) > 0 && {
                            section: section,
                          }),
                          ...(!item?.dateToShip && {
                            dateToShip: defaultDateToShip,
                          }),
                        };
                      }
                    ),
                    // ...tempKeyboardField,
                    ...(_.cloneDeep(event?.["eventRecords"]) ?? []),
                  ],
                };
              });
              return finalResult;
            } else if (existingRecord !== -1) {
              let tempValue = [...currentValue];
              let deepCloneKeyboard = _.cloneDeep(
                keyboardField?.eventRecords
              )?.[0];

              deepCloneKeyboard.faceValue.value =
                deepCloneKeyboard?.faceValue?.value !==
                faceValueRef?.current?.value
                  ? decimalNumber(faceValueRef?.current?.value)
                  : decimalNumber(deepCloneKeyboard?.faceValue?.value);

              deepCloneKeyboard.proceedValue.value =
                deepCloneKeyboard?.proceedValue?.value !==
                proceedValueRef?.current?.value
                  ? decimalNumber(proceedValueRef?.current?.value)
                  : decimalNumber(deepCloneKeyboard?.proceedValue?.value);

              deepCloneKeyboard.row =
                deepCloneKeyboard?.row !== rowRef?.current?.value
                  ? rowRef?.current?.value
                  : deepCloneKeyboard?.row;

              deepCloneKeyboard.firstSeat =
                deepCloneKeyboard?.firstSeat !== firstSeatRef?.current?.value
                  ? firstSeatRef?.current?.value
                  : deepCloneKeyboard?.firstSeat;

              tempValue[existingRecord]["eventRecords"] = [
                {
                  ...deepCloneKeyboard,
                  ticketsInHands: ticketsInHandValueAssigned(
                    deepCloneKeyboard?.quantity,
                    deepCloneKeyboard?.ticketFiles,
                    deepCloneKeyboard?.ticketsInHands
                  ),
                },
                ..._.cloneDeep(
                  tempValue[existingRecord]?.["eventRecords"]?.map(
                    (tEventRecord: any, tEIndex: number) => {
                      return {
                        ...tEventRecord,
                        card: generateUniqueId(),
                      };
                    }
                  )
                ),
              ];
              return tempValue;
            } else {
              let deepCloneOfKeyboardField = _.cloneDeep(keyboardField);
              return [
                {
                  ...deepCloneOfKeyboardField,
                  ticketsInHands: ticketsInHandValueAssigned(
                    deepCloneOfKeyboardField?.quantity,
                    deepCloneOfKeyboardField?.ticketFiles,
                    deepCloneOfKeyboardField?.ticketsInHands
                  ),
                },
                ...currentValue?.map((event: any) => {
                  return {
                    ...event,
                    selected: false,
                  };
                }),
              ];
            }
          }
        });
      } else if (layout.eventOrPerformer === "Performer/venue") {
        const selectedPerformers = _.map(
          performer.filter((perform: any) => perform.selected === true),
          "id"
        );
        setCustomLoader(true);

        const eventDetails = await getEventDetails(
          selectedPerformers,
          selectedPerformers.length
        ).then((res) => {
          return res?.data?.data;
        });

        const markinsight: any = await Promise.all(
          eventDetails?.map(async (obj: any) => {
            try {
              const result = await fetchMarketplacePriceWithSection(obj);
              return result?.data;
            } catch (error) {
              console.error("Error fetching data:", error);
              // Handle the error here, such as returning a default value or an error indicator
              return null; // or throw error; depending on your use case
            }
          })
        );

        setList((currentValue: any) => {
          //events are empty
          if (_.isEmpty(currentValue)) {
            let finalResult = eventDetails.map((perform: any, index: any) => {
              const markeInsights = markinsight?.[index]?.results?.sections;

              return {
                ...perform,
                markeInsightsForAvgPrice: markeInsights,
                eventRecords: [],
              };
            });

            layout.setSearchFieldValue(finalResult[0]);
            layout.setKeyboardField((curr: any) => {
              let defaultDateToShip = get2DaysBeforeDateOfEvent(
                finalResult[0]?.date
              );
              setTicketType(null);

              return {
                // ...initialKeyboardField,
                ...initialKeyboardField,
                id: finalResult[0].id,
                categories: finalResult[0]?.categories,
                event_exchanges: finalResult[0]?.event_exchanges,
                selected_event_exchanges:
                  finalResult[0]?.selected_event_exchanges,
                event_currencies: finalResult[0]?.event_currencies,
                eventRecords: initialKeyboardField["eventRecords"]?.map(
                  (item: any) => {
                    return {
                      ...item,
                      dateToShip: defaultDateToShip,
                    };
                  }
                ),
              };
            });
            // setKeyboardField()
            return finalResult;
          } //at least one event
          else if (Array.isArray(currentValue)) {
            let performs = eventDetails
              .filter((perform: any) => {
                let existingRecord = _.findIndex(
                  currentValue,
                  (value: any) => {
                    return value.id === perform.id;
                  },
                  0
                );
                if (existingRecord === -1) {
                  return perform;
                }
              })
              .map((perform: any) => {
                return {
                  ...perform,
                  eventRecords: [],
                };
              });
            let finalResult = [...performs, ...currentValue];
            layout.setSearchFieldValue(finalResult[0]);
            layout.setKeyboardField((curr: any) => {
              let defaultDateToShip = get2DaysBeforeDateOfEvent(
                finalResult[0]?.date
              );
              setTicketType(null);
              return {
                ...initialKeyboardField,
                id: finalResult[0].id,
                categories: finalResult[0]?.categories,
                event_exchanges: finalResult[0]?.event_exchanges,
                selected_event_exchanges:
                  finalResult[0]?.selected_event_exchanges,
                event_currencies: finalResult[0]?.event_currencies,
                eventRecords: initialKeyboardField["eventRecords"]?.map(
                  (item: any) => {
                    return {
                      ...item,
                      dateToShip: defaultDateToShip,
                    };
                  }
                ),
              };
            });
            return finalResult;
          }
        });

        layout.setPerformer([]);
        layout.setEventOrPerformer("Event");
        setCustomLoader(false);
      }
      layout.setKeyboardField((currentValue: any) => ({
        ...currentValue,
        eventRecords: (currentValue?.eventRecords || []).map((obj: any) => ({
          ...obj,
          ticketFiles: Array.from(
            { length: maxFileCondition(Number(obj.quantity) || 0) },
            () => ({})
          ),
        })),
      }));
    }, 200);

    setTimeout(() => {
      setAddListingBtnDisabled(false);
    }, 300);
  }, [
    keyboardField,
    performer,
    PPE,
    layout.eventOrPerformer,
    layout?.keyboardField,
  ]);

  let performerVenueLoader: any =
    ((action === "searchByPerformerId" ||
      action === "performerKeyboard" ||
      action === "paginatePerformer") &&
      isLoading) ||
    customLoader;

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (
      (action === "searchByPerformerId" ||
        action === "performerKeyboard" ||
        action === "paginatePerformer") &&
      isLoading
    ) {
      setDebouncedLoader(true);
    } else {
      timeout = setTimeout(() => {
        setDebouncedLoader(false);
      }, 1500); // Wait for xx-ms before removing the loader
    }

    return () => clearTimeout(timeout); // Clean up the timeout on unmount or if action/loader changes
  }, [action, isLoading, customLoader]);

  const handlePPETableChange = (data: any, eIndex: number, rIndex: number) => {
    let tempEvents = [...events];
    let dataValue = data.target.value.value;
    let dataSymbol = data.target.value.symbol;

    //CALCULATE THE DISPLAY PRICE
    let displayPrice: any = "";
    let currentMarketPlace =
      tempEvents[eIndex].event_currencies &&
      tempEvents[eIndex].event_currencies.find(
        (market: any) => market.id === data.target.name
      );
    if (!currentMarketPlace) {
      //SET DEAFUTL MARKET PLACE
      currentMarketPlace = tempEvents[eIndex]?.event_currencies
        ? tempEvents[eIndex].event_currencies.find(
            (market: any) => market.id === 14
          )
        : {};
    }
    let currnecyRates = layout.currencyRates[dataSymbol];

    displayPrice = calculateDisplayPrice(
      currnecyRates,
      currentMarketPlace,
      dataValue
    );

    if (tempEvents[eIndex]["eventRecords"][rIndex]["ppeExchanges"]) {
      tempEvents[eIndex]["eventRecords"][rIndex]["ppeExchanges"][
        data.target.name
      ] = {
        proceed_price: dataValue,
        display_price: displayPrice,
        currency: dataSymbol,
      };
    } else {
      tempEvents[eIndex]["eventRecords"][rIndex]["ppeExchanges"].push({
        [data.target.name]: {
          proceed_price: dataValue,
          display_price: displayPrice,
          currency: dataSymbol,
        },
      });
    }
    setList([...tempEvents]);
  };

  const resetPPEKeyboardFields = () => {
    layout.setKeyboardField((prev: any) => ({
      ...prev,
      ppeExchanges: [],
    }));
  };

  // RESET PERFORME FILTERS
  const resetPerformerFilters = (data: any) => {
    ref?.current?.clearValue();
    // COMMMENT BELOW CODE IF REQUIRED
    layout.setPerformerKeyboardField(performerFilters);
  };

  useEffect(() => {
    if (layout.eventOrPerformer === "Event") {
      setAction("event");
    } else if (layout.eventOrPerformer === "Performer/venue") {
      setAction("performer");
    }
  }, [layout.eventOrPerformer]);

  if (
    keyboardField?.ppeExchanges &&
    Object.keys(keyboardField?.ppeExchanges).length > 0 &&
    Object.values(keyboardField?.ppeExchanges).find(
      (item: any) => item?.proceed_price
    )
  ) {
    PPE_Enabled = true;
  } else {
    PPE_Enabled = false;
  }
  //return render of the Add Inventory component

  // HANDLE TO ALL EVENT FUNCTION
  const handleAddToAllEvent = (data: any) => {
    layout.setAddAllEvents(data.target.checked);
    layout.setSearchFieldValue({
      ...events[0],
      eventRecords: [],
      isDisabled: false,
    });
    // ADD TO ALL
    if (data?.target?.checked) {
      setList((currentEvt: any) => {
        return currentEvt.map((event: any) => {
          return {
            ...event,
            selected: data.target.checked,
          };
        });
      });
      // resetKeyboardFieldsValues();
      layout.setSearchFieldValue({
        ...events[0],
        isDisabled: true,
        name: `${events.length} events selected`,
        time: undefined,
        date: undefined,
        address: undefined,
      });
      layout.setKeyboardField((currentValue: any) => {
        const intersectedExchanges = events.reduce(
          (intersected: any, item: any, index: any) => {
            if (index === 0) {
              // @ts-ignore
              return item?.event_exchanges;
            } else {
              return intersected?.filter((exchange: any) =>
                // @ts-ignore
                item?.event_exchanges?.some(
                  (e: any) => e.exchange_id === exchange.exchange_id
                )
              );
            }
          },
          []
        );
        const interesectedCategories = events.reduce(
          (intersected: any, item: any, index: any) => {
            if (index === 0) {
              return item?.categories;
            } else {
              return intersected?.filter((exchange: any) => {
                return item?.categories?.some(
                  (e: any) => e.name === exchange.name
                );
              });
            }
          },
          []
        );

        return {
          // ...initialKeyboardField,
          ...currentValue,
          eventRecords: currentValue?.eventRecords?.map((obj: any) => {
            let ticketFiles: any = [];
            for (let i = 0; i < obj?.quantity; i++) {
              ticketFiles.push({});
            }
            return {
              ...obj,
              category: null,
              section: null,
              ticketFiles: ticketFiles,
              dateToShip: null,
            };
          }),
          categories: interesectedCategories,
          event_exchanges: intersectedExchanges,
        };
      });
      setSection([]);
    } else {
      setList((currentEvt: any) => {
        return currentEvt.map((event: any) => {
          if (layout.searchFieldValue?.id === event.id) {
            return {
              ...event,
              selected: true,
            };
          } else {
            return {
              ...event,
              selected: false,
            };
          }
        });
      });
      resetKeyboardFieldsValues();
      layout.setKeyboardField((currentValue: any) => {
        let defaultDateToShip = get2DaysBeforeDateOfEvent(events[0]?.date);
        return {
          ...initialKeyboardField,
          id: layout.searchFieldValue?.id,
          // @ts-ignore
          categories: events[0]?.categories,
          // @ts-ignore
          event_exchanges: events[0]?.event_exchanges,
          // @ts-ignore
          selected_event_exchanges: events[0]?.selected_event_exchanges,

          eventRecords: initialKeyboardField["eventRecords"]?.map(
            (item: any) => {
              return {
                ...item,
                dateToShip: defaultDateToShip,
              };
            }
          ),
        };
      });
    }
    setSection([]);
  };

  // SET keyboard event via Dashboard top selling widget
  useEffect(() => {
    if (location?.state) {
      handleEventSelect({ id: location?.state, setKeyboard: true });
    }
  }, [location.state]);

  // UPDATE SETSEARCFILED VIA DASHBOARD
  // useEffect(() => {
  //   if (location.state) {
  //     let dashboardData = {
  //       address: location.state?.venue,
  //       date: moment
  //         .unix(location.state?.datetime)
  //         .utc()
  //         .format("ddd, DD MMMM YYYY"),
  //       event_date_local: location.state?.datetime,
  //       id: location.state?.id,
  //       name: location.state?.event,
  //       time: moment.unix(location.state?.datetime).utc().format("HH:mm"),
  //       venue_name: location.state?.venue,
  //     };
  //     layout.setSearchFieldValue(dashboardData);
  //     layout.setTriggered(true);
  //     handleEventSelect(dashboardData);
  //   }
  // }, [location.state, layout.ticketType]);

  // Function to get index of dragged Filter item
  const getKeyboardItemIndex = (id: any) => {
    let index = layout?.draggedItems?.findIndex((item: any) => item.id === id);
    return index + 1;
  };
  const getKeyboardItemIndexForCheckbox = (id: any) => {
    let index = layout?.keyboardItems?.findIndex((item: any) => {
      return item.id === id;
    });
    return index;
  };

  // Save Draggable Keyboard Items Data to Local Storage
  const saveKeyboardItems = () => {
    localStorage.setItem(
      "saveKeyboardItems",
      JSON.stringify(layout.keyboardItems)
    );
  };

  useEffect(() => {
    saveKeyboardItems();
  }, [layout.keyboardItems]);

  const [isPopUpOpen, setIsPopUpOpen] = useState<any>({ isOpen: false });

  const handleEticket = (event: any, eventRecord: any) => {
    setIsPopUpOpen({
      isOpen: true,
      event: event?.name
        ? [event]
        : [
            {
              ...event,
              name: layout.searchFieldValue?.name,
              time: layout.searchFieldValue?.time,
              address: layout.searchFieldValue?.address,
              date: layout.searchFieldValue?.date,
            },
          ],
    });
  };

  const allTicketUploaded =
    keyboardField?.eventRecords?.[0]?.quantity &&
    keyboardField?.eventRecords?.[0]?.ticketFiles?.filter(
      (obj: any) => JSON.stringify(obj) !== "{}"
    )?.length > 0 &&
    Number(keyboardField?.eventRecords?.[0]?.quantity) ===
      keyboardField?.eventRecords?.[0]?.ticketFiles?.filter(
        (obj: any) => JSON.stringify(obj) !== "{}"
      )?.length &&
    (keyboardField.eventRecords?.[0]?.ticketType?.id === "eticket" ||
      keyboardField.eventRecords?.[0]?.ticketType?.id === "pdf" ||
      keyboardField.eventRecords?.[0]?.ticketType?.id === "mobile-qr" ||
      keyboardField.eventRecords?.[0]?.ticketType?.id === "mobile-link");
  const handleRemoveTicket = () => {
    const updatedEvent = {
      ...keyboardField,
      eventRecords: (keyboardField?.eventRecords || []).map((record: any) => {
        const ticketFiles: any = [];
        for (let i = 0; i < record?.quantity; i++) {
          ticketFiles.push({});
        }
        const visibility = visibilityMeterCalculationForKeyBoard(
          keyboardField,
          { ...keyboardField?.eventRecords?.[0], ticketFiles: ticketFiles }
        );

        return {
          ...record,
          ticketFiles: ticketFiles,
          oldVisibility:
            record.visibility === visibility
              ? record.oldVisibility
              : record.visibility,
          visibility: visibility,
        };
      }),
    };

    layout.setKeyboardField(updatedEvent);
  };

  const existAnyOfFile = keyboardField?.eventRecords?.filter((obj: any) => {
    return (
      obj?.ticketFiles?.filter((obj: any) => JSON.stringify(obj) !== "{}")
        ?.length > 0
    );
  });

  const ticketWithQty =
    !keyboardField.eventRecords?.[0]?.ticketType?.id ||
    keyboardField.eventRecords?.[0]?.ticketType?.id === "paper" ||
    keyboardField.eventRecords?.[0]?.ticketType?.id === "season-cards" ||
    keyboardField.eventRecords?.[0]?.ticketType?.id === "mobile" ||
    Number(keyboardField.eventRecords?.[0]?.quantity) < 1 ||
    layout.addAllEvents;

  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any,
    searchName: string = "name"
  ) => {
    if (benefitRef && benefitRef?.current) {
      benefitRef.current.blur();
    }
    if (deliveryTypeRef && deliveryTypeRef?.current) {
      deliveryTypeRef.current.blur();
    }
    if (restrictionRef && restrictionRef?.current) {
      restrictionRef.current.blur();
    }
    let isNameAvailable = options?.some((item: any) => item?.[`${searchName}`]);
    let updatedOptions =
      visibleOptions && size(visibleOptions) > 0
        ? visibleOptions
        : data?.target?.id === "select-all"
        ? options
        : null;

    if (data?.target?.id === "select-all") {
      layout.setKeyboardField((currentValue: any) => {
        return {
          ...currentValue,
          eventRecords: currentValue?.eventRecords?.map((obj: any) => {
            return {
              ...obj,
              [fieldName]: isNameAvailable
                ? updatedOptions?.filter(
                    (option: any) => option?.[`${searchName}`]
                  )
                : extractOptions(updatedOptions),
            };
          }),
        };
      });
    } else if (data?.target?.id === "deselect-all") {
      layout.setKeyboardField((currentValue: any) => {
        return {
          ...currentValue,
          eventRecords: currentValue?.eventRecords?.map((obj: any) => {
            return {
              ...obj,
              [fieldName]: removeSelectAll(
                obj?.[fieldName],
                isNameAvailable
                  ? updatedOptions
                  : extractOptions(updatedOptions)
              ),
            };
          }),
        };
      });
    }
  };

  const hasProceedValue =
    _.size(keyboardField?.eventRecords[0]?.proceedValue) > 0 &&
    keyboardField?.eventRecords[0]?.proceedValue?.value !== "0.00" &&
    keyboardField?.eventRecords[0]?.proceedValue?.value !== "";
  const hasNoCategory = _.size(keyboardField?.eventRecords[0]?.category) === 0;
  const hasNoSection = _.size(keyboardField?.eventRecords[0]?.section) === 0;

  const categorySectionRequired: any = {
    focus: priceFiledFocused,
    withoutCategory:
      _.size(keyboardField?.markeInsightsForAvgPrice) > 0
        ? hasProceedValue && (hasNoCategory || hasNoSection)
        : false,
    withCategory:
      _.size(keyboardField?.markeInsightsForAvgPrice) > 0
        ? hasProceedValue &&
          _.size(keyboardField?.eventRecords[0]?.category) > 0 &&
          _.size(keyboardField?.eventRecords[0]?.section) > 0
        : false,
  };

  function calculateAvgPriceForProceedValue(
    sectionname: any,
    markplaceArry: any
  ) {
    let sectionValue =
      sectionname?.target?.value?.originalName
        ?.trim()
        ?.toLowerCase()
        .split(" ")
        .join("") ||
      sectionname?.target?.value?.name
        ?.trim()
        ?.toLowerCase()
        .split(" ")
        .join("");

    let markeplaceValueWithAvg: any =
      markplaceArry &&
      Object.entries(markplaceArry)?.map((item: any) => {
        return {
          id: item?.[0],
          name: item?.[0]?.trim()?.toLowerCase().split(" ").join(""),
          avgPrice: item?.[1]?.total?.price_avg,
        };
      });
    let avgPrice = markeplaceValueWithAvg?.find(
      (obj: any) => obj.name === sectionValue
    )?.avgPrice;

    let finalAvgRate = avgPrice ? avgPrice * conversation_rate : null;
    layout.setKeyboardField((pre: any) => {
      return {
        ...pre,
        avg_price: finalAvgRate,
        eventRecords: pre?.eventRecords?.map((obj: any) => {
          return {
            ...obj,
            avg_price: finalAvgRate,
          };
        }),
      };
    });
  }

  const listingQualityMeterr =
    categorySectionRequired?.withCategory && keyboardField?.avg_price
      ? priceCalculation(
          keyboardField?.eventRecords?.[0]?.proceedValue?.value,
          Number(keyboardField?.avg_price),
          priceFactor
        )
      : false;

  const focusOnPriceField = (data: any) => {
    setPriceFieldFocused(data);
  };

  useEffect(() => {
    const footer_elem = document.getElementsByClassName(
      "page_footer"
    )[0] as HTMLElement;

    footer_dynamic();

    const handleResize = () => {
      footer_dynamic();
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", footer_dynamic);
    window?.addEventListener("change", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", footer_dynamic);
      window.removeEventListener("change", footer_dynamic);
    };
  }, []);

  const [proceedPriceLoader, setProceedPriceLoader] = useState<any>({
    count: 1,
    status: false,
  });

  // loader code for p
  // useEffect(() => {
  //   const handleProceedValue = async () => {
  //     if (
  //       _.size(keyboardField?.eventRecords?.[0]?.proceedValue) > 0 &&
  //       keyboardField?.eventRecords?.[0]?.proceedValue !== "0.00" &&
  //       _.size(keyboardField?.eventRecords[0]?.category) > 0 &&
  //       _.size(keyboardField?.eventRecords[0]?.section) > 0 &&
  //       priceFiledFocused &&
  //       proceedPriceLoader?.count === 1 &&
  //       keyboardField?.avg_price
  //     ) {
  //       setProceedPriceLoader((prevState: any) => ({
  //         ...prevState,
  //         status: true,
  //         count: (prevState?.count ?? 1) + 1,
  //       }));
  //       await new Promise((resolve) => setTimeout(resolve, 1300));
  //       setProceedPriceLoader((prevState: any) => ({
  //         ...prevState,
  //         status: false,
  //       }));
  //     }
  //     // }
  //   };

  //   handleProceedValue();
  // }, [
  //   _.size(keyboardField?.eventRecords?.[0]?.proceedValue) > 0 &&
  //     keyboardField?.eventRecords?.[0]?.proceedValue !== "0.00",
  //   priceFiledFocused,
  // ]);

  // CLOSE THE KEYBOARD UPLOAD TICKET POPUP
  const closeUploadTicketModal = () => {
    setIsPopUpOpen({ isOpen: false });
    if (!isPopUpOpen.hasOwnProperty("event")) {
      layout.setKeyboardField((current: any) => {
        return {
          ...current,
          eventRecords: current?.eventRecords?.map((record: any) => {
            return {
              ...record,
              ticketFiles: Array.from({
                length: maxFileCondition(record?.quantity),
              })?.map(() => ({})),
            };
          }),
        };
      });
    }
  };

  const handleResetKeyboard = () => {
    let defaultDateToShip = get2DaysBeforeDateOfEvent(events[0]?.date);
    resetKeyboardFieldsValues({
      dateToShip: layout.addAllEvents ? null : defaultDateToShip,
    });
  };

  function ToggleElemClose() {
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.remove("activeFIlter");
    document.body.classList.remove("overflow-hidden");
  }
  function ToggleElemOpen() {
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.add("activeFIlter");
    document.body.classList.add("overflow-hidden");
  }

  // useEffect(() => {
  //   const toggleButton: any = document.getElementById("filterBtn");
  //   const submenuWrapper: any = document.querySelector("#filtersMob");
  //   const closePopup: any = document.getElementById("closePopup");
  //   const ToggleElem: any = function () {
  //     if (submenuWrapper?.classList.contains("activeFIlter")) {
  //       // Slide up
  //       // submenuWrapper.style.maxHeight = null;
  //       submenuWrapper?.classList.remove("activeFIlter");
  //     } else {
  //       // Slide down
  //       // submenuWrapper.style.maxHeight = submenuWrapper.scrollHeight + "px";
  //       submenuWrapper?.classList.add("activeFIlter");
  //       document.body.classList.add("overflow-hidden");
  //     }
  //   };
  //   const ToggleElemClose = function () {
  //     submenuWrapper.classList.remove("activeFIlter");
  //     document.body.classList.remove("overflow-hidden");
  //   };

  //   toggleButton?.addEventListener("click", ToggleElem);
  //   closePopup.addEventListener("click", ToggleElemClose);
  //   return () => {
  //     toggleButton.removeEventListener("click", ToggleElem);
  //     closePopup.removeEventListener("click", ToggleElemClose);
  //   };
  // }, []);

  const handleButtonClick = () => {
    handleAddInventory();
    ToggleElemClose();
  };

  return (
    <>
      {isLoadingContent}
      {layout.isPublish && <PageTitle title={"Publish"} />}

      {/* Puhser Setup */}
      <PusherSetup setList={setList} />
      <RequestEvent />
      {/* Header Accordion */}
      {!layout.isPublish && (
        <>
          <PageTitle title={"Add Inventory"} />
          <div className="accordion mb-4 font-medium" id="accordionExample">
            <div className="accordion-item !rounded-none bg-white shadow-op2">
              <div
                className="flex accordion-header mb-0 max-md:hidden"
                id="headingOne"
              >
                <div className="pl-2 pr-5 py-2 flex max-md:hidden">
                  <HeaderfilterContainer />
                </div>
                <button
                  className=" group relative flex items-center p-[.9375rem] text-base text-gray-800 text-left bg-white border-l transition focus:outline-none group ml-auto [&[aria-expanded='false'] > .icon ]:rotate-180"
                  type="button"
                  data-te-collapse-init
                  data-te-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <IKTSVG
                    className="flex items-center justify-center  fill-violet-500 hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180 }"
                    path={Collapse}
                  />
                </button>
              </div>
              <div
                className={`md:border-t sm:gap-y-3 flex flex-wrap items-center md:-mx-[.313rem] top-search relative`}
              >
                <div
                  className={`${
                    layout.eventOrPerformer === "Event"
                      ? "gap-2.5"
                      : " gap-y-2.5"
                  } md:px-5 md:pr-2.5 px-[.9375rem] py-[.875rem] flex flex-wrap items-center flex-1 md:order-1 order-2 w-full`}
                >
                  <div
                    className={`w-full  md:min-w-[23.5rem] md:w-3/4 lg:w-1/2 xl:w-1/3 px-[.313rem] py-[.0625rem] md:max-w-[23.5rem] z-[1]`}
                    onClick={() => setEnabled(true)}
                  >
                    <SearchField
                      list={list}
                      placeholder={
                        layout.eventOrPerformer === "Event"
                          ? "Search event"
                          : "Search performer or venue"
                      }
                      options={algoliaOptions}
                      name="upcomingEvents"
                      classNamePrefix="inventory_select"
                      value={layout.searchFieldValue}
                      handleOnChange={(data: any, event: any) => {
                        if (data && _.size(data) > 0) {
                          layout.setTriggered(true);
                          layout.setSearchFieldValue({
                            ...data,
                            date: convertDate(data?.event_date_local),
                            time: convertTime(data?.event_date_local),
                            address: data?.venue_name,
                          });
                          handleEventSelect(data);
                        }
                      }}
                      inputValue={debouncedSearch?.value}
                      onInputChange={debouncedChangeHandler}
                      onClick={(data: any, ref: any) => {
                        handleSearchClick(data, ref);
                        // if (data.target?.id === "bottom") {
                        //   setSearchedValue((current: any) => {
                        //     return {
                        //       value: debouncedSearch.value,
                        //       id: "bottom",
                        //       per_page: 1,
                        //     };
                        //   });
                        // }
                      }}
                      onMenuScrollToBottom={() => {
                        // if (
                        //   searchFieldValue.per_page >= 1 &&
                        //   data?.[0]?.hits?.length > 0 &&
                        //   data?.[0]?.nbPages >= searchFieldValue.per_page + 2
                        // ) {
                        //   setSearchedValue((current: any) => {
                        //     return {
                        //       value: debouncedSearch.value,
                        //       id: "bottom",
                        //       per_page:
                        //         data[0]?.nbPages >= current?.per_page &&
                        //         current?.per_page + 1,
                        //     };
                        //   });
                        // }
                      }}
                      isLoading={isLoading}
                      isDisabled={layout?.searchFieldValue?.isDisabled}
                    />
                  </div>

                  {/* SideValue when select an event */}
                  {searchedValue &&
                  Object.keys(searchedValue).length > 0 &&
                  layout.eventOrPerformer === "Event" ? (
                    <div className="text-xs mt-0 lg:mt-2 lg:mb-2 pl-[.3125rem] gap-4 flex flex-wrap items-center">
                      <span className="inline-flex items-center">
                        {searchedValue?.date && (
                          <IKTSVG
                            className="flex items-center justify-center min-w-[0.75] fill-black mr-2"
                            path={Calendar}
                          />
                        )}
                        {searchedValue?.date}
                      </span>
                      <span className="inline-flex items-center">
                        {searchedValue?.time && (
                          <IKTSVG
                            className="flex items-center justify-center min-w-[0.75] fill-black mr-2"
                            path={ClockOutline}
                          />
                        )}
                        {searchedValue?.time}
                      </span>
                      <span
                        className="inline-flex items-center"
                        title={searchedValue?.address}
                      >
                        {searchedValue?.address && (
                          <IKTSVG
                            className="flex items-center justify-center min-w-[0.75] fill-black mr-2"
                            path={GeoIcon}
                          />
                        )}
                        {searchedValue?.address}
                      </span>
                    </div>
                  ) : triggered &&
                    layout.eventOrPerformer === "Performer/venue" &&
                    layout.performer.length > 0 ? (
                    <>
                      <div className="w-full sm:w-1/2 lg:w-1/4 xl:w-[12.5%] px-[.313rem] min-w-[12.0625rem]">
                        <DropDownField
                          options={TIMEFRAMES}
                          placeholder="Timeframe"
                          selectInputRef={ref}
                          isClearable={false}
                          name="timeframe"
                          value={layout.performerKeyboardField?.timeframe}
                          handleOnChange={(data: any) =>
                            PerformerKeyboardHandle(data)
                          }
                        />
                      </div>
                      <div className="w-full sm:w-1/2 lg:w-1/4 xl:w-[12.5%] px-[.313rem] min-w-[12.0625rem]">
                        <DatePickerField
                          name="start_date"
                          value={
                            _.size(layout.performerKeyboardField.start_date) > 0
                              ? layout.performerKeyboardField.start_date
                              : undefined
                          }
                          placeholder="Date range"
                          isDateRange={true}
                          format="dd/MM/yy"
                          disabledKeyboardNavigation={true}
                          handleOnChange={(data: any) => {
                            // if (data?.target?.value !== "") {
                            PerformerKeyboardHandle(data);
                            // }
                          }}
                          minDate={true}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                {/*{triggered && layout.eventOrPerformer === "Event" && (*/}
                <div className="visible-cir ml-auto flex items-center md:px-5 md:pl-2.5 px-[.9375rem] py-[.875rem] md:w-auto w-full justify-end md:border-b-0 border-b md:order-2">
                  <Tooltip
                    anchorId={`visibility_addInventory`}
                    // content={
                    //   keyboardField?.eventRecords?.[0]?.visibility === 1
                    //     ? `Listing Quality Low`
                    //     : keyboardField?.eventRecords?.[0]?.visibility === 2
                    //     ? `Listing Quality Low`
                    //     : keyboardField?.eventRecords?.[0]?.visibility === 3
                    //     ? `Listing Quality Level 1`
                    //     : keyboardField?.eventRecords?.[0]?.visibility === 4
                    //     ? `Listing Quality Level 2`
                    //     : keyboardField?.eventRecords?.[0]?.visibility === 5
                    //     ? `Listing Quality Level 3`
                    //     : keyboardField?.eventRecords?.[0]?.visibility === 6
                    //     ? `Listing Quality Level 4`
                    //     : ""
                    // }
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium "
                  />
                  <div
                    className={`inner-wrap-cir w-full flex max-md:gap-4 max-md:flex-wrap max-md:justify-end items-center px-2.5 pr-0 rounded ${
                      // keyboardField?.eventRecords?.[0]?.visibility === 5 ||
                      ` ${
                        flag &&
                        "border border-indigo-500 bg-indigo-500 bg-opacity-[7%] py-1.5"
                      } `
                    }`}
                  >
                    <div
                      className={`text-sm12 scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:mr-2.5 leading-4 anim-fade text-violet-800 flex g-2 items-center anim-fade
                      `}
                    >
                      {(keyboardField?.eventRecords?.[0]?.visibility === 0 &&
                        searchedValue === "") ||
                      searchedValue === "" ? (
                        <>
                          <span className="flex item-center">
                            Listing Quality
                            <span
                              data-tooltip-id="visibility_info"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offvisibility_info"
                              aria-controls="offvisibility_info"
                              onMouseEnter={() => {
                                setHoverInfo(true);
                              }}
                              onMouseLeave={() => {
                                setHoverInfo(false);
                              }}
                            >
                              <IKTSVG
                                svgClassName="w-3.5 h-3.5 ml-[.3125rem] fill-gray-400 hover:fill-indigo-500 cursor-pointer"
                                path={infoIcon}
                              />
                            </span>
                            <TooltipPortal
                              id={`visibility_info`}
                              content={"Click for more info"}
                              place="top"
                              variant="light"
                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium "
                            />
                          </span>
                        </>
                      ) : keyboardField?.eventRecords?.[0]?.visibility === 6 ? (
                        <>
                          <div className="logos flex items-center gap-2.5 transition-all anim-fade max-md:whitespace-nowrap">
                            {areImagesLoaded &&
                              svgImageUrls &&
                              svgImageUrls?.map((img: any) => {
                                return (
                                  <div className="single-logo">
                                    <SVG src={img} />
                                  </div>
                                );
                              })}
                            <div className="more text-sm12 font-medium text-violet-500">
                              +10 more
                            </div>
                          </div>
                          <div>
                            <span
                              data-tooltip-id="visibility_info"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offvisibility_info"
                              aria-controls="offvisibility_info"
                              // onMouseEnter={() => {
                              //   setHoverInfo(true);
                              // }}
                              // onMouseLeave={() => {
                              //   setHoverInfo(false);
                              // }}
                            >
                              <IKTSVG
                                svgClassName="w-3.5 h-3.5 ml-[.3125rem] fill-gray-400 hover:fill-indigo-500 cursor-pointer"
                                path={infoIcon}
                              />
                            </span>
                            <TooltipPortal
                              id={`visibility_info`}
                              content={"Click for more info"}
                              place="top"
                              variant="light"
                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium "
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <span
                            className={`${keyBoardVisiblityDynamicMessage1(
                              keyboardField?.eventRecords?.[0]?.visibility,
                              keyboardField?.eventRecords?.[0],
                              searchedValue,
                              listingQualityMeterr,
                              priceFiledFocused,
                              keyboardField,
                              proceedPriceLoader?.status
                            )}`}
                          >
                            {keyBoardVisiblityDynamicMessage(
                              keyboardField?.eventRecords?.[0]?.visibility,
                              keyboardField?.eventRecords?.[0],
                              searchedValue,
                              listingQualityMeterr,
                              priceFiledFocused,
                              keyboardField,
                              proceedPriceLoader?.status
                            )}
                          </span>
                          <div>
                            <span
                              data-tooltip-id="visibility_info"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offvisibility_info"
                              aria-controls="offvisibility_info"
                              // onMouseEnter={() => {
                              //   setHoverInfo(true);
                              // }}
                              // onMouseLeave={() => {
                              //   setHoverInfo(false);
                              // }}
                            >
                              <IKTSVG
                                svgClassName="w-3.5 h-3.5 ml-[.3125rem] fill-gray-400 hover:fill-indigo-500 cursor-pointer"
                                path={infoIcon}
                              />
                            </span>
                            <TooltipPortal
                              id={`visibility_info`}
                              content={"Click for more info"}
                              place="top"
                              variant="light"
                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium "
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <span
                      id="visibility_addInventory"
                      className="cursor-pointer"
                    >
                      <div
                        // className={`svg-wrap visibility-case${keyboardField?.eventRecords?.[0]?.oldVisibility}-${keyboardField?.eventRecords?.[0]?.visibility} border rounded p-[.1875rem] flex items-center justify-center gap-0.5`}
                        className={`svg-wrap   ${
                          searchedValue !== "" &&
                          visiblityMeter(
                            keyboardField?.eventRecords?.[0]?.oldVisibility,
                            keyboardField?.eventRecords?.[0]?.visibility,
                            searchedValue,
                            keyboardField?.eventRecords?.[0]
                          )
                        }    border rounded p-[.1875rem] flex items-center  ${
                          hoverInfo && "border-indigo-500"
                        }   ${colorForMeter(
                          listingQualityMeterr,
                          keyboardField?.eventRecords?.[0]
                        )}  hover:border-indigo-500 justify-center gap-0.5`}
                        data-tooltip-id="visibility_info"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offvisibility_info"
                        aria-controls="offvisibility_info"
                      >
                        <span className="s_block w-[4.125rem] h-1.5 bg-gray-300 rounded-l-[.125rem]"></span>
                        <span className="s_block w-[.9375rem] h-1.5 bg-gray-300"></span>
                        <span className="s_block w-[.9375rem] h-1.5 bg-gray-300"></span>
                        <span className="s_block w-[.9375rem] h-1.5 bg-gray-300"></span>
                        <span className="s_block w-[.9375rem] h-1.5 bg-gray-300 rounded-r-[.125rem]"></span>
                      </div>
                    </span>

                    {/* off canvas code */}
                    <div
                      data-te-modal-init
                      className={`offcanvas fill_canvas offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium  w-full upload-ticket-popup z-40`}
                      tabIndex={-1}
                      id="offvisibility_info"
                      aria-labelledby="OffVisibilityInfo"
                      data-bs-backdrop="false"
                    >
                      {/* FORMIk */}
                      <div className="h-full md:max-w-[43.125rem]  ml-auto">
                        <div className="  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
                          <div className="offcanvas-header flex items-center justify-between border-t border-b">
                            <h5
                              className="offcanvas-title mb-0 leading-normal font-semibold py-2 px-5 text-sm15"
                              id="offcanvasRightLabel"
                            >
                              Listing Quality
                            </h5>
                            <button
                              type="button"
                              className="flex items-center justify-center h-10 w-10 border-l box-content group"
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              // onClick={() => {
                              //   onClose(false);
                              // }}
                            >
                              {/* <span className="btn-close w-3 h-3 text-black hover:text-indigo-500"></span> */}
                              <KTSVG
                                className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                path="standard_icons/cross.svg"
                              />
                            </button>
                          </div>

                          {/* MAIN FORM */}
                          <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:p-[1.875rem] p-5 mb-1 text-gray-600">
                            <div className="top-content text-sm13 text-gray-600">
                              <p className="mb-5">
                                Tixstock partners with a vast global network,
                                granting access to our complete events
                                inventory. Each partner maintains control of the
                                listings they display to their customers,
                                including factors such as event type, seller
                                fulfillment levels and immediate download of
                                tickets. This prompts questions from Tixstock
                                sellers like, “Are my listings visible across
                                all channels?” and “Which listings need
                                attention?”.
                              </p>
                              <div className="logos flex items-center justify-center gap-2.5 my-[1.875rem] flex-wrap">
                                <img src={stubhub} className="w-[3.625rem]" />
                                <img src={ticombo} className="w-[3.75rem]" />
                                <img src={ticketped} className="w-[5.625rem]" />
                                <img src={txnetwork} className="w-[6.875rem]" />
                                <span className="text-sm13 text-violet-500">
                                  +10 more
                                </span>
                              </div>
                              <p className="mb-5">
                                To ensure maximum visibility on the Tixstock
                                Network, we’ve developed a system to determine
                                Listing Quality. This system assesses the
                                quality of a listing based on event type and
                                ticket details presented to consumers. Refer to
                                the information below and tooltips on the Add
                                Inventory keyboard to optimise your listings and
                                enhance your chances of selling quickly on the
                                Tixstock Network.
                              </p>
                            </div>
                            <div className="meter_info mt-[1.875rem] rounded-xl">
                              <h2 className="text-sm15 font-semibold leading-5 mb-5">
                                How it works
                              </h2>
                              <div className="info_block gap-x-1.5 flex flex-nowrap border border-gray-400 rounded-lg p-1.5 bg-white text-center text-white md:text-sm13 text-sm11 mb-5">
                                <span className="rounded-l bg-violet-500 text-white w-full md:min-w-[50%] min-w-[23%]  p-0.5">
                                  Low
                                </span>
                                <span className="w-full bg-green-600 p-0.5">
                                  Level 1
                                </span>
                                <span className="w-full bg-green-600 p-0.5">
                                  Level 2
                                </span>
                                <span className="w-full bg-green-600 p-0.5">
                                  Level 3
                                </span>
                                <span className="w-full bg-green-600 p-0.5 rounded-r">
                                  Level 4
                                </span>
                              </div>
                              <div className="require_field text-sm13 md:p-5 p-3.5 md:pt-4 bg-gray-100/50 rounded-lg">
                                <h2 className="text-sm15 font-semibold leading-5 mb-3.5">
                                  Required fields
                                </h2>
                                <p className="font-semibold mb-1.5">
                                  Ticket type + Quantity + Category +
                                  Section/block + Face value + Proceed price
                                </p>
                                <p>
                                  Listing Quality will increase as you complete
                                  the fields required to list on the Tixstock
                                  Network.
                                </p>
                              </div>
                              <div className="levels_info mt-5">
                                <div className="single-level bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4">
                                  <div className="title-level flex flex-wrap items-center mb-3">
                                    <h2 className="leading-5 text-sm15 font-semibold">
                                      Level 1
                                    </h2>
                                    <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white ml-5">
                                      <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem] rounded-r-sm"></span>
                                    </div>
                                  </div>
                                  <div className="level_content text-sm13">
                                    <p className="mb-3">All required fields:</p>
                                    <p className="font-semibold mb-3">
                                      Section/block
                                    </p>
                                    <p className="mb-4">
                                      ▪ Same as Category where block-specific
                                      options available
                                    </p>
                                    <div className="blocks -mx-2.5 flex flex-wrap">
                                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full max-sm:mb-4">
                                        <div className="input_inner bg-white border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                                            Category
                                          </label>
                                          Longside Lower
                                        </div>
                                      </div>
                                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full">
                                        <div className="input_inner bg-white border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                                            Section/block
                                          </label>
                                          Longside Lower
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="single-level bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4 mt-5">
                                  <div className="title-level flex flex-wrap items-center mb-3">
                                    <h2 className="leading-5 text-sm15 font-semibold">
                                      Level 2
                                    </h2>
                                    <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white ml-5">
                                      <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem] rounded-r-sm"></span>
                                    </div>
                                  </div>
                                  <div className="level_content text-sm13">
                                    <p className="mb-3">All required fields:</p>
                                    <p className="font-semibold mb-3">
                                      Section/block
                                    </p>
                                    <p className="mb-4">
                                      ▪ Different to Category where
                                      block-specific options available
                                    </p>
                                    <div className="blocks -mx-2.5 flex flex-wrap">
                                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full max-sm:mb-4">
                                        <div className="input_inner bg-white border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                                            Category
                                          </label>
                                          Longside Lower
                                        </div>
                                      </div>
                                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full">
                                        <div className="input_inner bg-white  border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                                            Section/block
                                          </label>
                                          Block 111
                                        </div>
                                      </div>
                                    </div>
                                    <p className="mb-4 mt-3">
                                      ▪ Same as Category where block-specific
                                      options unavailable
                                    </p>
                                    <div className="blocks -mx-2.5 flex flex-wrap">
                                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full max-sm:mb-4">
                                        <div className="input_inner bg-white border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                                            Category
                                          </label>
                                          Floor Standing
                                        </div>
                                      </div>
                                      <div className="dd_block input_block px-2.5 sm:w-1/2 w-full">
                                        <div className="input_inner bg-white border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center text-violet-800">
                                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                                            Section/block
                                          </label>
                                          Floor Standing
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="single-level bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4 mt-5">
                                  <div className="title-level flex flex-wrap items-center mb-3">
                                    <h2 className="leading-5 text-sm15 font-semibold">
                                      Level 3
                                    </h2>
                                    <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white ml-5">
                                      <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-gray-300 p-[.1875rem] rounded-r-sm"></span>
                                    </div>
                                  </div>
                                  <div className="level_content text-sm13">
                                    <p className="mb-3">
                                      All Level 2 requirements plus:
                                    </p>
                                    {/* <p className="font-semibold mb-3">
                                      Row (Sports)
                                    </p> */}

                                    {/* <div className="blocks -mx-2.5 flex flex-wrap">
                                      <div className=" input_block px-2.5 w-1/2">
                                        <div className="input_inner border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center">
                                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                                            Row
                                          </label>
                                          AAA
                                        </div>
                                      </div>
                                    </div> */}
                                    {/* <p className="font-semibold mb-3 mt-4">
                                      Row + Seat{" "}
                                      <span className="font-medium">
                                        (Concerts)
                                      </span>
                                    </p> */}
                                    {/* <div className="blocks -mx-2.5 flex flex-wrap">
                                      <div className=" input_block px-2.5 w-1/2">
                                        <div className="input_inner border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center">
                                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                                            Row
                                          </label>
                                          AAA
                                        </div>
                                      </div>
                                      <div className=" input_block px-2.5 w-1/2">
                                        <div className="input_inner border rounded relative text-sm12 p-1 min-h-[1.875rem] pl-2.5 leading-4 flex items-center">
                                          <label className="absolute top-0 left-2 -translate-y-1/2 bg-white px-[.1875rem] text-gray-400 text-xxs">
                                            First seat
                                          </label>
                                          111
                                        </div>
                                      </div>
                                    </div> */}
                                    {/* <p className="mb-3 mt-4">
                                      Row and Seat fields are limited to 3
                                      alphanumeric characters
                                    </p> */}
                                    <p className="font-semibold mb-3 mt-4">
                                      Tickets in hand
                                    </p>

                                    <div className="blocks -mx-2.5 flex flex-wrap">
                                      <div className="input_block px-2.5 sm:w-1/2 w-full ">
                                        <div className="input_inner bg-white border border-green-600 rounded relative text-sm12 text-green-600 p-1 min-h-[1.875rem] pl-[2.375rem] leading-4 flex items-center overflow-hidden justify-between">
                                          <div className="icon_block bg-white absolute left-0 top-0 w-[1.875rem] h-full flex items-center justify-center border-r border-green-600">
                                            <IKTSVG
                                              path={handIcon}
                                              svgClassName="fill-green-600 w-[.6875rem] h-3.5"
                                            />
                                          </div>
                                          Tickets in hand
                                          <div className="checkbox bg-green-500 w-[.8125rem] h-[.8125rem] flex items-center justify-center rounded-sm mr-1">
                                            <IKTSVG
                                              path={checkIcon}
                                              svgClassName="fill-white w-[.4375rem]"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="mt-3">
                                      In-hand tickets must be delivered within
                                      24 hours of a confirmed sale - failure to
                                      meet this deadline can result in
                                      cancellation and a penalty on your
                                      account.
                                    </p>
                                  </div>
                                </div>

                                <div className="single-level bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4 mt-5">
                                  <div className="title-level flex flex-wrap items-center mb-3">
                                    <h2 className="leading-5 text-sm15 font-semibold">
                                      Level 4
                                    </h2>
                                    <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white ml-5">
                                      <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                      <span className="w-[.9375rem] bg-green-600 p-[.1875rem] rounded-r-sm"></span>
                                    </div>
                                  </div>

                                  <div className="level_content text-sm13">
                                    <p className="mb-3">
                                      All required fields plus:
                                    </p>
                                    <p className="font-semibold mb-3 ">
                                      Upload tickets
                                    </p>

                                    <div className="blocks -mx-2.5 flex flex-wrap">
                                      <div className="input_block px-2.5 sm:w-1/2 w-full">
                                        <div className="input_inner bg-white border border-green-600 rounded relative text-sm12 text-green-600 p-1 min-h-[1.875rem] pl-[2.375rem] leading-4 flex items-center overflow-hidden justify-between">
                                          <div className="icon_block bg-white absolute left-0 top-0 w-[1.875rem] h-full flex items-center justify-center border-r border-green-600">
                                            <IKTSVG
                                              path={uploadIcon}
                                              svgClassName="fill-green-600 w-[.8125rem]"
                                            />
                                          </div>
                                          Tickets uploaded
                                          <div className="checkbox w-[.8125rem] h-[.8125rem] flex items-center justify-center rounded-sm mr-1">
                                            <IKTSVG
                                              path={Cross}
                                              svgClassName="fill-green-600 w-2 h-2"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <p className="mt-3">
                                      Make tickets available for immediate
                                      download to reach maximum Listing Quality.
                                    </p>
                                  </div>
                                </div>

                                <div className="single-level bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4 mt-5">
                                  <div className="title-level flex flex-wrap items-center mb-3">
                                    <h2 className="leading-5 text-sm15 font-semibold">
                                      Price suggestions
                                    </h2>
                                  </div>

                                  <div className="level_content text-sm13">
                                    <p className="mb-3">
                                      Suggestions are provided on the Add
                                      Inventory keyboard and within the Proceed
                                      price field.
                                    </p>
                                    <div className="meter_info flex flex-wrap flex-col mb-[.9375rem]">
                                      <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white max-w-[8.75rem] mb-2.5">
                                        <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-green-600 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-green-600 p-[.1875rem] rounded-r-sm"></span>
                                      </div>
                                      <p className="leading-4">
                                        Green - Competitively priced and likely
                                        to sell
                                      </p>
                                    </div>
                                    <div className="meter_info flex flex-wrap flex-col mb-[.9375rem]">
                                      <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white max-w-[8.75rem] mb-2.5">
                                        <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-orange-500 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-orange-500 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-orange-500 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-orange-500 p-[.1875rem] rounded-r-sm"></span>
                                      </div>
                                      <p className="leading-4">
                                        Orange - More likely to sell at a lower
                                        price
                                      </p>
                                    </div>
                                    <div className="meter_info flex flex-wrap flex-col mb-[.9375rem]">
                                      <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white max-w-[8.75rem] mb-2.5">
                                        <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-rose-500 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-rose-500 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-rose-500 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-rose-500 p-[.1875rem] rounded-r-sm"></span>
                                      </div>
                                      <p className="leading-4">
                                        Red - Not likely to sell at this price
                                      </p>
                                    </div>
                                    <div className="meter_info flex flex-wrap flex-col">
                                      <div className="info_block gap-x-0.5 flex flex-nowrap border border-gray-400 rounded p-[.1875rem] bg-white max-w-[8.75rem] mb-2.5">
                                        <span className="rounded-l-sm bg-violet-500 text-white w-[4.125rem] p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-sky-blue-500 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-sky-blue-500 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-sky-blue-500 p-[.1875rem]"></span>
                                        <span className="w-[.9375rem] bg-sky-blue-500 p-[.1875rem] rounded-r-sm"></span>
                                      </div>
                                      <p className="leading-4">
                                        Blue - Price too low
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="not_affect bg-gray-100/50 rounded-lg md:p-5 p-3.5 md:pt-4 mt-5 ">
                                  <h2 className="leading-5 text-sm15 font-semibold">
                                    The following fields do not affect Listing
                                    Quality levels
                                  </h2>
                                  <p className="mt-3 text-sm13">
                                    Split type, Max display quantity, Row, First
                                    seat, Benefits, Restrictions*
                                  </p>
                                  <p className="mt-2 text-sm13">
                                    *Failure to disclose Restrictions can result
                                    in cancellation and a penalty on your
                                    account.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {loader && <Loader />} */}
                    </div>
                  </div>
                </div>
                {/*})}*/}
              </div>
              {/* Keyboard Fields */}
              {triggered && layout.eventOrPerformer === "Event" ? (
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  data-te-collapse-item
                  data-te-collapse-show
                  aria-labelledby="headingOne"
                >
                  {/* Keyboard Components */}
                  <div
                    className="filtersMob max-md:flex max-md:flex-col"
                    id={"filtersMob"}
                  >
                    <div
                      className="closePopup hidden max-md:flex"
                      id="closePopup"
                      onClick={() => {
                        ToggleElemClose();
                      }}
                    >
                      <IKTSVG
                        path={close}
                        className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
                        svgClassName="w-4 h-4"
                      />
                    </div>
                    <div
                      className={`accordion-body -mx-[.3125rem] pt-4 pb-1 px-5 border-y max-md:pt-14 max-md:pb-16  max-md:flex-1 max-md:overflow-auto max-md:content-start ${
                        customLoader && `pointer-events-none opacity-30`
                      } h-full`}
                    >
                      <div className="flex flex-wrap">
                        <>
                          <div
                            className={`${
                              coreLayout.htmlFontSize === 120 &&
                              "min-w-[16.66%]"
                            } ${
                              coreLayout.htmlFontSize === 110 &&
                              "min-w-[14.28%]"
                            } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                              2
                            )} `}
                            // title={
                            //   keyboardField?.eventRecords?.[0]?.ticketType?.name
                            // }
                          >
                            <DropDownField
                              value={ticketType}
                              selectInputRef={ticketTypeRef}
                              options={layout.ticketType}
                              isCapital={true}
                              isValueCapital={true}
                              placeholder="Ticket type"
                              name="ticketType"
                              isClearable={true}
                              handleOnChange={(data: any) => {
                                setTicketType(data?.target?.value);
                                handleKeyboardChange(data);
                              }}
                              ticketTypeIconVisible={true}
                              //defaultValue={layout.ticketType?.[0]}
                            />
                          </div>

                          {/* Sub ticket type  */}
                          {/* {keyboardField?.eventRecords?.[0]?.ticketType?.id ===
                          "eticket" && (
                          <div
                            className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                              2
                            )}`}
                            title={
                              keyboardField?.eventRecords?.[0]?.ticketType?.name
                            }
                          >
                            <DropDownField
                              options={subTicketType.subtypes}
                              value={
                                keyboardField?.eventRecords?.[0]
                                  ?.sub_ticket_type
                              }
                              isValueCapital={true}
                              isCapital={true}
                              isClearable={false}
                              placeholder="Sub ticket type"
                              name="sub_ticket_type"
                              handleOnChange={(data: any) =>
                                handleKeyboardChange(data)
                              }
                            />
                          </div>
                        )} */}

                          {/* Deliverty Type */}
                          {ticketType?.id === "paper" && (
                            <div
                              className={`${
                                coreLayout.htmlFontSize === 120 &&
                                "min-w-[16.66%]"
                              } ${
                                coreLayout.htmlFontSize === 110 &&
                                "min-w-[14.28%]"
                              } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                                2
                              )}`}
                              title={
                                keyboardField?.eventRecords?.[0]?.deliveryType
                                  ?.type
                              }
                            >
                              <MultiDropDownField
                                name="deliveryType"
                                classNamePrefix={"form_multiDropDown"}
                                options={layout.deliveryType}
                                placeholder="Delivery type"
                                isSearchable={true}
                                handleOnChange={(data: any) =>
                                  handleKeyboardChange(data)
                                }
                                selectedRef={deliveryTypeRef}
                                value={
                                  keyboardField?.eventRecords[0]?.deliveryType
                                    ? keyboardField?.eventRecords[0]
                                        ?.deliveryType
                                    : null
                                }
                                getOptionLabel="type"
                                handleOnSelection={(
                                  data: any,
                                  visibleOptions: any
                                ) =>
                                  handleOnSelection(
                                    data,
                                    "deliveryType",
                                    layout.deliveryType,
                                    visibleOptions,
                                    "id"
                                  )
                                }
                              />
                            </div>
                          )}

                          {ticketType?.id === "paper" &&
                            isArray(
                              keyboardField?.eventRecords[0]?.deliveryType
                            ) &&
                            _.orderBy(
                              keyboardField?.eventRecords[0]?.deliveryType,
                              deliveryTypeOrders,
                              ["asc"]
                            ).map((item: any) => {
                              return (
                                <div
                                  className={`${
                                    coreLayout.htmlFontSize === 120 &&
                                    "min-w-[16.66%]"
                                  } ${
                                    coreLayout.htmlFontSize === 110 &&
                                    "min-w-[14.28%]"
                                  } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                                    2
                                  )}`}
                                >
                                  {" "}
                                  <Textfield
                                    name={item?.id}
                                    type="number"
                                    label={item?.type}
                                    id={item?.id}
                                    handleOnChange={(data: any) =>
                                      handleKeyboardChange(data)
                                    }
                                    isFocus={(data: any) => {}}
                                    passFocus={(data: any) => {}}
                                  />{" "}
                                </div>
                              );
                            })}
                        </>

                        {/* {keyboardField?.eventRecords?.[0]?.ticketType?.id ===
                        "eticket" &&
                        layout?.keyboardItems[
                          `${getKeyboardItemIndexForCheckbox(3)}`
                        ]?.checked && (
                          <div
                            className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                              3
                            )}`}
                            title={
                              keyboardField?.eventRecords?.[0]?.ticketType?.name
                            }
                          >
                            <DropDownField
                              options={subTicketType.subtypes}
                              value={
                                keyboardField?.eventRecords?.[0]
                                  ?.sub_ticket_type
                              }
                              isValueCapital={true}
                              isCapital={true}
                              isClearable={false}
                              placeholder="Sub ticket type"
                              name="sub_ticket_type"
                              handleOnChange={(data: any) =>
                                handleKeyboardChange(data)
                              }
                            />
                          </div>
                        )} */}

                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "min-w-[14.28%]"
                          } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                            5
                          )}`}
                        >
                          <Textfield
                            name="quantity"
                            reff={quantityRef}
                            type="number"
                            label="Quantity"
                            id="quantity"
                            isFocus={(data: any) => {}}
                            passFocus={(data: any) => {}}
                            onKeyDown={handleKeyDown}
                            handleOnChange={(data: any) =>
                              handleKeyboardChange(data)
                            }
                          />
                        </div>

                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "min-w-[14.28%]"
                          } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                            6
                          )}`}
                          title={
                            keyboardField?.eventRecords?.[0]?.splitType?.name
                          }
                        >
                          <DropDownField
                            selectInputRef={spittingRef}
                            options={layout.splitType}
                            placeholder="Split type"
                            name="splitType"
                            isClearable={false}
                            handleOnChange={(data: any) =>
                              handleKeyboardChange(data)
                            }
                            value={
                              keyboardField?.eventRecords?.[0]?.splitType
                                ?.id === "ANY"
                                ? { id: "ANY", name: "none" }
                                : keyboardField?.eventRecords?.[0]?.splitType
                            }
                          />
                        </div>

                        {sellMultipleSplitType &&
                          sellMultipleSplitType?.id === "MULTIPLES" && (
                            <div
                              className={`${
                                coreLayout.htmlFontSize === 120 &&
                                "min-w-[16.66%]"
                              } ${
                                coreLayout.htmlFontSize === 110 &&
                                "min-w-[14.28%]"
                              } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                                6
                              )}`}
                            >
                              <Textfield
                                name="MULTIPLES"
                                type="number"
                                label="Sell in multiple"
                                id="MULTIPLES"
                                reff={multipleRef}
                                isFocus={(data: any) => {}}
                                passFocus={(data: any) => {}}
                                handleOnChange={(data: any) => {
                                  handleKeyboardChange(data);
                                }}
                              />
                            </div>
                          )}

                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "!min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "!min-w-[14.28%]"
                          } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] min-w-[10.0625rem] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                            7
                          )}`}
                        >
                          <Textfield
                            name="maxDisplayQuantity"
                            type="number"
                            reff={displayQuantityRef}
                            label="Max display quantity"
                            id="maxDisplayQuantity"
                            isFocus={(data: any) => {}}
                            passFocus={(data: any) => {}}
                            handleOnChange={(data: any) =>
                              handleKeyboardChange(data)
                            }
                            WithIcon={true}
                            defaultValue={
                              keyboardField?.eventRecords?.[0]
                                ?.maxDisplayQuantity
                            }
                          />
                        </div>

                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "min-w-[14.28%]"
                          } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                            8
                          )}`}
                        >
                          <DropDownField
                            selectInputRef={categoryRef}
                            options={uniqueByNameWithPriority(
                              keyboardField?.categories,
                              ""
                            )}
                            placeholder="Category"
                            isCapital={true}
                            isValueCapital={true}
                            name="category"
                            handleOnChange={(data: any) => {
                              handleKeyboardChange(data);
                            }}
                            value={
                              _.size(keyboardField?.eventRecords[0]?.category) >
                                0 && keyboardField?.eventRecords[0]?.category
                            }
                          />
                        </div>

                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "min-w-[14.28%]"
                          } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 proceed-price order-${getKeyboardItemIndex(
                            9
                          )} ${PPE_Enabled && "pointer-events-none"}`}
                        >
                          <DropDownField
                            selectInputRef={sectionRef}
                            options={uniqueByNameWithPriority(section, "")}
                            isCapital={true}
                            isValueCapital={true}
                            placeholder="Section/block"
                            name="section"
                            handleOnChange={(data: any) => {
                              handleKeyboardChange(data);

                              calculateAvgPriceForProceedValue(
                                data,
                                keyboardField?.markeInsightsForAvgPrice
                              );
                            }}
                            value={
                              _.size(keyboardField?.eventRecords[0]?.section) >
                                0 && keyboardField?.eventRecords[0]?.section
                            }
                          />
                        </div>

                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "min-w-[14.28%]"
                          } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                            10
                          )}`}
                          title={
                            keyboardField?.eventRecords?.[0]?.category?.name
                          }
                        >
                          <Textfield
                            reff={rowRef}
                            name="row"
                            type="text"
                            label="Row"
                            id="row"
                            isFocus={(data: any) => {}}
                            passFocus={(data: any) => {}}
                            handleOnChange={(data: any) =>
                              handleKeyboardChange(data)
                            }
                          />
                        </div>

                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "min-w-[14.28%]"
                          } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                            11
                          )}`}
                          title={
                            keyboardField?.eventRecords?.[0]?.section?.name
                          }
                        >
                          <Textfield
                            reff={firstSeatRef}
                            name="firstSeat"
                            type="number"
                            label="First seat"
                            id="firstSeat"
                            passFocus={(data: any) => {}}
                            isFocus={(data: any) => {}}
                            handleOnChange={(data: any) =>
                              handleKeyboardChange(data)
                            }
                          />
                        </div>

                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "!min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "!min-w-[14.28%]"
                          } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 text-xs order-${getKeyboardItemIndex(
                            12
                          )}`}
                        >
                          <PriceField
                            name="faceValue"
                            currenncySymbol={target_currency}
                            id="Facevalue"
                            label="Face value"
                            handleOnChange={(data: any) => {
                              const value = decimalNumber(
                                data.target?.value?.value
                              );
                              let event = {
                                target: {
                                  name: data.target.name,
                                  value: {
                                    name: data.target.name,
                                    value: value,
                                    symbol: data?.target?.value?.symbol,
                                  },
                                },
                              };
                              handleKeyboardChange(event);
                            }}
                            reff={faceValueRef}
                            textfieldType={"number"}
                          />
                        </div>

                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "min-w-[14.28%]"
                          } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] proceed-price mb-3.5 order-${getKeyboardItemIndex(
                            13
                          )}`}
                        >
                          <PriceField
                            name="proceedValue"
                            id="proceedValue"
                            currenncySymbol={target_currency}
                            value={PPE_Enabled ? "" : undefined}
                            label="Proceed price"
                            handleOnChange={(data: any) => {
                              const value = decimalNumber(
                                data.target?.value?.value
                              );
                              let event = {
                                target: {
                                  name: data.target.name,
                                  value: {
                                    name: data.target.name,
                                    value: value,
                                    symbol: data?.target?.value?.symbol,
                                  },
                                },
                              };
                              handleKeyboardChange(event);
                            }}
                            reff={proceedValueRef}
                            isDisabled={PPE_Enabled}
                            isPriceField={
                              priceFiledFocused
                                ? categorySectionRequired
                                : false
                            }
                            fieldMessage={priceCalculation(
                              keyboardField?.eventRecords?.[0]?.proceedValue
                                ?.value,
                              keyboardField?.avg_price
                                ? Number(keyboardField?.avg_price)
                                : null,
                              priceFactor
                            )}
                            isValidationPopupVisible={
                              categorySectionRequired?.withCategory &&
                              !keyboardField?.avg_price
                                ? true
                                : false
                            }
                            handleOnFocus={(data: any) => {
                              focusOnPriceField(data);
                            }}
                            proceedPriceLoader={proceedPriceLoader?.status}
                            avgPrice={keyboardField?.avg_price}
                            textfieldType={"number"}
                          />
                        </div>

                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "min-w-[14.28%]"
                          } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                            14
                          )} dd_large`}
                        >
                          <MultiDropDownField
                            name="benefits"
                            classNamePrefix={"form_multiDropDown"}
                            options={layout.benefits}
                            placeholder="Benefits"
                            isSearchable={true}
                            handleOnChange={(data: any) =>
                              handleKeyboardChange(data)
                            }
                            isMenuPortalTarget={true}
                            selectedRef={benefitRef}
                            value={
                              keyboardField?.eventRecords[0]?.benefits
                                ? keyboardField?.eventRecords[0]?.benefits
                                : null
                            }
                            handleOnSelection={(
                              data: any,
                              visibleOptions: any
                            ) =>
                              handleOnSelection(
                                data,
                                "benefits",
                                layout.benefits,
                                visibleOptions
                              )
                            }
                            // isMultiSelection={true}
                          />
                        </div>

                        {/* <span className="order-1 order-2 order-3 order-4 order-5 order-6 order-7 order-8 order-9 order-10 order-11 order-12 order-13 order-14 order-15 order-16"></span> */}

                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "min-w-[14.28%]"
                          } w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                            15
                          )} dd_large`}
                        >
                          <MultiDropDownField
                            name="restrictions"
                            classNamePrefix={"form_multiDropDown"}
                            options={layout.restrictions}
                            placeholder="Restrictions"
                            isSearchable={true}
                            selectedRef={restrictionRef}
                            isMenuPortalTarget={true}
                            handleOnChange={(data: any) =>
                              handleKeyboardChange(data)
                            }
                            value={
                              keyboardField?.eventRecords[0]?.restrictions
                                ? keyboardField?.eventRecords[0]?.restrictions
                                : null
                            }
                            handleOnSelection={(
                              data: any,
                              visibleOptions: any
                            ) =>
                              handleOnSelection(
                                data,
                                "restrictions",
                                layout.restrictions,
                                visibleOptions
                              )
                            }
                          />
                        </div>

                        {/* {layout?.keyboardItems[
                        `${getKeyboardItemIndexForCheckbox(12)}`
                      ]?.checked && (
                        <div
                          className={`w-full sm:w-1/2 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                            12
                          )}`}
                        >
                          <Checkbox
                            label="PPE"
                            id="ppe"
                            name="ppe"
                            tooltip="true"
                            checked={PPE}
                            handleOnChange={(data: any) => {
                              setPPE(data.target.checked);
                            }}
                          />
                        </div>
                      )} */}
                        {/* {!keyboardField.eventRecords?.[0]?.ticketsInHands && ( */}
                        <div
                          className={`${
                            coreLayout.htmlFontSize === 120 && "min-w-[16.66%]"
                          } ${
                            coreLayout.htmlFontSize === 110 && "min-w-[14.28%]"
                          } w-full ${
                            keyboardField.eventRecords?.[0]?.ticketsInHands &&
                            "pointer-events-none disable_field"
                          }  sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                            16
                          )}`}
                        >
                          <DatePickerField
                            name="dateToShip"
                            placeholder="Date to ship"
                            maxDate={new Date(keyboardField?.date)}
                            disabledKeyboardNavigation={false}
                            handleOnChange={(data: any) =>
                              handleKeyboardChange(data)
                            }
                            value={
                              keyboardField.eventRecords?.[0]?.ticketsInHands
                                ? ""
                                : keyboardField.eventRecords?.[0]?.dateToShip
                            }
                            isDisabled={
                              !!keyboardField.eventRecords?.[0]?.ticketsInHands
                            }
                            WithIcon={
                              keyboardField.eventRecords?.[0]?.ticketsInHands
                                ? false
                                : true
                            }
                          />
                        </div>
                        {/* )} */}
                        <div
                          className={` inhand_keyboard w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 min-w-[11.5625rem] order-${getKeyboardItemIndex(
                            17
                          )} ${
                            allTicketUploaded &&
                            "disable_field pointer-events-none"
                          }`}
                        >
                          <Checkbox
                            label="Tickets in hand"
                            name="ticketsInHands"
                            id="ticketInHands"
                            WithIcon={true}
                            Icon={handIcon}
                            IconSize={"w-[.6875rem] h-[.9063rem]"}
                            TooltipContent={
                              "Tickets marked in hand must be delivered within 24h of sale - failure to do so may result in a penalty applied to your account."
                            }
                            TooltipID={"Tickets-hand"}
                            checked={
                              keyboardField.eventRecords?.[0]?.ticketsInHands &&
                              !allTicketUploaded
                            }
                            handleOnChange={(data: any) =>
                              handleKeyboardChange(data)
                            }
                            AddinventoryHand={true}
                          />
                        </div>
                        <div
                          className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] mb-3.5 order-${getKeyboardItemIndex(
                            18
                          )} ${allTicketUploaded && "min-w-[11.875rem]"} ${
                            allTicketUploaded &&
                            coreLayout.htmlFontSize === 110 &&
                            "min-w-[11.925rem]"
                          }
                        ${
                          allTicketUploaded &&
                          coreLayout.htmlFontSize === 120 &&
                          "min-w-[11.875rem]"
                        } max-md:hidden
                        `}
                        >
                          {/* <button
                          className="border p-1 tx-sm border-rounded"
                          type="button"
                          onClick={(e: any) => {
                            if (
                              keyboardField.eventRecords?.[0]?.ticketType
                                ?.id !== "eticket" &&
                              keyboardField.eventRecords?.[0]?.ticketType
                                ?.id !== "pdf" &&
                              keyboardField.eventRecords?.[0]?.ticketType
                                ?.id !== "mobile-qr"
                            ) {
                              e.preventDefault();
                            } else {
                              handleEticket(
                                keyboardField,
                                keyboardField.eventRecords?.[0]
                              );
                            }

                            //layout.setGetSelectedCount(true);
                          }}
                        >
                          upload ticket
                        </button> */}

                          <button
                            className={`upload_keyboard border rounded flex items-center relative  
                          overflow-hidden group h-[1.875rem] py-1 px-2 pl-[2.375rem]  border-gray-300 hover:border-indigo-500 hover:text-indigo-500 focus:border-indigo-500 focus:text-indigo-500 transition focus:outline-none cursor-pointer text-xs ${
                            allTicketUploaded &&
                            "border-green-600 hover:!border-green-600 text-green-600 hover:!text-green-600"
                          } ${
                              ticketWithQty &&
                              "pointer-events-none disable_field"
                            }`}
                            onClick={() => {
                              handleEticket(
                                keyboardField,
                                keyboardField.eventRecords
                              );
                            }}
                            disabled={ticketWithQty}
                          >
                            <div
                              className={`icon_block absolute left-0 top-0 w-[1.875rem] h-full flex items-center justify-center border-r  ${
                                allTicketUploaded
                                  ? "bg-white border-green-600"
                                  : "bg-transparent group-hover:border-indigo-500 group-focus:border-indigo-500"
                              }   `}
                            >
                              <IKTSVG
                                path={uploadIcon}
                                svgClassName={`w-[.8125rem] ${
                                  allTicketUploaded
                                    ? "fill-green-600"
                                    : "fill-violet-800 group-hover:fill-indigo-500 group-focus:fill-indigo-500"
                                }`}
                              />
                            </div>
                            {allTicketUploaded
                              ? `Tickets uploaded`
                              : `Upload tickets`}
                            <div
                              className={``}
                              data-tooltip-id={`UploadTooltip`}
                            >
                              <IKTSVG
                                path={infoIcon}
                                className="ml-1 fill-grey-400 block"
                                svgClassName={` hover:fill-indigo-500 w-3.5 h-3.5 ${
                                  allTicketUploaded
                                    ? "fill-green-600/50"
                                    : "fill-gray-400"
                                }`}
                              />
                            </div>
                            <TooltipPortal
                              id={`UploadTooltip`}
                              content={
                                "Make tickets available for immediate download to reach maximum listing quality"
                              }
                              className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
                            />
                            {allTicketUploaded && (
                              <button
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  handleRemoveTicket();
                                }}
                                className="cross_icon w-3.5 h-3.5 flex items-center justify-center [&:hover>span>svg]:fill-indigo-500 ml-auto"
                              >
                                <IKTSVG
                                  path={Cross}
                                  svgClassName="w-2 h-2 fill-green-600"
                                />
                              </button>
                            )}
                          </button>
                        </div>
                      </div>
                      {/* <div className="flex flex-wrap"> */}

                      {/* </div> */}
                    </div>
                    <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl bg-white z-10">
                      <button
                        type="button"
                        className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
                        onClick={handleResetKeyboard}
                      >
                        Clear
                      </button>
                      <button
                        type="button"
                        className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
                        onClick={handleButtonClick}
                      >
                        Add Listing
                      </button>
                    </div>
                  </div>
                  {PPE && (
                    <>
                      <div className="table-head-acc flex flex-wrap bg-gray-100/50 xxl:pl-1 text-xs border-b ">
                        {Object.keys(
                          keyboardField?.["eventRecords"]?.[0]?.["marketplaces"]
                        ).length > 0 ? (
                          keyboardField["eventRecords"][0]["marketplaces"].map(
                            (marketplace: any) => {
                              return (
                                <div
                                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/5 xxl:w-[15%] py-3 px-4 border-r border-b -mb-[1px]"
                                  key={`eventRecords${marketplace.exchange_id}`}
                                >
                                  <h5 className="mb-1">{marketplace.title}</h5>
                                  <div className="flex -mx-1">
                                    <div className="w-1/2 px-1">
                                      <div className="text-gray-400 mb-1.5">
                                        Proceed price
                                      </div>
                                      <PriceField
                                        name={marketplace.exchange_id}
                                        id={`exchangeIDProceedPrice${marketplace.exchange_id}`}
                                        // placeholder={"£220"}
                                        handleOnChange={(data: any) => {
                                          handlePPEKeyboardChange(
                                            data,
                                            getDisplayPriceCurrency(
                                              marketplace.exchange_id
                                            )
                                          );
                                        }}
                                        value={getProccedPrice(
                                          marketplace.exchange_id
                                        )}
                                      />
                                    </div>
                                    <div className="w-1/2 px-1 pointer-events-none">
                                      <div className="text-gray-400 mb-1.5">
                                        Display price
                                      </div>

                                      <PriceField
                                        id={`exchangeIDDisplayPrice${marketplace.exchange_id}`}
                                        value={getDisplayPrice(
                                          marketplace.exchange_id
                                        )}
                                        className={`pointer-events-none`}
                                        defaultSymbol={getDisplayPriceCurrency(
                                          marketplace.exchange_id
                                        )}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        ) : (
                          <div className="flex items-center justify-center py-3 px-4 text-rose-500">
                            <p>{Messages.ppeSelectExchange}</p>
                          </div>
                        )}
                        <div className="flex items-center justify-center max-w-[7.5rem] w-full ml-auto py-3 px-4 border-l">
                          <div className="flex -mx-1">
                            <div className="px-1">
                              <button
                                type="button"
                                className="border group bg-white rounded-sm w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center"
                                onClick={() => resetPPEKeyboardFields()}
                              >
                                <IKTSVG
                                  path={Refresh}
                                  className="fill-violet-500 group-hover:fill-white transition"
                                  svgClassName="ml-[.313rem]"
                                />
                              </button>
                            </div>
                            <div className="px-1">
                              <button
                                type="button"
                                className="group flex items-center justify-center h-6 w-6 border border-gray-300 hover:border-indigo-500 text-white bg-white hover:bg-indigo-500 transition rounded-sm"
                                onClick={(e: any) => {
                                  setPPE(false);
                                  layout.setKeyboardField((prev: any) => ({
                                    ...prev,
                                    ppeExchanges: [],
                                  }));
                                }}
                              >
                                <IKTSVG
                                  className="fill-violet-500 group-hover:fill-white transition"
                                  path={Cross}
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    className={`flex flex-wrap items-center justify-end py-4 px-5 max-md:border-t gap-y-2.5 ${
                      isDisabledListing ? "pointer-events-none" : ""
                    }`}
                  >
                    <span
                      className={`mr-4 text-sm13 font-medium  ${
                        Array.isArray(list) &&
                        list.length < 1 &&
                        `text-gray-400`
                      } ${
                        (isDiabled2 || existAnyOfFile?.length > 0) &&
                        "text-gray-400"
                      }`}
                    >
                      Add to all events
                    </span>
                    <div>
                      <label
                        className={`relative inline-flex items-center cursor-pointer ${
                          Array.isArray(list) && list.length < 1 && `disabled`
                        } [&.disabled>div:after]:bg-gray-400 [&.disabled]:pointer-events-none ${
                          (isDiabled2 || existAnyOfFile?.length > 0) &&
                          "pointer-events-none"
                        }`}
                      >
                        <input
                          type="checkbox"
                          name="addAllEvents"
                          value=""
                          className="sr-only peer"
                          checked={layout.addAllEvents}
                          onChange={(event) => handleAddToAllEvent(event)}
                        />
                        <div
                          className={`${
                            isDiabled2 || existAnyOfFile?.length > 0
                              ? "pointer-events-none bg-gray-100 peer-checked:after:bg-gray-400 after:bg-gray-400"
                              : "bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500"
                          } w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8`}
                        ></div>
                      </label>
                    </div>
                    <div className="ml-3 max-md:hidden">
                      <button
                        type="button"
                        className={`group border text-center bg-white rounded w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition `}
                        onClick={handleResetKeyboard}
                        title="Reset"
                        id="reset"
                      >
                        <Tooltip
                          anchorId={`reset`}
                          content={`Reset`}
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                        />
                        <IKTSVG
                          path={refreshSVG}
                          className="fill-violet-500 group-hover:fill-white transition"
                          svgClassName="mx-[.313rem] w-3 h-3"
                        />
                      </button>
                    </div>

                    <div className="ml-3 max-md:hidden">
                      <button
                        type="button"
                        className={`${
                          searchedValue &&
                          Object.keys(searchedValue).length === 0 &&
                          "pointer-events-none opacity-30"
                        } ${
                          customLoader || isDisabled || addlisitngBtnDisabled
                            ? "pointer-events-none bg-gray-100 text-gray-400 after:hidden"
                            : "bg-violet-500 text-white "
                        } relative inline-flex items-center z-10  hover:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase after:content-[''] after:h-2/4 after:w-full after:bg-violet-400 after:absolute after:top-0 after:left-0 after:rounded after:transition hover:after:opacity-0 after:-z-10`}
                        onClick={() => handleAddInventory()}
                      >
                        {/*  */}{" "}
                        <IKTSVG
                          className={`min-w-[.75rem] max-w-[.75rem] mr-2 -mt-0.5 flex items-center justify-center  ${
                            customLoader || isDisabled
                              ? "fill-gray-400"
                              : "fill-white"
                          } `}
                          path={Plus}
                        />
                        ADD LISTING
                      </button>
                    </div>
                    <div
                      className="filterBtn max-md:flex hidden pl-5 py-2.5 text-sm12 max-sm:order-2 max-sm:max-w-[10rem] max-md:py-0"
                      id="filterBtn"
                      onClick={() => ToggleElemOpen()}
                    >
                      <button className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5">
                        <IKTSVG
                          path={fieldIcon}
                          className=" mr-1.5 flex items-center justify-center fill-white group-hover:fill-white"
                          svgClassName="w-4 h-4"
                        />
                        Listing details
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                triggered &&
                layout.eventOrPerformer === "Performer/venue" && (
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                    data-te-collapse-item
                    data-te-collapse-show
                  >
                    {layout.performer.length > 0 && (
                      <>
                        <PerformerTable
                          handleOnChange={performerPaginateHandle}
                          id={id}
                          resetFilters={resetPerformerFilters}
                          resetIndividual={(name: any) => {
                            PerformerKeyboardHandle(name);
                          }}
                          isLoading={performerVenueLoader}
                        />
                        <div className="flex flex-wrap items-center justify-end p-4 per_button">
                          <div className="ml-6">
                            <button
                              type="button"
                              className={`
                       ${
                         !layout.performer.some((item: any) => {
                           return item.selected;
                         }) &&
                         "pointer-events-none !bg-gray-100 !text-gray-400 "
                       }
                      ${customLoader && `pointer-events-none opacity-50`}  
                      inline-flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase`}
                              onClick={handleAddInventory}
                            >
                              <IKTSVG
                                className={`min-w-[.75rem] max-w-[.75rem] mr-2 flex items-center justify-center  ${
                                  !layout.performer.some((item: any) => {
                                    return item.selected;
                                  })
                                    ? "fill-gray-400"
                                    : "fill-white"
                                } `}
                                path={Plus}
                              />
                              ADD EVENTS
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )
              )}
            </div>
          </div>
        </>
      )}

      {/* SAVE AS DRAFT */}
      {/* {!layout.isPublish && (
        <div className="text-right">
          <button
            type="button"
            className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
      bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 mr-auto mt-3 border-t border-t-red-50"
            disabled={saveAsBtnText === "Saved as Draft" ? true : false}
            onClick={() => saveEventTableAsDraft()}
          >
            {saveAsBtnText}
          </button>
        </div>
      )} */}

      {/* Table */}
      {list && list.length > 0 && (
        <div className="acc_wraps mt-5 md:px-5">
          {list.map((event: any, eIndex: any) => {
            return (
              <React.Fragment key={`events${event?.id}`}>
                <EventTable
                  list={list}
                  setList={setList}
                  event={event}
                  eIndex={eIndex}
                  isDisabled={isDisabled}
                  // handleTableField={MemoizedHandleTableField}
                  eventCheckBox={eventCheckBox}
                  searchInventoryOption={data}
                  eventError={eventError}
                  debouncedChangeHandler={debouncedChangeHandler}
                  isLoading={isLoading}
                  handlePPETableChange={handlePPETableChange}
                  setIsOpenListingQualityPopup={setIsOpenListingQualityPopup}
                  virtuosoRef={virtuosoRef}
                />
              </React.Fragment>
            );
          })}
        </div>
      )}
      {isOpenListingQualityPopup && (
        <ListingQualityPopup
          isOpen={isOpenListingQualityPopup}
          onClose={setIsOpenListingQualityPopup}
        />
      )}
      {debouncedLoader && <ProgressBardLoader secondCounter={0.5} />}
      {isPopUpOpen?.isOpen && (
        <ETicketUploadKeyboard
          isOpen={isPopUpOpen}
          closePopUp={closeUploadTicketModal}
          setIsPopUpOpen={setIsPopUpOpen}
        />
      )}

      {/* MOBILE VIEW - EDIT POPUP */}
      {coreLayout?.isMobile && (
        <EditInventoryMobileView list={list} setList={setList} />
      )}
    </>
  );
});

export default AddInventory;
