import { KTSVG } from "components/ui/KTSVG";
import { addCommaInNumber } from "helpers/Functions";
import { ConnectorContext } from "pages/API_connector/core/ConnectorProvider";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import ListingHeader from "./ListingHeader";

const ListingFilters = memo(
  ({
    list,
    isTableDisabled,
    // layout,
    countsData,
    publishStatus,
    setPublishStatus,
    isLoading,
    selectedListings,
    handleEditListings,
    handleDeleteListings,
    paginateData,
    listingFiltersRef,
  }: any) => {
    const heightRef1: any = useRef();
    const layout = useContext(ConnectorContext);

    const [IsToggle, setIsToggle] = useState<any>(true);

    const [total, setTotal] = useState(paginateData);

    useEffect(() => {
      if (paginateData) {
        setTotal(paginateData?.total);
      }
    }, [paginateData]);

    function calculateBodyTotalHeight() {
      const bodyComputedStyle = window.getComputedStyle(document.body);
      const bodyHeight =
        document.body.offsetHeight +
        parseInt(bodyComputedStyle.marginTop) +
        parseInt(bodyComputedStyle.marginBottom);

      return bodyHeight;
    }

    return (
      <div
        className="topFilters max-md:static md:sticky sticky-element top-[60px] z-[12] border border-red inv_topfilter max-md:pt-[2.375rem]"
        id="api-connector-listing-filters-acc"
        ref={listingFiltersRef}
      >
        <div
          className="accordion font-medium max-md:fixed max-md:top-[3.75rem] max-md:left-0 max-md:w-full max-md:z-30 bg-white"
          ref={heightRef1}
        >
          <div className="accordion-item !rounded-none bg-white border-b border-gray-200">
            <div
              className="flex  justify-between accordion-header mb-0"
              id="headingOne"
            >
              <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2"></div>
              <div>
                <button
                  className="collapse-icon group h-full group relative rounded-tr flex items-center py-4 px-[.9375rem] text-base text-gray-800 text-left bg-white border-l transition focus:outline-none group ml-auto [&[aria-expanded='false'] > .icon ]:rotate-180 "
                  type="button"
                  data-te-collapse-init
                  aria-expanded="true"
                  data-te-target={`#inventoryaccordion`}
                  aria-controls="inventoryaccordion"
                  onClick={() => setIsToggle(IsToggle === false ? true : false)}
                >
                  <KTSVG
                    className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180"
                    path="other_icons/Collapse.svg"
                    svgClassName="w-[.5625rem] h-[.5625rem]"
                  />
                </button>
              </div>
            </div>
          </div>
          {/* {Object.keys(layout?.tableAlert).length &&
            layout?.tableAlert?.confirm ? (
              <BulkOperations
                list={list}
                setList={setList}
                confirmedBulkAction={confirmedBulkAction}
                teamsOptions={teamsOptions}
                isGodAdmin={isGodAdmin}
                loadMoreListing={loadMoreListing}
              />
            ) : (
              ""
            )} */}
        </div>
        <ListingHeader
          countsData={countsData}
          publishStatus={publishStatus}
          disabled={isTableDisabled ? true : false}
          setPublishStatus={setPublishStatus}
          isLoading={isLoading}
        />
        {/* <div
          id={`filters_block`}
          className={`flex flex-wrap md:border-b border-t gap-y-2.5 py-4 px-[.9375rem] bg-white max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start
                  ${isTableDisabled ? "pointer-events-none" : ""} `}
        ></div> */}

        <div className="flex items-center justify-center sm:justify-start gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full min-h-[3.375rem]">
          <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)]">
            {isLoading ? (
              <div className="shimmer-effect inline-block rounded">
                <span className="opacity-0">
                  {total > 0
                    ? `${addCommaInNumber(total)} results`
                    : "0 results"}
                </span>
              </div>
            ) : (
              <>
                {total > 0 ? `${addCommaInNumber(total)} results` : "0 result"}
              </>
            )}
          </div>

          {selectedListings?.length > 0 && (
            <>
              <button
                type="button"
                className={`text-white hover:text-white bg-violet-500 hover:bg-indigo-500 group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
                onClick={handleEditListings}
              >
                Edit listings
              </button>
              {/* <button
                type="button"
                className={`text-white hover:text-white bg-violet-500 hover:bg-indigo-500 group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
                onClick={handleDeleteListings}
              >
                Delete listings
              </button> */}
              <p className="text-xs ">
                {selectedListings?.length} listings selected
              </p>
            </>
          )}

          <div className="flex items-center justify-center sm:justify-start gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start">
            {isLoading ? (
              <div className="flex gap-5">
                <div
                  className={`flex !rounded overflow-hidden `}
                  style={{ height: "1.625rem", width: "9.375rem" }}
                >
                  &nbsp;
                </div>
              </div>
            ) : (
              <>
                {Array?.isArray(layout?.listingFilters?.listingStatuses) &&
                  layout?.listingFilters?.listingStatuses?.length > 0 && (
                    <button
                      type="button"
                      className={`group border text-center bg-white rounded min-w-[1.5rem] w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition
                          // shimmerLoader && "shimmer-effect"
                        `}
                      id="reset"
                      onClick={() => {
                        layout?.setListingFilters({
                          page: 1,
                          per_page: 30,
                        });
                      }}
                      // title="Reset"
                    >
                      <Tooltip
                        anchorId={`reset`}
                        content={`Reset`}
                        place="top"
                        variant="light"
                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                      />
                      <KTSVG
                        path="other_icons/refresh.svg"
                        className="fill-violet-500 group-hover:fill-white transition"
                        svgClassName="mx-[.313rem] w-3 h-3"
                      />
                    </button>
                  )}

                {/* PUBLISH SELECTED  */}
                {Array?.isArray(layout?.listingFilters?.listingStatuses) &&
                  layout?.listingFilters?.listingStatuses?.map(
                    (item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <div className="flex py-1 px-2.5 text-xs border rounded-full">
                            {item?.name}
                            <button
                              type="button"
                              className="ml-2"
                              onClick={(e: any) => {
                                layout?.setListingFilters((pre: any) => {
                                  const newStatuses =
                                    pre?.listingStatuses?.filter(
                                      (status: any) => status?.id != item?.id
                                    );
                                  return {
                                    ...pre,
                                    listingStatuses: newStatuses,
                                  };
                                });
                              }}
                            >
                              <KTSVG
                                path="standard_icons/cross.svg"
                                svgClassName="h-2 w-2 hover:fill-indigo-500"
                              />
                            </button>
                          </div>
                        </React.Fragment>
                      );
                    }
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export default ListingFilters;
