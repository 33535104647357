import { useEffect, useRef } from "react";
import AngleLeft from "../../../assets/media/icons/standard_icons/angle_left.svg";
import AngleRight from "../../../assets/media/icons/standard_icons/angle_right.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ListingInfo from "./ListingInfo";

const InventoryHeader = ({
  listCountingInfo,
  publishStatus,
  setPublishStatus,
  disabled,
  isLoading,
}: any) => {
  const filter_scroll: any = useRef();
  const scroll_filter = (scrollOffset: any) => {
    filter_scroll.current.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    function hideArrowScroll() {
      const left_sc: any = document.getElementById("scroll_left");
      const right_sc: any = document.getElementById("scroll_right");
      const filter_scroll: any = document.getElementById("filter_scroll");
      const maxScrollLeft: any =
        filter_scroll?.scrollWidth - filter_scroll?.clientWidth;

      if (filter_scroll?.scrollLeft === 0) {
        left_sc.style.display = "none";
      } else {
        left_sc.style.display = "flex";
      }
      if (filter_scroll.scrollLeft >= maxScrollLeft) {
        right_sc.style.display = "none";
      } else {
        right_sc.style.display = "flex";
      }
    }
    const filter_scroll = document.getElementById("filter_scroll"); // Make sure filter_scroll is defined and references the correct element
    filter_scroll?.addEventListener("scroll", hideArrowScroll);
    hideArrowScroll();
    return () => {
      filter_scroll?.removeEventListener("scroll", hideArrowScroll); // Cleanup: Remove event listener when component unmounts
    };
  }, []);

  return (
    <>
      <div
        className="border-b "
        id="inventoryaccordion"
        data-te-collapse-item
        data-te-collapse-show
      >
        <div className="px-5 pt-3 pr-2.5 relative">
          <span className="text-sm15 font-semibold">Overview</span>
          <div
            className="divide-gray-200 divide-y-2 scroll_container"
            id={`scroll_container`}
          >
            <div
              ref={filter_scroll}
              id={`filter_scroll`}
              className="filter_scroll flex flex-nowrap pt-3 -mx-2.5 -ml-5 whitespace-nowrap overflow-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-violet-800 123"
            >
              {/** Commented due to hold suggestion API task  */}
              <div
                id={`scroll_right`}
                className="arrow_slide absolute top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center right-0 bg-gray-100 rounded-l group hover:bg-indigo-500 cursor-pointer mt-3"
                // onClick={() => scrollLeft()}
                onClick={() => scroll_filter(50)}
              >
                <IKTSVG
                  path={AngleRight}
                  svgClassName="w-1.5 h-[.5625rem]"
                  className=" group-hover:fill-white"
                />
              </div>
              <div
                id={`scroll_left`}
                className="arrow_slide absolute translate-y-1/2 w-6 h-6 flex items-center justify-center left-0 bg-gray-100 rounded-r group hover:bg-indigo-500 cursor-pointer mt-3"
                onClick={() => scroll_filter(-50)}
                // onClick={() => scroll_filter(50)}
              >
                <IKTSVG
                  path={AngleLeft}
                  svgClassName="w-1.5 h-[.5625rem]"
                  className=" group-hover:fill-white"
                />
              </div>
              <ListingInfo
                listName="Events"
                listCount={listCountingInfo?.total_events}
                ticketCount={undefined}
                isCheckbox={false}
                isFirstLabel={true}
                isLoading={isLoading}
              />

              <ListingInfo
                listName="Listings"
                listCount={listCountingInfo?.total_listings}
                ticketCount={listCountingInfo?.total_tickets}
                isCheckbox={false}
                isLoading={isLoading}
              />

              <ListingInfo
                listName="Published listings"
                listCount={listCountingInfo?.total_published_listings}
                ticketCount={listCountingInfo?.total_published_tickets}
                disabled={disabled}
                isCheckbox={
                  listCountingInfo?.total_published_listings > 0 ? true : false
                }
                postKey="yes"
                publishStatus={publishStatus}
                setPublishStatus={setPublishStatus}
                isLoading={isLoading}
              />

              <ListingInfo
                listName="Unpublished listings"
                listCount={listCountingInfo?.total_disabled_listings}
                ticketCount={listCountingInfo?.total_disabled_tickets}
                disabled={disabled}
                isCheckbox={
                  listCountingInfo?.total_disabled_listings > 0 ? true : false
                }
                postKey="no"
                publishStatus={publishStatus}
                setPublishStatus={setPublishStatus}
                isLoading={isLoading}
              />

              {/* <ListingInfo
                // listName="Hold listings"
                listName="Listings on hold"
                listCount={listCountingInfo?.total_hold_listings}
                ticketCount={listCountingInfo?.total_hold_tickets}
                disabled={disabled}
                isCheckbox={
                  listCountingInfo?.total_hold_listings > 0 ? true : false
                }
                postKey="hold"
                publishStatus={publishStatus}
                setPublishStatus={setPublishStatus}
                isLoading={isLoading}
              /> */}
              {/* 
              <ListingInfo
                listName="Listings with sold tickets"
                listCount={listCountingInfo?.total_sold_listings}
                ticketCount={listCountingInfo?.total_sold_tickets}
                disabled={disabled}
                isCheckbox={
                  listCountingInfo?.total_sold_listings > 0 ? true : false
                }
                postKey="sold"
                publishStatus={publishStatus}
                setPublishStatus={setPublishStatus}
                isLoading={isLoading}
              /> */}

              <ListingInfo
                listName="Listings with errors"
                listCount={listCountingInfo?.total_listings_with_errors}
                ticketCount={listCountingInfo?.total_errors_tickets}
                // isCheckbox={false}
                disabled={disabled}
                isCheckbox={
                  listCountingInfo?.total_listings_with_errors > 0
                    ? true
                    : false
                }
                postKey="errors"
                publishStatus={publishStatus}
                setPublishStatus={setPublishStatus}
                isLoading={isLoading}
              />

              <ListingInfo
                listName="Improve listing quality"
                listCount={listCountingInfo?.low_quality_count}
                ticketCount={listCountingInfo?.low_quality_count}
                // isCheckbox={false}
                disabled={disabled}
                isCheckbox={
                  listCountingInfo?.low_quality_count > 0 ? true : false
                }
                postKey="listing_quality"
                publishStatus={publishStatus}
                setPublishStatus={setPublishStatus}
                isLoading={isLoading}
                isLow={true}
                isLastLabel={true}
              />

              {/** Commented due to hold suggestion API task  */}
              <ListingInfo
                listName="Price high"
                listCount={listCountingInfo?.high_price_quality}
                // ticketCount={listCountingInfo?.low_quality_count}
                // isCheckbox={false}
                disabled={disabled}
                isCheckbox={
                  listCountingInfo?.high_price_quality > 0 ? true : false
                }
                postKey="price_quality"
                publishStatus={publishStatus}
                setPublishStatus={setPublishStatus}
                isLoading={isLoading}
                // isLow={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InventoryHeader;
