/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import PaperSVG from "../../../assets/media/icons/standard_icons/paper.svg";
import MobileSVG from "../../../assets/media/icons/standard_icons/ticket-2.svg";
import PDFSVG from "../../../assets/media/icons/standard_icons/ticket.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import Card from "../../../components/ui/Card";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import { addCommaInNumber, ticketTypeRename } from "../../../helpers/Functions";
import { SalesContext } from "../../Sales/core/SalesProvider";
import { DashboardContext } from "../core/DashboardProvider";
import { getfulfilledListing } from "../core/requests";
import DashBoardShimmerLoader from "./DashBoardShimmerLoader";

// DROPDOWN OPTIONS
let optionsdata = [
  //"today",
  "next_7day",
  "next_15day",
  "next_30day",
  "next_6month",
];

const options = [
  //{ id: "today", name: "Today" },
  { id: "next_7day", name: "Events in next 7 days" },
  { id: "next_14day", name: "Events in next 14 days" },
  { id: "next_30day", name: "Events in next 30 days" },
  { id: "next_6month", name: "Events in next 6 months" },
];

const ToBeFulfilledCard = ({
  // fulfilledCardData,
  fulfilledLoading,
  toBeFulfillfedMeta,
  selectedTeams,
  setFulfilledCardData,
  setToBeFulfiledMeta,
}: any) => {
  const layout = useContext(DashboardContext);
  const coreLayout = useContext(LayoutContext);
  const access = coreLayout.authUser?.allowed_access;
  const [customLoader, setCustomLoader] = useState(false);

  const sales = access?.some(
    (item: any) => item.id === "shipping" && item.status === true
  );
  const salesLayout = useContext(SalesContext);
  const toBeFulfilledData = layout.toBeFulfilledData;
  const navigate = useNavigate();
  const infiniteScrollRef = useRef<any>(null);

  let name = "duration";
  let placeholder = "Duration";

  let value = options?.find(
    (item: any) => layout?.fulfilledOptions?.id === item.id
  );
  // DROPDOWN ON CHANGE
  const handleOnChange = (event: any) => {
    setCustomLoader(true);
    layout?.setFulfilledOptions({
      id: event?.target?.value?.id,
      page: 1,
    });

    getfulfilledListing({
      optionsData: {
        id: event?.target?.value?.id,
        page: 1,
      },
      team_id: selectedTeams,
    }).then((response: any) => {
      setCustomLoader(false);

      layout.setToBeFulfilledData(response?.data);
      setToBeFulfiledMeta(response?.meta);
    });
    value = layout?.fulfilledOptions?.id
      ? layout?.fulfilledOptions?.id
      : options?.find((item: any) => layout?.fulfilledOptions?.id === item.id);
  };

  // NAVIGATE TO SALES MODULE
  const handleClick = () => {
    navigate("../sales", { replace: true, state: "to_be_fulfilled" });
  };

  // FETCH API INFINITE
  const fetchData = async () => {
    let currentPage = toBeFulfillfedMeta?.current_page + 1;

    getfulfilledListing({
      optionsData: {
        id: layout?.fulfilledOptions?.id,
        page: currentPage,
      },
      team_id: selectedTeams,
    }).then((response: any) => {
      setToBeFulfiledMeta(response?.meta);
      layout.setToBeFulfilledData((current: any) => {
        return {
          orders: response?.data?.orders,
          status: response?.data?.status,
          ticket: response?.data?.ticket,
          event: [...current?.event, ...response?.data?.event],
        };
      });
    });
  };

  const scrollTop = () => {
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    scrollTop();
  }, [layout.fulfilledOptions.id]);

  function emptyRecorsMessage(name: any) {
    if (name === "today") {
      return "No orders awaiting delivery for events in the today";
    }
    if (name === "next_7day") {
      return "No orders awaiting delivery for events in the next 7 days";
    }
    if (name === "next_14day") {
      return "No orders awaiting delivery for events in the next 14 days";
    }
    if (name === "next_30day") {
      return "No orders awaiting delivery for events in the next 30 days";
    }
    if (name === "next_6month") {
      return "No orders awaiting delivery for events in the next 6 months";
    }
  }

  return (
    // <div className="xl:w-1/3 lg:w-1/2 w-full px-2 mb-4">
    <div className="lg2:w-1/2 w-full md:px-2 md:mb-4 mb-2">
      <Card
        title="Awaiting delivery"
        name={name}
        placeholder=""
        data={optionsdata}
        options={options}
        handleOnChange={handleOnChange}
        value={value}
        threeDot={true}
        handleClick={handleClick}
        cardTitle="Sales: Awaiting delivery"
      >
        {customLoader ? (
          <DashBoardShimmerLoader
            mainLoading={fulfilledLoading}
            headerShimmer={false}
            isLoading={customLoader}
            width={true}
          />
        ) : (
          <div className="divide-gray-200 divide-y-2 ">
            <div className="flex flex-nowrap justify-between p-5 -mx-2.5 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
              <div className="px-2.5 max-md:min-w-[50%] md:w-1/2 w-full">
                <div className=" outline outline-1 outline-gray-200 rounded px-4 py-2.5">
                  <div className="flex flex-wrap sm:flex-nowrap justify-between">
                    <h3 className="text-2xl  md:text-boxtitle md:leading-9">
                      {toBeFulfilledData?.orders > 0
                        ? addCommaInNumber(toBeFulfilledData?.orders)
                        : 0}
                    </h3>
                    {sales && toBeFulfilledData?.orders > 0 && (
                      <h5>
                        <button
                          type="button"
                          className="bg-violet-500 text-white text-xxs font-medium px-2 py-0.5 rounded hover:bg-indigo-500"
                          onClick={() => handleClick()}
                          title="Awaiting delivery"
                        >
                          <span>View</span>
                        </button>
                      </h5>
                    )}
                  </div>

                  <p className="flex items-center text-xs w-full sm:w-auto font-medium text-gray-500 py-1 pr-2">
                    <span className="flex-none sm:flex-1 sm:w-full items-center mr-2">
                      Orders
                    </span>
                  </p>
                </div>
              </div>
              <div className="px-2.5 max-md:min-w-[50%] md:w-1/2 w-full">
                <div className=" outline outline-1 outline-gray-200 rounded px-4 py-2.5">
                  <h3 className="text-2xl  md:text-boxtitle md:leading-9">
                    {toBeFulfilledData?.ticket
                      ? addCommaInNumber(toBeFulfilledData?.ticket)
                      : 0}
                  </h3>
                  <p className="text-xs font-medium text-gray-500 py-1">
                    Tickets
                  </p>
                </div>
              </div>
            </div>
            {fulfilledLoading &&
            (toBeFulfilledData?.event?.length === 0 ||
              toBeFulfilledData?.event?.length === undefined) ? (
              <KTSVG
                className="flex items-center justify-center fill-violet-500 transition animate-spin"
                path="other_icons/process.svg"
              />
            ) : toBeFulfilledData?.event?.length > 0 ? (
              <div
                id="toBeFulfilledCard"
                className="no-scroll flow-root max-h-48 outline-black overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
                ref={infiniteScrollRef}
              >
                <div className="px-5 sticky top-0 bg-white text-gray-400 z-10">
                  <div className="flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                    <div className="flex-1">
                      <p className="text-xs font-medium">Event description</p>
                    </div>
                    {/* <div className="inline-flex items-center text-xs font-medium">
                    Revenue
                  </div> */}
                  </div>
                </div>
                <InfiniteScroll
                  dataLength={
                    toBeFulfilledData?.event?.length
                      ? toBeFulfilledData?.event?.length
                      : 0
                  }
                  next={fetchData}
                  hasMore={
                    toBeFulfillfedMeta?.current_page <
                    toBeFulfillfedMeta?.last_page
                  }
                  loader={Array.from({ length: 3 }, (v, i) => (
                    <div
                      className={`accordion font-medium px-5 mb-2 ${
                        i === 0 && "mt-2"
                      } `}
                      key={i}
                    >
                      <div
                        className={`accordion-item bg-white !rounded overflow-hidden shimmer-effect`}
                        style={{ height: "30px" }}
                      ></div>
                    </div>
                  ))}
                  scrollThreshold="20px"
                  scrollableTarget="toBeFulfilledCard"
                >
                  <ul className="max-h-50 overflow-y-auto ">
                    {toBeFulfilledData?.event?.map((item: any, index: any) => {
                      let ticketType: string;
                      if (item?.ticket_type === "eticket") {
                      }
                      return (
                        <li
                          key={index}
                          className="hover:bg-indigo-500/5 px-5 transition cursor-pointer"
                          onClick={() => {
                            coreLayout.setGlobalFulfilled(
                              toBeFulfilledData?.event?.[index]?.id
                            );
                            handleClick();
                          }}
                        >
                          <div className="flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                            <div className="flex-1">
                              <p
                                className="text-xs font-medium"
                                title={`${item?.event}, ${item.venue}`}
                              >
                                {item?.event}, {item.venue}
                              </p>
                            </div>
                            <span className="group flex items-center text-violet-800 text-xs font-medium">
                              <Tooltip
                                anchorId={`my-anchor-element2-${index}`}
                                content={
                                  // item?.ticket_type === "eticket" ||
                                  // item?.ticket_type === "PDF"
                                  //   ? "E-Ticket"
                                  //   : item?.ticket_type === "mobile"
                                  //   ? "Mobile"
                                  //   : item?.ticket_type === "mobile-link"
                                  //   ? "Mobile link"
                                  //   : item?.ticket_type === "paper"
                                  //   ? "Paper"
                                  //   : item?.ticket_type === "season-cards"
                                  //   ? "Season card"
                                  //   : ""
                                  ticketTypeRename(item?.ticket_type)
                                }
                                place="top"
                                variant="light"
                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium text-xs z-10"
                              />
                              <span id={`my-anchor-element2-${index}`}>
                                {
                                  <IKTSVG
                                    className="inline-block mr-1 hover:fill-indigo-500 fill-violet-800"
                                    // svgClassName="w-3.5 h-2.5"
                                    svgClassName={`${
                                      item?.ticket_type === "mobile" ||
                                      item?.ticket_type === "mobile-link"
                                        ? "w-[.5625rem] h-3.5"
                                        : item?.ticket_type === "paper" ||
                                          item?.ticket_type === "season-cards"
                                        ? "w-[.8125rem] h-[.6875rem]"
                                        : item?.ticket_type === "eticket"
                                        ? "w-[.6875rem] h-3.5"
                                        : "w-[.6875rem] h-3.5"
                                    }   `}
                                    path={
                                      item?.ticket_type === "eticket" ||
                                      item?.ticket_type === "PDF" || item?.ticket_type === "pdf" 
                                        ? PDFSVG
                                        : item?.ticket_type === "mobile" ||
                                          item?.ticket_type === "mobile-link" || item?.ticket_type === "mobile-transfer"
                                        ? MobileSVG
                                        : item?.ticket_type === "paper" ||
                                          item?.ticket_type === "season-cards"
                                        ? PaperSVG
                                        : ""
                                    }
                                  />
                                }
                              </span>
                              {item?.quantity}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </InfiniteScroll>
              </div>
            ) : (
              <div className="text-center text-sm py-4">
                {/* No records could be found at the moment. */}
                {emptyRecorsMessage(layout.fulfilledOptions?.id)}
              </div>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

export default ToBeFulfilledCard;
