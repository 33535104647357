import NoDataComponent from "components/tableComponent/NoDataComponent";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { QUERIES } from "helpers/const";
import {
  decimalNumber,
  ticketTypeRenameForSalesReport,
} from "helpers/Functions";
import useDebounce from "pages/add_inventory/core/useDebounce";
import { ConnectorContext } from "pages/API_connector/core/ConnectorProvider";
import { convertIntoFormatDate } from "pages/API_connector/core/functions";
import { getApiConnectorOrders } from "pages/API_connector/core/requests";
import { ListingTable } from "pages/API_connector/table/ListingTable";
import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { OrderFilters } from "./OrderFilters/OrderFilters";

const getColumns: any = [
  {
    title: "Order ID",
    width: "6.5rem",
    isArrow: false,
    postKey: "order_id",
    padding: "pl-5",
    getValue: (list: any) => list?.order_id,
  },
  {
    title: "Marketplace ID",
    width: "6.5rem",
    isArrow: false,
    postKey: "external_order_id",
    padding: "",
    getValue: (list: any) => list?.external_order_id,
  },
  {
    title: "Order date",
    width: "6.5rem",
    isArrow: false,
    postKey: "order_date_time",
    padding: "",
    getValue: (list: any) => convertIntoFormatDate(list?.order_date_time),
  },
  {
    title: "Team Name",
    width: "8rem",
    isArrow: false,
    postKey: "team_name",
    padding: "",
    getValue: (list: any) => list?.team_name,
  },
  {
    title: "Team member",
    width: "8rem",
    isArrow: false,
    postKey: "user_name",
    padding: "",
    getValue: (list: any) => list?.user_name,
  },
  {
    title: "Listing Value",
    width: "8rem",
    isArrow: false,
    postKey: "listing_value",
    padding: "",
    getValue: (list: any) => decimalNumber(list?.listing_price),
  },
  {
    title: "Event",
    width: "12rem",
    isArrow: false,
    postKey: "event_description",
    padding: "",
    getValue: (list: any) => list?.event_description,
  },

  {
    title: "Event Date",
    width: "6.5rem",
    isArrow: false,
    postKey: "event_date",
    padding: "",
    getValue: (list: any) => convertIntoFormatDate(list?.event_date),
  },
  {
    title: "Ticket type",
    width: "7rem",
    isArrow: false,
    postKey: "ticket_type",
    padding: "",
    getValue: (list: any) =>
      ticketTypeRenameForSalesReport(list?.ticket_type, list?.sub_ticket_type),
  },
  {
    title: "Qty",
    width: "4rem",
    isArrow: false,
    postKey: "ticket_quantity",
    padding: "",
    getValue: (list: any) => list?.ticket_quantity,
  },
  {
    title: "Order status",
    width: "8rem",
    isArrow: false,
    postKey: "order_status",
    padding: "",
    getValue: (list: any) => list?.order_status,
  },
];

export const OrdersTab = () => {
  const {
    orderData,
    setOrderData,
    orderFilters,
    setOrderFilters,
    connectorViewState,
  } = useContext(ConnectorContext);

  const [shimmerLoading, setShimmerLoading] = useState(true);
  const [orderState, setOrderState] = useState({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let filters = { ...orderFilters };
  let sorting = orderState;

  let clientsEventsDataMemo: any = useMemo(() => {
    return [filters, sorting];
  }, [filters, sorting]);

  let ordersFilterData: any = useDebounce(
    JSON.stringify(clientsEventsDataMemo),
    200
  );

  const { data, isLoading } = useQuery(
    [QUERIES.API_CONNECTOR_ORDERS, ...JSON.parse(ordersFilterData)],
    getApiConnectorOrders({
      id: connectorViewState?.data?.id,
      filters: orderFilters,
      sorting,
    }),

    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onSettled: () => setShimmerLoading(false),
    }
  );

  useEffect(() => {
    if (!isLoading) {
      if (data?.data?.orders.length > 0) {
        setOrderData((pre: any) => {
          if (data?.meta?.current_page > 1) {
            return [...pre, ...data?.data?.orders];
          } else {
            return [...data?.data?.orders];
          }
        });
      } else {
        setOrderData([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  const ordersFiltersRef = useRef();
  const previousHeight = useRef(0);
  const [height, setHeight] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    const element = ordersFiltersRef.current;

    if (!element) return;

    let debounceTimer: any;
    const debounce = (fn: any, delay: any) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fn();
      }, delay);
    };

    // Create a ResizeObserver to monitor the height changes
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newHeight = entry.contentRect.height;

        // Check if the height has changed significantly to update
        if (newHeight !== previousHeight.current) {
          debounce(() => {
            setHeight(newHeight); // Update height
            previousHeight.current = newHeight; // Update the previous height
            // console.log(newHeight, "height");
          }, 100); // Debouncing by 100ms (adjust if needed)
        }
      }
    });
    resizeObserver.observe(element);

    // Cleanup observer when component unmounts
    return () => {
      resizeObserver.unobserve(element);
    };
  }, []);

  useEffect(() => {
    const newHeight = document.body.clientHeight - 167 - height - 80;
    setTableHeight(newHeight);
  }, [height]);

  return (
    <Fragment>
      <OrderFilters
        isLoading={isLoading}
        paginateData={data?.meta}
        ordersFiltersRef={ordersFiltersRef}
      />

      <div
        id="ordersTab"
        className="bg-gray-200 min-h-[calc(100vh-10.25rem)] max-h-[calc(100vh-10.25rem)] content-block relative before:content before:absolute before:top-0 before:left-0 before:w-full before:h-16 before:-z-[1]  before:bg-gradient-to-b before:to-gray-650"
      >
        <div className="inner-main-content p-5 md:h-[calc(100vh-10.25rem)] h-auto flex flex-col">
          {shimmerLoading ? (
            <Fragment>
              {Array.from({ length: 10 }, (v, i) => (
                <div
                  className={`accordion font-medium  mb-2.5 mx-5 ${
                    i === 0 && "mt-5"
                  } `}
                  key={i}
                >
                  <div
                    key={i}
                    className={`accordion-item bg-white !rounded overflow-hidden ${
                      false && "shimmer-effect"
                    } `}
                    style={{ height: "40px" }}
                  ></div>
                </div>
              ))}
            </Fragment>
          ) : orderData.length > 0 ? (
            <ListingTable
              data={orderData}
              filters={orderFilters}
              setFilters={setOrderFilters}
              columns={getColumns}
              paginateData={data?.meta}
              orderState={orderState}
              setOrderState={setOrderState}
              rowSelection={true}
              height={`${tableHeight}px`}
              perPage={30}
              isLoading={isLoading}
            />
          ) : (
            <div className="p-4">
              <NoDataComponent />
            </div>
          )}

          {shimmerLoading && (
            <ProgressBardLoader
              LoadingText={"Loading Client's Orders data"}
              secondCounter={2.5}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
