import { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dragSVG from "../../../assets/media/icons/other_icons/dragg.svg";
import restoreSVG from "../../../assets/media/icons/other_icons/restore.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  REPORT_COLUMN_ADMIN,
  REPORT_COLUMN_ITEMS,
} from "../../../helpers/const";
import { ReportsContext } from "../core/ReportsProvider";

const DraggableColumns = ({ refetch }: any) => {
  const layout = useContext(ReportsContext);
  const globalLayout = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
  const access = globalLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );

  /**
   * REORDERING THE RESULT
   * @param list
   * @param startIndex
   * @param endIndex
   * @returns
   */
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items: any = reorder(
        layout.columnItems,
        source.index,
        destination.index
      );
      layout.setColumnItems([...items]);
      layout.setDraggedColumns(items);
      setTimeout(() => {
        refetch();
      }, 100);
    }
  };

  //Function For Filter Checkbox Handle
  const handleChange = (e: any) => {
    layout.setColumnItems((prev: any) => {
      let updatedArray =
        Array.isArray(prev) &&
        prev.map((item: any) => {
          if (item.name === e.target.name) {
            return {
              ...item,
              checked: e.target.checked,
            };
          } else {
            return item;
          }
        });
      return updatedArray;
    });
    setTimeout(() => {
      refetch();
    }, 100);
  };

  //Function For Handling Search
  const handleFilterSearch = (e: any) => {
    layout.setColumnItems((prev: any) => {
      let updatedArray =
        Array.isArray(prev) &&
        prev.map((item: any) => {
          if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return {
              ...item,
              searched: true,
            };
          } else {
            return {
              ...item,
              searched: false,
            };
          }
        });
      return updatedArray;
    });
  };

  let columnItemsLength: any;
  columnItemsLength = layout.columnItems.length * 30;

  const getColumnValue = isGodAdmin
    ? layout.columnItems
    : layout.columnItems?.filter(
        (obj: any) => !REPORT_COLUMN_ADMIN?.includes(obj?.name)
      );

  return (
    <>
      <div className="ba_search p-2.5 border-b">
        <input
          type="text"
          placeholder="Search columns"
          id="search"
          className="rounded bg-violet-100/5 border-violet-100/20 w-full h-[30px] text-sm12 px-2.5 py-2"
          onChange={handleFilterSearch}
        />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable1" key="droppable1">
          {(provided: any, snapshot: any) => (
            <div
              className="cb-block border-b px-2.5 py-2"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <ul className={`min-h-[${columnItemsLength}px] pb-3`}>
                {getColumnValue?.map((item: any, index: any) => (
                  <li className="font-medium group" key={item.id}>
                    {item.searched && (
                      <Draggable
                        draggableId={item.name}
                        key={item.id}
                        index={index}
                      >
                        {(provided: any, snapshot: any) => (
                          <div
                            className="singe_cb flex justify-between items-center mb-3"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className="inline-flex items-center text-violet-800transition font-medium rounded">
                              <div
                                className="drag_icon"
                                {...provided.dragHandleProps}
                              >
                                <IKTSVG
                                  className="min-w-[0.8125rem] max-w-[0.8125rem] flex items-center justify-center fill-gray-400   transition"
                                  path={dragSVG}
                                />
                              </div>
                              <label
                                htmlFor={`report-column-${item.id}`}
                                className="ml-2 text-sm12 font-medium dark:text-gray-300 group-hover:text-indigo-500 cursor-pointer"
                              >
                                {" "}
                                {item.name}
                              </label>
                            </div>

                            <input
                              type="checkbox"
                              className="w-3 h-3 text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              checked={item.checked}
                              id={`report-column-${item.id}`}
                              name={item.name}
                              onChange={handleChange}
                            />
                          </div>
                        )}
                      </Draggable>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="restore-btn px-2.5 py-2">
        <button
          type="button"
          className="group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
          onClick={() => {
            layout.setColumnItems(
              isGodAdmin
                ? REPORT_COLUMN_ITEMS
                : userSpecific
                ? REPORT_COLUMN_ITEMS?.filter(
                    (obj: any) =>
                      obj?.name !== "Team name" &&
                      obj?.name !== "Team member" &&
                      obj.name !== "Marketplace value" &&
                      obj.name !== "Settlement/net payout value" &&
                      obj.name !== "Marketplace" &&
                      obj?.name !== "Marketplace ID" &&
                      obj?.name !== "Tixstock revenue" &&
                      obj?.name !== "Internal order status" &&
                      obj.name !== "Internal replacement status"
                  )?.map((item: any) => ({
                    ...item,
                    name:
                      item?.name === "Listing value"
                        ? "Order value"
                        : item?.name,
                  }))
                : REPORT_COLUMN_ITEMS?.filter(
                    (obj: any) =>
                      obj?.name !== "Team name" &&
                      obj?.name !== "Team member" &&
                      obj.name !== "Marketplace value" &&
                      obj.name !== "Settlement/net payout value" &&
                      obj.name !== "Marketplace" &&
                      obj?.name !== "Marketplace ID" &&
                      obj?.name !== "Tixstock revenue" &&
                      obj?.name !== "Internal order status" &&
                      obj.name !== "Internal replacement status"
                  )?.map((item: any) => ({
                    ...item,
                    name:
                      item?.name === "Listing value"
                        ? "Order value"
                        : item?.name,
                  }))
            );
            setTimeout(() => {
              refetch();
            }, 100);
          }}
        >
          <IKTSVG
            className="min-w-4 max-w-4 mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
            path={restoreSVG}
          />
          Restore defaults
        </button>
      </div>
    </>
  );
};

export default DraggableColumns;
