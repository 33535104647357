import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  default as CloneSVG,
  default as cloneSVG,
} from "../../../assets/media/icons/other_icons/Clone.svg";
import UploadSVG from "../../../assets/media/icons/other_icons/Upload.svg";
import AddSVGGray from "../../../assets/media/icons/other_icons/add_icon-gray.svg";
import AddSVG from "../../../assets/media/icons/other_icons/add_icon.svg";
import externalLink from "../../../assets/media/icons/other_icons/external-link.svg";
import ProcessSVG from "../../../assets/media/icons/other_icons/process.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import CrossSVG from "../../../assets/media/icons/standard_icons/cross.svg";

import { useFormik } from "formik";
import * as Yup from "yup";
import LockIconOpen from "../../../assets/media/icons/other_icons/lock-open-solid.svg";
import LockIcon from "../../../assets/media/icons/other_icons/lock-solid.svg";
import crossIcon from "../../../assets/media/icons/standard_icons/cross.svg";
import DatePickerField from "../../../components/formComponent/DatePickerField";
import TextInput from "../../../components/formComponent/TextInput";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { base64ToPDF } from "../../../helpers/AssetHelpers";

import _ from "lodash";
import { useQuery } from "react-query";
import PriceField_copy from "../../../components/formComponent/PriceField";
import TableDropdown from "../../../components/tableComponent/TableDropdown";
import {
  daysDifference,
  decimalNumber,
  getOrderStatusColor,
  handleKeyDown,
  parseMMDDYYYY,
  ticketTypeRenameForSalesReport,
} from "../../../helpers/Functions";
import Messages from "../../../helpers/Messages";
import { showAlert } from "../../../helpers/ShowAlert";
import { SYMBOLS } from "../../../helpers/const";
import {
  addOrderNote,
  deleteOrderNote,
  updateCustomerDetails,
} from "../../Sales/core/_requests";
import PopUpShimmer from "../../Sales/widget/PopUpShimmer";
import { copy } from "../../add_inventory/core/_functions";
import { TxAccountContext } from "../../tx_account/core/TxAccountProvider";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { display } from "../core/_function";
import { reportDetailsView } from "../core/requests";

const OrderDetail = ({
  show,
  handleShow,
  isGodAdmin,
  setShowOrder = () => {},
  reportsOptions,
  layoutContextOrder,
  setLayoutContextOrder,
  lockButtonShow,
}: any) => {
  const layout = useContext(TxAccountContext);
  // const orderStatusOptions: any = reportsOptions?.order_statuses
  //   ?.flatMap((group: any) => group.data)
  //   ?.map((option: any) => {
  //     const descriptionType = `${option?.type || ""} - ${
  //       option?.description || ""
  //     }`;
  //     return {
  //       ...option, // Spread the existing properties
  //       descriptionType, // Add the new key
  //     };
  //   });

  const {
    isLoading: isDetailsLoading,
    data: detailsListingData,
    refetch,
    isFetching: isSalesFetching,
  } = useQuery(
    [`sales-details`, show?.data?.order_id],
    () => reportDetailsView({ order_id: show?.data?.order_id }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: show?.data?.order_id ? true : false,

      // staleTime: 0,
      cacheTime: 0,
    }
  );

  const [orderDetails, setOrderDetails] = useState<any>();
  const [copyData, setCopyData] = useState<any>(undefined);

  let shippingDetails =
    orderDetails?.fulfilled_ticket_files?.shipping_tracking_id;

  useEffect(() => {
    if (detailsListingData) {
      setOrderDetails(detailsListingData?.data?.[0]);
    }
  }, [detailsListingData]);

  // const layout = useContext(ReportsContext);
  const [isCopied, setIsCopied] = useState<any>({
    id: "",
    value: "",
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [isPenaltyLock, setIsPenaltyLock] = useState<boolean>(true);
  const [isCustomerLock, setIsCustomerLock] = useState<boolean>(true);
  const [isTicketLock, setIsTicketLock] = useState<boolean>(true);
  const [isAddressLock, setIsAddressLock] = useState<boolean>(true);
  const [isStatusLock, setIsStatusLock] = useState<boolean>(true);
  const [updateLoader, setUpdateLoader] = useState<Boolean>(false);
  const [orderNotes, setOrderNotes] = useState<any>(show?.data?.history);
  const [orderText, setOrderText] = useState<any>({
    show: false,
    note: "",
  });
  const [noteLoader, setNoteLoader] = useState<any>(false);
  let orders = layoutContextOrder;
  const canvasRef: any = useRef();

  // SHOW ORDER NOTE TEXT INPUT
  const HadleAddOrderNote = () => {
    setOrderText((prev: any) => {
      return {
        ...prev,
        show: true,
      };
    });
  };

  // HANDLE ONCHANGE TEXT INPUT
  const handleOrderText = (e: any) => {
    setOrderText((prev: any) => {
      return {
        ...prev,
        note: e.target?.value,
      };
    });
  };

  // ADD ORDER TEXT TO API
  const addOrderText = (e: any) => {
    e.preventDefault();
    let trimOrderText = orderText?.note?.trim();
    if (trimOrderText === "") {
      showAlert(Messages?.inValidOrderNote, true);
    } else {
      setNoteLoader(true);
      let payload: any = {
        order_id: show?.data?.order_id,
        note: trimOrderText,
      };
      addOrderNote(payload)
        .then((response: any) => {
          if (response?.data?.status === true) {
            showAlert("The order note has been successfully added.", false);
            response?.data?.history && setOrderNotes(response?.data?.history);
            cancleOrderText();
          } else {
            if (response?.errors?.title) {
              showAlert(response?.errors?.title, true);
            } else {
              showAlert("Something went wrong.", true);
            }
          }
        })
        .finally(() => setNoteLoader(false));
    }
  };

  // CANCEL ORDER TEXT
  const cancleOrderText = () => {
    setOrderText((prev: any) => {
      return {
        ...prev,
        show: false,
        note: "",
      };
    });
  };

  // DELETE ORDER TEXT
  const deleteOrderText = (data: any) => {
    setNoteLoader(true);
    let payload: any = {
      order_id: show?.data?.order_id,
      index: data?.index,
    };
    deleteOrderNote(payload)
      .then((response: any) => {
        if (response?.data?.id) {
          showAlert("The order note has been successfully deleted.", false);
          response?.data?.history && setOrderNotes(response?.data?.history);
          cancleOrderText();
        } else {
          if (response?.errors?.title) {
            showAlert(response?.errors?.title, true);
          } else {
            showAlert("Something went wrong.", true);
          }
        }
      })
      .finally(() => setNoteLoader(false));
  };

  // HANDLE SHOW ORDER VIEW PAGE
  // const display = (isOpen: boolean) => {
  //   var pop_width = document.getElementsByClassName("s_ticket")[0].clientWidth;
  //   if (isOpen) {
  //     // @ts-ignore
  //     (document.getElementById("EventAccordion")?.style).marginRight = `${
  //       pop_width - 20
  //     }px`;
  //   } else if (!isOpen) {
  //     // @ts-ignore
  //     (document.getElementById("EventAccordion")?.style).marginRight = `0px`;
  //     // @ts-ignore
  //     const singleTicket = document.getElementById("singleTicket");
  //     if (singleTicket) {
  //       singleTicket.style.visibility = "hidden";
  //     }
  //   }
  // };

  // ADJUsT TABLE STYLING
  useEffect(() => {
    display(show?.show);
    setOrderNotes(show?.data?.history);
    return () => {
      // @ts-ignore
      let eventAccordion: any = document.getElementById("EventAccordion");
      eventAccordion && (eventAccordion.style.marginRight = `0px`);
    };
  }, [show]);

  // ORDER ACTION COMPONENT
  // const OrderActionButton = ({ className, path }: any) => (
  //   <button
  //     className="relative mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l"
  //     type="button"
  //   >
  //     <IKTSVG
  //       className={`flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition ${className}`}
  //       path={path}
  //     />
  //   </button>
  // );

  // DETAIL INFO COMPONENT
  // const DetailInfo = ({
  //   header,
  //   headerClassName = "text-gray-400 text-sm12",
  //   value,
  //   valueClassName = "text-sm12",
  // }: any) => {
  //   return (
  //     <div className="md:w-1/2 p-2 w-full">
  //       <div className={headerClassName}>{header}</div>
  //       <div className={valueClassName}>{value}</div>
  //     </div>
  //   );
  // };
  const DetailInfo = ({
    header,
    headerClassName = "text-gray-400 text-sm12",
    value,
    valueClassName = "text-sm12",
    isSingle = false,
  }: any) => {
    return (
      <div className="md:w-1/2 p-2 w-full ">
        <div
          className={`${headerClassName} ${
            isGodAdmin && isSingle && "flex flex-inline"
          }`}
        >
          {header}
          {/* {isGodAdmin && isSingle && (
            <span
              className="ml-2 cursor-pointer"
              onClick={() => setIsStatusLock(!isStatusLock)}
            >
              <img
                src={isStatusLock ? LockIcon : LockIconOpen}
                alt="lockIcon"
                className="!w-[.9375rem] !h-[.9375rem]"
              />
            </span>
          )} */}
        </div>
        {isSingle ? (
          // isGodAdmin && !isStatusLock ? (
          //   <div className="w-full py-3 text-sm12 border-violet-900 flex flex-wrap items-center relative">
          //     <TableDropdown
          //       options={orderStatusOptions}
          //       name="order_status"
          //       placeholder="Order status"
          //       isOrderStatusDropdown="true"
          //       value={orderStatusOptions.find(
          //         (option: any) =>
          //           option.description === formik?.values?.order_status
          //       )}
          //       isClearable={false}
          //       handleOnChange={(e: any) => {
          //         formik.setFieldValue(`order_status_id`, e.target.value?.id);
          //         formik.setFieldValue(
          //           `order_status`,
          //           e.target.value?.description
          //         );
          //       }}
          //       getOptionLabel="descriptionType"
          //       getOptionValue="id"
          //       isValueCapital={true}
          //       isCapital={true}
          //     />
          //   </div>
          // ) : (
          <div className={headerClassName}>
            <div
              className={`inline-flex px-2.5 py-[3px] ${statusColor} text-sm12 text-white rounded-l`}
            >
              {value}
            </div>
            <div
              className={`inline-flex px-2.5 py-[3px] ${dateColor} text-sm12 text-violet-800 rounded-r `}
            >
              {ticketTypeRenameForSalesReport(
                orderDetails?.ticket_type,
                orderDetails?.sub_ticket_type
              )}
            </div>
          </div>
        ) : (
          // )
          <div className={valueClassName}>{value}</div>
        )}
      </div>
    );
  };

  // COPY TO CLIPBOARD COMPONENT
  const CopyToClipboard = ({ headerName, value, id, handleClick }: any) => {
    return (
      <>
        <div className="md:w-1/2 p-2 w-full">
          <div className="text-gray-400 text-sm12 mb-1">{headerName}</div>
          <div className="relative">
            <input
              type="text"
              value={value.trim() === "" ? "N/A" : value}
              className="text-sm12 rounded bg-gray-100 w-full border-none px-2 py-0 block text-violet-800"
              disabled
            />
            {value.trim() !== "" && (
              <button
                className="flex items-center justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
                type="button"
                onClick={(e: any) => {
                  handleClick({ id, value });
                }}
              >
                <IKTSVG
                  className={`flex items-center justify-center transition
                    ${
                      isCopied?.value === value && isCopied?.id === id
                        ? "fill-green-600  hover:fill-green-600"
                        : "fill-violet-500 hover:fill-indigo-500"
                    }
                    `}
                  path={cloneSVG}
                  svgClassName="w-[.7813rem] h-[.6563rem]"
                />
                {/* {isCopied === value && value !== "" ? (
                  <span className="text-xs">copied</span>
                ) : (
                  <IKTSVG
                    className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                    path={cloneSVG}
                    svgClassName="w-[.7813rem] h-[.6563rem]"
                  />
                )} */}
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  // HANDLE NAVIGATION OF ORDER VIEW PAGE
  const handleNavigation = (nav: string) => {
    let newIndex = show?.index;
    if (nav === "back") {
      newIndex = newIndex === 0 ? orders.length - 1 : newIndex - 1;
    } else {
      newIndex = newIndex === orders.length - 1 ? 0 : newIndex + 1;
    }
    const preRecord = orders[newIndex];
    handleShow({
      show: true,
      data: preRecord,
      index: newIndex,
    });
  };

  // NAVIGATION BUTTON COMPONENT
  const NavigationButtons = ({
    handleNavigation,
    imgPath,
    className,
    id,
    disabled,
  }: any) => {
    return (
      <button
        className={`group relative p-1 md:p-1 w-6 h-6 rounded-l ${className} ${
          id === "next" && "rotate-180"
        } ${disabled ? "pointer-events-none" : ""} `}
        type="button"
        id={id}
        onClick={() => handleNavigation(id)}
      >
        <IKTSVG
          className={`flex items-center justify-center ${
            disabled ? `fill-gray-400` : `fill-violet-500`
          }  transition group-hover:fill-indigo-500`}
          // path="standard_icons/angle_left.svg"
          path={imgPath}
          svgClassName="w-1.5 h-[.5625rem]"
        />
      </button>
    );
  };

  // HANDLE NAVIGATION OF ORDER VIEW PAGE
  // const handleNavigation = (nav: string) => {
  //   let newIndex = show?.index;
  //   if (nav === "back") {
  //     newIndex = newIndex === 0 ? orders.length - 1 : newIndex - 1;
  //   } else {
  //     newIndex = newIndex === orders.length - 1 ? 0 : newIndex + 1;
  //   }
  //   const preRecord = orders[newIndex];
  //   handleShow({
  //     show: true,
  //     data: preRecord,
  //     index: newIndex,
  //   });
  // };

  useEffect(() => {
    setTimeout(() => {
      setIsCopied("");
    }, 2000);
  }, [isCopied]);

  let copyShppingAddressText = `${orderDetails?.customer_shipping_address_line_1}, ${orderDetails?.customer_shipping_address_line_2}, ${orderDetails?.customer_shipping_town}, ${orderDetails?.customer_shipping_postcode}, ${orderDetails?.customer_shipping_country}`;

  let copyBillingAddressText = `${orderDetails?.customer_billing_address_line_1}, ${orderDetails?.customer_billing_address_line_2}, ${orderDetails?.customer_billing_town}, ${orderDetails?.customer_billing_postcode}, ${orderDetails?.customer_billing_country}`;

  const checkShippingAddress = copyShppingAddressText
    .replaceAll(",", "")
    .replaceAll(" ", "");

  const checkBillingAddress = copyBillingAddressText
    .replaceAll(",", "")
    .replaceAll(" ", "");

  // DOWNLOAD PDF
  async function downloadPDF(file: any, fileName: string) {
    try {
      setLoader(true);
      await Promise.resolve(base64ToPDF(file, fileName));
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  }

  // OPEN BLOB PDF IN NEW TAB
  function openBlobPDF(item: any) {
    const byteCharacters = atob(item);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a new blob from the byte array
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create a new URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Open the URL in a new tab
    window.open(url, "_blank");

    // Clean up the URL object after opening the PDF
    window.URL.revokeObjectURL(url);
  }

  const PdfTickitCheck =
    orderDetails?.ticket_type === "PDF" ||
    orderDetails?.ticket_type === "eticket";

  const detailsSchema = Yup.object().shape({
    customer_email: Yup.string().trim().email("Must be a valid email address"),
    customer_contact_number: Yup.string()
      .min(10, "Too short")
      .max(20, "Too long"),
    // .matches(phoneRegExp, "Phone number is not valid")
    // .min(10, "Too short")
    // .max(10, "Too long"),
    items: Yup.array().of(
      Yup.object().shape({
        customer_email_address: Yup.string()
          .trim()
          .email("Must be a valid email address"),
        customer_contact_number: Yup.string()
          .min(10, "Too short")
          .max(20, "Too long"),
      })
    ),
    fine_amount: Yup.number().when("isPenaltyLock", {
      is: false,
      then: Yup.number()
        .required("Penalty amount is required")
        .positive("Penalty amount must be greater than zero")
        .typeError("Penalty amount must be a number"),
    }),
    note_penalty: Yup.string().when("isPenaltyLock", {
      is: false,
      then: Yup.string().trim().required("Penalty note must be required."),
    }),
  });

  const initialValues = {
    customer_first_name: orderDetails?.customer_first_name || "",
    customer_last_name: orderDetails?.customer_last_name || "",
    customer_email: orderDetails?.customer_email || "",
    customer_contact_number: orderDetails?.customer_contact_number || "",
    customer_dob: orderDetails?.customer_dob
      ? moment(orderDetails?.customer_dob).format("YYYY-MM-DD")
      : null,
    customer_national_id: orderDetails?.customer_national_id || "",
    customer_shipping_address_line_1:
      orderDetails?.customer_shipping_address_line_1 || "",
    customer_shipping_address_line_2:
      orderDetails?.customer_shipping_address_line_2 || "",
    customer_shipping_country: orderDetails?.customer_shipping_country || "",
    customer_shipping_postcode: orderDetails?.customer_shipping_postcode || "",
    customer_shipping_town: orderDetails?.customer_shipping_town || "",
    customer_billing_address_line_1:
      orderDetails?.customer_billing_address_line_1 || "",
    customer_billing_address_line_2:
      orderDetails?.customer_billing_address_line_2 || "",
    customer_billing_country: orderDetails?.customer_billing_country || "",
    customer_billing_postcode: orderDetails?.customer_billing_postcode || "",
    customer_billing_town: orderDetails?.customer_billing_town || "",
    customer_nationality: orderDetails?.customer_nationality || "",
    customer_city_of_birth: orderDetails?.customer_city_of_birth || "",
    items:
      orderDetails?.items?.map((obj: any) => ({
        id: obj?.id,
        name: obj?.name,
        row: obj?.row,
        seat: obj?.seat,
        customer_email_address: obj?.customer_email_address,
        customer_national_id: obj?.customer_national_id,
        customer_nationality: obj?.customer_nationality,
        customer_contact_number: obj.customer_contact_number,
        customer_birth_place: obj?.customer_birth_place,
        customer_address: obj?.customer_address,
        customer_dob: obj?.customer_dob ? new Date(obj?.customer_dob) : null,
      })) || [],
    order_status_id: orderDetails?.internal_order_status,
    order_status: orderDetails?.order_status,
    note_penalty: orderDetails?.note_penalty || "",
    fine_amount: orderDetails?.fine_amount || "",
    isPenaltyLock: isPenaltyLock,
  };

  // FORMIk
  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: detailsSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values: any) => {
      setUpdateLoader(true);
      await updateCustomerDetails({
        ...formik.values,
        order_id: orderDetails?.order_id,
        items: values?.items?.map((item: any) => {
          return {
            ...item,
            customer_dob: item?.customer_dob
              ? moment(item?.customer_dob).format("YYYY-MM-DD")
              : item?.customer_dob,
          };
        }),
      })
        .then((response: any) => {
          setUpdateLoader(false);
          if (response?.data.status) {
            showAlert(response?.message, false);

            setOrderDetails((pre: any) => ({
              ...pre,
              ...response?.data?.payload.customer,
              items: response?.data?.payload.items,
              internal_order_status: formik?.values?.order_status_id,
            }));

            refetch();

            setLayoutContextOrder((pre: any) => {
              return pre?.map((obj: any) => {
                if (obj?.order_id === orderDetails?.order_id) {
                  return {
                    ...obj,
                    internal_order_status: formik?.values?.order_status_id,
                  };
                } else {
                  return obj;
                }
              });
            });

            // setShowOrder((prev: any) => ({
            //   index: prev?.index,
            //   show: prev?.show,
            //   data: {
            //     ...prev?.data,
            //     ...response?.data?.payload.customer,
            //     items: response?.data?.payload.items,
            //   },
            // }));

            setIsCustomerLock(true);
            setIsAddressLock(true);
            setIsTicketLock(true);
            setIsStatusLock(true);
            setIsPenaltyLock(true);
          } else {
            if (response?.errors?.title) {
              showAlert(response?.errors?.title, true);
            } else {
              showAlert("Something went wrong.", true);
            }
          }
        })
        .catch((error) => {
          setUpdateLoader(false);
          console.error(error);
        });
    },
  });

  useEffect(() => {
    formik.setFieldValue(`isPenaltyLock`, isPenaltyLock);
  }, [isPenaltyLock]);

  let deliveryByDays = daysDifference(
    parseMMDDYYYY(orderDetails?.deliver_by),
    new Date()
  );
  deliveryByDays = isNaN(deliveryByDays) ? 0 : deliveryByDays;

  let { statusColor, dateColor } = getOrderStatusColor({
    ...orderDetails,
    deliveryByDays,
  });

  //SET BENIFITS AND RESTRICTION
  let benefitsRestrictions = orderDetails?.restrictions;
  // let pngImagePath = "/media/png/";

  let penaltyDate =
    _.size(orderNotes) > 0 &&
    orderNotes?.find((item: any) => item?.type === 2)?.date;

  return (
    <>
      <form
        onSubmit={(e: any) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div
          data-te-modal-init
          className={`show popupinput-style2 upload-ticket-popup  offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium z-40 md:max-w-[43.125rem] w-full`}
          tabIndex={-1}
          id="singleTicket"
          ref={canvasRef}
          aria-labelledby="singleTicketLabel"
        >
          <div className="  bg-white border-t w-full h-full ml-auto flex flex-col">
            <div className="flex justify-between pl-5 items-center border-b">
              <div>
                <span className="md:text-sm15 text-sm12 block font-semibold">
                  {" "}
                  Order ID: {orderDetails?.order_id}
                </span>
              </div>
              <div className="flex flex-wrap">
                <button
                  type="button"
                  className="group relative mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l"
                  // data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={() => {
                    handleShow({
                      show: false,
                      data: "",
                    });
                    display(false);
                  }}
                >
                  <IKTSVG
                    className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition"
                    path={crossIcon}
                    svgClassName="w-2.5 h-2.5"
                  />
                </button>
              </div>
            </div>

            {/* VIEW ORDER DETAIL */}
            {isDetailsLoading ? (
              <PopUpShimmer />
            ) : (
              <>
                {" "}
                <div className="p-5 h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                  <div className="">
                    <div
                      className={`flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0 ${
                        isStatusLock === true && isGodAdmin
                          ? ""
                          : "shadow-op2 border-transparent"
                      }`}
                    >
                      <div
                        className={`title_block w-full border-b  flex  justify-between min-h-[2.5rem]`}
                      >
                        <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                          Order details
                        </h5>
                        {isGodAdmin && lockButtonShow && (
                          <button
                            type="button"
                            className="w-10 flex items-center justify-center group border-l"
                            onClick={() => setIsStatusLock(!isStatusLock)}
                          >
                            <IKTSVG
                              // path={LockIcon}
                              path={isStatusLock ? LockIcon : LockIconOpen}
                              svgClassName={`group-hover:fill-indigo-500  h-[.9375rem] ${
                                isStatusLock
                                  ? "w-3 fill-violet-500"
                                  : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
                              }`}
                            />
                          </button>
                        )}
                      </div>
                      <div className="inner-part px-3 py-3 flex flex-wrap">
                        <CopyToClipboard
                          headerName="Order ID"
                          value={`${orderDetails?.order_id}`}
                          id={"orderDetails"}
                          handleClick={(data: any) => {
                            let copiedValues = copy(data?.value);
                            setIsCopied({ id: data?.id, value: copiedValues });
                          }}
                        />
                        {/* <DetailInfo
                        header="Order ID"
                        value={orderDetails?.order_id}
                      /> */}
                        <DetailInfo
                          header="Order date"
                          value={moment(orderDetails?.order_date_time).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        />
                        {isGodAdmin && (
                          <DetailInfo
                            header="Marketplace"
                            value={orderDetails?.marketplace}
                          />
                        )}

                        {isGodAdmin && (
                          <DetailInfo
                            header="Marketplace ID"
                            value={orderDetails?.marketplace_id}
                          />
                        )}

                        {/* <DetailInfo
                            header="Order status"
                            headerClassName="text-gray-400 text-sm12 mb-1"
                            value={orderDetails?.order_status_type}
                            valueClassName={`inline-flex px-[10px] py-[3px]  ${
                              orderDetails?.order_status_type === "Commissionable" ||
                              orderDetails?.order_status_type === "Paid"
                                ? "bg-green-600"
                                : "bg-rose-500"
                            }   text-sm12 text-white rounded`}
                          /> */}
                        <DetailInfo
                          header="Order status"
                          // isSingle={orderDetails?.is_legacy_order == "true"}
                          isSingle={true}
                          headerClassName="text-gray-400 text-sm12 mb-1"
                          value={orderDetails?.order_status}
                          // valueClassName={`inline-flex px-2.5 py-[3px] ${
                          //   orderDetails?.is_legacy_order == "true"
                          //     ? `bg-rose-500`
                          //     : orderDetails?.order_status === "Commissionable" ||
                          //       orderDetails?.order_status === "Paid"
                          //     ? `bg-green-600`
                          //     : `bg-violet-500`
                          // } text-sm12 text-white rounded`}
                          valueClassName={`inline-flex px-2.5 py-[3px] ${statusColor} text-sm12 text-white rounded`}
                        />

                        {isGodAdmin && (
                          <div className=" md:w-1/2 w-full p-2">
                            <div className="flex gap-1">
                              {" "}
                              <p className="text-gray-400 text-sm12 flex flex-inline mb-1">
                                Internal order status
                              </p>{" "}
                              {/* <span
                                className="ml-2 cursor-pointer"
                                onClick={() => setIsStatusLock(!isStatusLock)}
                              >
                                
                                <IKTSVG
                                  svgClassName="!w-[.8125rem] !h-[.8125rem]"
                                  path={isStatusLock ? LockIcon : LockIconOpen}
                                />
                              </span> */}
                            </div>

                            {isGodAdmin && !isStatusLock ? (
                              <div className="w-full text-sm12 border-violet-900 flex flex-wrap items-center relative">
                                <TableDropdown
                                  options={
                                    reportsOptions?.internal_order_statuses
                                  }
                                  name="order_status_id"
                                  placeholder="Order status"
                                  isOrderStatusDropdown="true"
                                  value={reportsOptions?.internal_order_statuses?.find(
                                    (option: any) =>
                                      option?.name ===
                                      formik?.values?.order_status_id
                                  )}
                                  isClearable={false}
                                  handleOnChange={(e: any) => {
                                    formik.setFieldValue(
                                      `order_status_id`,
                                      e.target.value?.id
                                    );
                                    formik.setFieldValue(
                                      `order_status`,
                                      e.target.value?.description
                                    );
                                  }}
                                  // getOptionLabel="name"
                                  // getOptionValue="id"
                                  isValueCapital={true}
                                  isCapital={true}
                                />
                              </div>
                            ) : (
                              <p className="text-sm12">
                                {orderDetails?.internal_order_status
                                  ? orderDetails?.internal_order_status
                                  : "-"}
                              </p>
                            )}
                          </div>
                        )}

                        <DetailInfo
                          header="Deliver by"
                          headerClassName="text-gray-400 text-sm12 mb-1"
                          value={
                            orderDetails?.deliver_by
                              ? orderDetails?.deliver_by
                              : "-"
                          }
                          valueClassName={`inline-flex px-[10px] py-[3px]    ${dateColor} text-sm12 rounded inline-block`}
                        />

                        {orderDetails?.order_status_type === "Commissionable" &&
                          orderDetails?.paid === "false" && (
                            <DetailInfo
                              header="Expected payout date"
                              headerClassName="text-gray-400 text-sm12 mb-1"
                              value={moment(orderDetails?.event_date)
                                .add(14, "days")
                                .format("DD/MM/YYYY")}
                            />
                          )}

                        {shippingDetails?.delivery_partner_name && (
                          <DetailInfo
                            headerClassName="text-gray-400 text-sm12"
                            header="Delivery partner"
                            value={shippingDetails?.delivery_partner_name}
                          />
                        )}

                        {shippingDetails?.shipping_tracking_id && (
                          <DetailInfo
                            headerClassName="text-gray-400 text-sm12"
                            header="Shipping tracking ID"
                            value={shippingDetails?.shipping_tracking_id}
                          />
                        )}
                      </div>
                    </div>

                    <div className="flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
                      <div
                        className={`title_block w-full border-b  flex  justify-between min-h-[2.5rem]`}
                      >
                        <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                          Tickets ordered
                        </h5>
                      </div>
                      <div className="inner-part px-3 py-3 flex flex-wrap">
                        <DetailInfo
                          header="Event description"
                          value={orderDetails?.event_description}
                          valueClassName="text-sm12 overflow-hidden text-ellipsis whitespace-nowrap"
                        />
                        <DetailInfo
                          header="Venue"
                          value={orderDetails?.venue}
                        />
                        <DetailInfo
                          header="Event date"
                          value={moment(orderDetails?.event_date).format(
                            "ddd, DD MMM YYYY, HH:mm"
                          )}
                        />
                        <DetailInfo
                          header="Seat details"
                          value={orderDetails?.seating_location}
                        />

                        <div className="md:w-1/2 p-2 w-full flex">
                          <div className="w-1/2">
                            <div className="text-gray-400 text-sm12">
                              Ticket quantity
                            </div>
                            <div className="text-sm12">
                              {orderDetails?.ticket_quantity
                                ? orderDetails?.ticket_quantity
                                : "0"}
                            </div>
                          </div>
                          <div className="w-1/2">
                            <div className="text-gray-400 text-sm12">
                              Ticket price
                            </div>
                            <div className="text-sm12">
                              {orderDetails?.ticket_price
                                ? `${
                                    SYMBOLS?.[orderDetails?.ticket_currency]
                                  }${decimalNumber(orderDetails?.ticket_price)}`
                                : "-"}
                            </div>
                          </div>
                        </div>

                        <DetailInfo
                          header="Transaction value"
                          value={
                            orderDetails?.transaction_value
                              ? `${
                                  SYMBOLS?.[orderDetails?.ticket_currency]
                                }${decimalNumber(
                                  orderDetails?.transaction_value
                                )}`
                              : "-"
                          }
                        />
                      </div>
                    </div>

                    {/* RESTRICTIONS */}
                    <div className=" flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0  ">
                      <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                        <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                          Benefits/Restrictions
                        </h5>
                      </div>
                      <div className="inner-part px-3 py-3 flex flex-wrap w-full">
                        <div className="md:w-1/2 p-2 w-full ">
                          {benefitsRestrictions?.length > 0 ? (
                            benefitsRestrictions?.map(
                              (restriction: any, restKey: number) => {
                                return (
                                  <div
                                    className="single_list flex items-center text-xs p-0 md:w-1/2 w-full px-[.3125rem]"
                                    key={`restriction_${restKey}`}
                                  >
                                    - {restriction}
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <h5 className="font-medium text-xs ">N/A</h5>
                          )}
                        </div>
                      </div>
                    </div>

                    {(orderDetails?.customer_first_name ||
                      orderDetails?.customer_last_name ||
                      orderDetails?.customer_email ||
                      orderDetails?.customer_contact_number ||
                      orderDetails?.customer_city_of_birth ||
                      orderDetails?.customer_nationality) && (
                      <>
                        {/* {isGodAdmin && (
                          <div
                            className="px-5 py-3 pb-0 lock-icon flex justify-start"
                            onClick={() => setIsCustomerLock(!isCustomerLock)}
                          >
                            <IKTSVG
                              svgClassName="!w-[.8125rem] !h-[.8125rem]"
                              path={isCustomerLock ? LockIcon : LockIconOpen}
                            />
                          </div>
                        )} */}
                        <div
                          className={`flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0 ${
                            isCustomerLock === true && isGodAdmin
                              ? ""
                              : "shadow-op2 border-transparent"
                          }`}
                        >
                          {/* <div className="title text-sm13 absolute font-semibold bg-white px-[.3125rem] left-[.9375rem] top-0 -translate-y-[50%] leading-4">
                            Customer details
                          </div> */}
                          <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                            <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                              Customer details
                            </h5>
                            {isGodAdmin && lockButtonShow && (
                              <button
                                type="button"
                                className="w-10 flex items-center justify-center group border-l"
                                onClick={() =>
                                  setIsCustomerLock(!isCustomerLock)
                                }
                              >
                                <IKTSVG
                                  // path={LockIcon}
                                  path={
                                    isCustomerLock ? LockIcon : LockIconOpen
                                  }
                                  svgClassName={`group-hover:fill-indigo-500  h-[.9375rem] ${
                                    isCustomerLock
                                      ? "w-3 fill-violet-500"
                                      : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
                                  }`}
                                />
                              </button>
                            )}
                          </div>
                          <div className="inner-part px-3 py-3 flex flex-wrap w-full">
                            {isCustomerLock === false && isGodAdmin ? (
                              <>
                                {/* EDIT CUSTOMER INFO */}
                                <div className="flex flex-wrap py-2 form_cols attendee-form lock_form -mt-1">
                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem]  text-sm12 label_text">
                                      Customer first name
                                    </div>
                                    <TextInput
                                      name={`customer_first_name`}
                                      type="text"
                                      value={
                                        formik?.values?.customer_first_name
                                      }
                                      required={false}
                                      placeholder="Enter first name"
                                      className="!h-8"
                                      // errorClass={
                                      //   formik.touched.details?.[index]?.name &&
                                      //   formik.errors.details?.[index]?.name &&
                                      //   "border-rose-500"
                                      // }
                                      handleClearValue={() =>
                                        formik.setFieldValue(
                                          `customer_first_name`,
                                          ""
                                        )
                                      }
                                      handleOnChange={formik.handleChange}
                                    />
                                  </div>
                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem] text-sm12 label_text">
                                      Customer last name
                                    </div>
                                    <TextInput
                                      name={`customer_last_name`}
                                      type="text"
                                      value={formik?.values?.customer_last_name}
                                      required={false}
                                      placeholder="Enter last name"
                                      className="!h-8"
                                      // errorClass={
                                      //   formik.touched.details?.[index]?.name &&
                                      //   formik.errors.details?.[index]?.name &&
                                      //   "border-rose-500"
                                      // }
                                      handleClearValue={() =>
                                        formik.setFieldValue(
                                          `customer_last_name`,
                                          ""
                                        )
                                      }
                                      handleOnChange={formik.handleChange}
                                    />
                                  </div>
                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem] text-sm12 label_text">
                                      Customer email
                                    </div>
                                    <div className="flex-1 relative">
                                      <TextInput
                                        name={`customer_email`}
                                        type="email"
                                        value={formik?.values?.customer_email}
                                        required={false}
                                        placeholder="Enter Email"
                                        className="!h-8"
                                        errorClass={
                                          formik.touched.customer_email &&
                                          formik.errors.customer_email &&
                                          "border-rose-500"
                                        }
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            `customer_email`,
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                      {formik.touched.customer_email &&
                                        formik.errors.customer_email && (
                                          <div className="text-xs text-rose-500 error-msg ">
                                            {
                                              formik.errors
                                                .customer_email as string
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem] text-sm12 label_text">
                                      Customer contact number
                                    </div>
                                    <div className="flex-1 relative">
                                      <TextInput
                                        name={`customer_contact_number`}
                                        type="text"
                                        value={
                                          formik?.values
                                            ?.customer_contact_number
                                        }
                                        required={false}
                                        placeholder="Enter Contact number"
                                        className="!h-8"
                                        errorClass={
                                          formik.touched
                                            .customer_contact_number &&
                                          formik.errors
                                            .customer_contact_number &&
                                          "border-rose-500"
                                        }
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            `customer_contact_number`,
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                        handleKeyDown={handleKeyDown}
                                      />
                                      {formik.touched.customer_contact_number &&
                                        formik.errors
                                          .customer_contact_number && (
                                          <div className="text-xs text-rose-500 error-msg ">
                                            {
                                              formik.errors
                                                .customer_contact_number as string
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem] text-sm12 label_text">
                                      Customer nationality
                                    </div>
                                    <div className="flex-1 relative">
                                      <TextInput
                                        name={`customer_nationality`}
                                        type="text"
                                        value={
                                          formik?.values?.customer_nationality
                                        }
                                        required={false}
                                        placeholder="Enter nationality"
                                        className="!h-8"
                                        errorClass={
                                          formik.touched.customer_nationality &&
                                          formik.errors.customer_nationality &&
                                          "border-rose-500"
                                        }
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            "customer_nationality",
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                      {formik.touched.customer_nationality &&
                                        formik.errors.customer_nationality && (
                                          <div className="text-xs text-rose-500 error-msg ">
                                            {
                                              formik.errors
                                                .customer_nationality as string
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>

                                  <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                    <div className="min-w-[6.25rem] text-sm12 label_text">
                                      Customer city of birth
                                    </div>
                                    <div className="flex-1 relative">
                                      <TextInput
                                        name={`customer_city_of_birth`}
                                        type="text"
                                        value={
                                          formik?.values?.customer_city_of_birth
                                        }
                                        required={false}
                                        placeholder="Enter city of birth"
                                        className="!h-8"
                                        errorClass={
                                          formik.touched
                                            .customer_city_of_birth &&
                                          formik.errors
                                            .customer_city_of_birth &&
                                          "border-rose-500"
                                        }
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            "customer_city_of_birth",
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                      {formik.touched.customer_city_of_birth &&
                                        formik.errors
                                          .customer_city_of_birth && (
                                          <div className="text-xs text-rose-500 error-msg ">
                                            {
                                              formik.errors
                                                .customer_city_of_birth as string
                                            }
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {orderDetails?.customer_id && (
                                  <CopyToClipboard
                                    headerName="Customer ID"
                                    value={`${orderDetails?.customer_id}`}
                                    id={"customerName"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}
                                {(orderDetails?.customer_first_name ||
                                  orderDetails?.customer_last_name) && (
                                  <CopyToClipboard
                                    headerName="Customer name"
                                    value={`${orderDetails?.customer_first_name} ${orderDetails?.customer_last_name}`}
                                    id={"customerName"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {orderDetails?.customer_email && (
                                  <CopyToClipboard
                                    headerName="Customer email"
                                    value={`${
                                      orderDetails?.customer_email
                                        ? orderDetails?.customer_email
                                        : ""
                                    }`}
                                    id={"customerEmail"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {orderDetails?.customer_contact_number && (
                                  <CopyToClipboard
                                    headerName="Customer phone"
                                    value={`${
                                      orderDetails?.customer_contact_number
                                        ? orderDetails?.customer_contact_number
                                        : ""
                                    }`}
                                    id={"customer_contact_number"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {orderDetails?.customer_nationality && (
                                  <CopyToClipboard
                                    headerName="Customer nationality"
                                    value={`${
                                      orderDetails?.customer_nationality
                                        ? orderDetails?.customer_nationality
                                        : ""
                                    }`}
                                    id={"customer_nationality"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {orderDetails?.customer_city_of_birth && (
                                  <CopyToClipboard
                                    headerName="Customer city of birth"
                                    value={`${
                                      orderDetails?.customer_city_of_birth
                                        ? orderDetails?.customer_city_of_birth
                                        : ""
                                    }`}
                                    id={"customer_city_of_birth"}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {(orderDetails?.fine_amount > 0 || isGodAdmin) && (
                      <div className="flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0 ">
                        <div
                          className={`title_block w-full border-b  flex  justify-between min-h-[2.5rem]`}
                        >
                          <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                            Order penalty
                          </h5>
                          {isGodAdmin && !(orderDetails?.order_paid === 1) && (
                            <button
                              type="button"
                              className="w-10 flex items-center justify-center group border-l"
                              onClick={() => setIsPenaltyLock(!isPenaltyLock)}
                            >
                              <IKTSVG
                                path={isPenaltyLock ? LockIcon : LockIconOpen}
                                svgClassName={`group-hover:fill-indigo-500   h-[.9375rem] ${
                                  isPenaltyLock
                                    ? "w-3 fill-violet-500"
                                    : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
                                }`}
                              />
                            </button>
                          )}
                        </div>

                        <div className="py-5 px-5 justify-start w-full">
                          <div className="note-block false">
                            <div className="mt-5 first-of-type:mt-0 ">
                              <span className="text-sm12 font-medium mb-1 block text-gray-400 ">
                                Penalty{" "}
                                {penaltyDate &&
                                  `- ${moment(penaltyDate).format(
                                    "DD/MM/YYYY"
                                  )}`}{" "}
                              </span>
                              {isPenaltyLock ? (
                                <div className="px-2 py-1 bg-gray-100 flex justify-between rounded whitespace-pre-line ">
                                  <p className="text-sm12 break-words overflow-hidden">
                                    {orderDetails?.fine_amount
                                      ? `${
                                          orderDetails?.fine_currency
                                            ? SYMBOLS[
                                                orderDetails?.fine_currency
                                              ]
                                            : ""
                                        }${decimalNumber(
                                          orderDetails?.fine_amount
                                        )}`
                                      : "None"}
                                  </p>
                                </div>
                              ) : (
                                <div className="flex flex-col gap-2">
                                  <div className="flex-1 relative">
                                    <PriceField_copy
                                      currenncySymbol={
                                        orderDetails?.fine_currency
                                      }
                                      name="fine_amount"
                                      type="text"
                                      value={formik?.values?.fine_amount}
                                      required={false}
                                      errorClass={
                                        formik.touched.fine_amount &&
                                        formik.errors.fine_amount &&
                                        "border-rose-500"
                                      }
                                      labelErrorClass={
                                        formik.touched.fine_amount &&
                                        formik.errors.fine_amount &&
                                        "text-rose-500 "
                                      }
                                      label="Penalty amount"
                                      id="amount"
                                      handleOnChange={(data: any) =>
                                        formik.setFieldValue(
                                          data?.target?.name,
                                          data?.target?.value?.value
                                        )
                                      }
                                      handleClearValue={() =>
                                        formik.setFieldValue("fine_amount", "")
                                      }
                                      errorEventIndex={"fine_amount"}
                                      errorMessage={formik.errors.fine_amount}
                                    />
                                    {formik.touched.fine_amount &&
                                      formik.errors.fine_amount && (
                                        <div className="text-xs text-rose-500 error-msg ">
                                          {formik.errors.fine_amount as string}
                                        </div>
                                      )}
                                  </div>
                                  <div className="flex-1 relative">
                                    <TextInput
                                      name={`note_penalty`}
                                      type="text"
                                      value={formik?.values?.note_penalty}
                                      required={false}
                                      placeholder={
                                        Messages?.penaltyNotePlaceholder
                                      }
                                      className="!h-6 bg-gray-100/50"
                                      handleClearValue={() =>
                                        formik.setFieldValue("note_penalty", "")
                                      }
                                      handleOnChange={formik.handleChange}
                                      errorClass={
                                        formik.touched.note_penalty &&
                                        formik.errors.note_penalty &&
                                        "!border-rose-500"
                                      }
                                    />
                                    {formik.touched.note_penalty &&
                                      formik.errors.note_penalty && (
                                        <div className="text-xs text-rose-500 error-msg ">
                                          {formik.errors.note_penalty as string}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className={`flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0 ${
                        isTicketLock === true && isGodAdmin
                          ? ""
                          : "shadow-op2 border-transparent"
                      }`}
                    >
                      {/* {isGodAdmin && (
                        <div
                          className="px-5 py-3 pb-0 lock-icon flex justify-start"
                          onClick={() => setIsTicketLock(!isTicketLock)}
                        >
                          <IKTSVG
                            svgClassName="!w-[.8125rem] !h-[.8125rem]"
                            path={isTicketLock ? LockIcon : LockIconOpen}
                          />
                        </div>
                      )} */}

                      <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                        <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                          Attendee details
                        </h5>
                        {isGodAdmin && lockButtonShow && (
                          <button
                            type="button"
                            className="w-10 flex items-center justify-center group border-l"
                            onClick={() => setIsTicketLock(!isTicketLock)}
                          >
                            <IKTSVG
                              // path={LockIcon}
                              path={isTicketLock ? LockIcon : LockIconOpen}
                              svgClassName={`group-hover:fill-indigo-500 h-[.9375rem] ${
                                isTicketLock
                                  ? "w-3 fill-violet-500"
                                  : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
                              }`}
                            />
                          </button>
                        )}
                      </div>
                      {isTicketLock === false && isGodAdmin ? (
                        <>
                          {/* EDIT TICKIT INFO */}
                          {formik?.values?.items?.map(
                            (obj: any, index: any) => {
                              return (
                                <div className="last-of-type:border-b-0 border-b">
                                  <div className="flex flex-wrap px-3 py-3 form_cols attendee-form lock_form">
                                    <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                      <div className="min-w-[6.25rem] text-sm12 label_text">
                                        Ticket {index + 1} name
                                      </div>
                                      <TextInput
                                        name={`items[${index}].name`}
                                        type="text"
                                        value={obj?.name}
                                        required={false}
                                        placeholder="Enter ticket name"
                                        className="!h-8"
                                        // errorClass={
                                        //   formik.touched.details?.[index]?.name &&
                                        //   formik.errors.details?.[index]?.name &&
                                        //   "border-rose-500"
                                        // }
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            `items[${index}].name`,
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                    </div>
                                    <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                      <div className="min-w-[6.25rem] text-sm12 label_text">
                                        Row
                                      </div>
                                      <TextInput
                                        name={`items[${index}].row`}
                                        type="text"
                                        value={obj?.row}
                                        required={false}
                                        placeholder="Enter row name"
                                        className="!h-8"
                                        // errorClass={
                                        //   formik.touched.details?.[index]?.name &&
                                        //   formik.errors.details?.[index]?.name &&
                                        //   "border-rose-500"
                                        // }
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            `items[${index}].row`,
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                    </div>
                                    <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                      <div className="min-w-[6.25rem] text-sm12 label_text">
                                        Seat
                                      </div>
                                      <TextInput
                                        name={`items[${index}].seat`}
                                        type="text"
                                        value={obj?.seat}
                                        required={false}
                                        placeholder="Enter seat name"
                                        className="!h-8"
                                        // errorClass={
                                        //   formik.touched.details?.[index]?.name &&
                                        //   formik.errors.details?.[index]?.name &&
                                        //   "border-rose-500"
                                        // }
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            `items[${index}].seat`,
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                    </div>

                                    <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                      <div className="min-w-[6.25rem] text-sm12 label_text">
                                        Customer email address
                                      </div>
                                      <div className="flex-1 relative">
                                        <TextInput
                                          name={`items[${index}].customer_email_address`}
                                          type="text"
                                          value={obj?.customer_email_address}
                                          required={false}
                                          placeholder="Enter customer email"
                                          className="!h-8"
                                          handleClearValue={() =>
                                            formik.setFieldValue(
                                              `items[${index}].customer_email_address`,
                                              ""
                                            )
                                          }
                                          handleOnChange={formik.handleChange}
                                        />
                                        {formik.touched?.items?.[index]
                                          ?.customer_email_address &&
                                          formik.errors?.items?.[index]
                                            ?.customer_email_address && (
                                            <div className="text-xs text-rose-500 error-msg ">
                                              {
                                                formik.errors?.items?.[index]
                                                  ?.customer_email_address as string
                                              }
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                      <div className="min-w-[6.25rem] text-sm12 label_text">
                                        Customer national ID
                                      </div>
                                      <TextInput
                                        name={`items[${index}].customer_national_id`}
                                        type="text"
                                        value={obj?.customer_national_id}
                                        required={false}
                                        placeholder="Enter customer national ID"
                                        className="!h-8"
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            `items[${index}].customer_national_id`,
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                    </div>

                                    <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                      <div className="min-w-[6.25rem] text-sm12 label_text">
                                        Customer nationality
                                      </div>
                                      <TextInput
                                        name={`items[${index}].customer_nationality`}
                                        type="text"
                                        value={obj?.customer_nationality}
                                        required={false}
                                        placeholder="Enter customer nationality"
                                        className="!h-8"
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            `items[${index}].customer_nationality`,
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                    </div>

                                    <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                      <div className="min-w-[6.25rem] text-sm12 label_text">
                                        Customer contact number
                                      </div>
                                      <div className="flex-1 relative">
                                        <TextInput
                                          name={`items[${index}].customer_contact_number`}
                                          type="text"
                                          value={obj?.customer_contact_number}
                                          required={false}
                                          placeholder="Enter customer number "
                                          className="!h-8"
                                          handleClearValue={() =>
                                            formik.setFieldValue(
                                              `items[${index}].customer_contact_number`,
                                              ""
                                            )
                                          }
                                          errorClass={
                                            formik.touched?.items?.[index]
                                              ?.customer_contact_number &&
                                            formik.errors?.items?.[index]
                                              ?.customer_contact_number &&
                                            "border-rose-500"
                                          }
                                          handleOnChange={formik.handleChange}
                                        />
                                        {formik.touched?.items?.[index]
                                          ?.customer_contact_number &&
                                          formik.errors?.items?.[index]
                                            ?.customer_contact_number && (
                                            <div className="text-xs text-rose-500 error-msg ">
                                              {
                                                formik.errors?.items?.[index]
                                                  ?.customer_contact_number as string
                                              }
                                            </div>
                                          )}
                                      </div>
                                    </div>

                                    <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                      <div className="min-w-[6.25rem] text-sm12 label_text">
                                        Customer birth place
                                      </div>
                                      <TextInput
                                        name={`items[${index}].customer_birth_place`}
                                        type="text"
                                        value={obj?.customer_birth_place}
                                        required={false}
                                        placeholder="Enter customer birth place"
                                        className="!h-8"
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            `items[${index}].customer_birth_place`,
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                    </div>

                                    <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                      <div className="min-w-[6.25rem] text-sm12 label_text">
                                        Customer address
                                      </div>
                                      <TextInput
                                        name={`items[${index}].customer_address`}
                                        type="text"
                                        value={obj?.customer_address}
                                        required={false}
                                        placeholder="Enter customer address"
                                        className="!h-8"
                                        handleClearValue={() =>
                                          formik.setFieldValue(
                                            `items[${index}].customer_address`,
                                            ""
                                          )
                                        }
                                        handleOnChange={formik.handleChange}
                                      />
                                    </div>
                                    <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                      <div className="min-w-[6.25rem] text-sm12 label_text">
                                        Customer date of birth
                                      </div>
                                      <DatePickerField
                                        name={`items[${index}].customer_dob`}
                                        placeholderMoveTop={false}
                                        disabledKeyboardNavigation={false}
                                        value={obj?.customer_dob}
                                        id={"dateOfBirth"}
                                        isDateRange={false}
                                        IsIconRequire={false}
                                        handleOnChange={(data: any) => {
                                          formik.handleChange(data);
                                        }}
                                        placeholder="DD/MM/YYYY"
                                        format="dd/MM/yy"
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </>
                      ) : (
                        orderDetails?.items.map((item: any, key: number) => {
                          return (
                            <>
                              <div className="flex flex-wrap px-3 border-b last:border-none pb-3 mb-3 last:pb-0 last:mb-0 w-full">
                                {item?.name && (
                                  <CopyToClipboard
                                    headerName={`Ticket ${key + 1} name`}
                                    value={item?.name}
                                    id={key}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                <div className="md:w-1/2 flex flex-wrap w-full">
                                  <DetailInfo
                                    header="Row"
                                    headerClassName="text-gray-400 text-sm12 mb-2"
                                    value={item?.row ? item?.row : "N/A"}
                                  />
                                  <DetailInfo
                                    header="Seat"
                                    headerClassName="text-gray-400 text-sm12 mb-2"
                                    value={item?.seat ? item?.seat : "N/A"}
                                  />
                                </div>

                                {/* MOBILE LINK */}
                                {item?.mobile_link?.trim() && (
                                  <div className="md:w-1/2 p-2 w-full">
                                    <div className="text-gray-400 text-sm12 mb-1">
                                      Link
                                    </div>
                                    <div className="relative inline-flex overflow-hidden text-white rounded group">
                                      <button
                                        type="button"
                                        className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition font-medium !rounded-l text-sm13 px-2.5 py-0.5"
                                        onClick={() => {
                                          window.open(
                                            item?.mobile_link,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        <IKTSVG
                                          path={externalLink}
                                          className="w-[.6875rem] h-3 mr-2 fill-white"
                                          svgClassName="w-[.6875rem] h-3"
                                        ></IKTSVG>
                                        Link
                                      </button>

                                      <button
                                        title="Copy link"
                                        type="button"
                                        className="flex items-center text-white bg-gray-200 transition font-medium !rounded-r text-sm13 px-2.5 py-0.5 !border-0"
                                        onClick={(e: any) => {
                                          let data = copy(item?.mobile_link);
                                          setCopyData({
                                            id: key,
                                            value: data,
                                          });
                                          setTimeout(() => {
                                            setCopyData(undefined);
                                          }, 1000);
                                        }}
                                      >
                                        <IKTSVG
                                          path={CloneSVG}
                                          className={`w-[.6875rem] h-3 
                                        
                                        ${
                                          copyData?.id === key
                                            ? "fill-green-600  hover:fill-green-600"
                                            : "fill-violet-500 hover:fill-indigo-500"
                                        }
                                        `}
                                          svgClassName="w-[.6875rem] h-3"
                                        ></IKTSVG>
                                      </button>
                                    </div>
                                  </div>
                                )}
                                {/*CUSTOMER DOB */}
                                {item?.customer_dob && (
                                  <CopyToClipboard
                                    headerName={`Ticket ${key + 1} DOB`}
                                    value={
                                      item?.customer_dob
                                        ? moment(item.customer_dob).format(
                                            "DD/MM/YYYY"
                                          )
                                        : ``
                                    }
                                    id={key}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}
                                {/*CONTACT PHONE */}
                                {item?.customer_birth_place && (
                                  <CopyToClipboard
                                    headerName={`Ticket ${key + 1} birth place`}
                                    value={
                                      item?.customer_birth_place
                                        ? item?.customer_birth_place
                                        : ``
                                    }
                                    id={key}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}
                                {/*CONTACT PHONE */}
                                {item?.customer_contact_number && (
                                  <CopyToClipboard
                                    headerName={`Ticket ${
                                      key + 1
                                    } contact number`}
                                    value={
                                      item?.customer_contact_number
                                        ? item?.customer_contact_number
                                        : ``
                                    }
                                    id={key}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {/* CUSTOMER EMAIL ADDRESS */}
                                {item?.customer_email_address && (
                                  <CopyToClipboard
                                    headerName={`Ticket ${key + 1} email`}
                                    value={
                                      item?.customer_email_address
                                        ? item?.customer_email_address
                                        : ``
                                    }
                                    id={key}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {/*NATIONAL ID */}
                                {item?.customer_national_id && (
                                  <CopyToClipboard
                                    headerName={`Ticket ${key + 1} national ID`}
                                    value={
                                      item?.customer_national_id
                                        ? item?.customer_national_id
                                        : ``
                                    }
                                    id={key}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}
                                {/*NATIONALITY */}
                                {item?.customer_nationality && (
                                  <CopyToClipboard
                                    headerName={`Ticket ${key + 1} nationality`}
                                    value={
                                      item?.customer_nationality
                                        ? item?.customer_nationality
                                        : ``
                                    }
                                    id={key}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {/*address */}
                                {item?.customer_address && (
                                  <CopyToClipboard
                                    headerName={`Ticket ${key + 1} address`}
                                    value={
                                      item?.customer_address
                                        ? item?.customer_address
                                        : ``
                                    }
                                    id={key}
                                    handleClick={(data: any) => {
                                      let copiedValues = copy(data?.value);
                                      setIsCopied({
                                        id: data?.id,
                                        value: copiedValues,
                                      });
                                    }}
                                  />
                                )}

                                {PdfTickitCheck &&
                                  orderDetails?.fulfilled_ticket_files?.e_tickets?.map(
                                    // eslint-disable-next-line array-callback-return
                                    (eticket: any, rIndex: any) => {
                                      if (item?.id === eticket?.item_id) {
                                        return (
                                          <React.Fragment key={rIndex}>
                                            <div className="flex flex-wrap w-full">
                                              <div className="md:w-1/2 md:p-2 py-2 w-full">
                                                <button
                                                  type="button"
                                                  className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                                                  onClick={() => {
                                                    if (eticket?.is_url === 0) {
                                                      downloadPDF(
                                                        eticket?.file,
                                                        "ticket.pdf"
                                                      );
                                                      openBlobPDF(
                                                        eticket?.file
                                                      );
                                                    } else if (
                                                      eticket?.is_url === 1
                                                    ) {
                                                      window.open(
                                                        eticket?.file
                                                      );
                                                    }
                                                  }}
                                                >
                                                  {loader ? (
                                                    <IKTSVG
                                                      className="flex items-center justify-center fill-violet-500 transition animate-spin"
                                                      path={ProcessSVG}
                                                    />
                                                  ) : (
                                                    <IKTSVG
                                                      className="flex items-center justify-center fill-white transition mr-2 rotate-180"
                                                      path={UploadSVG}
                                                    />
                                                  )}
                                                  Download ticket
                                                </button>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        );
                                      }
                                    }
                                  )}
                              </div>
                            </>
                          );
                        })
                      )}
                    </div>

                    {/* for mobile */}
                    {orderDetails?.fulfilled_ticket_files
                      ?.mobile_transfer_proofs?.length > 0 && (
                      <div className="ticket-detail  flex flex-wrap border rounded relative mb-5 last-of-type:mb-0">
                        <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                          <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                            Mobile transfer proofs
                          </h5>
                        </div>
                        <div className="flex flex-wrap w-full">
                          <div className=" md:px-5 px-5  py-[9px]  w-full flex flex-wrap gap-2.5">
                            {orderDetails?.fulfilled_ticket_files?.mobile_transfer_proofs?.map(
                              (item: any, index: number) => {
                                return (
                                  <React.Fragment key={index}>
                                    <div className="flex flex-wrap ">
                                      <button
                                        type="button"
                                        className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                                        onClick={() => {
                                          window.open(item);
                                        }}
                                      >
                                        {loader ? (
                                          <IKTSVG
                                            className="flex items-center justify-center fill-violet-500 transition animate-spin"
                                            path={ProcessSVG}
                                          />
                                        ) : (
                                          <IKTSVG
                                            className="flex items-center justify-center fill-white transition mr-2 rotate-180"
                                            path={UploadSVG}
                                          />
                                        )}
                                        Download file
                                      </button>
                                    </div>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {orderDetails?.additional_file && (
                      <div className="flex flex-wrap border rounded relative mb-5 last-of-type:mb-0">
                        <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                          <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                            Additional File
                          </h5>
                        </div>
                        <div className="px-5 py-2 w-full">
                          <div className=" w-full">
                            <button
                              type="button"
                              className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                              onClick={() => {
                                if (orderDetails?.additional_file) {
                                  window.open(orderDetails?.additional_file);
                                }
                              }}
                            >
                              <IKTSVG
                                className="flex items-center justify-center fill-white transition mr-2 rotate-180"
                                path={UploadSVG}
                              />
                              Download File
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {(isGodAdmin ||
                      checkShippingAddress ||
                      checkBillingAddress) && (
                      <>
                        {/* {isGodAdmin && (
                          <div
                            className="px-5 py-3 pb-0 lock-icon flex justify-start"
                            onClick={() => setIsAddressLock(!isAddressLock)}
                          >
                            <IKTSVG
                              svgClassName="!w-[.8125rem] !h-[.8125rem]"
                              path={isAddressLock ? LockIcon : LockIconOpen}
                            />
                          </div>
                        )} */}
                        <div
                          className={`flex flex-wrap border rounded relative ${
                            isAddressLock === true && isGodAdmin
                              ? ""
                              : "shadow-op2 border-transparent"
                          }`}
                        >
                          <div className="title_block w-full border-b  flex  justify-between min-h-[2.5rem]">
                            <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                              Address details
                            </h5>
                            {isGodAdmin && lockButtonShow && (
                              <button
                                type="button"
                                className="w-10 flex items-center justify-center group border-l"
                                onClick={() => setIsAddressLock(!isAddressLock)}
                              >
                                <IKTSVG
                                  // path={LockIcon}
                                  path={isAddressLock ? LockIcon : LockIconOpen}
                                  svgClassName={`group-hover:fill-indigo-500  h-[.9375rem] ${
                                    isAddressLock
                                      ? "w-3 fill-violet-500"
                                      : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
                                  }`}
                                />
                              </button>
                            )}
                          </div>

                          {isAddressLock === false && isGodAdmin ? (
                            <>
                              {/* EDIT BILING ADDRESS */}
                              <div className="flex flex-wrap px-3 py-3 border-b form_cols attendee-form lock_form">
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Billing address line 1
                                  </div>
                                  <TextInput
                                    name={`customer_billing_address_line_1`}
                                    type="text"
                                    value={
                                      formik?.values
                                        ?.customer_billing_address_line_1
                                    }
                                    required={false}
                                    placeholder="Enter address line 1"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_billing_address_line_1`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Billing address line 2
                                  </div>
                                  <TextInput
                                    name={`customer_billing_address_line_2`}
                                    type="text"
                                    value={
                                      formik?.values
                                        ?.customer_billing_address_line_2
                                    }
                                    required={false}
                                    placeholder="Enter address line 2"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_billing_address_line_2`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Billing address town
                                  </div>
                                  <TextInput
                                    name={`customer_billing_town`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_billing_town
                                    }
                                    required={false}
                                    placeholder="Enter address town"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_billing_town`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Billing address country
                                  </div>
                                  <TextInput
                                    name={`customer_billing_country`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_billing_country
                                    }
                                    required={false}
                                    placeholder="Enter address country"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_billing_country`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Billing address postcode
                                  </div>
                                  <TextInput
                                    name={`customer_billing_postcode`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_billing_postcode
                                    }
                                    required={false}
                                    placeholder="Enter address postcode"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_billing_postcode`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                              </div>

                              {/* EDIT SHIPPING ADDRESS */}
                              <div className="flex flex-wrap px-3 py-3 form_cols attendee-form lock_form ">
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Shipping address line 1
                                  </div>
                                  <TextInput
                                    name={`customer_shipping_address_line_1`}
                                    type="text"
                                    value={
                                      formik?.values
                                        ?.customer_shipping_address_line_1
                                    }
                                    required={false}
                                    placeholder="Enter address line 1"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_shipping_address_line_1`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Shipping address line 2
                                  </div>
                                  <TextInput
                                    name={`customer_shipping_address_line_2`}
                                    type="text"
                                    value={
                                      formik?.values
                                        ?.customer_shipping_address_line_2
                                    }
                                    required={false}
                                    placeholder="Enter address line 2"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_shipping_address_line_2`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Shipping address town
                                  </div>
                                  <TextInput
                                    name={`customer_shipping_town`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_shipping_town
                                    }
                                    required={false}
                                    placeholder="Enter address town"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_shipping_town`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Shipping address country
                                  </div>
                                  <TextInput
                                    name={`customer_shipping_country`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_shipping_country
                                    }
                                    required={false}
                                    placeholder="Enter address country"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_shipping_country`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                                <div className="flex gap-1 flex-col md:w-1/2 sm:w-1/2 w-full px-2 py-1">
                                  <div className="min-w-[6.25rem] text-sm12 label_text">
                                    Shipping address postcode
                                  </div>
                                  <TextInput
                                    name={`customer_shipping_postcode`}
                                    type="text"
                                    value={
                                      formik?.values?.customer_shipping_postcode
                                    }
                                    required={false}
                                    placeholder="Enter address postcode"
                                    className="!h-8"
                                    // errorClass={
                                    //   formik.touched.details?.[index]?.name &&
                                    //   formik.errors.details?.[index]?.name &&
                                    //   "border-rose-500"
                                    // }
                                    handleClearValue={() =>
                                      formik.setFieldValue(
                                        `customer_shipping_postcode`,
                                        ""
                                      )
                                    }
                                    handleOnChange={formik.handleChange}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="flex flex-wrap w-full p-3">
                              {(isGodAdmin || checkBillingAddress) && (
                                <div className="md:w-1/2 p-2 w-full">
                                  <div className="text-gray-400 text-sm12 mb-1">
                                    Billing address
                                  </div>
                                  <div className="relative">
                                    <textarea
                                      className=" whitespace-pre-line scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full text-sm12 rounded bg-gray-100 w-full border-none px-2 block text-violet-800 resize-none min-h-[8.125rem] py-1"
                                      disabled
                                      //     value={`${
                                      //       orderDetails?.customer_billing_address_line_1
                                      //         ? `${orderDetails?.customer_billing_address_line_1},`
                                      //         : " "
                                      //     }
                                      // ${
                                      //   orderDetails?.customer_billing_address_line_2
                                      //     ? `${orderDetails?.customer_billing_address_line_2},`
                                      //     : " "
                                      // }
                                      // ${
                                      //   orderDetails?.customer_billing_town
                                      //     ? `${orderDetails?.customer_billing_town},`
                                      //     : " "
                                      // }
                                      // ${
                                      //   orderDetails?.customer_billing_postcode
                                      //     ? `${orderDetails?.customer_billing_postcode},`
                                      //     : " "
                                      // }
                                      // ${
                                      //   orderDetails?.customer_billing_country
                                      //     ? `${orderDetails?.customer_billing_country}`
                                      //     : " "
                                      // }
                                      // `}
                                      value={
                                        !checkBillingAddress
                                          ? "N/A"
                                          : (orderDetails?.customer_billing_address_line_1
                                              ? `${orderDetails?.customer_billing_address_line_1},\n`
                                              : "") +
                                            (orderDetails?.customer_billing_address_line_2
                                              ? `${orderDetails?.customer_billing_address_line_2},\n`
                                              : "") +
                                            (orderDetails?.customer_billing_town
                                              ? `${orderDetails?.customer_billing_town},\n`
                                              : "") +
                                            (orderDetails?.customer_billing_postcode
                                              ? `${orderDetails?.customer_billing_postcode},\n`
                                              : "") +
                                            (orderDetails?.customer_billing_country
                                              ? orderDetails?.customer_billing_country
                                              : "")
                                      }
                                    ></textarea>
                                    {checkBillingAddress ? (
                                      <button
                                        className="inline-flex !h-full align-top pt-2 justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
                                        type="button"
                                        onClick={(e: any) => {
                                          copy(copyBillingAddressText);
                                          setIsCopied(copyBillingAddressText);
                                        }}
                                      >
                                        {isCopied === copyBillingAddressText ? (
                                          <IKTSVG
                                            className="flex items-start justify-center fill-green-600  hover:fill-green-600 transition"
                                            path={CloneSVG}
                                            svgClassName="w-[.7813rem] h-[.6563rem]"
                                          />
                                        ) : (
                                          <IKTSVG
                                            className="flex items-start justify-center fill-violet-500 hover:fill-indigo-500 transition"
                                            path={cloneSVG}
                                            svgClassName="w-[.7813rem] h-[.6563rem]"
                                          />
                                        )}
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              )}
                              {(isGodAdmin || checkShippingAddress) && (
                                <div className="md:w-1/2 p-2 w-full">
                                  <div className="text-gray-400 text-sm12 mb-1">
                                    Shipping address
                                  </div>
                                  <div className="relative">
                                    <textarea
                                      className="whitespace-pre-line scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full text-sm12 rounded bg-gray-100 w-full border-none px-2 block text-violet-800 resize-none min-h-[8.125rem] py-1"
                                      disabled
                                      //     value={`${
                                      //       orderDetails?.customer_shipping_address_line_1
                                      //         ? `${orderDetails?.customer_shipping_address_line_1},`
                                      //         : " "
                                      //     }
                                      // ${
                                      //   orderDetails?.customer_shipping_address_line_2
                                      //     ? `${orderDetails?.customer_shipping_address_line_2},`
                                      //     : " "
                                      // }
                                      // ${
                                      //   orderDetails?.customer_shipping_town
                                      //     ? `${orderDetails?.customer_shipping_town},`
                                      //     : " "
                                      // }
                                      // ${
                                      //   orderDetails?.customer_shipping_postcode
                                      //     ? `${orderDetails?.customer_shipping_postcode},`
                                      //     : " "
                                      // }
                                      // ${
                                      //   orderDetails?.customer_shipping_country
                                      //     ? `${orderDetails?.customer_shipping_country}`
                                      //     : " "
                                      // }
                                      // `}
                                      value={
                                        !checkShippingAddress
                                          ? "N/A"
                                          : (orderDetails?.customer_shipping_address_line_1
                                              ? `${orderDetails.customer_shipping_address_line_1},\n`
                                              : "") +
                                            (orderDetails?.customer_shipping_address_line_2
                                              ? `${orderDetails.customer_shipping_address_line_2},\n`
                                              : "") +
                                            (orderDetails?.customer_shipping_town
                                              ? `${orderDetails.customer_shipping_town},\n`
                                              : "") +
                                            (orderDetails?.customer_shipping_postcode
                                              ? `${orderDetails.customer_shipping_postcode},\n`
                                              : "") +
                                            (orderDetails?.customer_shipping_country
                                              ? orderDetails.customer_shipping_country
                                              : "")
                                      }
                                    ></textarea>
                                    {orderDetails?.customer_shipping_address_line_1 ||
                                    orderDetails?.customer_shipping_address_line_2 ||
                                    orderDetails?.customer_shipping_town ||
                                    orderDetails?.customer_shipping_postcode ||
                                    orderDetails?.customer_shipping_country ? (
                                      <button
                                        className="inline-flex !h-full align-top pt-2 justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
                                        type="button"
                                        onClick={(e: any) => {
                                          copy(copyShppingAddressText);
                                          setIsCopied(copyShppingAddressText);
                                        }}
                                      >
                                        {isCopied === copyShppingAddressText ? (
                                          <IKTSVG
                                            className="flex items-start justify-center fill-green-600  hover:fill-green-600 transition"
                                            path={CloneSVG}
                                            svgClassName="w-[.7813rem] h-[.6563rem]"
                                          />
                                        ) : (
                                          <IKTSVG
                                            className="flex items-start justify-center fill-violet-500 hover:fill-indigo-500 transition"
                                            path={cloneSVG}
                                            svgClassName="w-[.7813rem] h-[.6563rem]"
                                          />
                                        )}
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    {/** Order Note */}

                    <div className="flex flex-wrap mt-5  border rounded relative mb-5 last-of-type:mb-0">
                      <div
                        className={`title_block w-full ${
                          orderNotes?.length > 0 || orderText?.show
                            ? "border-b"
                            : ""
                        } flex  justify-between min-h-[2.5rem]`}
                      >
                        <div className="left_block flex items-center justify-between flex-1 pr-5">
                          <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center flex-1 font-semibold">
                            Order notes
                          </h5>
                          <button
                            type="button"
                            className={`group inline-flex group items-center    transition font-medium rounded text-sm13 px-2.5 py-0.5 false ${
                              orderText?.show
                                ? "bg-gray-100 hover:bg-gray-100 text-gray-400 pointer-events-none"
                                : "bg-violet-500 hover:bg-indigo-500 text-white"
                            }`}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#detailCanvas"
                            aria-controls="detailCanvas"
                            onClick={HadleAddOrderNote}
                          >
                            <IKTSVG
                              className={`min-w-[0.75rem] max-w-[0.75rem] mr-1 flex items-center justify-center  ${
                                orderText?.show
                                  ? "fill-gray-400 group-hover:fill-gray-400"
                                  : "fill-violet-500 group-hover:fill-white"
                              }`}
                              path={orderText?.show ? AddSVGGray : AddSVG}
                              svgClassName={`fill-violet-500`}
                            />
                            Add note
                          </button>
                        </div>
                      </div>
                      <div className="py-5 px-5 justify-start w-full">
                        <div
                          className={`add-note-btn  ${
                            noteLoader ? `shimmer-effect` : ``
                          }`}
                        >
                          {orderText?.show && (
                            <div className="m-auto">
                              <textarea
                                className={`PLACEHOLDER:truncate font-medium transition bg-gray-100 focus:border-indigo-500 focus:bg-violet-300/50 peer
                      border border-gray-300 whitespace-normal text-sm12 rounded w-full px-2 block text-violet-800 resize-none py-1`}
                                value={orderText?.note}
                                onChange={(e: any) => handleOrderText(e)}
                                placeholder={Messages?.orderNotePlaceholder}
                              ></textarea>
                              <button
                                className="mt-2 border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                                onClick={cancleOrderText}
                              >
                                Cancel
                              </button>

                              <button
                                onClick={(e: any) => addOrderText(e)}
                                className={`mt-2 group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false mx-2`}
                              >
                                Save
                              </button>
                            </div>
                          )}
                        </div>
                        <div
                          className={`note-block ${orderText?.show && "mt-5"}`}
                        >
                          {orderNotes?.map((note: any) => {
                            return (
                              <div
                                className={`mt-5 first-of-type:mt-0 ${
                                  noteLoader ? `shimmer-effect` : ``
                                }`}
                              >
                                <span className="text-sm12 font-medium mb-1 block text-gray-400">
                                  {note?.type === 1
                                    ? "Internal"
                                    : note?.type === 2
                                    ? "Penalty"
                                    : note?.type === 3
                                    ? "External"
                                    : note?.type === 4 && note?.user_name}
                                  {" - "}
                                  {moment(note?.date).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}{" "}
                                </span>
                                <div className="px-2 py-1 bg-gray-100 flex justify-between rounded whitespace-pre-line">
                                  <p className="text-sm12 break-words overflow-hidden">
                                    {note?.note}
                                  </p>
                                  {note?.type !== 2 && (
                                    <span
                                      id={`note-${note?.index}`}
                                      data-tooltip-id={`note-${note?.index}`}
                                      className="mt-1 mr-0.5 cursor-pointer  transition right-0 top-0"
                                      onClick={(e: any) =>
                                        deleteOrderText({ index: note?.index })
                                      }
                                    >
                                      <TooltipPortal
                                        id={`note-${note?.index}`}
                                        content="Delete"
                                        className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium  text-xs z-[45] max-w-[9rem] cursor-default "
                                      />
                                      <IKTSVG
                                        className={`fill-violet-500 hover:fill-indigo-500 transition`}
                                        svgClassName="w-[.5rem] h-[.5rem]"
                                        path={CrossSVG}
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* {orderNotes?.length <= 0 && (
                        <div className="text-sm font-medium text-center my-4 p-2 bg-white rounded ">
                          No note available
                        </div>
                      )} */}
                    </div>

                    {/* <div className="px-5 py-5 border-b">
                      <div
                        className={`add-note-btn ${
                          orderNotes?.length <= 0 ? "" : "mb-5"
                        } ${noteLoader ? `shimmer-effect` : ``}`}
                      >
                        {!orderText?.show && (
                          <button
                            type="button"
                            className="group inline-flex group items-center text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#detailCanvas"
                            aria-controls="detailCanvas"
                            onClick={HadleAddOrderNote}
                          >
                            <IKTSVG
                              className="min-w-[0.75rem] max-w-[0.75rem] mr-1 flex items-center justify-center fill-violet-500 group-hover:fill-white"
                              path={AddSVG}
                              svgClassName={`fill-violet-500`}
                            />
                            Add note
                          </button>
                        )}

                        {orderText?.show && (
                          <div className="m-auto">
                            <textarea
                              className={`PLACEHOLDER:truncate font-medium transition bg-gray-100 focus:border-indigo-500 focus:bg-violet-300/50 peer
                      border border-gray-300 whitespace-normal text-sm12 rounded w-full px-2 block text-violet-800 resize-none py-1`}
                              value={orderText?.note}
                              onChange={(e: any) => handleOrderText(e)}
                              placeholder={Messages?.orderNotePlaceholder}
                            ></textarea>
                            <button
                              className="mt-2 border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                              onClick={cancleOrderText}
                            >
                              Cancel
                            </button>

                            <button
                              onClick={(e: any) => addOrderText(e)}
                              className={`mt-2 group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false mx-2`}
                            >
                              Save
                            </button>
                          </div>
                        )}
                      </div>
                      {orderNotes?.map((note: any) => {
                        return (
                          <div className="note-block">
                            <div
                              className={`mb-4 ${
                                noteLoader ? `shimmer-effect` : ``
                              }`}
                            >
                              <span className="text-sm12 font-medium mb-1 block text-gray-400">
                                {note?.type === 1
                                  ? "Internal"
                                  : note?.type === 2
                                  ? "Penalty"
                                  : note?.type === 3
                                  ? "External"
                                  : note?.type === 4 && note?.user_name}
                                {" - "}
                                {moment(note?.date).format(
                                  "DD/MM/YYYY HH:mm"
                                )}{" "}
                              </span>
                              <div className="px-2 py-1 bg-gray-100 flex justify-between rounded whitespace-pre-line">
                                <p className="text-sm12 break-words overflow-hidden">
                                  {note?.note}
                                </p>
                                {note?.type !== 2 && (
                                  <span
                                    id={`note-${note?.index}`}
                                    data-tooltip-id={`note-${note?.index}`}
                                    className="mt-1 mr-0.5 cursor-pointer  transition right-0 top-0"
                                    onClick={(e: any) =>
                                      deleteOrderText({ index: note?.index })
                                    }
                                  >
                                    <TooltipPortal
                                      id={`note-${note?.index}`}
                                      content="Delete"
                                      className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium  text-xs z-[45] max-w-[9rem] cursor-default "
                                    />
                                    <IKTSVG
                                      className={`fill-violet-500 hover:fill-indigo-500 transition`}
                                      svgClassName="w-[.5rem] h-[.5rem]"
                                      path={CrossSVG}
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      
                    </div> */}
                  </div>
                </div>
              </>
            )}

            {/* NAVIGATION BUTTONS */}
            <div
              className={` bottom-0 right-0 w-full flex ${
                isGodAdmin ? "justify-between" : "justify-end"
              } px-5 py-3 bg-white z-10 border-t min-h-[3.1875rem]`}
            >
              <div className="btns flex flex-wrap items-center tx-pay-module gap-3.5">
                {isGodAdmin &&
                  (isAddressLock === false ||
                    isCustomerLock === false ||
                    isTicketLock === false ||
                    isStatusLock === false ||
                    isPenaltyLock === false) && (
                    <>
                      <button
                        type="button"
                        className="btn flex-1 text-sm13 font-medium py-1 px-2.5"
                        onClick={() => {
                          handleShow({
                            show: false,
                            data: "",
                          });
                          display(false);
                        }}
                      >
                        Cancel
                      </button>

                      <button
                        // type="button"
                        type="submit"
                        className={`${
                          updateLoader && "pointer-events-none !bg-gray-300"
                        } text-sm13 font-medium py-1 px-2.5 bg-green-600 hover:bg-indigo-500 !text-white rounded border-hidden	 hover:border-hidden	 flex`}
                        onClick={() => {}}
                      >
                        {updateLoader && (
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition animate-spin mr-2"
                            path={ProcessSVG}
                          />
                        )}
                        Update
                      </button>
                    </>
                  )}
              </div>

              <div>
                {/* {/ BACK BUTTON /} */}
                <NavigationButtons
                  id="back"
                  imgPath={angleLeftSVG}
                  className={
                    show?.index === 0 && orders?.length > 1 ? "" : show?.index
                  }
                  handleNavigation={(data: any) => handleNavigation(data)}
                  disabled={
                    (isGodAdmin &&
                      (isAddressLock === false ||
                        isCustomerLock === false ||
                        isTicketLock === false ||
                        isPenaltyLock === false)) ||
                    orders?.length === 1
                      ? true
                      : false
                  }
                />

                {/* {/ NEXT BUTTON /} */}
                <NavigationButtons
                  id="next"
                  imgPath={angleLeftSVG}
                  className={
                    show?.index === 0 ? "" : orders?.length - 1 === show?.index
                  }
                  handleNavigation={(data: any) => handleNavigation(data)}
                  disabled={
                    (isGodAdmin &&
                      (isAddressLock === false ||
                        isCustomerLock === false ||
                        isTicketLock === false ||
                        isPenaltyLock === false)) ||
                    orders?.length === 1
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default OrderDetail;
